import {useMutation, useQueryClient} from '@tanstack/react-query'
import {EditProcessPayload, ProcessRecord, httpEditProcess} from '../services/process.http'
import {QUERY_KEYS} from '@/queryClient'

type useEditProcessProps = {
    onError?: (e: unknown) => void
    onSuccess?: (data: ProcessRecord) => void
}

type MutationArgs = EditProcessPayload & {projectId: number; processId: number}

export const useEditProcess = ({onError, onSuccess}: useEditProcessProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({projectId, processId, ...payload}: MutationArgs) =>
            httpEditProcess(projectId, processId, payload),
        onError,
        onSuccess: (data, {projectId, processId}) => {
            queryClient.invalidateQueries([QUERY_KEYS.PROCESS, projectId, processId])
            onSuccess?.(data)
        }
    })
}
