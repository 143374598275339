import {StyledProjectsTableEmptyState} from '@/features/projects/components/project-table-empty-state/style.ts'
import {IconContainer} from '@components/ui/IconContainer'
import {FolderIcon, PlusIcon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import Button from '@components/ui/Button'
import toast from 'react-hot-toast'

export const ProjectsTableEmptyState = () => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <>
            <StyledProjectsTableEmptyState>
                <IconContainer>
                    <FolderIcon fill={theme.palette.neutral['700']} />
                </IconContainer>
                <hgroup>
                    <h3>{t('projects:table:empty_state_title')}</h3>
                    <h4>{t('projects:table:empty_state_subtitle')}</h4>
                </hgroup>
                <Button onClick={() => toast.success(t('projects:new_success'))}>
                    <PlusIcon />
                    {t('projects:new_project')}
                </Button>
            </StyledProjectsTableEmptyState>
        </>
    )
}

ProjectsTableEmptyState.displayName = 'ProjectsTableEmptyState'
