import axios from '@services/axiosInstance.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {Company} from '@/features/companies/types.ts'

export const httpGetCompanies = (): Promise<Company[]> => {
    return axios
        .get('companies') //
        .then(response => response.data)
        .catch(errorHandler)
}

export type httpGetCompaniesVendorsParams = {
    vendor_id?: number
    search?: string
    order_by?: string
    order_direction?: string
}

export const httpGetCompaniesVendors = (params?: httpGetCompaniesVendorsParams): Promise<Company[]> => {
    return axios
        .get('companies/vendors', {params}) //
        .then(response => response.data)
        .catch(errorHandler)
}

export const httpGetCompanyVendorById = (vendorId: number): Promise<Company> => {
    return axios
        .get(`companies/vendors/${vendorId}`)
        .then(response => response.data)
        .catch(errorHandler)
}

type HttpUpdateCompanyPayload = {
    id: number
    data: Partial<Company>
}
export const httpUpdateCompany = ({id, data}: HttpUpdateCompanyPayload) => {
    return axios.patch(`companies/${id}`, data)
}

export const httpGetCompany = (id: number) => {
    return axios.get(`companies/${id}`).then(res => res.data as Company)
}
