import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpEditProjectBatch} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type UseEditProjectBatchProps = {
    onSuccess: () => void
    onError: () => void
}

export const useEditProjectBatch = ({onError, onSuccess}: UseEditProjectBatchProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: httpEditProjectBatch,
        onError,
        onSuccess: () => {
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES])
            queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_BATCHES])
            onSuccess?.()
        }
    })
}
