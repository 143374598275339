import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/IconContainer'
import Button from '@components/ui/Button'
import {StyledContainer, StyledErrorWrapper} from '@components/commons/ErrorBox/style.ts'
import {AlertCircleIcon} from '@components/ui/Icon'

export const ErrorBox = () => {
    const {t} = useTranslation()

    return (
        <StyledContainer fullHeight>
            <StyledErrorWrapper>
                <IconContainer>
                    <AlertCircleIcon />
                </IconContainer>
                <h1>{t('errors:default')}</h1>
                <Button variant="primary" onClick={() => window.location.reload()}>
                    {t('commons:reload_the_page')}
                </Button>
            </StyledErrorWrapper>
        </StyledContainer>
    )
}

ErrorBox.displayName = 'ErrorBox'
