import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {z} from 'zod'

const PerformanceStatsSearchParamsSchema = z.object({
    range_type: z
        .enum(['custom', 'this_week', 'this_month', 'this_year', 'last_week', 'last_month'])
        .optional()
        .default('last_week'),
    start_date: z.coerce.date().nullable().default(null),
    end_date: z.coerce.date().nullable().default(null),
    metric: z.enum(['efficiency', 'quality', 'throughput']).optional().default('efficiency')
})

export type PerformanceStatsSearchParamsSchema = z.infer<typeof PerformanceStatsSearchParamsSchema>

export const usePerformanceStatsSearchParams = () => {
    return useParsedSearchParams([], PerformanceStatsSearchParamsSchema)
}
