import {useAuth} from '@/context/AuthProvider'
import {statsProjectSectionViewBySlug} from '@/features/roles/helpers'
import {useMemo} from 'react'
import {ProjectDetailsStatsWrapper} from './style'

export const ProjectStats = () => {
    const {user} = useAuth()
    const statsRenderer = useMemo(() => statsProjectSectionViewBySlug(user?.role.slug), [user])

    return <ProjectDetailsStatsWrapper>{statsRenderer}</ProjectDetailsStatsWrapper>
}

ProjectStats.displayName = 'ProjectStats'
