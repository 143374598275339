import {ReactNode} from 'react'
import {palette} from '@/theme/palette.ts'
import {StyledBadge} from '@components/ui/Badge/style.ts'
import {DefaultTFuncReturn} from 'i18next'

interface BadgeProps {
    label: string | DefaultTFuncReturn
    icon?: ReactNode
    size?: 'sm' | 'md' | 'lg'
    border?: 'none' | 'small' | 'medium' | 'large'
    background?: string
    color?: string
    borderColor?: string
}

export const Badge = ({
    label,
    icon,
    size = 'sm',
    border = 'large',
    background = palette.light.success['50'],
    color = palette.light.success['700'],
    borderColor = palette.light.success['200']
}: BadgeProps) => (
    <StyledBadge size={size} border={border} background={background} color={color} borderColor={borderColor}>
        <>
            {icon && icon}
            {label}
        </>
    </StyledBadge>
)

Badge.displayName = 'Badge'
