import {FC} from 'react'
import {StyledModal} from './style'
import {FolderIcon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {Flexbox} from '@/components/ui/Flexbox'

type EmptyDatasourceModalProps = {
    open: boolean
    setOpen: (bool: boolean) => void
}

export const EmptyDatasourceModal: FC<EmptyDatasourceModalProps> = ({open, setOpen}) => {
    const {t} = useTranslation()
    if (open) {
        return (
            <StyledModal onClose={() => setOpen(false)}>
                <div className="header">
                    <div className="icon-container">
                        <FolderIcon size={24} />
                    </div>
                    <div className="text-container">
                        <h2>{t('projectDetails:batches:no_data_source')}</h2>
                        <p>{t('projectDetails:batches:no_data_source_helper')}</p>
                    </div>
                </div>
                <Flexbox align={'center'} justify={'end'}>
                    <Button size="lg" onClick={() => setOpen(false)}>
                        {t('commons:close')}
                    </Button>
                </Flexbox>
            </StyledModal>
        )
    }

    return null
}
