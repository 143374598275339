import {Modal} from '@/components/ui/Modal'
import styled, {css} from 'styled-components'

export const StyledModal = styled(Modal)`
    ${({theme: {spacing, palette, typography}}) => css`
        width: 480px;

        h2 {
            font-size: ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }

        h3 {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }

        .modal-header {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 8}px;
        }

        .modal-footer {
            border-top: 1px solid ${palette.neutral[200]};
            padding: ${spacing * 6}px;
        }

        .radio-label {
            font-weight: 500;
            font-size: ${typography.textSm};
            color: ${palette.neutral[700]};
        }

        .radio-label-subtext {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }
    `}
`
