import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useMemo} from 'react'
import {useProject} from '@/features/projects/queries/useProject.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import Button from '@components/ui/Button'
import {PauseCircleIcon, PlayIcon} from '@components/ui/Icon'
import {CAN_WRITE_PROJECT} from '@utilities/constants/permissions.ts'
import toast from 'react-hot-toast'
import {useUpdateProject} from '../../queries/useUpdateProject'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'

export const StartProjectToggleCta = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const {id: projectId} = useParams()
    if (!projectId) {
        throw new Error('Missing param :id')
    }
    const {data: project} = useProject(projectId)
    const queryClient = useQueryClient()

    const updateProjectMutation = useUpdateProject({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES, Number(projectId)])
            queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_BATCHES])
        }
    })
    const canEditProject = useMemo(() => user?.permissions.includes(CAN_WRITE_PROJECT), [user])

    const handleClick = () => {
        if (project?.status === 'in_progress') {
            updateProjectMutation.mutate({
                id: Number(projectId),
                data: {
                    status: 'paused'
                }
            })
            return
        }

        if (project?.status === 'paused') {
            updateProjectMutation.mutate({
                id: Number(projectId),
                data: {
                    status: 'in_progress'
                }
            })
            return
        }
    }

    return (
        <>
            {canEditProject && (
                <Button
                    variant={'primary'}
                    onClick={handleClick}
                    disabled={!canEditProject || (project?.status !== 'in_progress' && project?.status !== 'paused')}
                >
                    {project?.status !== 'in_progress' ? (
                        <>
                            <PlayIcon />
                            {t('projectDetails:start')}
                        </>
                    ) : (
                        <>
                            <PauseCircleIcon />
                            {t('projectDetails:pause')}
                        </>
                    )}
                </Button>
            )}
        </>
    )
}

StartProjectToggleCta.displayName = 'StartProjectToggleCta'
