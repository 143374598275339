import {ComponentProps, FC} from 'react'
import {TasksStats} from '.'
import {useTasksStatsSearchParams} from '../../hooks/useTasksStatsSearchParams'

export const TasksStatsWithParams: FC<
    Omit<ComponentProps<typeof TasksStats>, 'onTaskTypeClick' | 'taskType'>
> = props => {
    const {searchParams: tasksSearchParams, setSearchParams: setTasksSearchParams} = useTasksStatsSearchParams()

    return (
        <TasksStats
            {...props}
            onTaskTypeClick={taskType => setTasksSearchParams({task_type: taskType})}
            taskType={tasksSearchParams.task_type ?? 'all'}
        />
    )
}
