import {useMutation} from '@tanstack/react-query'
import {CreateProcessStepPayload, ProcessRecord, httpCreateProcessStep} from '../services/process.http'

type useCreateProcessStepProps = {
    onError?: (e: unknown) => void
    onSuccess?: (data: ProcessRecord) => void
}

type MutationArgs = CreateProcessStepPayload & {projectId: number}

export const useCreateProcessStep = ({onError, onSuccess}: useCreateProcessStepProps) => {
    return useMutation({
        mutationFn: ({projectId, ...payload}: MutationArgs) => httpCreateProcessStep(projectId, payload),
        onError,
        onSuccess
    })
}
