import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetProjectById, httpGetSubProjectById} from '@/features/projects/services/projects.http.ts'
import {ProjectRecord} from '../types'
import {useAuth} from '@context/AuthProvider.tsx'

type useProjectOptions = Omit<
    UseQueryOptions<ProjectRecord, unknown, ProjectRecord, (string | number)[]>,
    'initialData' | 'queryKey' | 'queryFn'
>

export const useProject = (id?: string | number, options?: useProjectOptions) => {
    const {user} = useAuth()
    if (!id) {
        throw new Error('id is required')
    }
    return useQuery({
        queryKey: [QUERY_KEYS.PROJECT_BY_ID, id],
        queryFn: () => (user?.role.slug === 'vendor_manager' ? httpGetSubProjectById(id) : httpGetProjectById(id)),
        ...options
    })
}
