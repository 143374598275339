import {useQuery} from '@tanstack/react-query'
import {HttpgGetBudgetStatsParams, httpgGetBudgetStats} from '../services/http'
import {QUERY_KEYS} from '@/queryClient'

type UseBudgetStatsProps = {
    enabled: boolean
}

export const useBudgetStats = (params?: HttpgGetBudgetStatsParams, options?: UseBudgetStatsProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.BUDGET_STATS, params],
        queryFn: () => httpgGetBudgetStats(params),
        ...options
    })
}
