import {Flexbox} from '@/components/ui/Flexbox'
import {StyledModal} from './style'
import {AlertCircleIcon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {FC} from 'react'
import toast from 'react-hot-toast'
import {useUnassignBatch} from '../../queries/useUnassignBatch'
import {useBulkUnassignBatches} from '../../queries/useBulkUnassignBatches'

type BatchesUnassignModalProps = {
    batchId: number | number[]
    onClose: () => void
    onSuccess?: () => void
}

export const BatchesUnassignModal: FC<BatchesUnassignModalProps> = ({batchId, onClose, onSuccess}) => {
    const {t} = useTranslation()

    const {mutate: unassignBatch, isLoading: isLoadingUnassignBatch} = useUnassignBatch({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onSuccess?.()
            onClose()
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {mutate: bulkUnassignBatches, isLoading: isLoadingBulkUnassignBatches} = useBulkUnassignBatches({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onSuccess?.()
            onClose()
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const onSubmit = () => {
        if (batchId instanceof Array) {
            bulkUnassignBatches({ids: batchId})
            return
        }
        unassignBatch({batchId})
    }

    return (
        <StyledModal onClose={onClose}>
            <Flexbox gap={4} className="modal-header">
                <div className="icon-container">
                    <AlertCircleIcon size={24} />
                </div>
                <Flexbox direction="column" gap={1}>
                    <h2>{t('batches:unassign_batch_title', {count: batchId instanceof Array ? batchId.length : 1})}</h2>
                    <h3>
                        {t('batches:unassign_batch_modal_title', {
                            count: batchId instanceof Array ? batchId.length : 1
                        })}
                    </h3>
                </Flexbox>
            </Flexbox>
            <Flexbox justify="end" gap={3} className="modal-footer">
                <Button variant="secondary" size="lg" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button disabled={isLoadingUnassignBatch || isLoadingBulkUnassignBatches} size="lg" onClick={onSubmit}>
                    {t('commons:unassign')}
                </Button>
            </Flexbox>
        </StyledModal>
    )
}
