import {useMutation, useQueryClient} from '@tanstack/react-query'
import {ConfirmProcessPayload, httpConfirmProcess} from '../services/process.http'
import {QUERY_KEYS} from '@/queryClient.ts'

type useConfirmProcessProps = {
    onError?: () => void
    onSuccess?: () => void
}

type MutationArgs = ConfirmProcessPayload & {projectId: number}

export const useConfirmProcess = ({onError, onSuccess}: useConfirmProcessProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({projectId, ...payload}: MutationArgs) => httpConfirmProcess(projectId, payload),
        onError,
        onSuccess: (_, variables) => {
            onSuccess?.()
            queryClient.invalidateQueries([QUERY_KEYS.PROCESS, variables.projectId])
            queryClient.invalidateQueries([QUERY_KEYS.PROCESS_EDGES, variables.projectId])
        }
    })
}
