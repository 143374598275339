import {z} from 'zod'
import {User} from '@/types/auth.ts'
import {BatchRecord} from '@/features/batches/types.ts'
import {UserSession} from '@/features/users/types.ts'

export const CompletedTasksSearchParams = z.object({
    orderBy: z.enum(['modality', 'modality_type', 'task_type', 'claimed_at', 'finished_at']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional()
})
export type CompletedTasksSearchParams = z.infer<typeof CompletedTasksSearchParams>

export type TaskStatus = 'pending' | 'ongoing' | 'completed'
export type TaskType = 'Edit' | 'QA'

export type Task = {
    batch_id: number
    claimed_at: string | null
    created_at: string | null
    deleted_at: string | null
    estimated_time: number | null
    finished_at: string | null
    id: number
    is_clone: 0 | 1
    media_id: number | null
    process_flow_id: number | null
    refers_to_task_id: number | null
    status: TaskStatus
    subbatch_id: number | null
    task_type_id: number | null
    type: TaskType | null
    updated_at: string
    user_id: number
}

export type TaskExtended = {
    batch: BatchRecord
    batch_id: number
    claimed_at: string | null
    created_at: string | null
    deleted_at: string | null
    estimated_time: number | null
    finished_at: string | null
    id: number
    instructions_url: string
    label_studio_url: string
    is_clone: 0 | 1
    media: {
        id: number
        url: string
        media_type: string
        data_service_id: number
        created_at: string
        updated_at: string
        deleted_at: string | null
        original_key: string
    }
    media_id: number | null
    process: {
        id: number
        project_id: number
        process_type_id: number
        order: number
        template_values: {
            workflow: {
                task_types_id: number[]
                confidence_rate: number
                estimated_time_qa: number
                confidence_interval: number
                estimated_time_edit: number
            }
            objective: string
            taxonomy_id: number
            data_source_id: number
            data_target_id: number
        }
        created_at: string
        updated_at: string
        deleted_at: string | null
        process_template_config_id: number
        x_coordinate: number | null
        y_coordinate: number | null
        workflow_id: number
        is_temporary: 0 | 1
        override_options: 0 | 1
    }
    process_flow_id: number | null
    refers_to_task_id: number | null
    status: TaskStatus
    subbatch_id: number | null
    task_type: {
        id: number
        name: TaskType | null
        description: string | null
        created_at: string | null
        updated_at: string | null
        deleted_at: string | null
    }
    task_type_id: number | null
    type: TaskType | null
    updated_at: string
    user: User
    user_id: number
    userSession: UserSession
} & Task
