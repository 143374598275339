import styled, {css, DefaultTheme} from 'styled-components'
import {NavLink} from 'react-router-dom'

type StyledNavLinkProps = {
    disabled?: boolean
    size: 'sm' | 'md' | 'lg'
    variant: 'ghost'
}
export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        width: auto;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 500;
        text-transform: capitalize;
        & svg {
            width: 20px;
        }
    `}
    ${({variant, disabled, theme}) => ColorStyles(theme, disabled)[variant]};
    ${({size, theme}) => SizeStyles(theme)[size]};
`

const ColorStyles = ({palette}: DefaultTheme, disabled?: boolean) => ({
    ghost: css`
        background: transparent;
        color: ${palette.neutral['700']};

        ${disabled
            ? css`
                  background: ${palette.neutral.white};
                  color: ${palette.neutral['300']};
                  pointer-events: none;
              `
            : css`
                  &:hover {
                      background: ${palette.neutral['50']};
                  }

                  &.active {
                      background: ${palette.neutral['50']};
                      font-weight: 600;
                  }
              `}
    `
})

const SizeStyles = ({spacing, typography}: DefaultTheme) => ({
    sm: css`
        height: 36px;
        padding: ${spacing * 4}px ${spacing * 3.5}px;
        ${typography.textSm}
    `,
    md: css`
        height: 44px;
        padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        ${typography.textMd}
    `,
    lg: css`
        height: 60px;
        padding: ${spacing * 4}px ${spacing * 7}px;
        ${typography.textLg}
    `
})
