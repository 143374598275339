import {
    routes,
    PROJECTS_HEADER_OBJ,
    STATS_HEADER_OBJ,
    TASK_HEADER_OBJ,
    TAXONOMIES_HEADER_OBJ,
    USERS_HEADER_OBJ,
    PROD_MANAGEMENT_HEADER_OBJ
} from '@utilities/constants'
import {Slug, UserRole} from '@/types/auth.ts'
import Spinner from '@components/ui/Spinner'
import {palette} from '@/theme/palette.ts'
import {VendorAdminSettings} from '@/features/settings/components/vendor-admin-settings/VendorAdminSettings.tsx'
import {OperatorSettings} from '@/features/settings/components/operator-settings/OperatorSettings.tsx'
import {Dispatch, SetStateAction} from 'react'
import {ProjectRecord} from '@/features/projects/types.ts'
import {ProgramManagerView} from '@/features/projects/components/projects-table-view-by-role/ProgramManagerView.tsx'
import {ClientEngineerView} from '@/features/projects/components/projects-table-view-by-role/ClientEngineerView.tsx'
import {VendorManagerView} from '@/features/projects/components/projects-table-view-by-role/VendorManagerView.tsx'
import {BatchesProgramManagerProdView} from '@/features/batches/components/BatchesTableViews/BatchesProgramManagerProdView.tsx'
import {BatchesVendorManagerProdView} from '@/features/batches/components/BatchesTableViews/BatchesVendorManagerProdView.tsx'
import {BatchesTeamLeadProdView} from '@/features/batches/components/BatchesTableViews/BatchesTeamLeadProdView.tsx'
import {BatchesProgramManagerProjectView} from '@/features/batches/components/BatchesTableViews/BatchesProgramManagerProjectView.tsx'
import {BatchesVendorManagerProjectView} from '@/features/batches/components/BatchesTableViews/BatchesVendorManagerProjectView.tsx'
import {ProjectStats} from '../analytics/views/project-view'
import {SubprojectStats} from '../analytics/views/subproject-view'
import {SubbatchStats} from '../analytics/views/subbatch-view'
import {generatePath} from 'react-router-dom'

/**
 * Home page role based redirect
 * @param accessToken
 * @param slug
 */
export const roleBasedRedirect = (accessToken: string | undefined, slug: Slug | undefined | null) => {
    if (!accessToken) {
        return routes.LOGIN.path
    }

    switch (slug) {
        case 'company_admin':
            return routes.USERS.path
        case 'vendor_admin':
            return routes.USERS.path
        case 'program_manager':
            return PROJECTS_HEADER_OBJ.path
        case 'client_engineer':
            return PROJECTS_HEADER_OBJ.path
        case 'vendor_manager':
            return PROJECTS_HEADER_OBJ.path
        case 'team_lead':
            return routes.PRODUCTION_MANAGEMENT.path
        case 'labeler':
            return TASK_HEADER_OBJ.path
        default:
            return '/' //shows 404
    }
}

/**
 * Project Details page role based redirect
 * @param slug
 * @param projectId
 */
export function projectDetailsRoleBasedRedirect(slug: Slug | undefined, projectId: number) {
    switch (slug) {
        case 'program_manager':
            return generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_PROPERTIES.path}`, {
                id: projectId
            })
        case 'client_engineer':
            return generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_STATS.path}`, {id: projectId})
        case 'vendor_manager':
            return generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_BATCHES.path}`, {id: projectId})
        default:
            return generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_STATS.path}`, {id: projectId})
    }
}

/**
 * rolesWithSettings const
 */
export const rolesWithSettings: Slug[] = ['vendor_admin', 'labeler']

/**
 * Utils that help us to render the right headers items based on the slug
 */
type HeaderObj = {
    name: string
    path: string
}
export const headerItemsBySlug = (slug: Slug): HeaderObj[] => {
    switch (slug) {
        case 'company_admin':
        case 'vendor_admin':
            return [USERS_HEADER_OBJ]
        case 'program_manager':
            return [PROJECTS_HEADER_OBJ, PROD_MANAGEMENT_HEADER_OBJ, TAXONOMIES_HEADER_OBJ, STATS_HEADER_OBJ]
        case 'client_engineer':
            return [PROJECTS_HEADER_OBJ, TAXONOMIES_HEADER_OBJ, STATS_HEADER_OBJ]
        case 'vendor_manager':
            return [PROJECTS_HEADER_OBJ, PROD_MANAGEMENT_HEADER_OBJ, STATS_HEADER_OBJ, USERS_HEADER_OBJ]
        case 'team_lead':
            return [PROD_MANAGEMENT_HEADER_OBJ, STATS_HEADER_OBJ, USERS_HEADER_OBJ]
        case 'labeler':
            return [TASK_HEADER_OBJ]
        default:
            return []
    }
}

/**
 * Utils that help us to render the right settings component based on the slug
 */
export const settingsFormRendererBySlug = (slug: Slug | undefined) => {
    switch (slug) {
        case 'vendor_admin':
            return <VendorAdminSettings />
        case 'labeler':
            return <OperatorSettings />
        default:
            return <Spinner size={24} color={palette.light.neutral[300]} /> //todo: 404
    }
}

/**
 * Utils that help us to render the right projects table component based on the slug
 */
export const projectsTableViewRendererBySlug = (
    slug: Slug | undefined,
    setProjectToCloneCb: Dispatch<SetStateAction<ProjectRecord | null>>,
    setProjectToDeleteCb: Dispatch<SetStateAction<ProjectRecord | null>>,
    onClearSearch: () => void
) => {
    switch (slug) {
        case 'program_manager':
            return (
                <ProgramManagerView
                    setProjectToCloneCb={setProjectToCloneCb}
                    setProjectToDeleteCb={setProjectToDeleteCb}
                    onClearSearchCb={onClearSearch}
                />
            )
        case 'client_engineer':
            return <ClientEngineerView onClearSearchCb={onClearSearch} />
        case 'vendor_manager':
            return <VendorManagerView onClearSearchCb={onClearSearch} />
        default:
            return <Spinner size={24} color={palette.light.neutral[300]} />
    }
}

/**
 * Utils that help us to render the right batches table component
 * in the production management section based on the user slug
 */
export const batchesProdTableViewBySlug = (slug: Slug | undefined) => {
    switch (slug) {
        case 'program_manager':
            return <BatchesProgramManagerProdView />
        case 'vendor_manager':
            return <BatchesVendorManagerProdView />
        case 'team_lead':
            return <BatchesTeamLeadProdView />
        default:
            return <Spinner size={24} color={palette.light.neutral[300]} />
    }
}

/**
 * Utils that help us to render the right batches table component in the Project details
 * under the batches section based on the user slug
 */
export const batchesProjectSectionTableViewBySlug = (slug: Slug | undefined) => {
    switch (slug) {
        case 'program_manager':
            return <BatchesProgramManagerProjectView />
        case 'vendor_manager':
            return <BatchesVendorManagerProjectView />
        default:
            return <Spinner size={24} color={palette.light.neutral[300]} />
    }
}

/**
 * Utils that help us to render the right stats component in the Project details
 * under the batches section based on the user slug
 */
export const statsProjectSectionViewBySlug = (slug: Slug | undefined) => {
    switch (slug) {
        case 'program_manager':
            return <ProjectStats />
        case 'vendor_manager':
            return <SubprojectStats />
        case 'client_engineer':
            return <ProjectStats />
        default:
            return <Spinner size={24} color={palette.light.neutral[300]} />
    }
}

/**
 * Utils that help us to render the right stats component in the Batch details
 */
export const statsBatchSectionViewBySlug = (slug: Slug | undefined) => {
    switch (slug) {
        case 'vendor_manager':
            return <SubbatchStats />

        case 'team_lead':
            return <SubbatchStats />

        default:
            return <Spinner size={24} color={palette.light.neutral[300]} />
    }
}

/**
 * projectTabsRoleBased
 * @param slug
 */
export type ProjectTab = {label: string; path: string}
export const projectTabsRoleBased = (slug: Slug | undefined): ProjectTab[] => {
    switch (slug) {
        case 'client_engineer':
            return [{label: 'projectDetails:tabs:stats', path: routes.PROJECT_DETAIL_STATS.path}]
        case 'vendor_manager':
            return [
                {label: 'projectDetails:tabs:batches', path: routes.PROJECT_DETAIL_BATCHES.path},
                {label: 'projectDetails:tabs:stats', path: routes.PROJECT_DETAIL_STATS.path},
                {label: 'projectDetails:tabs:operators', path: routes.PROJECT_DETAIL_OPERATORS.path}
            ]
        case 'team_lead':
            return [
                {label: 'projectDetails:tabs:stats', path: routes.PROJECT_DETAIL_STATS.path},
                {label: 'projectDetails:tabs:operators', path: routes.PROJECT_DETAIL_OPERATORS.path}
            ]
        default:
            return [
                {label: 'projectDetails:tabs:properties', path: routes.PROJECT_DETAIL_PROPERTIES.path},
                {label: 'projectDetails:tabs:process', path: routes.PROJECT_DETAIL_PROCESS.path},
                {label: 'projectDetails:tabs:vendors', path: routes.PROJECT_DETAIL_VENDORS.path},
                {label: 'projectDetails:tabs:batches', path: routes.PROJECT_DETAIL_BATCHES.path},
                {label: 'projectDetails:tabs:stats', path: routes.PROJECT_DETAIL_STATS.path},
                {label: 'projectDetails:tabs:settings', path: routes.PROJECT_DETAIL_SETTINGS.path}
            ]
    }
}

export const roleSlugToLabel = {
    company_admin: 'users:roles:company_admin',
    vendor_admin: 'users:roles:vendor_admin',
    program_manager: 'users:roles:program_manager',
    client_engineer: 'users:roles:client_engineer',
    vendor_manager: 'users:roles:vendor_manager',
    team_lead: 'users:roles:team_lead',
    labeler: 'users:roles:labeler'
} as const satisfies Record<Slug, string>

export const roleSlugToID = (roles: UserRole[], slug: Slug) => {
    const roleID = roles.find(role => role.slug == slug)?.id
    if (!roleID) {
        throw new Error(`Missing role ID for '${slug}'`)
    }
    return roleID ?? 0
}

type UsersTab = {
    label: string
    path: string
}
export const usersTabs = {
    program_manager: {label: roleSlugToLabel['program_manager'], path: 'program-manager'},
    client_engineer: {label: roleSlugToLabel['client_engineer'], path: 'client-engineer'},
    vendor_admin: {label: roleSlugToLabel['vendor_admin'], path: 'vendor-admin'},
    vendor_manager: {label: roleSlugToLabel['vendor_manager'], path: 'vendor-manager'},
    team_lead: {label: roleSlugToLabel['team_lead'], path: 'team-lead'},
    labeler: {label: roleSlugToLabel['labeler'], path: 'operators'}
} as const satisfies Partial<Record<Slug, UsersTab>>

export const roleSlugToUsersTabs = {
    company_admin: [
        usersTabs['program_manager'],
        usersTabs['client_engineer'],
        usersTabs['vendor_admin'],
        usersTabs['vendor_manager'],
        usersTabs['team_lead'],
        usersTabs['labeler']
    ],
    program_manager: [],
    client_engineer: [],
    vendor_admin: [usersTabs['vendor_manager'], usersTabs['team_lead'], usersTabs['labeler']],
    vendor_manager: [usersTabs['team_lead'], usersTabs['labeler']],
    team_lead: [usersTabs['labeler']],
    labeler: []
} as const satisfies Record<Slug, Readonly<UsersTab[]>>

/**
 * Util that help us to check if the user can see the current route (tab).
 * If the user cannot see it, the util returns the redirect path
 */
export const checkUsersRouteRedirect = (locationPath: string, roleSlug: Slug | undefined) => {
    //check if this role can see any users tab, else return the home path
    if (roleSlug && roleSlugToUsersTabs[roleSlug].length >= 1) {
        //if this role can see the current tab return null (it means no redirects), else return the first available tab for this role
        return roleSlugToUsersTabs[roleSlug].some(tab => locationPath.endsWith(tab.path))
            ? null
            : roleSlugToUsersTabs[roleSlug][0]?.path
    } else {
        return routes.HOME.path
    }
}
