import {StatsLoader} from '@/features/analytics/components/stats-loader'
import {TasksAverageCharts} from '@/features/analytics/components/task-average-charts'
import {DATE_RANGES} from '@/features/analytics/constants'
import {dateRangeTypeToDateRange} from '@/features/analytics/helpers'
import {usePerformanceStatsSearchParams} from '@/features/analytics/hooks/usePerformanceSearchParams'
import {useTasksAverage} from '@/features/analytics/queries/useTasksAverage'
import {FC, useMemo} from 'react'
import {ChartProps} from '../types'

export const EfficiencyCharts: FC<ChartProps> = ({projectId, subprojectId, subbatchId}) => {
    const {
        searchParams: {range_type, start_date, end_date}
    } = usePerformanceStatsSearchParams()

    const params = useMemo(() => {
        return {
            ...(range_type === DATE_RANGES.CUSTOM && start_date && end_date
                ? {start_date, end_date}
                : dateRangeTypeToDateRange(range_type))
        }
    }, [range_type, start_date, end_date])

    const {data, isLoading, error, isSuccess} = useTasksAverage({
        ...params,
        ...(projectId && {project_id: projectId}),
        ...(subprojectId && {subproject_id: subprojectId}),
        ...(subbatchId && {subbatch_idb: subbatchId})
    })

    if (error) {
        throw error
    }

    if (isLoading || !isSuccess) {
        return <StatsLoader />
    }
    return <TasksAverageCharts edit={data.edit} qa={data.qa} />
}
