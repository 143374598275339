import {ProjectStructure} from '@/features/projects/types'
import axios from '@services/axiosInstance.ts'

type ProcessTemplateValues = {
    workflow: {
        task_types_id: number[]
        confidence_interval: number | null
        confidence_rate: number | null
        estimated_time_qa: number | null
        estimated_time_edit: number | null
    }
}

export type CreateProcessStepPayload = {
    process_type_id: number
    override_options: boolean
    template_values: ProcessTemplateValues
}

export type ProcessRecord = {
    id: number
    process_type_id: number
    override_options: boolean
    template_values: ProcessTemplateValues
}

export const httpCreateProcessStep = (projectId: number, payload: CreateProcessStepPayload) => {
    return axios.post(`/projects/${projectId}/processes`, payload).then(res => res.data as ProcessRecord)
}

export type EditProcessPayload = Partial<CreateProcessStepPayload>

export const httpEditProcess = (projectId: number, processId: number, payload: Partial<CreateProcessStepPayload>) => {
    return axios.patch(`/projects/${projectId}/processes/${processId}`, payload).then(res => res.data as ProcessRecord)
}

export type ConfirmProcessPayload = {
    nodes: number[]
    edges: {source: number; target: number}[]
}

export const httpConfirmProcess = (projectId: number, payload: ConfirmProcessPayload) => {
    return axios.post(`/projects/${projectId}/processes/confirm`, payload).then(res => res.data)
}

export type httpGetProcessesResponse = ProcessRecord[]

export const httpGetProcesses = (projectId: number) => {
    return axios.get(`projects/${projectId}/processes`).then(res => res.data as httpGetProcessesResponse)
}

export const httpGetProcessStructure = (projectId: number, processId: number) => {
    return axios
        .get(`/projects/${projectId}/processes/${processId}/structure`)
        .then(res => res.data as ProjectStructure)
}

export type httpGetProcessesEdgesResponse = {from_process_id: number; to_process_id: number}[]

export const httpGetProcessesEdges = (projectId: number) => {
    return axios.get(`projects/${projectId}/processes/edges`).then(res => res.data as httpGetProcessesEdgesResponse)
}

export const httpGetProcess = (projectId: number, processId: number) => {
    return axios.get(`projects/${projectId}/processes/${processId}`).then(res => res.data as ProcessRecord)
}
