import styled, {css} from 'styled-components'
import {Modal} from '@components/ui/Modal'

export const StyledManageUsersModal = styled(Modal)(
    ({theme: {spacing, typography, palette}}) => css`
        & .header {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 5}px;
            h2 {
                ${typography.textLg};
                font-weight: 600;
                color: ${palette.neutral['900']};
            }
            h3 {
                ${typography.textSm};
            }
        }
    `
)
