import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {httpActivateTaxonomy} from '@/features/taxonomies/services/taxonomies.http.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {GetTaxonomiesResponse, TaxonomyRecord} from '@/features/taxonomies/types.ts'

type QueryData = {pages?: GetTaxonomiesResponse[]; pageParams?: unknown[]}
interface useActivateTaxonomyProps {
    onSuccess: () => void
}

export const useActivateTaxonomy = ({onSuccess}: useActivateTaxonomyProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.ACTIVATE_TAXONOMY],
        mutationFn: httpActivateTaxonomy,
        onMutate: async (deactivatedTaxonomy: TaxonomyRecord) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.TAXONOMIES]})
            //todo: UnOptimistically update our data HERE
            queryClient.setQueriesData([QUERY_KEYS.TAXONOMIES], (oldData?: QueryData) => ({
                ...oldData,
                pages: oldData?.pages?.map(page => ({
                    ...page,
                    data: page.data?.filter(taxonomy => taxonomy.id !== deactivatedTaxonomy.id)
                }))
            }))
        },
        onSuccess: () => onSuccess(),
        onError: errorHandler,
        // refetch
        onSettled: (_1, _2, variables) => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TAXONOMIES]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TAXONOMIES, variables.id]})
        }
    })
}
