import {Flexbox} from '@/components/ui/Flexbox'
import {StyledStatCard, StyledStatHeading, StyledStatLabel, StyledStatValue} from '../style'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'

type StatsOverviewProps = {
    budget?: number
    usedBudget: number
    turnAroundTime: number
    showTotalBudget?: boolean
}

export const StatsOverview: FC<StatsOverviewProps> = ({budget, usedBudget, turnAroundTime, showTotalBudget}) => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={4} width={'100%'}>
            <StyledStatHeading>{t('stats:overview')}</StyledStatHeading>
            <Flexbox gap={8} width={'100%'}>
                <StyledStatCard>
                    <Flexbox width={'100%'}>
                        {showTotalBudget && (
                            <Flexbox direction="column" width={'100%'}>
                                <StyledStatLabel>{t('stats:total_budget')}</StyledStatLabel>
                                <StyledStatValue>{t('stats:usd', {dollars: budget})}</StyledStatValue>
                            </Flexbox>
                        )}
                        <Flexbox direction="column" width={'100%'}>
                            <StyledStatLabel>{t('stats:used_budget')}</StyledStatLabel>
                            <StyledStatValue>{t('stats:usd', {dollars: usedBudget})}</StyledStatValue>
                        </Flexbox>
                    </Flexbox>
                </StyledStatCard>
                <StyledStatCard>
                    <Flexbox direction="column" width={'100%'}>
                        <StyledStatLabel>{t('stats:turn_around_time')}</StyledStatLabel>
                        <StyledStatValue>{t('stats:h', {hours: turnAroundTime})}</StyledStatValue>
                    </Flexbox>
                </StyledStatCard>
            </Flexbox>
        </Flexbox>
    )
}
