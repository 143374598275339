import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpUpdateCompany} from '../services/companies.http'
import {QUERY_KEYS} from '@/queryClient'

type useUpdateCompanyProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useUpdateCompany = (options?: useUpdateCompanyProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: httpUpdateCompany,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries([QUERY_KEYS.COMPANIES])
            queryClient.invalidateQueries([QUERY_KEYS.COMPANY, variables.id])
            options?.onSuccess?.()
        },
        onError: options?.onError
    })
}
