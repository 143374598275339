import {Outlet, useParams} from 'react-router-dom'
import Divider from '@components/ui/Divider'
import {StyledNavLink, TabsWrapper} from '@pages/users/style.ts'
import {useTranslation} from 'react-i18next'
import {routes} from '@utilities/constants'
import {StyledContainer, StyledProductionBatchDetailsGrid} from '@pages/production-batch-details/style.ts'
import {BatchDetailsHeader} from '@/features/batches/components/BatchDetailsHeader/BatchDetailsHeader.tsx'

export const ProductionBatchDetails = () => {
    const {t} = useTranslation()
    const {batchId} = useParams()
    console.log(batchId)
    //const {data: project} = useProject(id)
    const tabs = [
        {label: t('projectDetails:tabs:stats'), path: routes.PROJECT_DETAIL_BATCH_STATS.path},
        {label: t('commons:late_operators'), path: routes.PROJECT_DETAIL_BATCH_OPERATORS.path}
    ]

    return (
        <StyledContainer fullHeight>
            <BatchDetailsHeader />
            <Divider topSpacing={0} bottomSpacing={0} />
            <StyledProductionBatchDetailsGrid>
                <TabsWrapper>
                    {tabs.map(tab => (
                        <StyledNavLink to={tab.path} key={tab.path}>
                            {tab.label}
                        </StyledNavLink>
                    ))}
                </TabsWrapper>
                <Outlet />
            </StyledProductionBatchDetailsGrid>
        </StyledContainer>
    )
}

ProductionBatchDetails.displayName = 'ProductionBatchDetails'
