import {useCallback} from 'react'
import {useReactFlow} from 'reactflow'
import {GraphNode} from '../types'
import {NODE_HEIGHT, NODE_WIDTH} from '../components/nodes/constants'
import {useGraphContext} from '../context/graphContext'

export const useCenterViewPortOnNode = () => {
    const {setCenter} = useReactFlow()
    const {nodes, edges} = useGraphContext()

    const centerViewPort = useCallback(
        (node: GraphNode) => {
            setCenter(node.position.x + NODE_WIDTH, node.position.y + NODE_HEIGHT, {
                duration: 500,
                zoom: 1
            })
        },
        [setCenter]
    )

    const centerViewPortOnParent = useCallback(
        ({nodeId}: {nodeId: string}) => {
            const parentId = edges.find(edge => edge.target === nodeId)?.source
            const parent = nodes.find(node => node.id === parentId)
            if (parent) {
                centerViewPort(parent)
            }
        },
        [centerViewPort, edges, nodes]
    )

    return {centerViewPort, centerViewPortOnParent}
}
