import {Navigate, Outlet, useNavigate} from 'react-router-dom'
import {useAuth} from '@context/AuthProvider.tsx'
import {containsAtLeastOne} from '@utilities/helpers.ts'
import {TAXONOMIES_GUARD_PERMISSIONS} from '@utilities/constants/permissions.ts'
import {roleBasedRedirect} from '@/features/roles/helpers.tsx'
import {useEffect, useState} from 'react'
import {routes} from '@utilities/constants'
import {LoadingScreen} from '@components/commons/LoadingScreen/LoadingScreen.tsx'

export const TaxonomiesRouteGuard = () => {
    const {accessToken, user} = useAuth()
    const navigate = useNavigate()
    const [guardCondition, setGuardCondition] = useState(false)

    useEffect(() => {
        if (user) {
            if (containsAtLeastOne(TAXONOMIES_GUARD_PERMISSIONS, user.permissions)) {
                setGuardCondition(containsAtLeastOne(TAXONOMIES_GUARD_PERMISSIONS, user.permissions))
            } else {
                navigate(roleBasedRedirect(accessToken, user?.role?.slug))
            }
        }
    }, [user])

    return (
        <>
            {!accessToken && <Navigate to={routes.LOGIN.path} />}
            {user && guardCondition ? <Outlet /> : <LoadingScreen />}
        </>
    )
}
