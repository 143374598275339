import {useTheme} from 'styled-components'
import ContentLoader from 'react-content-loader'

export const Skeleton: typeof ContentLoader = props => {
    const {palette} = useTheme()

    return (
        <ContentLoader
            backgroundColor={palette.neutral[100]}
            foregroundColor={palette.neutral[200]}
            width="100%"
            height="100%"
            {...props}
        >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
    )
}

Skeleton.displayName = 'Skeleton'
