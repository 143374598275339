import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {ChangeEvent, useEffect, useState} from 'react'
import {useProject} from '@/features/projects/queries/useProject.ts'
import {EditableInputText} from '@components/commons/EditableInputText'
import toast from 'react-hot-toast'
import {ProjectHeaderContentLoader} from '@/features/projects/components/project-content-loaders/ProjectHeaderContentLoader.tsx'
import PageHeader from '@components/commons/PageHeader'
import {useUpdateProject} from '@/features/projects/queries/useUpdateProject.ts'
import {StartProjectToggleCta} from '@/features/projects/components/start-project-toggle-cta/StartProjectToggleCta.tsx'

export const ProjectDetailsHeader = () => {
    //const {user} = useAuth()
    const {t} = useTranslation()
    const {id} = useParams()
    const {data: project, isLoading} = useProject(id)
    const updateProjectMutation = useUpdateProject({
        onSuccess: () => toast.success(t('projectDetails:nameEditor:success'))
    })
    //const canEditProject = useMemo(() => user?.permissions.includes(CAN_WRITE_PROJECT), [user])
    //const showStartProjectToggle = useMemo( () =>  pathname === `${routes.PROJECTS.path}/${project?.id}/${routes.PROJECT_DETAIL_BATCHES.path}`, [pathname])
    const [projectName, setProjectName] = useState<string | null>('')
    const [newProjectName, setNewProjectName] = useState<string | null>('')

    useEffect(() => {
        if (project) {
            setProjectName(project.name)
            setNewProjectName(project.name)
        }
    }, [project])

    const onChangeProjectName = (event: ChangeEvent<HTMLInputElement>) => {
        setNewProjectName(event.target.value)
        return event.target.value
    }

    const onUpdateProjectName = () => {
        if (newProjectName) {
            if (newProjectName.length < 5) {
                toast.error(t('projectDetails:nameEditor:min_size_error'))
            } else if (newProjectName !== projectName && id) {
                updateProjectMutation.mutate({id, data: {name: newProjectName}})
            }
        } else {
            toast.error(t('projectDetails:nameEditor:empty_error'))
            setNewProjectName(projectName || 'Untitled')
        }
    }

    return (
        <>
            {isLoading ? (
                <ProjectHeaderContentLoader ctaHeight={48} ctaWidth={140} titleHeight={44} titleWidth={400} />
            ) : (
                <PageHeader
                    title={
                        <EditableInputText
                            type={'text'}
                            disabled={true}
                            onEnterPress={onUpdateProjectName}
                            onChange={onChangeProjectName}
                            value={newProjectName ?? 'Untitled'}
                        />
                    }
                    ctaComponent={<StartProjectToggleCta />}
                />
            )}
        </>
    )
}

ProjectDetailsHeader.displayName = 'ProjectDetailsHeader'
