import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {httpEditUser} from '@/features/users/services/users.http.ts'
import {GetUsersResponse} from '@/features/users/types.ts'
import {errorHandler} from '@utilities/helpers.ts'

type QueryData = {pages?: GetUsersResponse[]; pageParams?: unknown[]}

interface UseEditUserProps {
    onSuccess: () => void
    roleID: number
}

export const useEditUser = ({onSuccess, roleID}: UseEditUserProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.EDIT_USER],
        mutationFn: httpEditUser,
        onError: errorHandler,
        onSuccess: async (userResponse, {userToEditID, payload}) => {
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.USERS, roleID]})

            if (payload.role_id == roleID) {
                queryClient.setQueriesData([QUERY_KEYS.USERS, roleID], (oldData?: QueryData) => ({
                    ...oldData,
                    pages: oldData?.pages?.map(page => ({
                        ...page,
                        data: page.data?.map(user => (user.id == userToEditID ? userResponse : user))
                    }))
                }))
            } else {
                queryClient.setQueriesData([QUERY_KEYS.USERS, roleID], (oldData?: QueryData) => ({
                    ...oldData,
                    pages: oldData?.pages?.map(page => ({
                        ...page,
                        data: page.data?.filter(user => user.id != userToEditID)
                    }))
                }))

                queryClient.invalidateQueries([QUERY_KEYS.USERS, roleID])
                queryClient.invalidateQueries([QUERY_KEYS.USERS, payload.role_id])
            }
            onSuccess()
        }
    })
}
