import styled, {css} from 'styled-components'

export const DataServiceConfiguratorWrapper = styled.div<{error?: boolean}>`
    ${({theme: {spacing, palette, typography}, error}) => css`
        padding: ${spacing * 4}px;
        width: 100%;
        border: 1px solid ${palette.neutral[200]};
        ${error &&
        css`
            border: 1px solid ${palette.danger[500]};
        `}
        border-radius: 12px;
        background-color: ${palette.neutral.white};

        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        width: 100%;

        .icon-container {
            border: 1px solid ${palette.neutral[200]};
            display: grid;
            place-items: center;
            border-radius: 8px;
            width: 40px;
            aspect-ratio: 1/1;
        }

        .info-container {
            display: flex;
            flex-direction: column;
            gap: ${spacing / 2}px;

            p {
                font-size: ${typography.textSm};
                font-weight: 600;
                color: ${palette.neutral[900]};
            }

            small {
                font-size: ${typography.textSm};
                font-weight: 400;
                color: ${palette.neutral[600]};
            }
        }

        .cta-container {
            margin-left: auto;
            display: flex;
            align-items: center;
        }
    `}
`
