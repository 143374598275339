import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateTaxonomy} from '../services/taxonomies.http'
import {TaxonomyRecord} from '../types'
import {QUERY_KEYS} from '@/queryClient'

type useCreateTaxonomyProps = {
    onSuccess?: (data: TaxonomyRecord) => void
    onError?: () => void
}

export const useCreateTaxonomy = ({onError, onSuccess}: useCreateTaxonomyProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: httpCreateTaxonomy,
        onError,
        onSuccess: data => {
            queryClient.invalidateQueries([QUERY_KEYS.TAXONOMIES])
            onSuccess?.(data)
        }
    })
}
