import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {httpDeleteTemplate} from '@/features/templates/services/templates.http.ts'
import {GetTemplateResponse} from '@/features/templates/types.ts'

type QueryData = {pages?: GetTemplateResponse[]; pageParams?: unknown[]}

/*interface useDelete {
    onSuccess: () => void
}*/

export const useDeleteTemplate = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_TEMPLATE],
        mutationFn: httpDeleteTemplate,
        onSuccess: id => {
            console.log('delete', id)
            queryClient.setQueriesData([QUERY_KEYS.ACTIVE_TEMPLATES], (oldData?: QueryData) => ({
                ...oldData,
                pages: oldData?.pages?.map(page => ({
                    ...page,
                    data: page.data?.filter(template => template.id != id)
                }))
            }))
        },
        onError: errorHandler
    })
}
