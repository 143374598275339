import {Modal} from '@/components/ui/Modal'
import {FC, useId, useState} from 'react'
import {DataServiceModalWrapper, StyledCheckBoxContainer} from './styles'
import {useTranslation} from 'react-i18next'
import {Radio} from '@/components/commons/Radio'
import Divider from '@/components/ui/Divider'
import {GoogleDriveForm} from './GoogleDriveForm'
import {DataServiceFormProps} from './types'
import {GoogleCloudForm} from './GoogleCloudForm'
import {AmazonS3Form} from './AmazonS3Form'
import {GetDataServiceResponse} from '../../services/dataServices.http'

type DataServiceModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    type: 'source' | 'target'
    onSuccess: (data: {id: number}) => void
    data?: GetDataServiceResponse
    alwaysCreate?: boolean
    showFileRegex?: boolean
}

type ServiceType = 's3' | 'cloud' | 'drive'

const dataServiceTypeMap = {
    s3: 's3',
    google_cloud: 'cloud',
    google_drive: 'drive'
} as const

const formByServiceType: Record<ServiceType, FC<DataServiceFormProps>> = {
    s3: (props: DataServiceFormProps) => <AmazonS3Form {...props} />,
    cloud: (props: DataServiceFormProps) => <GoogleCloudForm {...props} />,
    drive: (props: DataServiceFormProps) => <GoogleDriveForm {...props} />
} as const

export const DataServiceModal: FC<DataServiceModalProps> = ({
    open,
    setOpen,
    type,
    onSuccess,
    data,
    alwaysCreate,
    showFileRegex
}) => {
    const initServiceType = data ? dataServiceTypeMap[data.data_config.type] : 's3'
    const [serviceType, setServiceType] = useState<ServiceType>(initServiceType)

    const onClose = () => {
        setOpen(false)
        setServiceType(initServiceType)
    }

    const {t} = useTranslation()
    const amazonS3Id = useId()
    const googleCloudId = useId()
    const googleDriveId = useId()
    if (open) {
        return (
            <Modal onClose={onClose} width={640}>
                <DataServiceModalWrapper>
                    <div className="header-section">
                        <h1>
                            {t('projectDetails:properties:data')}{' '}
                            {type === 'source'
                                ? t('projectDetails:properties:source')
                                : t('projectDetails:properties:target')}
                        </h1>

                        <p className="service-type-label">{t('projectDetails:properties:serviceType')}</p>
                        <div className="checkbox-group">
                            <StyledCheckBoxContainer>
                                <span>Amazon S3</span>
                                <Radio
                                    id={amazonS3Id}
                                    checked={serviceType === 's3'}
                                    onChange={() => setServiceType('s3')}
                                />
                            </StyledCheckBoxContainer>

                            <StyledCheckBoxContainer>
                                <span>Google Cloud</span>
                                <Radio
                                    id={googleCloudId}
                                    checked={serviceType === 'cloud'}
                                    onChange={() => setServiceType('cloud')}
                                />
                            </StyledCheckBoxContainer>

                            <StyledCheckBoxContainer>
                                <span>Google Drive</span>
                                <Radio
                                    id={googleDriveId}
                                    checked={serviceType === 'drive'}
                                    onChange={() => setServiceType('drive')}
                                />
                            </StyledCheckBoxContainer>
                        </div>
                        <Divider />
                    </div>
                    {formByServiceType[serviceType]({
                        onCancel: onClose,
                        onSuccess: data => {
                            onSuccess(data)
                            setOpen(false)
                        },
                        data,
                        alwaysCreate,
                        showFileRegex
                    })}
                </DataServiceModalWrapper>
            </Modal>
        )
    }
    return null
}
