import ReactFlow, {Background, BackgroundVariant, Controls} from 'reactflow'
import {WideStepEdge} from './components/custom-edge/CustomEdge'
import {EndNode, ProcessNode, StartNode} from './components/nodes'
import {GraphProvider, useGraphContext} from './context/graphContext'
import {NodeSidebar} from './components/node-sidebar'
import 'reactflow/dist/style.css'
import {GraphViewerWrapper} from './styles'
import {ProjectStepActions} from '../projects/components/project-step-actions/ProjectStepActions.tsx'
import {useTranslation} from 'react-i18next'
import {useConfirmProcess} from '../process/queries/useConfirmProcess'
import toast from 'react-hot-toast'
import {Edge, GraphNode} from './types'
import {generatePath, useNavigate} from 'react-router-dom'
import {PROJECTS_HEADER_OBJ, routes} from '@/utilities/constants'

const nodeTypes = {startNode: StartNode, processNode: ProcessNode, tempProcessNode: ProcessNode, endNode: EndNode}
const edgeTypes = {wideStep: WideStepEdge}

type UnprovidedGraphViewerProps = {
    projectId: number
    processes?: object[]
}

const UnprovidedGraphViewer = ({projectId, processes}: UnprovidedGraphViewerProps) => {
    const {nodes, onNodesChange, edges, onEdgesChange, setIsDirty} = useGraphContext()
    const navigate = useNavigate()

    const {t} = useTranslation()
    const {mutate: confirmProcess, isLoading: isLoadingConfirmProcess} = useConfirmProcess({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            setIsDirty(false)
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const saveProcess = () => {
        const filteredNodesIds = nodes.filter(node => node.type === 'processNode').map(node => Number(node.id))
        const filteredEdges = edges
            .filter(edges => {
                return (
                    edges.source !== 'start' &&
                    edges.target !== 'start' &&
                    edges.source !== 'end' &&
                    edges.target !== 'end'
                )
            })
            .map(edge => ({
                source: Number(edge.source),
                target: Number(edge.target)
            }))

        confirmProcess({
            projectId,
            nodes: filteredNodesIds,
            edges: filteredEdges
        })
    }

    const onClose = () => {
        navigate(PROJECTS_HEADER_OBJ.path)
        console.log('on close callback')
    }

    const onNextClick = async () => {
        setIsDirty(false)
        await saveProcess()
        navigate(generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_VENDORS.path}`, {id: projectId}))
    }

    return (
        <>
            <GraphViewerWrapper>
                <div className="graph-container">
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        edgeTypes={edgeTypes}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        proOptions={{hideAttribution: true}}
                    >
                        <NodeSidebar />
                        <Controls />
                        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                    </ReactFlow>
                </div>
                <div className="cta-container">
                    <ProjectStepActions
                        onCloseCb={onClose}
                        onNextClickCb={onNextClick}
                        onSave={saveProcess}
                        nextStepLabel={t('projectDetails:tabs:vendors')}
                        nextButtonDisabled={!processes?.length}
                        isLoading={isLoadingConfirmProcess}
                    />
                </div>
            </GraphViewerWrapper>
        </>
    )
}

type GraphViewerProps = {
    projectId: number
    initialNodes: GraphNode[]
    initialEdges: Edge[]
    processes?: object[]
}

export const GraphViewer = ({projectId, initialNodes, initialEdges, processes}: GraphViewerProps) => {
    return (
        <GraphProvider initialNodes={initialNodes} initialEdges={initialEdges}>
            <UnprovidedGraphViewer projectId={projectId} processes={processes} />
        </GraphProvider>
    )
}
