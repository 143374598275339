import {useTranslation} from 'react-i18next'
import {FC, useMemo} from 'react'
import {Sidebar} from '@components/ui/Sidebar'
import {UserRecord, UsersSearchParams} from '@/features/users/types.ts'
import {roleSlugToID, roleSlugToLabel} from '@/features/roles/helpers.tsx'
import {Slug} from '@/types/auth.ts'
import {useRoles} from '@/features/roles/queries/useRoles.ts'
import {EditUserForm} from '@/features/users/components/edit-user-sidebar/edit-user-form/EditUserForm.tsx'
import Spinner from '@components/ui/Spinner'
import {useTheme} from 'styled-components'
import {Flexbox} from '@components/ui/Flexbox'
import {useCompaniesVendors} from '@/features/companies/queries/useCompaniesVendors.ts'
import {remapSelectOptions} from '@utilities/helpers.ts'
import useUsers from '@/features/users/queries/useUsers.ts'

interface EditUserSidebarProps {
    onClose: () => void
    roleSlug: Slug
    user: UserRecord
}

export const EditUserSidebar: FC<EditUserSidebarProps> = ({onClose, user, roleSlug}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const rolesQuery = useRoles()
    const teamLeadRoleID = roleSlugToID(rolesQuery.data, 'team_lead')
    const vendorManagerRoleID = roleSlugToID(rolesQuery.data, 'vendor_manager')
    const companiesQuery = useCompaniesVendors()
    const teamLeadsQuery = useUsers(teamLeadRoleID, UsersSearchParams['team_lead'])
    const vendorManagersQuery = useUsers(vendorManagerRoleID, UsersSearchParams['vendor_manager'])
    const roleOptions = useMemo(
        () =>
            remapSelectOptions(rolesQuery.data, 'slug', role => t(roleSlugToLabel[role.slug], {count: 1})) as Array<{
                label: string
                value: Slug
            }>,
        [rolesQuery.data]
    )
    const companiesOptions = useMemo(() => remapSelectOptions(companiesQuery.data, 'id', 'name'), [companiesQuery.data])
    const teamLeadsOptions = useMemo(
        () => remapSelectOptions(teamLeadsQuery.remappedData, 'id', 'full_name'),
        [teamLeadsQuery.remappedData]
    )
    const vendorManagersOptions = useMemo(
        () => remapSelectOptions(vendorManagersQuery.remappedData, 'id', 'full_name'),
        [teamLeadsQuery.remappedData]
    )

    if (rolesQuery.isLoading || companiesQuery.isLoading || teamLeadsQuery.isLoading || vendorManagersQuery.isLoading) {
        return (
            <Sidebar width={400} onClose={onClose}>
                <Flexbox justify="center" align="center" height="100%">
                    <Spinner size={48} color={theme.palette.neutral['500']} />
                </Flexbox>
            </Sidebar>
        )
    }

    return (
        <Sidebar width={400} onClose={onClose}>
            <EditUserForm
                user={user}
                onClose={onClose}
                teamLeadsOptions={teamLeadsOptions}
                vendorManagersOptions={vendorManagersOptions}
                companiesOptions={companiesOptions}
                roleOptions={roleOptions}
                roleSlug={roleSlug}
            />
        </Sidebar>
    )
}
