import {Dropzone} from '@/components/ui/Dropzone'
import {UploaderWrapper} from './styles'
import {Accept, useDropzone} from 'react-dropzone'
import {FileCheck02Icon, UploadCloud02Icon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {useTheme} from 'styled-components'
import InputHelpText from '@components/ui/InputHelpText'
import {megabytesToBytes, truncateText} from '@utilities/helpers.ts'

type UploaderProps = {
    maxSizeInMB: number
    accept?: Accept
    value?: File
    onChange?: (file: File) => void
    error?: string
    url?: string
    downloadCallback: (file: File | undefined) => void
}

export const Uploader: FC<UploaderProps> = ({maxSizeInMB, accept, onChange, value, error, url, downloadCallback}) => {
    const dropzoneState = useDropzone({
        onDrop: acceptedFiles => {
            const [file] = acceptedFiles
            if (file) {
                onChange?.(file)
            }
        },
        accept,
        maxSize: megabytesToBytes(maxSizeInMB),
        maxFiles: 1,
        noClick: true
    })

    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <>
            <Dropzone state={dropzoneState}>
                <UploaderWrapper
                    dragAccepted={dropzoneState.isDragAccept}
                    dragRejected={dropzoneState.isDragReject}
                    error={!!error}
                >
                    <div className="icon-container" onClick={() => downloadCallback(value)}>
                        {value ? (
                            <FileCheck02Icon color={palette.success['600']} size={20} />
                        ) : (
                            <UploadCloud02Icon size={20} />
                        )}
                    </div>
                    <div className="text-container">
                        {value && <p className="filename">{truncateText(value.name, 70)}</p>}
                        {url && !value && <p className="filename">{url}</p>}
                        <p>
                            <span onClick={dropzoneState.open} className="choose-file">
                                {t('fileUpload:chooseFile')}{' '}
                            </span>
                            {t('fileUpload:orDnd')}
                        </p>
                        {!value && !url && <p>Max. {maxSizeInMB}MB</p>}
                    </div>
                </UploaderWrapper>
            </Dropzone>
            <InputHelpText error={error} />
        </>
    )
}
Uploader.displayName = 'Uploader'
