import {FC} from 'react'
import {StyledButtons, StyledFields, StyledForm} from '@/features/users/components/edit-user-sidebar/style.ts'
import InputText from '@components/commons/InputText'
import {Controller, useForm} from 'react-hook-form'
import InputSelect, {SelectValue} from '@components/commons/Select'
import Button from '@components/ui/Button'
import Spinner from '@components/ui/Spinner'
import {useTranslation} from 'react-i18next'
import {
    editUserValidationSchema,
    EditUserValidationSchema
} from '@/features/users/components/edit-user-sidebar/edit-user-form/editUserValidationSchema.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {UserRecord} from '@/features/users/types.ts'
import {Slug} from '@/types/auth.ts'
import {useEditUser} from '@/features/users/queries/useEditUser.ts'
import {roleSlugToID} from '@/features/roles/helpers.tsx'
import {useRoles} from '@/features/roles/queries/useRoles.ts'
import {TaxonomiesSelect} from '@/features/taxonomies/components/TaxonomiesSelect'
import {useAuth} from '@context/AuthProvider.tsx'

interface EditUserFormProps {
    user: UserRecord
    onClose: () => void
    roleSlug: Slug
    roleOptions: {label: string; value: Slug}[]
    companiesOptions: SelectValue[]
    teamLeadsOptions: SelectValue[]
    vendorManagersOptions: SelectValue[]
}

export const EditUserForm: FC<EditUserFormProps> = ({
    user,
    onClose,
    roleSlug,
    roleOptions,
    companiesOptions,
    vendorManagersOptions,
    teamLeadsOptions
}) => {
    const {t} = useTranslation()
    const {user: authUser} = useAuth()
    const rolesQuery = useRoles()
    const form = useForm<EditUserValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(editUserValidationSchema(roleSlug)),
        defaultValues: {
            first_name: user.first_name ?? '',
            last_name: user.last_name ?? '',
            email: user.email ?? '',
            role: roleOptions.find(option => option.value == roleSlug),
            vendor: companiesOptions.find(option => option.value == user.company_id),
            supervisor_email: (roleSlug == 'team_lead' ? vendorManagersOptions : teamLeadsOptions).find(
                option => option.value == user.supervisor_id?.toString()
            ),
            skill_taxonomy_id: user.skill_taxonomy_id ?? null
        }
    })
    const editUserMutation = useEditUser({
        onSuccess: onClose,
        roleID: roleSlugToID(rolesQuery.data, roleSlug)
    })

    const showVendorField = ['vendor_admin', 'vendor_manager', 'team_lead', 'labeler'].includes(
        form.watch('role').value
    )
    const showSupervisorEmailField = form.watch('role').value == 'team_lead' || form.watch('role')?.value == 'labeler'
    const showSkillsField = form.watch('role').value == 'labeler'

    const onSubmit = (formValues: EditUserValidationSchema) => {
        console.log(formValues)
        editUserMutation.mutate({
            userToEditID: user.id,
            payload: {
                company_id: Number(formValues.vendor?.value),
                email: formValues.email,
                first_name: formValues.first_name,
                last_name: formValues.last_name,
                role_id: roleSlugToID(rolesQuery.data, formValues.role.value),
                supervisor_id: Number(formValues.supervisor_email?.value),
                skill_taxonomy_id: formValues.skill_taxonomy_id ? Number(formValues.skill_taxonomy_id) : undefined
            }
        })
    }

    return (
        <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
            <h1>{t('users:edit_user')}</h1>
            <StyledFields>
                <InputText
                    type="text"
                    label={t('commons:first_name')}
                    errorMessage={t(form.formState.errors.first_name?.message ?? '')}
                    {...form.register('first_name')}
                />
                <InputText
                    type="text"
                    label={t('commons:last_name')}
                    errorMessage={t(form.formState.errors.last_name?.message ?? '')}
                    {...form.register('last_name')}
                />
                <InputText
                    type="text"
                    label={t('commons:email')}
                    errorMessage={t(form.formState.errors.email?.message ?? '')}
                    {...form.register('email')}
                />
                <Controller
                    name="role"
                    control={form.control}
                    render={({field}) => (
                        <InputSelect
                            ref={field.ref}
                            value={field.value}
                            onChange={newValue => {
                                field.onChange(newValue as {label: string; value: Slug})
                            }}
                            errorMessage={t(form.formState.errors.role?.message ?? '')}
                            options={roleOptions}
                            label={t('commons:role')}
                            disabled
                        />
                    )}
                />
                {showVendorField && (
                    <Controller
                        name="vendor"
                        control={form.control}
                        render={({field}) => (
                            <InputSelect
                                ref={field.ref}
                                value={field.value}
                                onChange={newValue => {
                                    field.onChange(newValue as SelectValue)
                                }}
                                errorMessage={t(form.formState.errors.vendor?.message ?? '')}
                                options={companiesOptions}
                                label={t('commons:vendor')}
                                disabled
                            />
                        )}
                    />
                )}
                {showSupervisorEmailField && (
                    <Controller
                        name="supervisor_email"
                        control={form.control}
                        render={({field}) => (
                            <InputSelect
                                disabled={
                                    authUser?.role.slug === 'vendor_manager' || authUser?.role.slug === 'team_lead'
                                }
                                ref={field.ref}
                                value={field.value}
                                onChange={newValue => {
                                    field.onChange(newValue as SelectValue)
                                }}
                                errorMessage={t(form.formState.errors.supervisor_email?.message ?? '')}
                                options={
                                    form.watch('role').value == 'team_lead' ? vendorManagersOptions : teamLeadsOptions
                                }
                                label={t('users:supervisor_email')}
                            />
                        )}
                    />
                )}
                {showSkillsField && (
                    <Controller
                        name="skill_taxonomy_id"
                        control={form.control}
                        render={({field}) => (
                            <TaxonomiesSelect
                                value={field.value ?? null}
                                onChange={newValue => {
                                    field.onChange(newValue)
                                }}
                                errorMessage={t(form.formState.errors.skill_taxonomy?.message ?? '')}
                                label={t('users:skills')}
                            />
                        )}
                    />
                )}
            </StyledFields>
            <StyledButtons>
                <Button variant="secondary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    type="submit"
                    disabled={!form.formState.isValid || !form.formState.isDirty || editUserMutation.isLoading}
                >
                    {t('commons:save')}
                    {editUserMutation.isLoading && <Spinner size={16} />}
                </Button>
            </StyledButtons>
        </StyledForm>
    )
}

EditUserForm.displayName = 'EditUserForm'
