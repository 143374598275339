import {
    StyledFastActions,
    StyledFastActionsWrapper,
    StyledSelectedCounter
} from '@/features/operators/components/OperatorsTable/style.ts'
import Button from '@components/ui/Button'
import {DefaultTFuncReturn} from 'i18next'
import {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

export type FastActionItem = {
    text?: string | DefaultTFuncReturn
    icon?: ReactNode
    isDanger?: boolean
    disabled?: boolean
    onClickCb?: () => void
}

type SelectionFastActionsProps = {
    selectedItemsCounter?: number
    actions: FastActionItem[]
}

export const SelectionFastActions = ({selectedItemsCounter, actions}: SelectionFastActionsProps) => {
    const {t} = useTranslation()

    return (
        <StyledFastActionsWrapper>
            <StyledSelectedCounter>{t('commons:selected', {selected: selectedItemsCounter})}</StyledSelectedCounter>
            <StyledFastActions>
                {actions.map((action, index) => (
                    <Button key={`a-${index}`} variant={'linkColor'} onClick={action.onClickCb}>
                        {action.icon}
                        {action.text}
                    </Button>
                ))}
            </StyledFastActions>
        </StyledFastActionsWrapper>
    )
}

SelectionFastActions.displayName = 'SelectionFastActions'
