import styled, {css} from 'styled-components'
import {ADDER_BUTTON_GAP} from '../constants'
import Button from '@/components/ui/Button'

export const StyledAdderButton = styled(Button)`
    ${({theme: {palette}}) => css`
        width: 24px;
        height: 24px;
        z-index: 10;
        position: absolute;
        background-color: ${palette.neutral.white};

        & svg {
            width: unset;
            height: unset;
        }

        &.right {
            right: ${ADDER_BUTTON_GAP}px;
        }

        &.bottom {
            bottom: ${ADDER_BUTTON_GAP}px;
        }

        &.left {
            left: ${ADDER_BUTTON_GAP}px;
        }
    `}
`

export const AdderButtonPopoverContentWrapper = styled.div`
    ${({theme: {spacing, shadows, typography, palette}}) => css`
        padding: ${spacing * 2}px;
        border-radius: 8px;
        ${shadows.lg};
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        ul {
            font-weight: 400;
            ${typography.textMd};
            color: ${palette.neutral[900]};
            display: flex;
            flex-direction: column;
            gap: ${spacing};
        }

        li {
            padding: ${spacing * 2}px;
            cursor: pointer;
        }
    `}
`
