import {FC, useState} from 'react'
import {DataServiceConfiguratorWrapper} from './styles'
import {ArrowRightIcon, Edit01Icon, FolderIcon, Trash01Icon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {DataServiceModal} from '../data-service-modal'
import {useDataService} from '../../queries/useDataService'
import Spinner from '@/components/ui/Spinner'
import {toast} from 'react-hot-toast'
import InputHelpText from '@/components/ui/InputHelpText'

type DataServiceConfiguratorProps = {
    value?: number | null
    disabled?: boolean
    type: 'source' | 'target'
    error?: string
    onChange?: (val: number | null) => void
    onBlur?: () => void
    alwaysCreate?: boolean
    showFileRegex?: boolean
}

const dataServiceTypeMap = {
    s3: 'Amazon S3',
    google_cloud: 'Google Cloud',
    google_drive: 'Gooogle Drive'
} as const

export const DataServiceConfigurator: FC<DataServiceConfiguratorProps> = ({
    value,
    type,
    onChange,
    onBlur,
    error,
    disabled,
    alwaysCreate,
    showFileRegex
}) => {
    const [openModal, setOpenModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const {t} = useTranslation()

    const {
        data: dataService,
        isLoading: isLoadingDataService,
        isFetching: isFetchingDataService,
        error: dataServiceError
    } = useDataService(value as number, {enabled: !!value})

    if (dataServiceError) {
        //TODO: handle get errror
    }

    let cta
    if (isLoadingDataService && isFetchingDataService) {
        //TODO: remove color black
        cta = <Spinner size={16} color={'black'} />
    } else if (value) {
        cta = (
            <>
                <Button iconOnly variant="ghost" disabled={disabled}>
                    <Edit01Icon size={20} onClick={() => setOpenEditModal(true)} />
                </Button>
                <Button
                    iconOnly
                    disabled={disabled}
                    variant="ghostDanger"
                    onClick={() => {
                        onChange?.(null)
                        onBlur?.()
                    }}
                >
                    <Trash01Icon size={20} />
                </Button>
            </>
        )
    } else {
        cta = (
            <Button variant="linkColor" onClick={() => setOpenModal(true)}>
                {t('commons:configure')} <ArrowRightIcon size={20} />
            </Button>
        )
    }

    return (
        <>
            <DataServiceModal
                open={openModal}
                showFileRegex={showFileRegex}
                setOpen={setOpenModal}
                type={type}
                onSuccess={({id}) => {
                    onChange?.(id)
                    onBlur?.()
                }}
            />
            <DataServiceModal
                alwaysCreate={alwaysCreate}
                showFileRegex={showFileRegex}
                open={openEditModal}
                setOpen={setOpenEditModal}
                type={type}
                key={dataService?.data_config.type}
                data={dataService}
                onSuccess={({id}) => {
                    onChange?.(id)
                    toast.success(t('commons:genericSuccess'))
                    onBlur?.()
                }}
            />
            <DataServiceConfiguratorWrapper error={!!error}>
                <div className="icon-container">
                    <FolderIcon size={20} />
                </div>
                <div className="info-container">
                    <p>
                        {t('projectDetails:properties:data')}{' '}
                        {type === 'source'
                            ? t('projectDetails:properties:source')
                            : t('projectDetails:properties:target')}
                    </p>
                    {value && dataService && <small>{dataServiceTypeMap[dataService.data_config.type]}</small>}
                </div>
                <div className="cta-container">{cta}</div>
            </DataServiceConfiguratorWrapper>
            {error && <InputHelpText error={error} />}
        </>
    )
}
