import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {GetTaxonomiesResponse} from '@/features/taxonomies/types.ts'
import {httpDeleteTaxonomy} from '@/features/taxonomies/services/taxonomies.http.ts'

type QueryData = {pages?: GetTaxonomiesResponse[]; pageParams?: unknown[]}
interface useDeleteTaxonomyProps {
    onSuccess: () => void
}

export const useDeleteTaxonomy = ({onSuccess}: useDeleteTaxonomyProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_TAXONOMY],
        mutationFn: httpDeleteTaxonomy,
        onMutate: async (id: number) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.TAXONOMIES]})
            // Optimistically update our data
            queryClient.setQueriesData([QUERY_KEYS.TAXONOMIES], (oldData?: QueryData) => ({
                ...oldData,
                pages: oldData?.pages?.map(page => ({
                    ...page,
                    data: page.data?.filter(taxonomy => taxonomy.id !== id)
                }))
            }))
        },
        onSuccess: () => onSuccess(),
        onError: errorHandler,
        // Always refetch cause is a delete action in an infinite table, and we need to be sure to be in sync with the b-end:
        onSettled: (_1, _2, id) => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TAXONOMIES]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TAXONOMIES, id]})
        }
    })
}
