import {Modal} from '@components/ui/Modal'
import {
    StyledContent,
    StyledFooter,
    StyledTaxonomyStatusToggleModal
} from '@/features/taxonomies/components/TaxonomyStatusToggleModal/style.ts'
import {FC, ReactNode} from 'react'
import Button from '@components/ui/Button'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import Spinner from '@components/ui/Spinner'
import {DefaultTFuncReturn} from 'i18next'
import {InfoCircleIcon} from '@components/ui/Icon'

interface TaxonomyStatusToggleModalProps {
    isLoading: boolean
    onClose: () => void
    onConfirm: () => void
    title: string | DefaultTFuncReturn
    paragraph: ReactNode
    cta_text?: string | DefaultTFuncReturn
}

export const TaxonomyStatusToggleModal: FC<TaxonomyStatusToggleModalProps> = ({
    isLoading,
    onClose,
    onConfirm,
    title,
    cta_text,
    paragraph
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Modal width={544} closeIconOffset="sm" onClose={onClose}>
            <StyledTaxonomyStatusToggleModal>
                <StyledContent>
                    <InfoCircleIcon size={24} fill={theme.palette.warning['600']} />
                    <div>
                        <h3>{title}</h3>
                        <p>{paragraph}</p>
                    </div>
                </StyledContent>
                <StyledFooter>
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {t('commons:cancel')}
                    </Button>
                    <Button variant="primary" onClick={onConfirm} disabled={isLoading}>
                        {cta_text ? cta_text : t('commons:confirm')}
                        {isLoading && <Spinner size={16} />}
                    </Button>
                </StyledFooter>
            </StyledTaxonomyStatusToggleModal>
        </Modal>
    )
}

TaxonomyStatusToggleModal.displayName = 'TaxonomyStatusToggleModal'
