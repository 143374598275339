import styled, {css} from 'styled-components'
import Container from '@components/ui/Container'

export const StyledContainer = styled(Container)`
    display: grid;
    grid-template-rows: auto auto 1fr;
`

export const StyledTemplatesGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto auto 1fr;
        gap: ${spacing * 8}px;
    `
)

export const StyledTableActions = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
    `
)
