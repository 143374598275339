import {FC} from 'react'
import {StyledModal} from './style'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {FolderIcon, PlusIcon, Trash01Icon} from '@/components/ui/Icon'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {FormValues, validationSchema} from './form-model'
import {Radio} from '@/components/commons/Radio'
import InputText from '@/components/commons/InputText'
import Label from '@/components/ui/Label'
import {IconContainer} from '@/components/ui/IconContainer'
import {Flexbox} from '@/components/ui/Flexbox'
import {useSyncProjectBatches} from '../../queries/useSyncProjectBatches'
import {toast} from 'react-hot-toast'
import {zodResolver} from '@hookform/resolvers/zod'
import InputHelpText from '@/components/ui/InputHelpText'
import {useMediaCountSocket} from '@/features/batches/hooks/useMediaCountSocket.ts'

type GenerateBatchesModalProps = {
    open: boolean
    setOpen: (bool: boolean) => void
    projectId: number
}

export const GenerateBatchesModal: FC<GenerateBatchesModalProps> = ({open, setOpen, projectId}) => {
    const {t} = useTranslation()
    const {countInProgress, mediaAmount} = useMediaCountSocket({projectId})
    const {mutate, isLoading} = useSyncProjectBatches({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            setOpen(false)
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors}
    } = useForm<FormValues>({
        mode: 'onSubmit',
        resolver: zodResolver(validationSchema)
    })

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'percentages'
    })

    const onSubmit = (values: FormValues) => {
        if (values.batchType === 'one') {
            mutate({batch_mode: 'one', id: projectId})
        } else if (values.batchType === 'tasks') {
            mutate({batch_mode: 'fixed', id: projectId, batch_tasks_amount: values.tasks || 0})
        } else {
            mutate({
                batch_mode: 'percentage',
                id: projectId,
                batch_percentages: values.percentages.map(percentage => percentage.value) ?? []
            })
        }
    }

    const resetOnBatchTypeChange = (batchType: FormValues['batchType']) => {
        reset({batchType})
    }

    const watchedBatchType = watch('batchType')

    if (open) {
        return (
            <StyledModal onClose={() => setOpen(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flexbox direction="column" className="header">
                        <h2>{t('projectDetails:batches:generate_batches')}</h2>
                        <p>{t('projectDetails:batches:generate_batches_helper')}</p>
                    </Flexbox>
                    <Flexbox direction="column" gap={5} className="body">
                        <Flexbox align="center" gap={4} className="media-sources-container">
                            <IconContainer>
                                <FolderIcon size={20} />
                            </IconContainer>
                            <Flexbox direction="column" className="text-container">
                                <h2>
                                    {mediaAmount} {t('projectDetails:batches:medias_in_source')}
                                    {countInProgress && <small>media count in progress...</small>}
                                </h2>
                            </Flexbox>
                        </Flexbox>
                        <Flexbox gap={2} className="radio-container">
                            <Controller
                                name="batchType"
                                control={control}
                                render={({field: {value, onChange}}) => (
                                    <Radio
                                        id="one_batch"
                                        checked={value === 'one'}
                                        onChange={() => {
                                            if (value === 'one') {
                                                onChange(null)
                                                resetOnBatchTypeChange(null)
                                                return
                                            }
                                            onChange('one')
                                            resetOnBatchTypeChange('one')
                                        }}
                                    />
                                )}
                            />

                            <div className="text-container">
                                <h2>{t('projectDetails:batches:one_batch')}</h2>
                                <p>{t('projectDetails:batches:one_batch_helper')}</p>
                            </div>
                        </Flexbox>
                        <Flexbox direction="column" className="radio-with-field-container">
                            <Flexbox gap={2} className="radio-container">
                                <Controller
                                    name="batchType"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <Radio
                                            id="task_batch"
                                            checked={value === 'tasks'}
                                            onChange={() => {
                                                if (value === 'tasks') {
                                                    onChange(null)
                                                    resetOnBatchTypeChange(null)
                                                    return
                                                }
                                                onChange('tasks')
                                                resetOnBatchTypeChange('tasks')
                                            }}
                                        />
                                    )}
                                />

                                <div className="text-container">
                                    <h2>{t('projectDetails:batches:tasks_batch')}</h2>
                                    <p>{t('projectDetails:batches:tasks_batch_helper')}</p>
                                </div>
                            </Flexbox>
                            {watchedBatchType === 'tasks' && (
                                <div className="field">
                                    <InputText
                                        type="text"
                                        {...register('tasks')}
                                        placeholder={
                                            t('projectDetails:batches:tasks_batch_placeholder') satisfies string
                                        }
                                        errorMessage={t(errors.tasks?.message ?? '')}
                                    />
                                </div>
                            )}
                        </Flexbox>
                        <Flexbox direction="column" className="radio-with-field-container">
                            <Flexbox gap={2} className="radio-container">
                                <Controller
                                    name="batchType"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <Radio
                                            id="percentage_batch"
                                            checked={value === 'percentage'}
                                            onChange={() => {
                                                if (value === 'percentage') {
                                                    onChange(null)
                                                    resetOnBatchTypeChange(null)
                                                    return
                                                }

                                                append({value: 0})
                                                onChange('percentage')
                                                resetOnBatchTypeChange('percentage')
                                            }}
                                        />
                                    )}
                                />

                                <div className="text-container">
                                    <h2>{t('projectDetails:batches:percentage_batch')}</h2>
                                    <p>{t('projectDetails:batches:percentage_batch_helper')}</p>
                                </div>
                            </Flexbox>

                            {watchedBatchType === 'percentage' && (
                                <Flexbox direction="column" gap={4} className="field">
                                    <Label>{t('commons:batches')}</Label>

                                    {fields.map((field, index) => (
                                        <Flexbox align="start" gap={2} key={field.id}>
                                            <InputText
                                                {...register(`percentages.${index}.value`)}
                                                typeIcon="%"
                                                type="text"
                                                placeholder={t('commons:percentage') satisfies string}
                                                errorMessage={t(errors.percentages?.[index]?.value?.message ?? '')}
                                            />
                                            <Button variant="ghostDanger" onClick={() => remove(index)}>
                                                <Trash01Icon size={20} />
                                            </Button>
                                        </Flexbox>
                                    ))}

                                    {errors.percentages?.message && (
                                        <InputHelpText error={t(errors.percentages?.message)} />
                                    )}

                                    <div>
                                        <Button variant="secondary" size="md" onClick={() => append({value: 0})}>
                                            <PlusIcon size={20} />
                                            {t('projectDetails:batches:add_batch')}
                                        </Button>
                                    </div>
                                </Flexbox>
                            )}
                        </Flexbox>
                    </Flexbox>
                    <Flexbox align="center" justify="end" gap={3} className="footer">
                        <Button type="button" size="lg" variant="secondary" onClick={() => setOpen(false)}>
                            {t('commons:cancel')}
                        </Button>
                        <Button disabled={isLoading} type="submit" size="lg">
                            {t('projectDetails:batches:generate')}
                        </Button>
                    </Flexbox>
                </form>
            </StyledModal>
        )
    }

    return null
}
