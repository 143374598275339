import {XCloseIcon} from '@components/ui/Icon'
import {StyledCloseButton, StyledOverlay, StyledSidebar} from '@components/ui/Sidebar/style.ts'
import {FC, ReactNode} from 'react'
import {Percentage} from '@/types/commons.ts'

interface SidebarProps {
    children: ReactNode
    className?: string
    onClose: () => void
    transparentOverlay?: boolean
    width: number | Percentage
}

export const Sidebar: FC<SidebarProps> = ({children, className, onClose, width, transparentOverlay}) => {
    return (
        <>
            <StyledOverlay onClick={onClose} transparent={transparentOverlay} />
            <StyledSidebar width={width} className={className}>
                <StyledCloseButton size="lg" iconOnly variant="ghost" onClick={onClose}>
                    <XCloseIcon />
                </StyledCloseButton>
                {children}
            </StyledSidebar>
        </>
    )
}

Sidebar.displayName = 'Sidebar'
