import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpBulkUnssignbBatches} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type useBulkUnassignBatchesProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useBulkUnassignBatches = (options?: useBulkUnassignBatchesProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: httpBulkUnssignbBatches,
        onError: options?.onError,
        onSuccess: () => {
            options?.onSuccess?.()
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES])
            queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_BATCHES])
        }
    })
}
