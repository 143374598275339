import styled, {css} from 'styled-components'

export const HeaderWrapper = styled.div`
    ${({theme: {palette}}) => css`
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral['200']};
        height: 72px;
    `}
`

export const HeaderGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr auto;
        height: 100%;
        gap: ${spacing * 4}px;
        & svg {
            cursor: pointer;
        }
    `}
`

export const MenuItems = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const DropdownUserInfosItems = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: grid;
        align-items: center;
        gap: ${spacing}px;
        & span {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        & small {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['600']};
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral['400']};
        }
    `}
`
