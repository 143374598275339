import {Flexbox} from '@/components/ui/Flexbox'
import {FC} from 'react'
import {StyledStatCard, StyledStatHeading, StyledStatValue} from '../style'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'

type TasksAverageChartsProps = {
    edit: {
        total: number
        data: {x: string; y: number}[]
    }
    qa: {
        total: number
        data: {x: string; y: number}[]
    }
}

export const TasksAverageCharts: FC<TasksAverageChartsProps> = ({edit, qa}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Flexbox gap={4} width={'100%'} height={'369px'}>
            <StyledStatCard>
                <Flexbox height={'100%'} width={'100%'} direction="column" gap={4}>
                    <StyledStatHeading>{t('stats:avg_edit_task_time')}</StyledStatHeading>
                    <StyledStatValue>{t('stats:minutes', {minutes: edit.total})}</StyledStatValue>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart width={500} height={300} data={edit.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis tickFormatter={tick => `${tick}m`} />
                            <Tooltip />
                            <Line
                                type="monotone"
                                dataKey="y"
                                stroke={theme.palette.primary[600]}
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Flexbox>
            </StyledStatCard>
            <StyledStatCard>
                <Flexbox height={'100%'} width={'100%'} direction="column" gap={4}>
                    <StyledStatHeading>{t('stats:avg_qa_task_time')}</StyledStatHeading>
                    <StyledStatValue>{t('stats:minutes', {minutes: qa.total})}</StyledStatValue>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart width={500} height={300} data={qa.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis tickFormatter={tick => `${tick}m`} />
                            <Tooltip />
                            <Line
                                type="monotone"
                                dataKey="y"
                                stroke={theme.palette.primary[600]}
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Flexbox>
            </StyledStatCard>
        </Flexbox>
    )
}
