import styled, {css} from 'styled-components'

export const StyledUsersTableEmptyState = styled('div')(
    ({theme: {spacing, palette, typography}}) => css`
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 12px;
        height: max-content;
        padding: ${spacing * 10}px ${spacing * 10}px ${spacing * 12}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        hgroup {
            width: 352px;
            margin: ${spacing * 4}px 0 ${spacing * 6}px;

            h3 {
                ${typography.textMd};
                font-weight: 600;
                color: ${palette.neutral['900']};
                margin-bottom: ${spacing}px;
            }
            h4 {
                ${typography.textSm};
            }
        }
    `
)
