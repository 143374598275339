import {useQuery} from '@tanstack/react-query'
import {httpGetTasksLoading, httpGetTasksLoadingParams} from '../services/http'
import {QUERY_KEYS} from '@/queryClient'

export const useTasksLoading = (params?: httpGetTasksLoadingParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TASKS_LOADING, params],
        queryFn: () => httpGetTasksLoading(params)
    })
}
