import {Component, ReactNode} from 'react'
import {ErrorBox} from 'src/components/commons/ErrorBox'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme'

interface ErrorBoundaryProps {
    children: ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            return (
                <ThemeProvider theme={getAppTheme({name: 'light'})}>
                    <StyleSheetManager>
                        <>
                            <GlobalStyles />
                            <ErrorBox />
                        </>
                    </StyleSheetManager>
                </ThemeProvider>
            )
        }

        return this.props.children
    }
}
