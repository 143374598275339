import {Flexbox} from '@/components/ui/Flexbox'
import {useTranslation} from 'react-i18next'
import {StyledButtonGroup, StyledStatHeading} from '../style'
import InputSelect, {SelectValue} from '@/components/commons/Select'
import {FC} from 'react'
import i18n from '@/translations/i18n'
import {SingleValue} from 'react-select'
import DatePicker from '@/components/commons/DatePicker'
import {formatLocaleDate} from '@/utilities/helpers'
import {CalendarIcon} from '@/components/ui/Icon'
import {DATE_RANGES, DateRangeType, MetricType} from '../../constants'

type StatsPerformanceType = {
    rangeType: DateRangeType
    onRangeTypeSelect: (newRangeType: DateRangeType) => void
    dateRange: [Date | null, Date | null]
    onDateRangeChange: (range: [Date, Date | null] | null) => void
    metric: MetricType
    onMetricChange: (metric: MetricType) => void
}

const dateRangeOptions = [
    {value: DATE_RANGES.CUSTOM, label: i18n.t('commons:custom')},
    {value: DATE_RANGES.THIS_WEEK, label: i18n.t('commons:this_week')},
    {value: DATE_RANGES.THIS_MONTH, label: i18n.t('commons:this_month')},
    {value: DATE_RANGES.THIS_YEAR, label: i18n.t('commons:this_year')},
    {value: DATE_RANGES.LAST_WEEK, label: i18n.t('commons:last_week')},
    {value: DATE_RANGES.LAST_MONTH, label: i18n.t('commons:last_month')}
]

export const StatsPerformance: FC<StatsPerformanceType> = ({
    rangeType,
    onRangeTypeSelect,
    dateRange,
    onDateRangeChange,
    metric,
    onMetricChange
}) => {
    const {t} = useTranslation()

    const isDateRangeValid = dateRange?.filter(date => date).length == 2

    return (
        <Flexbox direction="column" gap={4} width={'100%'}>
            <StyledStatHeading>{t('tasks:performance')}</StyledStatHeading>

            <Flexbox gap={4} align="end">
                <InputSelect
                    value={dateRangeOptions.find(opt => opt.value === rangeType)}
                    width={176}
                    placeholder={t('commons:date_range')}
                    label={t('commons:date_range')}
                    onChange={newSelection => {
                        const value = (newSelection as SingleValue<SelectValue> | undefined)?.value
                        if (!value) {
                            return
                        }
                        onRangeTypeSelect(value as DateRangeType)
                    }}
                    options={dateRangeOptions}
                />
                <Flexbox gap={1} align="center">
                    {rangeType === 'custom' && (
                        <Flexbox width={'272px'}>
                            <DatePicker
                                mode="range"
                                numMonths={2}
                                maxDate={new Date()}
                                selectedDates={[
                                    ...(dateRange[0] ? [dateRange[0]] : []),
                                    ...(dateRange[1] ? [dateRange[1]] : [])
                                ]}
                                formatDateFn={date => formatLocaleDate(date.toISOString())}
                                triggerProps={{
                                    typeIcon: <CalendarIcon size={20} />,
                                    placeholder: t('commons:no_date_selected') satisfies string
                                }}
                                onBlur={() => {
                                    if (!isDateRangeValid) {
                                        onDateRangeChange(null)
                                    }
                                }}
                                onDatesChange={dates => {
                                    if (!dates.length) {
                                        onDateRangeChange(null)
                                        return
                                    }
                                    onDateRangeChange([dates[0], dates[1] ?? null])
                                }}
                            />
                        </Flexbox>
                    )}
                </Flexbox>
                <StyledButtonGroup>
                    <button
                        className={metric === 'efficiency' ? 'selected' : ''}
                        onClick={() => onMetricChange('efficiency')}
                    >
                        {t('commons:efficiency')}
                    </button>
                    <button
                        className={metric === 'quality' ? 'selected' : ''}
                        onClick={() => onMetricChange('quality')}
                    >
                        {t('commons:quality')}
                    </button>
                    <button
                        className={metric === 'throughput' ? 'selected' : ''}
                        onClick={() => onMetricChange('throughput')}
                    >
                        {t('commons:throughput')}
                    </button>
                </StyledButtonGroup>
            </Flexbox>
        </Flexbox>
    )
}
