import {Navigate, Outlet} from 'react-router-dom'
import {useAuth} from '@context/AuthProvider.tsx'
import {roleBasedRedirect} from '@/features/roles/helpers.tsx'

const PublicRoute = () => {
    const {accessToken, user} = useAuth()

    return accessToken ? <Navigate to={roleBasedRedirect(accessToken, user?.role.slug)} /> : <Outlet />
}

export default PublicRoute
