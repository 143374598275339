import {Flexbox} from '@/components/ui/Flexbox'
import {ManHoursCharts} from '@/features/analytics/components/man-hours-charts'
import {StatsLoader} from '@/features/analytics/components/stats-loader'
import {TasksLoadingCharts} from '@/features/analytics/components/tasks-loading-charts'
import {DATE_RANGES, TASK_TYPES, TaskType} from '@/features/analytics/constants'
import {dateRangeTypeToDateRange} from '@/features/analytics/helpers'
import {usePerformanceStatsSearchParams} from '@/features/analytics/hooks/usePerformanceSearchParams'
import {useManHours} from '@/features/analytics/queries/useManHours'
import {useTasksLoading} from '@/features/analytics/queries/useTasksLoading'
import {FC, useMemo, useState} from 'react'
import {ChartProps} from '../types'

export const TroughputCharts: FC<ChartProps> = ({projectId, subprojectId, subbatchId}) => {
    const [loadingTasksType, setLoadingTaskType] = useState<TaskType>(TASK_TYPES.ALL)
    const [manHourTasksType, setManHourTaskType] = useState<TaskType>(TASK_TYPES.ALL)

    const {
        searchParams: {range_type, start_date, end_date}
    } = usePerformanceStatsSearchParams()

    const params = useMemo(() => {
        return {
            ...(range_type === DATE_RANGES.CUSTOM && start_date && end_date
                ? {start_date, end_date}
                : dateRangeTypeToDateRange(range_type))
        }
    }, [range_type, start_date, end_date])

    const {
        data: tasksLoading,
        isLoading: isLoadingTasksLoading,
        error: errorTasksLoading,
        isSuccess: isSuccessTasksLoading
    } = useTasksLoading({
        ...params,
        ...(loadingTasksType !== TASK_TYPES.ALL && {task_type: loadingTasksType}),
        ...(projectId && {project_id: projectId}),
        ...(subprojectId && {subproject_id: subprojectId}),
        ...(subbatchId && {subbatch_idb: subbatchId})
    })

    const {
        data: manHours,
        isLoading: isLoadingManHours,
        error: errorManHours,
        isSuccess: isSuccessManHours
    } = useManHours({
        ...params,
        ...(manHourTasksType !== TASK_TYPES.ALL && {task_type: manHourTasksType}),
        ...(projectId && {project_id: projectId}),
        ...(subprojectId && {subproject_id: subprojectId}),
        ...(subbatchId && {subbatch_idb: subbatchId})
    })

    if (errorTasksLoading || errorManHours) {
        throw errorTasksLoading || errorManHours
    }

    return (
        <Flexbox direction="column" gap={4} width={'100%'}>
            {isLoadingTasksLoading || !isSuccessTasksLoading ? (
                <StatsLoader />
            ) : (
                <TasksLoadingCharts
                    taskType={loadingTasksType}
                    onTaskTypeChange={setLoadingTaskType}
                    data={tasksLoading.data}
                    total={tasksLoading.total}
                />
            )}
            {isLoadingManHours || !isSuccessManHours ? (
                <StatsLoader />
            ) : (
                <ManHoursCharts
                    taskType={manHourTasksType}
                    onTaskTypeChange={setManHourTaskType}
                    data={manHours.data}
                    total_consumed={manHours.total_consumed}
                    total_estimated={manHours.total_estimated}
                />
            )}
        </Flexbox>
    )
}
