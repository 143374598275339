import styled, {css} from 'styled-components'

export const StyledProjectProperties = styled.form`
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    & > * {
        min-height: 0;
    }
`

export const ProjectPropertiesSectionsWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        overflow-y: auto;
        .dataset-information {
            font-size: ${typography.textXs};
            font-weight: 400;
            color: ${palette.neutral[600]};
        }

        section {
            width: 100%;
        }

        .section-container > p {
            font-size: ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[700]};
            margin-bottom: ${spacing * 2}px;
        }
    `}
`
