import {useQuery} from '@tanstack/react-query'
import {httpGetProjectBatchesStatus} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type useProjectBatchesStatusProps = {
    id: number
}

export const useProjectBatchesStatus = ({id}: useProjectBatchesStatusProps) => {
    return useQuery({
        staleTime: 0,
        cacheTime: 0,
        queryKey: [QUERY_KEYS.PROJECT_BATCHES_STATUS, id],
        queryFn: () => httpGetProjectBatchesStatus(id)
    })
}
