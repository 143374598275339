import styled, {css, DefaultTheme} from 'styled-components'
import {GroupBase, StylesConfig} from 'react-select'
import {SelectValue} from '@components/commons/Select/index.tsx'
import {Percentage} from '@/types/commons.ts'

interface StyledContainerProps {
    width?: number | Percentage
}

export const StyledContainer = styled.div(
    ({width}: StyledContainerProps) => css`
        position: relative;
        min-height: auto;
        width: ${width ? (typeof width == 'number' ? `${width}px` : width) : '100%'};
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    `
)

type CustomStylesType = {
    theme: DefaultTheme
    isError: boolean
}
//the following code should be jss for react select custom styling
export const customStyles = ({
    theme: {palette, spacing, shadows},
    isError
}: CustomStylesType): StylesConfig<SelectValue, boolean, GroupBase<SelectValue>> => ({
    control: (baseStyles, state) => ({
        ...baseStyles,
        background: palette.neutral.white,
        padding: `0 ${spacing * 3.5}px`,
        width: '100%',
        minHeight: 44,
        border: `1px solid`,
        borderColor: state.menuIsOpen ? `${palette.primary['300']}!important` : `${palette.neutral['300']}!important`,
        boxShadow: state.menuIsOpen ? `${shadows.xs}, 0px 0px 0px 4px ${palette.primary['100']}!important` : shadows.xs,
        borderRadius: '8px',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        ...(state.isDisabled && {
            background: palette.neutral['100'] + '!important'
        }),
        ...(isError && {
            borderColor: palette.danger['300'] + '!important'
        }),
        ...(isError &&
            state.menuIsOpen && {
                boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${palette.danger['100']}!important`
            })
    }),
    placeholder: provided => ({
        ...provided,
        //text/md regular
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 400,
        margin: 0,
        color: palette.neutral['500']
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
        padding: 0,
        textAlign: 'start',
        //text/md medium,
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 500,
        color: palette.neutral['900']
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
        padding: `0 0 0 ${spacing * 2}px`,
        '& svg': {
            fill: `${palette.neutral['500']}`
        }
    }),

    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s ease'
    }),

    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        display: 'none'
    }),

    menu: baseStyles => ({
        ...baseStyles,
        borderRadius: `${spacing * 2}px`,
        overflow: 'hidden',
        marginTop: spacing,
        boxShadow: shadows.lg,
        padding: `0 ${spacing * 1.5}px`,
        border: `1px solid ${palette.neutral['200']}`
    }),

    menuList: baseStyles => ({
        ...baseStyles,
        padding: `${spacing}px 0`,
        cursor: 'pointer'
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        textAlign: 'start',
        //text/md medium,
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: palette.neutral['900'],
        borderRadius: `${spacing * 1.5}px`,
        background:
            state.isFocused && !state.isSelected
                ? palette.neutral['50']
                : state.isSelected
                ? palette.primary['100']
                : 'transparent',
        '&:active': {
            background: 'transparent'
        }
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${spacing * 0.5}px ${spacing}px`,
        gap: spacing,
        height: 24,
        background: palette.neutral.white,
        border: `1px solid ${palette.neutral['300']}`,
        borderRadius: '6px'
    }),
    multiValueLabel: baseStyles => ({
        ...baseStyles,
        //Text sm/Medium
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        color: palette.neutral['700']
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles
    }),
    input: baseStyles => ({
        ...baseStyles,
        margin: 0
    })
})
