import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {z} from 'zod'

const TasksStatsSearchParamsSchema = z.object({
    task_type: z.enum(['all', 'edit', 'qa']).optional().default('all')
})

export type TasksStatsSearchParamsSchema = z.infer<typeof TasksStatsSearchParamsSchema>

export const useTasksStatsSearchParams = () => {
    return useParsedSearchParams([], TasksStatsSearchParamsSchema)
}
