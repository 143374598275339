import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {PROJECTS_HEADER_OBJ, routes} from '@utilities/constants'
import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {useProject} from '@/features/projects/queries/useProject.ts'
import {useUpdateProject} from '@/features/projects/queries/useUpdateProject'
import {toast} from 'react-hot-toast'
import {FC, Fragment} from 'react'
import {ProjectRecord, ProjectStructure, TemplateValues} from '@/features/projects/types'
import {useProjectStructure} from '@/features/projects/queries/useProjectStructure'
import {generateField} from '@/features/projects/form/fieldgenerator'
import {Flexbox} from '@/components/ui/Flexbox'
import {ProjectSettingsContentLoader} from '@/features/projects/components/project-content-loaders/ProjectSettingsContentLoader'
import {ProjectPropertiesSectionsWrapper, StyledProjectProperties} from './style'
import {ProjectStepActions} from '@/features/projects/components/project-step-actions/ProjectStepActions'
import {DynamicErrors, setErrorsFromResponse} from '@/features/projects/form/dynamicErrors'
import {AxiosError} from 'axios'

export const ProjectProperties = () => {
    const {id} = useParams()
    const {data: project, isLoading: isLoadingProject, error: ProjectError} = useProject(id)
    const {
        data: projectStructure,
        isLoading: isLoadingProjectStructure,
        error: projectStructureError
    } = useProjectStructure(id)

    if (ProjectError || projectStructureError) {
        throw ProjectError ?? projectStructureError
    }

    return (
        <>
            {(isLoadingProject || isLoadingProjectStructure) && <ProjectSettingsContentLoader />}
            {project && projectStructure && (
                <ProjectPropertiesWithProjectData project={project} projectStructure={projectStructure} />
            )}
        </>
    )
}

type ProjectPropertiesWithProjectDataProps = {
    project: ProjectRecord
    projectStructure: ProjectStructure
}
export const ProjectPropertiesWithProjectData: FC<ProjectPropertiesWithProjectDataProps> = ({
    project,
    projectStructure
}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {
        handleSubmit,
        control,
        register,
        getValues,
        watch,
        setError,
        formState: {errors}
    } = useForm({
        mode: 'onBlur',
        defaultValues: project.template_values
    })

    const {
        mutate: updateProject,
        mutateAsync: updateProjectAsync,
        isLoading: isLoadingUpdateProject
    } = useUpdateProject({
        onSuccess: () => {
            toast.success(t('projectDetails:updatedCorrectly'))
        },
        onError: e => {
            setErrorsFromResponse(e as AxiosError<DynamicErrors>, setError, t)
        }
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit: SubmitHandler<any> = values => {
        if (!project) {
            throw new Error('Cannot update a project before fetching it')
        }
        updateProject({
            id: project.id,
            data: {
                template_values: values
            }
        })
    }

    const onClose = () => {
        navigate(PROJECTS_HEADER_OBJ.path)
        console.log('on close callback')
    }

    const watchedRequired = watch(
        Object.entries(projectStructure)
            .filter(([, v]) => v.is_required)
            .map(([k]) => k)
    )

    const isNextEnabled = watchedRequired.reduce((prev, curr) => prev && curr, true)

    const onNextClick = () => {
        const values = getValues()
        updateProjectAsync({
            id: project.id,
            data: {
                priority: values?.priority || null,
                template_values: {
                    data_source_id: values?.data_source_id,
                    data_target_id: values?.data_target_id,
                    taxonomy_id: values?.taxonomy_id
                } as TemplateValues
            }
        }).then(() => {
            navigate(generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_PROCESS.path}`, {id}))
        })
    }

    return (
        <StyledProjectProperties onSubmit={handleSubmit(onSubmit)}>
            <ProjectPropertiesSectionsWrapper>
                <Flexbox direction="column" gap={4} width="384px">
                    {Object.entries(projectStructure).map(([k, v]) => {
                        return (
                            <Fragment key={k}>
                                {generateField({
                                    fieldName: k,
                                    fieldData: v,
                                    control,
                                    errors,
                                    register,
                                    t
                                })}
                            </Fragment>
                        )
                    })}
                </Flexbox>
            </ProjectPropertiesSectionsWrapper>
            <ProjectStepActions
                onCloseCb={onClose}
                onNextClickCb={onNextClick}
                nextButtonDisabled={!isNextEnabled}
                nextStepLabel={t('projectDetails:tabs:process')}
                isLoading={isLoadingUpdateProject}
            />
        </StyledProjectProperties>
    )
}

ProjectProperties.displayName = 'ProjectProperties'
