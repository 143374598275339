import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetProjectBatches,
    httpGetSubProjectBatches,
    BatchesIndexParams
} from '@/features/batches/services/batches.http.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {ProjectBatchesSearchParams} from '@/features/batches/types.ts'

const useProjectBatches = (id: number, params?: Omit<BatchesIndexParams, 'currentPage'>) => {
    const {user} = useAuth()
    const canQueryProject = user?.role.slug === 'program_manager' || user?.role.slug === 'client_engineer'
    const multiParams = [] as const satisfies ReadonlyArray<keyof ProjectBatchesSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, ProjectBatchesSearchParams)

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PROJECT_BATCHES, id, params],
        queryFn: ({pageParam = 1}) => {
            const remapParams = () => {
                if (params) {
                    const {orderBy, orderDirection, ...restParams} = params
                    return {
                        ...restParams,
                        perPage: 50,
                        order_direction: orderDirection,
                        order_by: orderBy
                    }
                } else {
                    return {}
                }
            }

            //Project & Subproject check based on role
            return canQueryProject
                ? httpGetProjectBatches({
                      projectId: id,
                      params: {
                          ...remapParams(),
                          currentPage: pageParam
                      }
                  })
                : httpGetSubProjectBatches({
                      subProjectId: id,
                      params: {
                          ...remapParams(),
                          currentPage: pageParam
                      }
                  })
        },
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    const sort = (sorter: {
        orderBy: ProjectBatchesSearchParams['orderBy']
        orderDirection: ProjectBatchesSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? [],
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}

export default useProjectBatches
