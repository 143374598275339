import axios from './axiosInstance.ts'
import {LoginFormData, User} from '@/types/auth.ts'
import {AxiosResponse} from 'axios'

type SSOUrlResponse = {url: string}
export const httpOneLoginSSO = () => {
    return axios.get<SSOUrlResponse>('auth/onelogin-url')
}

type LoginResponse = {
    user: User
    tokens: {
        accessToken: string
        refreshToken: string
    }
}
export const httpLogin = (values: LoginFormData) => {
    return axios.post<LoginResponse>('/login', values)
}

export const httpSSOGetUser = (code: string) => {
    return axios.get<LoginResponse>(`auth/login/onelogin?code=${code}`)
}

export const httpLogout = () => {
    return axios.get('auth/logout')
}

export const httpUserMe = () => {
    return axios.get('users/me').then((response: AxiosResponse<User>) => response.data)
}
