import axios from '@services/axiosInstance.ts'

type AmazonS3DataConfig = {
    type: 's3'
    configuration: {
        storage_name: string
        bucket_name: string
        bucket_prefix: string
        region_name: string
        s3_endpoint: string
        access_key_id: string
        secret_access_key: string
        session_token: string
        file_filter_regex?: string
    }
}

type GoogleCloudDataConfig = {
    type: 'google_cloud'
    configuration: {
        bucket_name: string
        credentials: {
            type: string
            auth_uri: string
            client_id: string
            token_uri: string
            project_id: string
            private_key: string
            client_email: string
            private_key_id: string
            universe_domain: string
            client_x509_cert_url: string
            auth_provider_x509_cert_url: string
        }
        storage_name: string
        bucket_prefix: string
        file_filter_regex?: string
    }
}

type GoogleDriveDataConfig = {
    type: 'google_drive'
    configuration: {
        folder_key: string
        credentials: {
            type: string
            auth_uri: string
            client_id: string
            token_uri: string
            project_id: string
            private_key: string
            client_email: string
            private_key_id: string
            universe_domain: string
            client_x509_cert_url: string
            auth_provider_x509_cert_url: string
        }
        file_filter_regex?: string
    }
}

export type CreateDataServicePayload = {
    company_id?: number
    data_config: AmazonS3DataConfig | GoogleCloudDataConfig | GoogleDriveDataConfig
}

export const httpCreateDataService = (payload: CreateDataServicePayload) => {
    return axios.post(`data-services`, payload).then(response => response.data)
}

export type EditDataServicePayload = Partial<CreateDataServicePayload> & {data_config: {id: number}}

export const httpEditDataService = (id: number, payload: EditDataServicePayload) => {
    return axios.patch(`data-services/${id}`, payload).then(response => response.data)
}

export type GetDataServiceResponse = {
    id: number
    company_id: number
    data_config: {id: number} & (AmazonS3DataConfig | GoogleCloudDataConfig | GoogleDriveDataConfig)
}

export const httpGetDataService = (id: number) => {
    return axios.get(`data-services/${id}`).then(response => response.data as GetDataServiceResponse)
}
