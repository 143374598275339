import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetUsers} from '@/features/users/services/users.http.ts'
import {UsersRoleSearchParams} from '@/features/users/types.ts'

type useUserSelectProps = {
    searchValue?: UsersRoleSearchParams['search']
    roleId: number
}

export const useUsersSelect = ({roleId, ...params}: useUserSelectProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.USERS, roleId, 'full_name', 'desc', params.searchValue],
        queryFn: () => httpGetUsers(1, 'full_name', 'desc', params.searchValue, roleId),
        keepPreviousData: true
    })
}
