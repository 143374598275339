import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpManualBulkAssignBatches} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type useManualBulkAssignBatchesProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useManualBulkAssignBatches = (options?: useManualBulkAssignBatchesProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: httpManualBulkAssignBatches,
        onError: options?.onError,
        onSuccess: (_, variables) => {
            options?.onSuccess?.()
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES, variables.projectId])
            queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_BATCHES])
        }
    })
}
