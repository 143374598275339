import {Edge, Position} from 'reactflow'

import {NODE_CONTAINER_PADDING, NODE_HEIGHT, NODE_WIDTH, TERMINAL_NODE_SIZE} from '../components/nodes/constants'
import dagre from 'dagre'
import {GraphNode} from '../types'

const sizeByType = {
    processNode: {width: NODE_WIDTH + NODE_CONTAINER_PADDING, height: NODE_HEIGHT + NODE_CONTAINER_PADDING},
    tempProcessNode: {width: NODE_WIDTH + NODE_CONTAINER_PADDING, height: NODE_HEIGHT + NODE_CONTAINER_PADDING},
    endNode: {width: TERMINAL_NODE_SIZE + NODE_CONTAINER_PADDING, height: TERMINAL_NODE_SIZE + NODE_CONTAINER_PADDING},
    startNode: {width: TERMINAL_NODE_SIZE + NODE_CONTAINER_PADDING, height: TERMINAL_NODE_SIZE + NODE_CONTAINER_PADDING}
}

export const getLayoutedElements = (
    nodes: (GraphNode & {targetPosition?: Position; sourcePosition?: Position})[],
    edges: Edge[]
) => {
    const dagreGraph = new dagre.graphlib.Graph()
    dagreGraph.setDefaultEdgeLabel(() => ({}))

    dagreGraph.setGraph({rankdir: 'RL', ranker: 'tight-tree', ranksep: 0, nodesep: 0})

    nodes.forEach(node => {
        const {width, height} = sizeByType[node.type]
        dagreGraph.setNode(node.id, {width, height})
    })

    edges.forEach(edge => {
        dagreGraph.setEdge(edge.target, edge.source)
    })
    dagre.layout(dagreGraph)

    nodes.forEach(node => {
        const nodeWithPosition = dagreGraph.node(node.id)
        node.targetPosition = Position.Left
        node.sourcePosition = Position.Right

        const {width, height} = sizeByType[node.type]
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - width / 2,
            y: nodeWithPosition.y - height / 2
        }

        return node
    })
    return {nodes, edges}
}
