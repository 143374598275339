import DatePicker from '@/components/commons/DatePicker'
import InputText from '@/components/commons/InputText'
import Button from '@/components/ui/Button'
import {Flexbox} from '@/components/ui/Flexbox'
import {CalendarIcon} from '@/components/ui/Icon'
import {Slug as RoleSlug} from '@/types/auth'
import {Label} from '@radix-ui/react-dropdown-menu'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {BatchRecord} from '../../types'
import {Controller, useForm} from 'react-hook-form'
import {FormValues, validationSchema} from './form-model'
import {zodResolver} from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import {useEditProjectBatch} from '../../queries/useEditProjectBatch'
import toast from 'react-hot-toast'

type BatchEditSidebarFormProps = {
    projectId: number
    batch: BatchRecord
    onSuccess?: () => void
    onClose: () => void
    roleSlug: Extract<RoleSlug, 'vendor_manager' | 'program_manager'>
}

export const BatchEditSidebarForm: FC<BatchEditSidebarFormProps> = ({
    projectId,
    batch,
    roleSlug,
    onSuccess,
    onClose
}) => {
    const {mutate: editProjectBatch, isLoading} = useEditProjectBatch({
        onError: () => {
            toast.error(t('commons:genericError'))
        },
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onSuccess?.()
        }
    })
    const {t} = useTranslation()
    const {
        register,
        control,
        handleSubmit,
        formState: {isValid, errors}
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues: {
            name: batch.name,
            priority: batch.priority,
            need_date: batch.need_date ? new Date(batch.need_date) : null,
            possible_date: batch.possible_date ? new Date(batch.possible_date) : null
        }
    })

    const onSubmit = (values: FormValues) => {
        editProjectBatch({
            projectId,
            batchId: batch.id,
            payload: {
                name: values.name,
                priority: values.priority || null,
                need_date: values.need_date?.toISOString() || null,
                possible_date: values.possible_date?.toISOString() || null
            }
        })
    }

    return (
        <Flexbox onSubmit={handleSubmit(onSubmit)} as="form" width={'100%'} height={'100%'} direction="column">
            <Flexbox direction="column" className="header">
                <h2>{t('projectDetails:batches:edit_batch')}</h2>
                <h3>project-batch-name</h3>
            </Flexbox>

            <Flexbox direction="column" className="body" gap={6} width={'100%'}>
                <InputText
                    {...register('name')}
                    disabled={roleSlug === 'vendor_manager'}
                    type="text"
                    label={t('commons:name')}
                    placeholder={t('commons:name') satisfies string}
                    errorMessage={t(errors.name?.message ?? '')}
                />
                <InputText
                    {...register('priority')}
                    disabled={roleSlug === 'vendor_manager'}
                    type="text"
                    label={t('commons:priority_optional')}
                    placeholder={t('commons:enter_priority') satisfies string}
                    helpText={t('commons:priority_helper')}
                    errorMessage={t(errors.priority?.message ?? '')}
                />

                <Flexbox direction="column" className="w-full" gap={1.5}>
                    <Label>{t('projectDetails:batches:need_date')}</Label>
                    <Controller
                        name="need_date"
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                            <DatePicker
                                minDate={new Date()}
                                onBlur={onBlur}
                                disabled={roleSlug === 'vendor_manager'}
                                mode="single"
                                triggerProps={{
                                    typeIcon: <CalendarIcon size={20} />,
                                    placeholder: t('commons:no_date_selected') satisfies string
                                }}
                                formatDateFn={d => dayjs(d).format('DD/MM/YYYY')}
                                numMonths={1}
                                onDatesChange={dates => {
                                    const [date] = dates
                                    onChange(date)
                                }}
                                selectedDates={value ? [value] : []}
                                errorMessage={t(errors.need_date?.message ?? '') satisfies string}
                            />
                        )}
                    />
                </Flexbox>

                <Flexbox direction="column" className="w-full" gap={1.5}>
                    <Label>{t('projectDetails:batches:possible_date')}</Label>
                    <Controller
                        name="possible_date"
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                            <DatePicker
                                minDate={new Date()}
                                onBlur={onBlur}
                                disabled={roleSlug === 'program_manager'}
                                mode="single"
                                triggerProps={{
                                    typeIcon: <CalendarIcon size={20} />,
                                    placeholder: t('commons:no_date_selected') satisfies string
                                }}
                                formatDateFn={d => dayjs(d).format('DD/MM/YYYY')}
                                numMonths={1}
                                onDatesChange={dates => {
                                    const [date] = dates
                                    onChange(date)
                                }}
                                selectedDates={value ? [value] : []}
                                errorMessage={t(errors.possible_date?.message ?? '') satisfies string}
                            />
                        )}
                    />
                </Flexbox>
            </Flexbox>
            <Flexbox justify="end" className="footer" gap={3} width={'100%'}>
                <Button variant="secondary" size="lg" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button type="submit" variant="primary" size="lg" disabled={!isValid || isLoading}>
                    {t('commons:save')}
                </Button>
            </Flexbox>
        </Flexbox>
    )
}
