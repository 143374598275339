import {useSearchParams} from 'react-router-dom'
import {useGraphContext} from '../context/graphContext'
import {SELECTED_NODE_PARAM_KEY} from '../constants'
import {useEffect} from 'react'

export const useIsNodeSidebarOpen = () => {
    const [params, setParams] = useSearchParams()
    const {nodes} = useGraphContext()
    const selectedNodeId = params.get(SELECTED_NODE_PARAM_KEY)
    const doesNodeExists = nodes.find(node => node.id === selectedNodeId)
    useEffect(() => {
        if (selectedNodeId && !doesNodeExists) {
            setParams(prev => {
                prev.delete(SELECTED_NODE_PARAM_KEY)
                return prev
            })
        }
    }, [params, setParams, selectedNodeId, doesNodeExists])
    return {isOpen: !!(selectedNodeId && doesNodeExists), selectedNode: nodes.find(node => node.id === selectedNodeId)}
}
