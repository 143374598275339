import {BaseEdge, EdgeProps} from 'reactflow'
import {useTheme} from 'styled-components'

export const WideStepEdge = (props: EdgeProps) => {
    const {palette} = useTheme()
    const {sourceX, sourceY, targetX, targetY, markerEnd, target} = props

    const endEdgeSteps = [`H${targetX - 10}`, `V${targetY}`, `H${targetX}`]
    const regularEdgeSteps = [`H${sourceX + 10}`, `V${targetY}`, `H${targetX}`]

    const isTargetEndNode = target === 'end'

    const edgePath = [`M${sourceX},${sourceY}`, ...(isTargetEndNode ? endEdgeSteps : regularEdgeSteps)].join(' ')

    return (
        <BaseEdge
            path={edgePath}
            markerEnd={markerEnd}
            style={{
                strokeWidth: 2,
                strokeLinecap: 'round',
                stroke: palette.neutral[300]
            }}
        />
    )
}
