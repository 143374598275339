import {
    GetProjectsResponse,
    GetSubProjectsResponse,
    ProjectStructure,
    ProjectRecord,
    ProjectsSearchParams,
    SubProjectRecord
} from '@/features/projects/types.ts'
import axios from '@services/axiosInstance.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {AxiosResponse} from 'axios'
import {User} from '@/types/auth.ts'
import {Company} from '@/features/companies/types'

export const httpGetProjects = (
    pageParam: number,
    orderBy: ProjectsSearchParams['orderBy'],
    orderDirection: ProjectsSearchParams['orderDirection'],
    searchValue: ProjectsSearchParams['search']
): Promise<GetProjectsResponse> => {
    const params = {
        search: searchValue,
        perPage: 50,
        currentPage: pageParam,
        order_by: orderBy,
        order_direction: orderDirection
    }

    return axios
        .get('projects', {params})
        .then(response => response.data)
        .catch(errorHandler)
}

export const httpGetSubProjects = (
    pageParam: number,
    orderBy: ProjectsSearchParams['orderBy'],
    orderDirection: ProjectsSearchParams['orderDirection'],
    searchValue: ProjectsSearchParams['search']
): Promise<GetSubProjectsResponse> => {
    const params = {
        search: searchValue,
        perPage: 50,
        currentPage: pageParam,
        order_by: orderBy,
        order_direction: orderDirection
    }

    return axios
        .get('subprojects', {params})
        .then(response => response.data)
        .catch(errorHandler)
}

export const httpGetProjectById = (id: number | string): Promise<ProjectRecord> => {
    return axios
        .get(`projects/${id}`)
        .then(response => response.data)
        .catch(errorHandler)
}

export const httpGetSubProjectById = (id: number | string): Promise<SubProjectRecord> => {
    return axios
        .get(`subprojects/${id}`)
        .then(response => response.data)
        .catch(errorHandler)
}

export const httpGetProjectStructure = (id: number | string): Promise<ProjectStructure> => {
    return axios
        .get(`projects/${id}/structure`)
        .then(response => response.data)
        .catch(errorHandler)
}

export const httpCreateProject = (data: object): Promise<ProjectRecord> => {
    return axios.post(`projects`, {...data}).then(response => response.data)
}

export const httpDeleteProject = (id: number): Promise<AxiosResponse> => {
    return axios.delete(`projects/${id}`)
}

type EditProjectArgs = {
    id?: number | string
    data: Omit<Partial<ProjectRecord>, 'contact_users' | 'vendors'> & {
        contact_users?: string[] | User[]
        vendors?: string[] | null
        all_vendors?: boolean
    }
}
export const httpUpdateProject = ({id, data}: EditProjectArgs): Promise<ProjectRecord> => {
    return axios.patch(`projects/${id}`, {...data}).then(response => response.data)
}

export const httpCloneProject = (project: ProjectRecord): Promise<ProjectRecord> => {
    return axios.post(`projects/${project.id}/clone`, {name: project.name}).then(response => response.data)
}

export type httpGetProjectVendorsParams = {
    vendor_id?: number
    vendor_name?: string
    vendor_manager?: string
    order_by?: string
    order_direction?: string
}

export const httpGetProjectVendors = (projectId: number, params?: httpGetProjectVendorsParams) => {
    return axios.get(`projects/${projectId}/vendors`, {params}).then(res => res.data as Company[])
}
