import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetCompaniesVendors, httpGetCompaniesVendorsParams} from '@/features/companies/services/companies.http.ts'

export const useCompaniesVendors = (params?: httpGetCompaniesVendorsParams) => {
    const query = useQuery({
        queryKey: [QUERY_KEYS.COMPANIES, params],
        queryFn: () => httpGetCompaniesVendors(params)
    })

    return {
        ...query,
        data: query.data ?? []
    }
}
