import {palette} from '@/theme/palette.ts'
import {Badge} from '@components/ui/Badge'
import {ProjectStatus} from '@/features/projects/types.ts'
import {TFunction} from 'i18next'
import {BatchRecord, BatchStatus} from '@/features/batches/types.ts'
import {DotsVerticalIcon, Edit01Icon, PauseCircleIcon, PlayIcon, StopSquareIcon} from '@components/ui/Icon'
import DropdownMenu from '@components/ui/DropdownMenu'

const statusBadges = {
    draft: {
        label: 'projects:statuses:draft',
        borderColor: palette.light.neutral['200'],
        color: palette.light.neutral['700'],
        background: palette.light.neutral['50'],
        icon: null
    },
    syncing: {
        label: 'projects:statuses:syncing',
        borderColor: palette.light.tertiary['200'],
        color: palette.light.tertiary['700'],
        background: palette.light.tertiary['50'],
        icon: null
    },
    paused: {
        label: 'projects:statuses:paused',
        borderColor: palette.light.warning['200'],
        color: palette.light.warning['700'],
        background: palette.light.warning['50'],
        icon: null
    },
    in_progress: {
        label: 'projects:statuses:in_progress',
        borderColor: palette.light.primary['200'],
        color: palette.light.primary['700'],
        background: palette.light.primary['50'],
        icon: null
    },
    inactive: {
        label: 'projects:statuses:inactive',
        borderColor: palette.light.danger['200'],
        color: palette.light.danger['700'],
        background: palette.light.danger['50'],
        icon: null
    },
    completed: {
        label: 'projects:statuses:completed',
        borderColor: palette.light.success['200'],
        color: palette.light.success['700'],
        background: palette.light.success['50'],
        icon: null
    }
} as const

export const projectBadgeRenderer = (status: ProjectStatus | null, t: TFunction) => {
    switch (status) {
        case 'draft':
            return (
                <Badge
                    label={t(statusBadges['draft'].label)}
                    icon={null}
                    color={statusBadges['draft'].color}
                    borderColor={statusBadges['draft'].borderColor}
                    background={statusBadges['draft'].background}
                />
            )
        case 'syncing':
            return (
                <Badge
                    label={t(statusBadges['syncing'].label)}
                    icon={null}
                    color={statusBadges['syncing'].color}
                    borderColor={statusBadges['syncing'].borderColor}
                    background={statusBadges['syncing'].background}
                />
            )
        case 'paused':
            return (
                <Badge
                    label={t(statusBadges['paused'].label)}
                    icon={null}
                    color={statusBadges['paused'].color}
                    borderColor={statusBadges['paused'].borderColor}
                    background={statusBadges['paused'].background}
                />
            )
        case 'in_progress':
            return (
                <Badge
                    label={t(statusBadges['in_progress'].label)}
                    icon={null}
                    color={statusBadges['in_progress'].color}
                    borderColor={statusBadges['in_progress'].borderColor}
                    background={statusBadges['in_progress'].background}
                />
            )
        case 'inactive':
            return (
                <Badge
                    label={t(statusBadges['inactive'].label)}
                    icon={null}
                    color={statusBadges['inactive'].color}
                    borderColor={statusBadges['inactive'].borderColor}
                    background={statusBadges['inactive'].background}
                />
            )
        case 'completed':
            return (
                <Badge
                    label={t(statusBadges['completed'].label)}
                    icon={null}
                    color={statusBadges['completed'].color}
                    borderColor={statusBadges['completed'].borderColor}
                    background={statusBadges['completed'].background}
                />
            )
        default:
            return (
                <Badge
                    label={'-'}
                    icon={null}
                    color={statusBadges['draft'].color}
                    borderColor={statusBadges['draft'].borderColor}
                    background={statusBadges['draft'].background}
                />
            )
    }
}

/**
 * I understand that this may appear as redundant code, but considering that in the future,
 * batches and project statuses might differ significantly, maintaining them separately
 * is a more prudent approach to accommodate potential changes
 * @param status
 * @param t
 */
export const batchBadgeRenderer = (status: BatchStatus | null, t: TFunction) => {
    switch (status) {
        case 'in_progress':
            return (
                <Badge
                    label={t(statusBadges['in_progress'].label)}
                    icon={null}
                    color={statusBadges['in_progress'].color}
                    borderColor={statusBadges['in_progress'].borderColor}
                    background={statusBadges['in_progress'].background}
                />
            )
        case 'paused':
            return (
                <Badge
                    label={t(statusBadges['paused'].label)}
                    icon={null}
                    color={statusBadges['paused'].color}
                    borderColor={statusBadges['paused'].borderColor}
                    background={statusBadges['paused'].background}
                />
            )
        case 'inactive':
            return (
                <Badge
                    label={t(statusBadges['inactive'].label)}
                    icon={null}
                    color={statusBadges['inactive'].color}
                    borderColor={statusBadges['inactive'].borderColor}
                    background={statusBadges['inactive'].background}
                />
            )
        case 'completed':
            return (
                <Badge
                    label={t(statusBadges['completed'].label)}
                    icon={null}
                    color={statusBadges['completed'].color}
                    borderColor={statusBadges['completed'].borderColor}
                    background={statusBadges['completed'].background}
                />
            )
        default:
            return (
                <Badge
                    label={'-'}
                    icon={null}
                    color={statusBadges['draft'].color}
                    borderColor={statusBadges['draft'].borderColor}
                    background={statusBadges['draft'].background}
                />
            )
    }
}

/**
 * Here we conditionally render the actions for the batches record in table based on their status
 * @param batch
 * @param t
 */
export const batchesDropdownRenderByStatus = (batch: BatchRecord, t: TFunction) => {
    switch (batch.status) {
        case 'inactive':
        case 'paused':
            return (
                <DropdownMenu
                    trigger={<DotsVerticalIcon />}
                    actions={[
                        {
                            component: t('commons:edit'),
                            icon: <Edit01Icon size={16} />,
                            onClickCb: () => console.log('edit => ', batch)
                        },
                        {
                            component: t('commons:activate'),
                            icon: <PlayIcon size={16} />,
                            onClickCb: () => console.log('activate => ', batch)
                        }
                    ]}
                />
            )
        case 'in_progress':
            return (
                <DropdownMenu
                    trigger={<DotsVerticalIcon />}
                    actions={[
                        {
                            component: t('commons:edit'),
                            icon: <Edit01Icon size={16} />,
                            onClickCb: () => console.log('edit => ', batch)
                        },
                        {
                            component: t('commons:pause'),
                            icon: <PauseCircleIcon size={16} />,
                            onClickCb: () => console.log('pause => ', batch)
                        },
                        {
                            component: t('commons:deactivate'),
                            isDanger: true,
                            icon: <StopSquareIcon size={16} />,
                            onClickCb: () => console.log('deactivate => ', batch)
                        }
                    ]}
                />
            )
        default:
            return (
                <DropdownMenu
                    trigger={<DotsVerticalIcon />}
                    actions={[
                        {
                            component: t('commons:edit'),
                            icon: <Edit01Icon size={16} />,
                            onClickCb: () => console.log('batch edit =>', batch)
                        }
                    ]}
                />
            )
    }
}
