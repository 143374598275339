import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {httpUpdateProject} from '@/features/projects/services/projects.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

interface useUpdateProjectProps {
    onSuccess: () => void
    onError?: (e: unknown) => void
}

export const useUpdateProject = ({onSuccess, onError}: useUpdateProjectProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_PROJECT],
        /**
         * I removed the optimistic update logic, due to some problems: when we get the project the properties vendors is an array of Company object
         * When we edit the project is an array of strings (id).
         */
        mutationFn: httpUpdateProject,
        onSuccess: data => {
            /**
             * TODO We have a problem use Pojects accepts both a string or a number as id
             * This creates a lot of confusion when invalidating the query, since the type of the id matters in the key
             * We need to choose a single type for the id. Right now I am invalidating both for string and number, but this is not optimal
             */

            queryClient.invalidateQueries([QUERY_KEYS.PROJECTS])
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BY_ID, data.id.toString()])
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BY_ID, Number(data.id)])
            queryClient.invalidateQueries([QUERY_KEYS.PROCESS, data.id.toString()])
            queryClient.invalidateQueries([QUERY_KEYS.TAXONOMIES])
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_MEDIA_COUNT, data.id])
            onSuccess?.()
        },
        onError: onError ?? errorHandler
    })
}
