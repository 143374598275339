import Cookies from 'js-cookie'
import {envVars} from '@/envVars.ts'

const DOMAIN = envVars.VITE_HOST
const KEY = 'sessionid'
const VALUE = envVars.VITE_SESSION_ID ?? ''

export const sessionCookie = {
    set: () => Cookies.set(KEY, VALUE, {domain: DOMAIN, path: '', secure: false}),
    remove: () => Cookies.remove(KEY, {domain: DOMAIN, path: '', secure: false})
}
