import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {AddUsersResponse, httpAddUsers} from '@/features/users/services/users.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

interface UseAddUsersProps {
    onSuccess?: (response: AddUsersResponse) => void
}

export const useAddUsers = ({onSuccess}: UseAddUsersProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.ADD_USERS],
        mutationFn: httpAddUsers,
        onError: errorHandler,
        onSuccess: response => {
            queryClient.invalidateQueries([QUERY_KEYS.USERS])
            onSuccess?.(response)
        }
    })
}
