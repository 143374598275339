import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {httpGetProjects, httpGetSubProjects} from '@/features/projects/services/projects.http.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import {ProjectsSearchParams} from '@/features/projects/types.ts'

export const useProjects = ({orderBy = 'created_at', orderDirection = 'asc', search}: ProjectsSearchParams) => {
    const {user} = useAuth()
    const canQueryProject = user?.role.slug === 'program_manager' || user?.role.slug === 'client_engineer'

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PROJECTS, orderBy, orderDirection, search],
        queryFn: ({pageParam = 1}) =>
            canQueryProject
                ? httpGetProjects(pageParam, orderBy, orderDirection, search)
                : httpGetSubProjects(pageParam, orderBy, orderDirection, search),
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? []
    }
}
