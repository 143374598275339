import {io} from 'socket.io-client'
import {envVars} from '@/envVars.ts'

export const tasksSocket = io(`${envVars.VITE_API_ENDPOINT}projects`, {
    autoConnect: false,
    transports: ['websocket']
})

export const tasksSocketEvents = {
    JOIN_ROOM: 'joinRoom',
    LEAVE_ROOM: 'leaveRoom',
    TASK_SYNC_STATUS: 'taskSyncStatus'
}
