import {useTranslation} from 'react-i18next'
import {
    StyledProjectVendorsForm,
    StyledProjectVendorsGrid,
    StyledProjectVendorsSelection
} from '@pages/project-details/vendors/style.ts'
import {ProjectStepActions} from '@/features/projects/components/project-step-actions/ProjectStepActions.tsx'
import {ChangeEvent, FC, useMemo, useState} from 'react'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    ProjectVendorsFormSchema,
    ProjectVendorsValidationSchema
} from '@pages/project-details/vendors/vendorFormModel.ts'
import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {PROJECTS_HEADER_OBJ, routes} from '@utilities/constants'
import {Radio} from '@components/commons/Radio'
import {Flexbox} from '@components/ui/Flexbox'
import {SearchLgIcon} from '@components/ui/Icon'
import InputText from '@components/commons/InputText'
import CheckBox from '@components/commons/CheckBox'
import {ProjectRecord} from '@/features/projects/types'
import {useProject} from '@/features/projects/queries/useProject'
import Spinner from '@/components/ui/Spinner'
import {useUpdateProject} from '@/features/projects/queries/useUpdateProject'
import toast from 'react-hot-toast'
import {useCompaniesVendors} from '@/features/companies/queries/useCompaniesVendors.ts'
import {useProcesses} from '@/features/process/queries/useProcesses.ts'
import {VendorSortValue, VendorSorter} from '@/features/vendors/components/VendorSorter'

type SelectionCategory = 'selectVendors' | 'allVendors'

type ProjectVendorsWithDataProps = {
    project: ProjectRecord
}

export const ProjectVendors = () => {
    const {id} = useParams()
    if (!id) {
        throw new Error('Missing param : id')
    }

    const {data, isFetching, error} = useProject(Number(id))

    if (error) {
        throw error
    }

    if (isFetching || !data) {
        //TODO: remove spinner color black
        return (
            <Flexbox justify="center" align="center" height={'100%'} width={'100%'}>
                <Spinner size={48} color="black" />
            </Flexbox>
        )
    }
    return <ProjectVendorsWithData project={data} />
}

export const ProjectVendorsWithData: FC<ProjectVendorsWithDataProps> = ({project}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id} = useParams()
    const {data: processes} = useProcesses({id: Number(id)})
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState<VendorSortValue>({
        order_by: 'unit_capacity',
        order_direction: 'desc'
    })
    const query = useCompaniesVendors({search: search || undefined, ...sorting})
    const [selectionCategory, setSelectionCategory] = useState<SelectionCategory>('selectVendors')
    const form = useForm<ProjectVendorsValidationSchema>({
        mode: 'onBlur',
        defaultValues: {
            vendors: project.vendors?.map(company => company.id.toString()) ?? []
        },
        resolver: zodResolver(ProjectVendorsFormSchema)
    })

    const {mutate, mutateAsync, isLoading} = useUpdateProject({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
        }
    })

    const selectedVendorsIds = form.watch('vendors')
    const selectedVendors = query.data.filter(vendor => selectedVendorsIds?.includes(vendor.id.toString()))

    const filteredVendors = useMemo(
        () =>
            query.data.filter(vendor => {
                const includedInSearch = vendor.name.toLowerCase().includes(search.toLowerCase())
                const isSelected = selectedVendorsIds?.includes(`${vendor.id}`)
                return includedInSearch && !isSelected
            }),
        [search, selectedVendorsIds, query.data]
    )

    const onSubmit: SubmitHandler<ProjectVendorsValidationSchema> = data => {
        if (selectionCategory === 'selectVendors') {
            mutate({
                id: project.id,
                data: {
                    vendors: data.vendors?.length ? data.vendors : null
                }
            })
        } else {
            mutate({
                id: project.id,
                data: {
                    vendors: data.vendors?.length ? data.vendors : null,
                    all_vendors: true
                }
            })
        }
    }

    const onClose = () => {
        navigate(PROJECTS_HEADER_OBJ.path)
    }

    const onNextClick = async () => {
        const values = form.getValues()
        if (selectionCategory === 'selectVendors') {
            await mutateAsync({
                id: project.id,
                data: {
                    vendors: values.vendors?.length ? values.vendors : null
                }
            })
        } else {
            await mutateAsync({
                id: project.id,
                data: {
                    vendors: values.vendors?.length ? values.vendors : null,
                    all_vendors: true
                }
            })
        }

        navigate(generatePath(`${routes.PROJECT_DETAIL.path}/${routes.PROJECT_DETAIL_BATCHES.path}`, {id}))
    }

    const onSearch = (search: ChangeEvent<HTMLInputElement>) => {
        setSearch(search.target.value)
    }

    return (
        <FormProvider {...form}>
            <StyledProjectVendorsForm
                extraRow={!!selectedVendors?.length && !!filteredVendors.length}
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <StyledProjectVendorsSelection gap={12}>
                    <Flexbox gap={2}>
                        <Radio
                            name="selectionCategory"
                            defaultChecked
                            onChange={() => {
                                setSelectionCategory('selectVendors')
                            }}
                        />
                        <Flexbox direction="column">
                            <h2>{t('projectDetails:vendors:select_vendors')}</h2>
                            <p>{t('projectDetails:vendors:select_vendors_helper')}</p>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox gap={2}>
                        <Radio
                            name="selectionCategory"
                            onChange={() => {
                                setSelectionCategory('allVendors')
                            }}
                        />
                        <Flexbox direction="column">
                            <h2>{t('projectDetails:vendors:all_vendors')}</h2>
                            <p>{t('projectDetails:vendors:all_vendors_helper')}</p>
                        </Flexbox>
                    </Flexbox>
                </StyledProjectVendorsSelection>
                <Flexbox direction="column" gap={6}>
                    <h2>{t('projectDetails:vendors:select_vendor')}</h2>
                    <Flexbox justify="space-between" align="center" width="100%">
                        <InputText
                            width="320px"
                            type="text"
                            typeIcon={<SearchLgIcon size={20} />}
                            inputSize="sm"
                            placeholder={t('projectDetails:vendors:search_vendor') ?? 'Search vendor'}
                            onChange={onSearch}
                        />
                        <VendorSorter value={sorting} onChange={setSorting} />
                    </Flexbox>
                </Flexbox>
                <div className="selection">
                    {selectionCategory == 'selectVendors' ? (
                        <>
                            {selectedVendors?.length >= 1 && (
                                <section>
                                    <h4>{t('projectDetails:vendors:selected_vendor')}</h4>
                                    <StyledProjectVendorsGrid>
                                        {selectedVendors.map(vendor => {
                                            return (
                                                <CheckBox
                                                    key={vendor.id}
                                                    containerClass="vendor-choice"
                                                    id="vendors"
                                                    label={vendor.name}
                                                    helpText={t('batches:workload', {
                                                        used_units: vendor.workload.assigned_tasks,
                                                        percentage_used: vendor.workload.percent_assigned_tasks
                                                    })}
                                                    {...form.register(`vendors`)}
                                                    value={vendor.id}
                                                />
                                            )
                                        })}
                                    </StyledProjectVendorsGrid>
                                </section>
                            )}
                            <section>
                                {selectedVendors?.length >= 1 && filteredVendors.length >= 1 && (
                                    <h4>{t('projectDetails:vendors:other_vendors')}</h4>
                                )}
                                <StyledProjectVendorsGrid>
                                    {filteredVendors.map(vendor => {
                                        return (
                                            <CheckBox
                                                key={vendor.id}
                                                containerClass={'vendor-choice'}
                                                id={`vendors`}
                                                label={vendor.name}
                                                helpText={t('batches:workload', {
                                                    used_units: vendor.workload.assigned_tasks,
                                                    percentage_used: vendor.workload.percent_assigned_tasks
                                                })}
                                                {...form.register('vendors')}
                                                value={vendor.id}
                                            />
                                        )
                                    })}
                                </StyledProjectVendorsGrid>
                            </section>
                        </>
                    ) : (
                        <section>
                            <h4>{t('projectDetails:vendors:all_vendors')}</h4>
                            {!query.data.length ? (
                                <p>{t('projectDetails:vendors:all_vendors_content')}</p>
                            ) : (
                                <StyledProjectVendorsGrid>
                                    {query.data.map(vendor => (
                                        <CheckBox
                                            disabled
                                            invisible
                                            key={vendor.id}
                                            containerClass={'vendor-choice'}
                                            id={`vendors`}
                                            label={vendor.name}
                                            helpText={t('batches:workload', {
                                                used_units: vendor.workload.assigned_tasks,
                                                percentage_used: vendor.workload.percent_assigned_tasks
                                            })}
                                        />
                                    ))}
                                </StyledProjectVendorsGrid>
                            )}
                        </section>
                    )}
                </div>
                <ProjectStepActions
                    onCloseCb={onClose}
                    onNextClickCb={onNextClick}
                    nextStepLabel={t('projectDetails:tabs:batches')}
                    isLoading={isLoading}
                    nextButtonDisabled={!processes?.length}
                />
            </StyledProjectVendorsForm>
        </FormProvider>
    )
}

ProjectVendors.displayName = 'ProjectVendors'
