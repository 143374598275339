import {Sidebar} from '@/components/ui/Sidebar'
import styled, {css} from 'styled-components'

export const StyledSidebar = styled(Sidebar)`
    ${({theme: {typography, palette, spacing}}) => css`
        .title-section {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 8}px ${spacing * 6}px;

            h2 {
                font-size: ${typography.textXl};
                font-weight: 600;
                color: ${palette.neutral[900]};
            }

            h3 {
                font-size: ${typography.textSm};
                color: ${palette.neutral[600]};
            }
        }

        .empty-state-section {
            flex-grow: 1;
            overflow-y: auto;

            .empty-state-title {
                text-align: center;
                width: 100%;
                font-size: ${typography.textMd};
                font-weight: 600;
                color: ${palette.neutral[900]};
            }

            .empty-state-subtitle {
                text-align: center;
                font-size: ${typography.textSm};
                color: ${palette.neutral[600]};
            }
        }

        .form-section {
            padding: 0 ${spacing * 6}px;
            flex-grow: 1;
            overflow-y: auto;
        }

        .cta-section {
            border-top: 1px solid ${palette.neutral[200]};
            padding: ${spacing * 6}px;
        }

        .team-lead-column {
            width: 274px;
        }

        .percentage-column {
            width: 102px;
        }

        .action-column {
            width: 40px;
        }
    `}
`
