import styled, {css, DefaultTheme} from 'styled-components'
import Container from '@components/ui/Container'
import {NavLink} from 'react-router-dom'

export const StyledContainer = styled(Container)`
    ${({theme: {spacing, typography, palette}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-rows: auto auto 1fr;
        gap: ${spacing * 6}px;
        & h1 {
            ${typography.displaySm};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }
    `}
`
//TODO: make StyledNavLink common (it's used in other routes)
export const TabsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `
)

//TODO: make StyledNavLink common (it's used in other routes)
export const StyledNavLink = styled(NavLink)`
    ${({theme: {typography, palette, spacing}}) => css`
        height: 32px;
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral['500']};
        cursor: pointer;

        &.active {
            color: ${palette.primary['700']};
            border-bottom: 2px solid ${palette.primary['700']};
            .counter {
                color: ${palette.primary['700']};
                background-color: ${palette.primary['50']};
                border-color: ${palette.primary['200']};
            }
        }

        &[aria-disabled='true'] {
            color: ${palette.neutral['300']};
            pointer-events: none;
        }

        .counter {
            padding: ${spacing * 0.5}px ${spacing * 2}px;
            border-radius: 16px;
            color: ${palette.neutral['700']};
            background-color: ${palette.neutral['50']};
            border: 1px solid ${palette.neutral['200']};
            font-size: ${typography.textXs}px;
            margin-left: ${spacing}px;
        }
    `}
`

export const StyledTabContent = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 8}px;
    `
)
