import {useMemo, useState} from 'react'

export const useRecordSelection = <T extends {id: number}>() => {
    const [selectedItems, setSelectedItems] = useState<Record<number, T>>({})
    const selectedItemsCounter = useMemo(() => Object.keys(selectedItems).length, [selectedItems])

    const selectedItemsToggle = (itemSelected: T) => {
        setSelectedItems(prevSelectedItems => {
            const newSelectedItems = {...prevSelectedItems}
            if (itemSelected.id in newSelectedItems) {
                delete newSelectedItems[itemSelected.id]
            } else {
                newSelectedItems[itemSelected.id] = itemSelected
            }
            return newSelectedItems
        })
    }

    const resetSelectedItems = () => setSelectedItems({})

    return {selectedItems, selectedItemsCounter, selectedItemsToggle, resetSelectedItems}
}
