import {Flexbox} from '@/components/ui/Flexbox'
import {spacing} from '@/theme'
import styled, {css} from 'styled-components'

export const VendorSorterWrapper = styled.div`
    ${({theme: {typography, palette}}) => css`
        transition: all 0.3s ease-out;

        &:has(button[data-state='open']) {
            .chevron-container {
                transform: rotate(180deg);
            }
        }

        &:has(button[data-state='closed']) {
            .chevron-container {
                transform: rotate(0);
            }
        }

        .trigger {
            width: 200px;
            p {
                font-size: ${typography.textMd};
                color: ${palette.neutral[900]};
            }

            .chevron-container {
                display: grid;
                place-items: center;
                transition: all 0.3s ease-out;
            }
        }
    `}
`

export const StyledPopoverContent = styled.div`
    ${({theme: {palette, spacing}}) => css`
        width: 200px;
        padding: ${spacing}px;
        .sort-fields-container {
            border-bottom: 1px solid ${palette.neutral[200]};
            padding-bottom: ${spacing}px;
        }
    `}
`

export const StyledListItem = styled(Flexbox)<{isSelected?: boolean}>`
    ${({theme: {palette}, isSelected}) => css`
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease-out;
        padding: ${spacing * 2.5}px;
        color: ${palette.neutral[900]};
        ${isSelected &&
        css`
            background-color: ${palette.neutral[50]};
        `}
        .icon-container {
            color: ${palette.primary[600]};
        }
    `}
`
