import {AxiosResponse} from 'axios'
import axios from '@services/axiosInstance.ts'
import {Pagination} from '@/types/commons.ts'
import {CompletedTasksSearchParams, Task, TaskExtended} from '@/features/tasks/types.ts'

/* GET TASK */
export const httpGetTask = (taskId: number) => {
    return axios.get(`tasks/${taskId}`).then((response: AxiosResponse<Task>) => response.data)
}

/* COMPLETE TASK */
export const httpCompleteTask = (taskId: number) => {
    return axios.patch(`tasks/${taskId}`, {status: 'completed'}).then((response: AxiosResponse<Task>) => response.data)
}

/* GET ASSIGNED TASK */
export const httpGetAssignedTask = () => {
    return axios.get(`tasks/assigned`).then((response: AxiosResponse<TaskExtended | undefined>) => response.data)
}

/* CLAIM TASK */
export const httpClaimTask = () => {
    return axios.post(`tasks/claim`).then((response: AxiosResponse<TaskExtended>) => response.data)
}

/* UNASSIGN TASKS */
export const httpUnassignTasks = (taskIds: string[]) => {
    return axios.patch(`tasks/unassign`, {task_ids: taskIds}).then(res => res.data)
}

/* GET COMPLETED TASKS */
type HttpGetCompletedTasksParams = {
    currentPage: number
    perPage: number
    order_by: CompletedTasksSearchParams['orderBy']
    order_direction: CompletedTasksSearchParams['orderDirection']
}
type HttpGetCompletedTasksResponse = {
    data: TaskExtended[]
    pagination: Pagination
}

export const httpGetCompletedTasks = (params: HttpGetCompletedTasksParams) => {
    return axios
        .get('tasks/completed', {params})
        .then((response: AxiosResponse<HttpGetCompletedTasksResponse>) => response.data)
}
