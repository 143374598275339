import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@components/ui/Button'
import InputText from '@components/commons/InputText'
import {Mail01Icon} from '@components/ui/Icon'
import {HOME_FORM_MODEL, HomeFormSchema, ValidationSchema} from '@pages/home/components/HomeFormModel.ts'
import {useTranslation} from 'react-i18next'
import TextArea from '@components/commons/TextArea'
import InputSelect, {SelectValue} from '@components/commons/Select'
import DatePicker from '@/components/commons/DatePicker'
import dayjs from 'dayjs'
import {useState} from 'react'
import {publish} from '@utilities/helpers.ts'
import CheckBox from '@components/commons/CheckBox'
import {Radio} from '@components/commons/Radio'

const HomeForm = () => {
    const {t} = useTranslation()
    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: {errors, touchedFields}
    } = useForm<ValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(HomeFormSchema)
    })
    console.log(errors, getValues())
    const onSubmit: SubmitHandler<ValidationSchema> = data => console.log(data)

    //Free Checkbox input logic
    const [checked, setChecked] = useState(false)
    const onToggleClick = () => {
        console.log('here', checked)
        setChecked(!checked)
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{display: 'grid', gridTemplateColumns: '1fr', width: 600, marginTop: 40, gap: 16}}
        >
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: 16}}>
                <InputText
                    label={t(HOME_FORM_MODEL.text.label)}
                    type={'text'}
                    touched={touchedFields.text}
                    errorMessage={t(errors.text?.message || '')}
                    placeholder={t(HOME_FORM_MODEL.password.name).toString()}
                    {...register(t(HOME_FORM_MODEL.text.name))}
                    helpText={t(HOME_FORM_MODEL.text.helpText)}
                />
                <InputText
                    label={t(HOME_FORM_MODEL.email.label)}
                    type={'email'}
                    errorMessage={t(errors.email?.message || '')}
                    {...register(t(HOME_FORM_MODEL.email.name))}
                    typeIcon={<Mail01Icon size={20} />}
                    disabled={true}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: 16}}>
                <InputText
                    label={t(HOME_FORM_MODEL.password.label)}
                    type={'password'}
                    visibilityToggle={true}
                    touched={touchedFields.password}
                    errorMessage={t(errors.password?.message || '')}
                    placeholder={t(HOME_FORM_MODEL.password.name).toString()}
                    {...register(t(HOME_FORM_MODEL.password.name))}
                />
                <InputText
                    label={t(HOME_FORM_MODEL.confirmPassword.label)}
                    type={'password'}
                    visibilityToggle={true}
                    touched={touchedFields.confirmPassword}
                    errorMessage={t(errors.confirmPassword?.message || '')}
                    placeholder={'confirm Password'}
                    {...register(t(HOME_FORM_MODEL.confirmPassword.name))}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: 16}}>
                <CheckBox
                    id={'x'}
                    helpText={'Save my login details for next time.'}
                    label={'Uncontrolled'}
                    checked={checked}
                    onChange={onToggleClick}
                />
                <CheckBox
                    disabled
                    checked
                    id="terms"
                    label={t(HOME_FORM_MODEL.checkbox.label)}
                    errorMessage={t(errors.checkbox?.message || '')}
                    helpText={t(HOME_FORM_MODEL.checkbox.helpText)}
                    {...register('checkbox')}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: 16}}>
                <Radio
                    id="radio-1"
                    label={t(HOME_FORM_MODEL.radio.label)}
                    errorMessage={t(errors.radio?.message || '')}
                    helpText={t(HOME_FORM_MODEL.radio.helpText)}
                    {...register('radio')}
                />
                <Radio
                    id="radio-2"
                    label={t(HOME_FORM_MODEL.radio.label)}
                    errorMessage={t(errors.radio?.message || '')}
                    helpText={t(HOME_FORM_MODEL.radio.helpText)}
                    {...register('radio')}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: 16}}>
                <Controller
                    defaultValue={[]}
                    render={({field: {onChange, ref, value}}) => (
                        <InputSelect
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue[])
                            }}
                            ref={ref}
                            size={'medium'}
                            name={HOME_FORM_MODEL.select.name}
                            label={t(HOME_FORM_MODEL.select.label)}
                            isMulti={true}
                            isClearable={true}
                            isCreatable={true}
                            isSearchable={true}
                            errorMessage={t(errors.select?.message || '')}
                            placeholder={t(HOME_FORM_MODEL.select.name)}
                            helpText={t(HOME_FORM_MODEL.select.helpText)}
                            options={[
                                {value: '1', label: 'uno'},
                                {value: '2', label: 'due'},
                                {value: '3', label: 'tre'}
                            ]}
                        />
                    )}
                    control={control}
                    name={'select'}
                />

                <InputSelect
                    name={'select2'}
                    size={'medium'}
                    label={'Select uncontrolled'}
                    isMulti={true}
                    placeholder={'simple select'}
                    helpText={'Text to help'}
                    onChange={event => console.log(event)}
                    options={[
                        {value: '1', label: 'uno'},
                        {value: '2', label: 'due'},
                        {value: '3', label: 'tre'}
                    ]}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'start', gap: 16}}></div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'start', gap: 16}}>
                <TextArea
                    disabled={true}
                    label={t(HOME_FORM_MODEL.textarea.label)}
                    rows={5}
                    cols={33}
                    touched={touchedFields.textarea}
                    errorMessage={t(errors.textarea?.message || '')}
                    placeholder={t(HOME_FORM_MODEL.textarea.name).toString()}
                    helpText={t(HOME_FORM_MODEL.textarea.helpText)}
                    {...register(t(HOME_FORM_MODEL.textarea.name))}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'start', gap: 16}}>
                <Controller
                    control={control}
                    name={HOME_FORM_MODEL.singleDate.name}
                    render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                        <DatePicker
                            toggle
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                            numMonths={1}
                            mode={'single'}
                            selectedDates={value ? [value] : []}
                            onDatesChange={dates => onChange(dates[0])}
                            onBlur={onBlur}
                            triggerProps={{
                                label: t(HOME_FORM_MODEL.singleDate.label),
                                errorMessage: t(error?.message || ''),
                                placeholder: t(HOME_FORM_MODEL.singleDate.name).toString(),
                                helpText: t(HOME_FORM_MODEL.singleDate.helpText)
                            }}
                        />
                    )}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'start', gap: 16}}>
                <Controller
                    control={control}
                    name={HOME_FORM_MODEL.dateRange.name}
                    render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                        <DatePicker
                            toggle
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                            numMonths={2}
                            mode={'range'}
                            selectedDates={value || []}
                            onDatesChange={onChange}
                            onBlur={onBlur}
                            triggerProps={{
                                label: t(HOME_FORM_MODEL.dateRange.label),
                                errorMessage: t(error?.message || ''),
                                placeholder: t(HOME_FORM_MODEL.dateRange.name).toString(),
                                helpText: t(HOME_FORM_MODEL.dateRange.helpText)
                            }}
                        />
                    )}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'start', gap: 16}}>
                <Controller
                    control={control}
                    name={HOME_FORM_MODEL.multipleDate.name}
                    render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                        <DatePicker
                            toggle
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                            numMonths={1}
                            mode={'multiple'}
                            selectedDates={value || []}
                            onDatesChange={onChange}
                            onBlur={onBlur}
                            triggerProps={{
                                label: t(HOME_FORM_MODEL.multipleDate.label),
                                errorMessage: t(error?.message || ''),
                                placeholder: t(HOME_FORM_MODEL.multipleDate.name).toString(),
                                helpText: t(HOME_FORM_MODEL.multipleDate.helpText),
                                options: []
                            }}
                        />
                    )}
                />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: 16}}>
                <Button type={'submit'} variant={'primary'} size={'lg'}>
                    Submit
                </Button>
                <Button type={'button'} variant={'secondary'} size={'lg'} onClick={() => publish('REFRESH_TOKENS', {})}>
                    Submit action
                </Button>
            </div>
        </form>
    )
}

export default HomeForm
