import styled, {css} from 'styled-components'

export const StyledStepActionsGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto auto;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledCancelBtnWrapper = styled.div`
    max-width: 200px;
`
