import {FC} from 'react'
import {BatchRecord} from '../../types'
import {BatchesAlreadyAssignedWrapper} from './style'
import {Flexbox} from '@/components/ui/Flexbox'
import {AssignedVendorsList} from './AssignedVendorsList'
import Button from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {AlertCircleIcon} from '@/components/ui/Icon'
import {useUnassignBatch} from '../../queries/useUnassignBatch'
import toast from 'react-hot-toast'

type BatchAlreadyAssignedProps = {
    batch: BatchRecord
    onClose: () => void
}

export const BatchAlreadyAssigned: FC<BatchAlreadyAssignedProps> = ({batch, onClose}) => {
    const {t} = useTranslation()

    const {mutate: unassignBatch, isLoading: isLoadingUnassignBatch} = useUnassignBatch({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    return (
        <BatchesAlreadyAssignedWrapper>
            <Flexbox className="sidebar-header" direction="column" width={'100%'}>
                <h2>{t('batches:assign_batch')}</h2>
                <h3>{batch.name}</h3>
            </Flexbox>
            <div className="already-assignment-alert-container">
                <Flexbox className="already-assignment-alert" gap={4}>
                    <div className="alert-icon-container">
                        <AlertCircleIcon size={20} />
                    </div>
                    <Flexbox direction="column" gap={4}>
                        <p className="already-assigned-title">{t('batches:already_assigned_title')}</p>
                        <p className="already-assigned-subtitle">{t('batches:already_assigned_subtitle')}</p>
                        <Flexbox gap={2}>
                            <Button variant="linkColor" onClick={onClose}>
                                {t('batches:keep_assigned')}
                            </Button>
                            <Button
                                variant="ghostDanger"
                                disabled={isLoadingUnassignBatch}
                                onClick={() => unassignBatch({batchId: batch.id})}
                            >
                                {t('commons:unassign')}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </div>
            <Flexbox className="vendors-list-container" direction="column" width={'100%'}>
                <AssignedVendorsList batch={batch} />
            </Flexbox>
            <Flexbox className="cta-container" gap={2} justify="end">
                <Button onClick={onClose}>{t('commons:close')}</Button>
            </Flexbox>
        </BatchesAlreadyAssignedWrapper>
    )
}
