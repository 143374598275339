import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {httpGetLateOperators} from '@/features/operators/services/operators.http.ts'
import {OperatorsIndexParams} from '@/features/operators/types.ts'
import {useAuth} from '@context/AuthProvider.tsx'

export const useLateOperators = (
    subProjectId?: string,
    subBatchId?: string,
    params?: Omit<OperatorsIndexParams, 'currentPage'>
) => {
    const {user} = useAuth()
    const canQueryProject = user?.role.slug === 'program_manager' || user?.role.slug === 'client_engineer'

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.LATE_OPERATORS, subProjectId, subBatchId, params],
        queryFn: ({pageParam = 1}) => {
            const remapParams = () => {
                if (params) {
                    const {orderBy, orderDirection, ...restParams} = params
                    return {
                        //todo: remove this check and make different table views based on role
                        ...restParams,
                        subproject_id: canQueryProject ? undefined : subProjectId,
                        subbatch_id: subBatchId,
                        order_direction: orderDirection,
                        order_by: orderBy,
                        perPage: 50
                    }
                } else {
                    return {}
                }
            }

            //Perform our service api call
            return httpGetLateOperators({
                params: {
                    ...remapParams(),
                    currentPage: pageParam
                }
            })
        },
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? []
    }
}
