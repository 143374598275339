import styled, {css, DefaultTheme} from 'styled-components'
import Container from '@components/ui/Container'
import {NavLink} from 'react-router-dom'

export const StyledContainer = styled(Container)`
    ${({theme: {spacing, typography, palette}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-rows: auto auto 1fr;
        gap: ${spacing * 6}px;
        overflow: hidden;
        & h1 {
            ${typography.displaySm};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }
    `}
`

export const TabsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `
)

//TODO: make a common component for this
export const StyledNavLink = styled(NavLink)`
    ${({theme: {typography, palette}}) => css`
        height: 32px;
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral['500']};
        cursor: pointer;

        &.active {
            color: ${palette.primary['700']};
            border-bottom: 2px solid ${palette.primary['700']};
        }

        &[aria-disabled='true'] {
            color: ${palette.neutral['300']};
            pointer-events: none;
        }
    `}
`
