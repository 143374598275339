import Container from '@components/ui/Container'
import PageHeader from '@components/commons/PageHeader'
import {useTranslation} from 'react-i18next'
import Divider from '@components/ui/Divider'
import {useAuth} from '@context/AuthProvider.tsx'
import {useMemo} from 'react'
import {settingsFormRendererBySlug} from '@/features/roles/helpers.tsx'

export const Settings = () => {
    const {t} = useTranslation()
    const {user} = useAuth()

    const formRenderer = useMemo(() => settingsFormRendererBySlug(user?.role.slug), [user])

    return (
        <Container>
            <PageHeader title={t('settings:title')} subtitle={t('settings:subtitle')} />
            <Divider />
            {formRenderer}
        </Container>
    )
}

Settings.displayName = 'Settings'
