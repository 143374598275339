import {useAuth} from '@context/AuthProvider.tsx'
import {useMemo} from 'react'
import {batchesProdTableViewBySlug} from '@/features/roles/helpers.tsx'

export const ProductionManagementBatches = () => {
    const {user} = useAuth()
    const tableRenderer = useMemo(() => batchesProdTableViewBySlug(user?.role.slug), [user])

    return <>{tableRenderer}</>
}

ProductionManagementBatches.displayName = 'ProductionManagementBatches'
