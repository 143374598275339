import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {httpUpdateTaxonomy} from '@/features/taxonomies/services/taxonomies.http.ts'
import {TaxonomyRecord} from '@/features/taxonomies/types.ts'

type useEditTaxonomyProps = {
    onSuccess?: (data: TaxonomyRecord) => void
    onError?: () => void
}

export const useEditTaxonomy = ({onSuccess}: useEditTaxonomyProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_TAXONOMY],
        mutationFn: httpUpdateTaxonomy,
        onError: errorHandler,
        onSuccess: (data, {id}) => {
            queryClient.invalidateQueries([QUERY_KEYS.TAXONOMIES])
            queryClient.invalidateQueries([QUERY_KEYS.TAXONOMIES, id])
            onSuccess?.(data)
        }
    })
}
