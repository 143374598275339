import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetTaskTypes} from '@/features/settings/services/settings.http.ts'

export const useTaskTypes = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.TASKS_TYPES],
        queryFn: httpGetTaskTypes
    })
}
