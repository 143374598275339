import {useTranslation} from 'react-i18next'
import {useTasksStatsSearchParams} from '../../hooks/useTasksStatsSearchParams'
import {useBudgetStats} from '../../queries/useBudgetStats'
import {useTasksStats} from '../../queries/useTasksStats'
import {TASK_TYPES} from '../../constants'
import {Flexbox} from '@/components/ui/Flexbox'
import {StatsLoader} from '../../components/stats-loader'
import {StyledEntityCount} from '../../components/style'
import {TasksStatsWithParams} from '../../components/stats-tasks/TasksStatsWithParams'
import {StatsPerformanceWithParams} from '../../components/stats-performance/StatsPerformanceWithParams'
import {useAuth} from '@/context/AuthProvider'
import {Charts} from '../../components/charts'
import {StatsOverview} from '../../components/stats-overview'
import {Slug} from '@/types/auth'
import {TFunction} from 'i18next'
import {HttpgGetBudgetStatsResponse} from '../../services/http'
import toast from 'react-hot-toast'

const countByRole = (roleSlug: Slug, t: TFunction, budgetStats: HttpgGetBudgetStatsResponse) => {
    switch (roleSlug) {
        case 'program_manager':
            return t('commons:projects_count', {projects_count: budgetStats.projects_count})
        case 'client_engineer':
            return t('commons:projects_count', {projects_count: budgetStats.projects_count})
        case 'vendor_manager':
            return t('commons:projects_count', {projects_count: budgetStats.subprojects_count})
        case 'team_lead':
            return t('commons:batches_count', {batches_count: budgetStats.subbatches_count})
    }
}

export const OverallStats = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const {searchParams: tasksSearchParmas} = useTasksStatsSearchParams()

    const {
        data: budgetStats,
        isLoading: isLoadingBudgetStats,
        isSuccess: isSuccessBudgetStats,
        error: budgetStatsError
    } = useBudgetStats()

    const {
        data: tasksStats,
        isLoading: isLoadingTasksStats,
        isSuccess: isSuccessTasksStats,
        error: tasksStatsError
    } = useTasksStats({
        task_type: tasksSearchParmas.task_type !== TASK_TYPES.ALL ? tasksSearchParmas.task_type : undefined
    })

    const error = budgetStatsError || tasksStatsError

    const role = user?.role.slug

    if (error) {
        toast.error('commons:genericError') //TODO Handle error
        return null
    }

    return (
        <Flexbox gap={8} direction="column">
            {isLoadingBudgetStats || !isSuccessBudgetStats ? (
                <StatsLoader />
            ) : (
                <>
                    <StyledEntityCount>
                        {user?.role.slug && countByRole(user?.role.slug, t, budgetStats)}
                    </StyledEntityCount>
                    {user?.role.slug !== 'team_lead' && (
                        <StatsOverview
                            usedBudget={budgetStats.used_budget}
                            budget={budgetStats.budget}
                            turnAroundTime={budgetStats.turn_around_time}
                            showTotalBudget={role === 'program_manager' || role === 'client_engineer'}
                        />
                    )}
                </>
            )}
            {isLoadingTasksStats || !isSuccessTasksStats ? (
                <StatsLoader />
            ) : (
                <TasksStatsWithParams
                    pendingCount={tasksStats.pending_count}
                    onGoingCount={tasksStats.ongoing_count}
                    completedCount={tasksStats.completed_count}
                    totalCount={tasksStats.total_count}
                />
            )}
            <StatsPerformanceWithParams />
            <Charts />
        </Flexbox>
    )
}
