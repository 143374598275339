import styled, {css} from 'styled-components'

export const StyledProjectProcessGrid = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`
