import {GetTaxonomiesResponse, TaxonomyRecord, TaxonomySearchParams} from '@/features/taxonomies/types'
import axios from '@services/axiosInstance.ts'

export const httpGetTaxonomies = (pageParam: number, params: {name?: string; is_active?: boolean}) => {
    return axios
        .get('taxonomies', {params: {...params, currentPage: pageParam, perPage: 50}})
        .then(response => response.data as GetTaxonomiesResponse)
}

export const httpGetTaxonomy = (id: number) => {
    return axios.get(`taxonomies/${id}`).then(response => response.data as TaxonomyRecord)
}

export type CreateTaxonomyPayload = {
    name: string
    taxonomy: File
    instructions: File
    is_active?: boolean
}

export const httpCreateTaxonomy = (payload: CreateTaxonomyPayload) => {
    const formData = new FormData()
    //TODO: move to helpers
    Object.entries(payload).forEach(([k, v]) => {
        if (v === undefined) {
            return
        }
        if (typeof v === 'boolean') {
            formData.append(k, `${v}`)
            return
        }
        formData.append(k, v)
    })
    return axios.post(`taxonomies`, formData).then(response => response.data as TaxonomyRecord)
}

export const httpGetInfiniteTaxonomies = (
    pageParam: number,
    orderBy: TaxonomySearchParams['orderBy'],
    orderDirection: TaxonomySearchParams['orderDirection'],
    searchValue: TaxonomySearchParams['search'],
    isActive: boolean
) => {
    const params = {
        search: searchValue,
        perPage: 50,
        currentPage: pageParam,
        order_by: orderBy,
        order_direction: orderDirection,
        is_active: isActive
    }

    return axios.get('taxonomies', {params}).then(response => response.data as GetTaxonomiesResponse)
}
export const httpDeleteTaxonomy = async (id: number) => {
    await axios.delete(`taxonomies/${id}`)
}

export const httpDeactivateTaxonomy = ({id}: Partial<TaxonomyRecord>) => {
    const formData = new FormData()
    formData.append('is_active', 'false')
    return axios
        .post(`taxonomies/${id}`, formData)
        .then(response => response.data as TaxonomyRecord)
        .catch(() => {
            throw new Error('error')
        })
}

export const httpActivateTaxonomy = ({id}: Partial<TaxonomyRecord>) => {
    const formData = new FormData()
    formData.append('is_active', 'true')
    return axios
        .post(`taxonomies/${id}`, formData)
        .then(response => response.data as TaxonomyRecord)
        .catch(() => {
            throw new Error('error')
        })
}

type UpdateTaxonomyArgs = {
    id: number | string
    data: Partial<CreateTaxonomyPayload>
}
export const httpUpdateTaxonomy = ({id, data}: UpdateTaxonomyArgs) => {
    const formData = new FormData()
    Object.entries(data).forEach(([k, v]) => {
        if (v === undefined) {
            return
        }
        if (typeof v === 'boolean') {
            formData.append(k, `${v}`)
            return
        }
        formData.append(k, v)
    })
    return axios
        .post(`taxonomies/${id}`, formData)
        .then(response => response.data as TaxonomyRecord)
        .catch(() => {
            throw new Error('error')
        })
}
