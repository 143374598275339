import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

type StyledEditableInputProps = {
    disabled?: boolean
    size: 'sm' | 'md' | 'lg'
    width?: CSSProperties['width']
}

export const StyledEditableInputWrapper = styled.div<StyledEditableInputProps>`
    ${({disabled, theme: {spacing, palette}}) => css`
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: ${spacing}px;
        transition: ease-in-out 200ms;
        cursor: ${disabled ? 'default' : 'pointer'};
        ${!disabled &&
        css`
            &:not(:disabled):focus {
                background: ${palette.neutral['100']};
            }
        `}
    `}
    ${({size}) => InputSizeStyles()[size]};
`

const InputSizeStyles = () => ({
    sm: css`
        height: 40px;
    `,
    md: css`
        height: 44px;
    `,
    lg: css`
        height: 48px;
    `
})

export const StyledEditableInput = styled.input`
    ${({value, theme: {typography, palette}}) => css`
        width: ${value ? `100%` : `1ch`};
        max-width: 46ch;
        border: none;
        outline: none;
        color: ${palette.neutral['900']};
        ${typography.displaySm};
        font-weight: 600;

        &:focus {
            color: ${palette.neutral['600']};
            outline: none;
        }

        &::placeholder {
            color: ${palette.neutral['400']};
        }

        &:disabled {
            cursor: default;
        }
    `}
`
