import {z} from 'zod'
import {Slug} from '@/types/auth.ts'

export const editUserValidationSchema = (initialRoleSlug: Slug) =>
    z
        .object({
            first_name: z.string().min(1, {message: 'errors:first_name'}),
            last_name: z.string().min(1, {message: 'errors:last_name'}),
            email: z.string().min(1, {message: 'errors:email'}).email({
                message: 'errors:email_valid'
            }),
            role: z.object({label: z.string(), value: Slug}),
            vendor: z.object({label: z.string(), value: z.string()}).nullish().optional(),
            supervisor_email: z.object({label: z.string(), value: z.string()}).nullish().optional(),
            skill_taxonomy: z.object({label: z.string(), value: z.string()}).nullish().optional(),
            skill_taxonomy_id: z.number().optional().nullish().nullable(),
            supervisor_id: z.number().optional().nullish().nullable(),
            company_id: z.number().optional().nullable().nullish()
        })
        .superRefine((values, context) => {
            if (
                ['vendor_admin', 'vendor_manager', 'team_lead', 'labeler'].includes(values.role.value) &&
                !values.vendor?.value
            ) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'errors:vendor',
                    path: ['vendor']
                })
            }
            if (
                (values.role.value == 'team_lead' || values.role.value == 'labeler') &&
                !values.supervisor_email?.value
            ) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'errors:supervisor_email',
                    path: ['supervisor_email']
                })
            }
            if (values.role.value == 'labeler' && !values.skill_taxonomy_id && initialRoleSlug != 'labeler') {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'errors:skills',
                    path: ['skills']
                })
            }
        })

export type EditUserValidationSchema = z.infer<ReturnType<typeof editUserValidationSchema>>
