import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {z} from 'zod'

const FiltersSchema = z.object({
    search: z.string().optional(),
    status: z.array(z.string()).optional(),
    orderBy: z.string().optional(),
    orderDirection: z.enum(['asc', 'desc']).optional()
})

export type FiltersSchemaType = z.infer<typeof FiltersSchema>
const multiParams = ['status'] as const satisfies ReadonlyArray<keyof FiltersSchemaType>

export const useLateOperatorsSearchParams = () => {
    return useParsedSearchParams(multiParams, FiltersSchema)
}
