import styled, {css} from 'styled-components'
import Container from '@components/ui/Container'
import {Link} from 'react-router-dom'

export const StyledContainer = styled(Container)`
    display: grid;
    grid-template-rows: auto auto 1fr;
`

export const StyledTaxonomyName = styled.div(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
        font-weight: 500;
    `
)

export const StyledLink = styled(Link)`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.primary['700']};
        font-weight: 500;
        ${typography.textSm};
        cursor: pointer;
    `}
`

export const StyledFakeLink = styled.span`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.primary['700']};
        font-weight: 500;
        ${typography.textSm};
        cursor: pointer;
    `}
`
