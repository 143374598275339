import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetTaxonomies} from '../services/taxonomies.http'

type useTaxonomiesProps = {
    name?: string
    is_active?: boolean
    page?: number
}

export const useTaxonomies = ({page = 1, ...params}: useTaxonomiesProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TAXONOMIES, page, params],
        queryFn: () => httpGetTaxonomies(page, params),
        keepPreviousData: true
    })
}
