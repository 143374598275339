import {Link, RouteObject, useMatches} from 'react-router-dom'
import {StyledBreadCrumbs} from '@components/commons/BreadCrumbs/style.ts'
import {NavLink} from '@components/ui/NavLink'
import {ChevronRightIcon, HomeLineIcon} from '@components/ui/Icon'
import {Fragment} from 'react'
import {PROJECTS_HEADER_OBJ, routes} from '@utilities/constants'
import {useTranslation} from 'react-i18next'

export type HandleRouterObj = {
    path: string
    paramsName?: string[]
    name: string
    end: boolean
}

type Crumb = {
    item: HandleRouterObj
    match: RouteObject
}
const Breadcrumbs = () => {
    const {t} = useTranslation()
    const matches: RouteObject[] = useMatches()
    const crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter(match => Boolean(match.handle))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match): Crumb => ({item: match.handle, match: match}))

    const pathRenderer = (path: string) => {
        switch (path) {
            case routes.PROJECTS.path:
                return PROJECTS_HEADER_OBJ.path
            default:
                return path
        }
    }

    const replaceParamsInPath = (params: string[], path: string, match: RouteObject) => {
        let parsedPath = path
        params.map(param => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Necessary because params is not mapped in the RouteObject Type
            parsedPath = parsedPath.replace(`:${param}`, match?.params[param])
            return param
        })
        return parsedPath
    }

    let paramsIndex = 0
    return (
        <StyledBreadCrumbs>
            {crumbs.map(({item, match}, index) => {
                let crumbLabel = ''

                const path =
                    item?.paramsName?.length === 0
                        ? item.path
                        : replaceParamsInPath(item.paramsName ?? [], item.path, match)

                if (item?.name) {
                    crumbLabel = t(item.name)
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: Necessary because params is not mapped in the RouteObject Type
                    crumbLabel = item.paramsName ? match.params[item.paramsName[paramsIndex]] : ''
                    ++paramsIndex
                }
                const pathWithExceptions = pathRenderer(path)
                return (
                    <Fragment key={item.name}>
                        {path === routes.HOME.path ? (
                            <Link to={path}>
                                <HomeLineIcon data-color size={20} />
                            </Link>
                        ) : (
                            <NavLink
                                path={pathWithExceptions}
                                size={'sm'}
                                name={crumbLabel}
                                key={index}
                                end={item.end}
                            />
                        )}
                        {index < crumbs.length - 1 && (
                            <span>
                                <ChevronRightIcon data-color size={16} />
                            </span>
                        )}
                    </Fragment>
                )
            })}
        </StyledBreadCrumbs>
    )
}

export default Breadcrumbs
Breadcrumbs.displayName = 'Breadcrumbs'
