import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpUpdateCurrentSettings} from '@/features/settings/services/settings.http.ts'

type UseMarkAsCompletedProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useUpdateSettings = ({onSuccess, onError}: UseMarkAsCompletedProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_SETTINGS],
        mutationFn: httpUpdateCurrentSettings,
        onError,
        onSuccess: () => {
            queryClient.invalidateQueries([QUERY_KEYS.CURRENT_SETTINGS])
            onSuccess?.()
        }
    })
}
