import {useQuery} from '@tanstack/react-query'
import {httpGetProcesses} from '../services/process.http'
import {QUERY_KEYS} from '@/queryClient'

type useProcessesesProps = {
    id: number
}

export const useProcesses = ({id}: useProcessesesProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROCESS, id],
        queryFn: () => httpGetProcesses(id)
    })
}
