export const DATE_RANGES = {
    CUSTOM: 'custom',
    THIS_WEEK: 'this_week',
    THIS_MONTH: 'this_month',
    THIS_YEAR: 'this_year',
    LAST_WEEK: 'last_week',
    LAST_MONTH: 'last_month'
} as const

export type DateRangeType = (typeof DATE_RANGES)[keyof typeof DATE_RANGES]

export const TASK_TYPES = {
    ALL: 'all',
    EDIT: 'edit',
    QA: 'qa'
} as const

export type TaskType = (typeof TASK_TYPES)[keyof typeof TASK_TYPES]

export const STATS_METRICS = {
    EFFICIENCY: 'efficiency',
    QUALITY: 'quality',
    THROUGHPUT: 'throughput'
} as const

export type MetricType = (typeof STATS_METRICS)[keyof typeof STATS_METRICS]
