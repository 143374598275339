import {useParams} from 'react-router-dom'
import {useTasksStatsSearchParams} from '../../hooks/useTasksStatsSearchParams'
import {useProject} from '@/features/projects/queries/useProject'
import {useBudgetStats} from '../../queries/useBudgetStats'
import {useTasksStats} from '../../queries/useTasksStats'
import {TASK_TYPES} from '../../constants'
import {Flexbox} from '@/components/ui/Flexbox'
import {StatsLoader} from '../../components/stats-loader'
import {StatsOverview} from '../../components/stats-overview'
import {TasksStatsWithParams} from '../../components/stats-tasks/TasksStatsWithParams'
import {StatsPerformanceWithParams} from '../../components/stats-performance/StatsPerformanceWithParams'
import {Charts} from '../../components/charts'
import toast from 'react-hot-toast'

export const SubprojectStats = () => {
    const {id} = useParams()
    if (!id) {
        throw new Error('Missing param :id')
    }

    const subProjectId = Number(id)
    const {searchParams: tasksSearchParmas} = useTasksStatsSearchParams()

    const {data: subproject} = useProject(subProjectId)

    const {
        data: budgetStats,
        isLoading: isLoadingBudgetStats,
        isSuccess: isSuccessBudgetStats,
        error: budgetStatsError
    } = useBudgetStats(
        {
            project_id: subproject?.project_id || -1,
            subproject_id: subProjectId
        },
        {
            enabled: !!subproject?.project_id
        }
    )

    const {
        data: tasksStats,
        isLoading: isLoadingTasksStats,
        isSuccess: isSuccessTasksStats,
        error: tasksStatsError
    } = useTasksStats(
        {
            project_id: subproject?.project_id || -1,
            subproject_id: subProjectId,
            task_type: tasksSearchParmas.task_type !== TASK_TYPES.ALL ? tasksSearchParmas.task_type : undefined
        },
        {enabled: !!subproject?.project_id}
    )

    const error = budgetStatsError || tasksStatsError

    if (error) {
        toast.error('commons:genericError') //TODO Handle error
        return null
    }

    return (
        <Flexbox gap={8} direction="column">
            {isLoadingBudgetStats || !isSuccessBudgetStats ? (
                <StatsLoader />
            ) : (
                <StatsOverview
                    budget={budgetStats.budget}
                    usedBudget={budgetStats.used_budget}
                    turnAroundTime={budgetStats.turn_around_time}
                />
            )}
            {isLoadingTasksStats || !isSuccessTasksStats ? (
                <StatsLoader />
            ) : (
                <TasksStatsWithParams
                    pendingCount={tasksStats.pending_count}
                    onGoingCount={tasksStats.ongoing_count}
                    completedCount={tasksStats.completed_count}
                    totalCount={tasksStats.total_count}
                />
            )}
            <StatsPerformanceWithParams />
            {subproject && <Charts projectId={subproject.project_id} subprojectId={subProjectId} />}
        </Flexbox>
    )
}
