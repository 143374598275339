import axios from '@services/axiosInstance.ts'
import {UserRole} from '@/types/auth.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const httpGetRoles = (): Promise<UserRole[]> => {
    return axios
        .get('roles')
        .then(response => response.data)
        .catch(errorHandler)
}
