import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {BatchesIndexParams, httpGetProductionBatches, httpGetProductionSubBatches} from '../services/batches.http'
import {useAuth} from '@/context/AuthProvider'

const useProductionBatches = (params?: Omit<BatchesIndexParams, 'currentPage'>) => {
    const {user} = useAuth()
    const canQueryBatch = user?.role.slug === 'program_manager' || user?.role.slug === 'client_engineer'

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PRODUCTION_BATCHES, params],
        queryFn: ({pageParam = 1}) => {
            const remapParams = () => {
                if (params) {
                    const {orderBy, orderDirection, ...restParams} = params
                    return {
                        ...restParams,
                        perPage: 50,
                        order_direction: orderDirection,
                        order_by: orderBy
                    }
                } else {
                    return {}
                }
            }

            return canQueryBatch
                ? httpGetProductionBatches({
                      params: {
                          ...remapParams(),
                          currentPage: pageParam
                      }
                  })
                : httpGetProductionSubBatches({
                      params: {
                          ...remapParams(),
                          currentPage: pageParam
                      }
                  })
        },
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? []
    }
}

export default useProductionBatches
