import {ClearIndicatorProps, components, DropdownIndicatorProps, GroupBase, MultiValueRemoveProps} from 'react-select'
import {ChevronDownIcon, XCloseIcon} from '@components/ui/Icon'
import {SelectValue} from '@components/commons/Select/index.tsx'

const MultiValueRemove = (props: MultiValueRemoveProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.MultiValueRemove {...props}>
            <XCloseIcon data-color size={12} />
        </components.MultiValueRemove>
    )
}

const DropdownIndicator = (props: DropdownIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon size={16} />
        </components.DropdownIndicator>
    )
}

const ClearIndicator = (props: ClearIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.ClearIndicator {...props}>
            <XCloseIcon data-color size={16} />
        </components.ClearIndicator>
    )
}

export const customComponents = {
    MultiValueRemove,
    DropdownIndicator,
    ClearIndicator
}
