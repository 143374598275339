import {useQuery} from '@tanstack/react-query'
import {HttpgGetTasksAverageParams, httpGetTasksAverage} from '../services/http'
import {QUERY_KEYS} from '@/queryClient'

export const useTasksAverage = (params?: HttpgGetTasksAverageParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TASKS_AVERAGE, params],
        queryFn: () => httpGetTasksAverage(params)
    })
}
