import {StyledUsersTableEmptyState} from '@/features/users/components/UsersTableEmptyState/style.ts'
import {IconContainer} from '@components/ui/IconContainer'
import {Users01Icon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import Button from '@components/ui/Button'
import {ManageUsersModal} from '@/features/users/components/ManageUsersModal'
import {roleSlugToLabel, usersTabs} from '@/features/roles/helpers.tsx'

interface UsersTableEmptyState {
    roleSlug: keyof typeof usersTabs
}

export const UsersTableEmptyState: FC<UsersTableEmptyState> = ({roleSlug}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [showManageUsersModal, setShowManageUsersModal] = useState(false)

    return (
        <>
            <StyledUsersTableEmptyState>
                <IconContainer>
                    <Users01Icon fill={theme.palette.neutral['700']} />
                </IconContainer>
                <hgroup>
                    <h3>{t('users:empty_state_title')}</h3>
                    <h4>
                        {t('users:empty_state_subtitle', {
                            userTypeKey: roleSlugToLabel[roleSlug],
                            count: 2
                        })}
                    </h4>
                </hgroup>
                <Button onClick={() => setShowManageUsersModal(true)}>
                    <Users01Icon />
                    {t('users:manage_users')}
                </Button>
            </StyledUsersTableEmptyState>
            <ManageUsersModal
                isOpen={showManageUsersModal}
                roleSlug={roleSlug}
                onClose={() => setShowManageUsersModal(false)}
            />
        </>
    )
}
