import {Modal} from '@/components/ui/Modal'
import styled, {css} from 'styled-components'

export const StyledModal = styled(Modal)`
    ${({theme: {spacing, palette, typography}}) => css`
        width: 544px;
        padding: ${spacing * 8}px;

        .header {
            display: flex;
            gap: ${spacing * 6}px;
            margin-bottom: ${spacing * 8}px;

            .icon-container {
                width: 48px;
                height: 48px;
                flex-shrink: 0;
                display: grid;
                place-items: center;
                border-radius: 100%;
                background-color: ${palette.warning[100]};
                color: ${palette.warning[600]};
            }

            .text-container {
                display: flex;
                flex-direction: column;

                h2 {
                    font-size: ${typography.textLg};
                    font-weight: 600;
                    color: ${palette.neutral[900]};
                }

                p {
                    font-size: ${typography.textSm};
                    font-weight: 400;
                    color: ${palette.neutral[600]};
                }
            }
        }
    `}
`
