import {Trans, useTranslation} from 'react-i18next'
import PageHeader from '@components/commons/PageHeader'
import {useAuth} from '@context/AuthProvider.tsx'
import {projectsTableViewRendererBySlug} from '@/features/roles/helpers.tsx'
import Button from '@components/ui/Button'
import {PlusIcon, SearchLgIcon} from '@components/ui/Icon'
import toast from 'react-hot-toast'
import {useTheme} from 'styled-components'
import {useMemo, useRef, useState} from 'react'
import {StyledContainer, StyledProjectsGrid} from '@pages/projects/style.ts'
import {ProjectRecord} from '@/features/projects/types.ts'
import {DeleteModal} from '@components/commons/DeleteModal'
import {useDeleteProject} from '@/features/projects/queries/useDeleteProject.ts'
import {useCloneProject} from '@/features/projects/queries/useCloneProject.ts'
import {CloneProjectModal} from '@/features/projects/components/clone-project-modal/CloneProjectModal.tsx'
import {CreateProjectModal} from '@/features/projects/components/create-project-modal/CreateProjectModal.tsx'
import {routes} from '@utilities/constants'
import {useNavigate} from 'react-router-dom'
import InputText from '@components/commons/InputText'
import {CAN_WRITE_PROJECT} from '@utilities/constants/permissions.ts'
import {useProjectsTable} from '@/features/projects/queries/useProjectsTable.ts'

export const Projects = () => {
    const {t} = useTranslation()
    const {spacing} = useTheme()
    const navigate = useNavigate()
    const {user} = useAuth()
    const canEditProject = user?.permissions.includes(CAN_WRITE_PROJECT)
    //projects query
    const query = useProjectsTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [showCreateProject, setShowCreateProject] = useState(false)
    //delete project mutation
    const [projectToDelete, setProjectToDelete] = useState<ProjectRecord | null>(null)
    const deleteProjectMutation = useDeleteProject({
        onSuccess: () => {
            setProjectToDelete(null)
            toast.success(t('projects:delete_project_success'))
        }
    })
    //clone project mutation
    const [projectToClone, setProjectToClone] = useState<ProjectRecord | null>(null)
    const cloneProjectMutation = useCloneProject({
        onSuccess: (data: ProjectRecord) => {
            navigate(`${routes.PROJECTS.path}/${data.id}/${routes.PROJECT_DETAIL_PROPERTIES.path}`)
            toast.success(t('projects:clone_project_success'))
        }
    })

    const onClearSearch = () => {
        if (searchRef.current) {
            searchRef.current.value = ''
        }
        query.search('')
    }

    const tableRenderer = useMemo(
        () => projectsTableViewRendererBySlug(user?.role.slug, setProjectToClone, setProjectToDelete, onClearSearch),
        [user]
    )

    return (
        <StyledContainer fullHeight>
            <PageHeader
                title={t('projects:headline')}
                ctaComponent={
                    canEditProject && (
                        <div style={{display: 'flex', gap: spacing * 3}}>
                            <Button size={'md'} variant={'primary'} onClick={() => setShowCreateProject(true)}>
                                <PlusIcon />
                                {t('projects:new_project')}
                            </Button>
                        </div>
                    )
                }
            />

            <StyledProjectsGrid>
                <InputText
                    ref={searchRef}
                    width="320px"
                    type="text"
                    defaultValue={query.searchValue}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    placeholder={t('projects:search_project') ?? 'Search Project'}
                    onChange={event => {
                        query.search(event.target.value)
                    }}
                />

                {tableRenderer}
            </StyledProjectsGrid>

            {!!projectToDelete && (
                <DeleteModal
                    isLoading={deleteProjectMutation.isLoading}
                    onClose={() => setProjectToDelete(null)}
                    onDelete={() => deleteProjectMutation.mutate(projectToDelete.id)}
                    title={t('projects:delete_project_title')}
                    paragraph={
                        <Trans
                            i18nKey="projects:delete_project_subtitle"
                            values={{name: projectToDelete.name}}
                            components={[<br />]}
                        />
                    }
                />
            )}
            {!!projectToClone && (
                <CloneProjectModal
                    project={projectToClone}
                    isLoading={cloneProjectMutation.isLoading}
                    onClose={() => setProjectToClone(null)}
                    onClone={clonedProject => cloneProjectMutation.mutate(clonedProject)}
                    title={t('projects:clone_project_title')}
                    paragraph={t('projects:clone_project_subtitle')}
                />
            )}
            {showCreateProject && <CreateProjectModal onClose={() => setShowCreateProject(!showCreateProject)} />}
        </StyledContainer>
    )
}

Projects.displayName = 'Projects'
