import dayjs from '@utilities/dayjs.ts'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import toast from 'react-hot-toast'
import i18n from '@/translations/i18n.ts'
import {AxiosError} from 'axios'
import {SerializedSearchParam} from '@/types/commons.ts'
import {SelectValue} from '@components/commons/Select'
import {ConfigType, UnitType} from 'dayjs'

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const retrieveSelectSingleValue = (options: SelectValue[], value: string) => {
    return options.find(option => option.value?.toString() === value)
}

export const retrieveSelectMultiValues = (options: SelectValue[], values: string[] | undefined) => {
    if (!values || !options) return
    return options?.filter(option => values?.find(value => value?.toString() === option?.value?.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

/*date time helpers*/
//TODO: improve args types
export const formatLocaleDate = (date?: string | null, formatType = 'YYYY-MM-DD') =>
    date ? dayjs(date).locale(i18n.language).format(formatType) : '-'

export const getDatesDifference = (startDate: ConfigType, endDate: ConfigType, unit: UnitType) =>
    dayjs(startDate).diff(endDate, unit)

export const isPasteDate = (date: string): boolean => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()

export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)

    return dayjs.tz.guess()
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

//TODO: refactor errorHandler based on backend error response structure
export const errorHandler = (error: AxiosError<{message: string}>) => {
    const messageKey =
        error.response?.data.message && error.status != 500
            ? `errors:${error.response?.data.message}`
            : 'errors:default'
    toast.error(i18n.t(messageKey))
    return error
}

//TODO: move it in useParsedSearchParams
export const URLSearchParamsIterator = (searchValue: URLSearchParams) => {
    const keys: SerializedSearchParam = {}
    for (const key of searchValue.keys()) {
        keys[key] = searchValue.getAll(key)
    }
    return keys
}

export const containsAtLeastOne = <T>(master: T[] | readonly T[], sub: T[]): boolean => {
    return sub.some(r => master.indexOf(r) >= 0)
}

//events helpers
export const subscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
    document.addEventListener(eventName, listener)
}

export const unsubscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
    document.removeEventListener(eventName, listener)
}

export const publish = (eventName: string, data: Record<string, string>) => {
    const event = new CustomEvent(eventName, data)
    document.dispatchEvent(event)
}

export const secondsToDays = (seconds: number) => {
    return Math.round(seconds / (60 * 60 * 24))
}

export const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

export const iterateOverDirtyFields = <T extends object>(
    dirtyFields: Partial<Record<keyof T, boolean | undefined>>,
    formValues: T
): object => {
    let dataToSend: Record<keyof T, unknown> | object = {}
    for (const key in dirtyFields) {
        if (formValues[key]) {
            dataToSend = {
                ...dataToSend,
                [key]: formValues[key]
            }
        }
    }
    return dataToSend
}

export const downloadFile = (file: File | undefined) => {
    if (!file) return
    const fileLocalURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileLocalURL
    link.download = file.name
    link.click()
    URL.revokeObjectURL(fileLocalURL)
}

export const buildJSON = (fileName = 'data', jsonValues: object | undefined) => {
    if (!jsonValues) return
    const jsonString = JSON.stringify(jsonValues)
    const jsonBlob = new Blob([jsonString], {type: 'application/json'})
    return new File([jsonBlob], `${fileName}.json`, {type: 'application/json'})
}

export const buildTSV = (fileName = 'data', tsvData: string | undefined) => {
    if (!tsvData) return
    const blob = new Blob([tsvData], {type: 'text/tsv'})
    return new File([blob], `${fileName}.tsv`, {type: 'text/tsv'})
}

export const remapSelectOptions = <T extends Record<string, unknown>>(
    array: T[] | undefined,
    valueKey: keyof T,
    labelKey: keyof T | ((item: T, index: number, array: T[], valueKey: keyof T) => string)
) => {
    return (
        array?.map((item, index) => ({
            value: String(item[valueKey]),
            label: typeof labelKey == 'function' ? labelKey(item, index, array, valueKey) : String(item[labelKey])
        })) ?? []
    )
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

export const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
}
