import styled, {css} from 'styled-components'

export const StyledSubtext = styled.p`
    ${({theme: {typography, palette}}) => css`
        text-align: center;
        width: 100%;
        color: ${palette.neutral[700]};
        font-size: ${typography.textSm};
        font-weight: 500;
    `}
`
