import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetProcessStructure} from '../services/process.http'

type useProcessStructureProps = {
    enabled?: boolean
}

export const useProcessStructure = (projectId: number, processId: number, options?: useProcessStructureProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROCESS_STRUCTURE, projectId, processId],
        queryFn: () => httpGetProcessStructure(projectId, processId),
        ...options
    })
}
