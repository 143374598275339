import {Modal} from '@/components/ui/Modal'
import styled, {css} from 'styled-components'

export const StyledModal = styled(Modal)`
    ${({theme: {spacing, palette, typography}}) => css`
        h2 {
            font-size: ${typography.textLg};
            color: ${palette.neutral[900]};
            font-weight: 600;
        }

        h3 {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }

        .icon-container {
            width: 48px;
            aspect-ratio: 1/1;
            border-radius: 100%;
            flex-shrink: 0;
            display: grid;
            place-items: center;
            background-color: ${palette.warning[100]};
            color: ${palette.warning[600]};
        }
        .modal-header {
            padding: ${spacing * 6}px ${spacing * 6}px 0 ${spacing * 6}px;
        }

        .modal-footer {
            padding: ${spacing * 8}px ${spacing * 6}px ${spacing * 6}px ${spacing * 6}px;
        }
    `}
`
