import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetProjectBatch} from '../services/batches.http'

type useProjectBatchProps = {
    enabled?: boolean
}

export const useProjectBatch = (projectId: number, batchId: number, options?: useProjectBatchProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROJECT_BATCHES, projectId, batchId],
        queryFn: () => httpGetProjectBatch({projectId, batchId}),
        ...options
    })
}
