import Button from '@/components/ui/Button'
import {FormControlsWrapper} from './styles'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

type FormControlsProps = {
    onCheckConnection?: () => void
    isLoadingCheckConnection?: boolean
    onCancel?: () => void
    isConfirmLoading?: boolean
}

export const FormControls: FC<FormControlsProps> = ({
    onCheckConnection,
    isLoadingCheckConnection,
    onCancel,
    isConfirmLoading
}) => {
    const {t} = useTranslation()
    return (
        <FormControlsWrapper>
            <Button
                style={{visibility: 'hidden'}}
                variant="ghost"
                onClick={onCheckConnection}
                disabled={isLoadingCheckConnection}
            >
                {t('projectDetails:properties:checkConnection')}
            </Button>
            <Button variant="secondary" onClick={onCancel} className="cancel-btn">
                {t('commons:cancel')}
            </Button>
            <Button type="submit" disabled={isConfirmLoading}>
                {t('commons:confirm')}
            </Button>
        </FormControlsWrapper>
    )
}
