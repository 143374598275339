import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {httpClaimTask} from '@/features/tasks/services/tasks.http.ts'
import {TaskExtended} from '@/features/tasks/types.ts'
import {AxiosError} from 'axios'

type UseClaimTaskProps = {
    onError?: (error: AxiosError<{message: string}>) => void
    onSuccess?: (response: TaskExtended) => void
}
export const useClaimTask = ({onSuccess, onError}: UseClaimTaskProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CLAIM_TASKS],
        mutationFn: httpClaimTask,
        onError: (error: AxiosError<{message: string}>) => {
            errorHandler(error)
            onError?.(error)
        },
        onSuccess: async response => {
            await queryClient.cancelQueries([QUERY_KEYS.ASSIGNED_TASK])
            queryClient.setQueriesData([QUERY_KEYS.ASSIGNED_TASK], () => response)
            onSuccess?.(response)
        }
    })
}
