import {Flexbox} from '@/components/ui/Flexbox'
import {StyledModal} from './style'
import {AlertCircleIcon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {FC} from 'react'
import {useDeactivateBatch} from '../../queries/useDeactivateBatch'
import toast from 'react-hot-toast'
import {useBulkDeactivateBatches} from '../../queries/useBulkDeactivateBatches'

type BatchesDeactivateModalProps = {
    batchId: number | number[]
    onClose: () => void
    onSuccess?: () => void
}

export const BatchesDeactivateModal: FC<BatchesDeactivateModalProps> = ({batchId, onClose, onSuccess}) => {
    const {t} = useTranslation()

    const {mutate: deactivateBatch, isLoading: isLoadingDeactivateBatch} = useDeactivateBatch({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onSuccess?.()
            onClose()
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {mutate: bulkDeactivateBatches, isLoading: isLoadingBulkDeactivateBatches} = useBulkDeactivateBatches({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onSuccess?.()
            onClose()
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const onSubmit = () => {
        if (batchId instanceof Array) {
            bulkDeactivateBatches({body: {ids: batchId}})
            return
        }
        deactivateBatch({batchId})
    }

    return (
        <StyledModal onClose={onClose}>
            <Flexbox gap={4} className="modal-header">
                <div className="icon-container">
                    <AlertCircleIcon size={24} />
                </div>
                <Flexbox direction="column" gap={1}>
                    <h2>
                        {t('batches:deactivate_batch_title', {count: batchId instanceof Array ? batchId.length : 1})}
                    </h2>
                    <h3>
                        {t('batches:deactivate_batch_modal_title', {
                            count: batchId instanceof Array ? batchId.length : 1
                        })}
                    </h3>
                </Flexbox>
            </Flexbox>
            <Flexbox justify="end" gap={3} className="modal-footer">
                <Button variant="secondary" size="lg" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    disabled={isLoadingDeactivateBatch || isLoadingBulkDeactivateBatches}
                    size="lg"
                    onClick={onSubmit}
                >
                    {t('batches:deactivate')}
                </Button>
            </Flexbox>
        </StyledModal>
    )
}
