import {z} from 'zod'

export const makeValidationSchema = (taskNumber: number) => {
    return z.object({
        assignments: z
            .array(
                z.object({
                    user_id: z.number({
                        required_error: 'errors:required',
                        invalid_type_error: 'errors:required'
                    }),
                    percentage: z.coerce
                        .number({
                            required_error: 'errors:number',
                            invalid_type_error: 'errors:number'
                        })
                        .int('errors:number')
                        .positive('errors:positiveNumber')
                })
            )
            .refine(
                percentages => percentages.reduce((prev, curr) => prev + curr.percentage, 0) === 100,
                'errors:sum_to_100'
            )
            .refine(
                percentages => percentages.every(({percentage}) => (percentage / 100) * taskNumber >= 1),
                'errors:invalid_percentage'
            )
    })
}

export type FormValues = {
    assignments: {
        user_id: number | null
        percentage: number
    }[]
}
