import {Handle, NodeProps, Position} from 'reactflow'
import {ProcessNodeWrapper, StyledProcessNode} from './style'
import {useState} from 'react'
import Button from '@/components/ui/Button'
import {useRemoveNode} from '../../helpers/useRemoveNode'
import {useHasMultipleChildren} from '../../helpers/useHasMultipleChildren'
import {handleStyle} from './constants'
import {AdderButton} from './adder-button'
import {ArrowRightIcon, Trash01Icon} from '@/components/ui/Icon'
import {useSearchParams} from 'react-router-dom'
import {SELECTED_NODE_PARAM_KEY} from '../../constants'
import {useGraphContext} from '../../context/graphContext'
import {useCenterViewPortOnNode} from '../../helpers/useCenterViewPortOnNode'
import {useTranslation} from 'react-i18next'
import {GraphNode} from '../../types'

const processTypeIdMap = {
    '1': 'Image Box',
    '2': 'Image Segmentation'
} as const

export const ProcessNode = (
    nodeData: NodeProps<Extract<GraphNode, {type: 'processNode' | 'tempProcessNode'}>['data']>
) => {
    const {t} = useTranslation()

    const [showControls, setShowControls] = useState(false)
    const [lockAdderButton, setLockAdderButton] = useState<'left' | 'bottom' | 'right' | null>(null)
    const [, setSearchParams] = useSearchParams()

    const {removeNode} = useRemoveNode()
    const {hasMultipleChildren} = useHasMultipleChildren(nodeData.id)
    const {edges} = useGraphContext()

    const {centerViewPort, centerViewPortOnParent} = useCenterViewPortOnNode()

    const [searchParams] = useSearchParams()
    const isSelected = searchParams.get(SELECTED_NODE_PARAM_KEY) === nodeData.id

    const parentId = edges.find(edge => edge.target === nodeData.id)?.source || 'start'
    const childId = edges.find(edge => edge.source === nodeData.id)?.target || 'end'

    const selectNode = () => {
        centerViewPort({
            ...nodeData,
            position: {x: nodeData.xPos, y: nodeData.yPos},
            type: nodeData.type as 'processNode' | 'tempProcessNode'
        })
        setSearchParams(prev => {
            prev.set(SELECTED_NODE_PARAM_KEY, nodeData.id)
            return prev
        })
    }

    const subTitle = !nodeData.data.is_qa ? (
        t('commons:edit')
    ) : (
        <div className="subtitle-container">
            {t('commons:edit')}
            {<ArrowRightIcon size={16} />}
            {t('projectDetails:process:qa')}
        </div>
    )

    return (
        <ProcessNodeWrapper onMouseOver={() => setShowControls(true)} onMouseOut={() => setShowControls(false)}>
            {!hasMultipleChildren && (showControls || lockAdderButton === 'right') && (
                <AdderButton
                    mode="series"
                    className="right"
                    parentId={nodeData.id}
                    childId={childId}
                    onPopoverOpenChange={open => {
                        if (open) {
                            setLockAdderButton('right')
                        } else {
                            setLockAdderButton(null)
                            setShowControls(false)
                        }
                    }}
                />
            )}
            {(showControls || lockAdderButton === 'bottom') && (
                <AdderButton
                    mode="parallel"
                    className="bottom"
                    parentId={parentId}
                    childId={'end'}
                    onPopoverOpenChange={open => {
                        if (open) {
                            setLockAdderButton('bottom')
                        } else {
                            setLockAdderButton(null)
                            setShowControls(false)
                        }
                    }}
                />
            )}

            {(showControls || lockAdderButton === 'left') && (
                <AdderButton
                    parentId={parentId}
                    childId={nodeData.id}
                    mode="series"
                    className="left"
                    onPopoverOpenChange={open => {
                        if (open) {
                            setLockAdderButton('left')
                        } else {
                            setLockAdderButton(null)
                            setShowControls(false)
                        }
                    }}
                />
            )}

            <StyledProcessNode isSelected={isSelected} onClick={selectNode}>
                <div className="delete-btn-container">
                    <Button
                        variant="ghostDanger"
                        size="sm"
                        iconOnly
                        onClick={e => {
                            e.stopPropagation()
                            removeNode({nodeId: nodeData.id})
                            centerViewPortOnParent({nodeId: nodeData.id})
                        }}
                    >
                        <Trash01Icon />
                    </Button>
                </div>
                <h2>{processTypeIdMap[nodeData.data.process_type_id.toString() as keyof typeof processTypeIdMap]}</h2>
                <div className="subtitle">
                    {nodeData.type === 'tempProcessNode' ? t('projectDetails:process:newNode') : subTitle}
                </div>
                <Handle type="source" position={Position.Right} id="a" isConnectable={false} style={handleStyle} />
                <Handle type="target" position={Position.Left} id="b" isConnectable={false} style={handleStyle} />
                <Handle type="source" position={Position.Bottom} id="b" isConnectable={false} style={handleStyle} />
            </StyledProcessNode>
        </ProcessNodeWrapper>
    )
}
