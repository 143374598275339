import {Flexbox} from '@/components/ui/Flexbox'
import {TASK_TYPES} from '@/features/analytics/constants'
import {useTasksStatsSearchParams} from '@/features/analytics/hooks/useTasksStatsSearchParams'
import {useBudgetStats} from '@/features/analytics/queries/useBudgetStats'
import {useTasksStats} from '@/features/analytics/queries/useTasksStats'
import {useParams} from 'react-router-dom'
import {useBatchDetails} from '@/features/batches/queries/useBatchDetails'
import {StatsLoader} from '../../components/stats-loader'
import {StatsOverview} from '../../components/stats-overview'
import {TasksStatsWithParams} from '../../components/stats-tasks/TasksStatsWithParams'
import {StatsPerformanceWithParams} from '../../components/stats-performance/StatsPerformanceWithParams'
import {Charts} from '../../components/charts'
import toast from 'react-hot-toast'

export const SubbatchStats = () => {
    const {batchId} = useParams()
    if (!batchId) {
        throw new Error('Missing param :batchId')
    }

    const subBatchId = Number(batchId)
    const {searchParams: tasksSearchParmas} = useTasksStatsSearchParams()

    const {data: subBatch} = useBatchDetails(batchId)

    const {
        data: budgetStats,
        isLoading: isLoadingBudgetStats,
        isSuccess: isSuccessBudgetStats,
        error: budgetStatsError
    } = useBudgetStats(
        {
            project_id: subBatch?.project_id || -1,
            subbatch_id: subBatchId
        },
        {
            enabled: !!subBatch?.project_id && !!subBatch?.subproject_id
        }
    )

    const {
        data: tasksStats,
        isLoading: isLoadingTasksStats,
        isSuccess: isSuccessTasksStats,
        error: tasksStatsError
    } = useTasksStats(
        {
            project_id: subBatch?.project_id || -1,
            subbatch_id: subBatchId,
            task_type: tasksSearchParmas.task_type !== TASK_TYPES.ALL ? tasksSearchParmas.task_type : undefined
        },
        {enabled: !!subBatch?.project_id && !!subBatch?.subproject_id}
    )

    const error = budgetStatsError || tasksStatsError

    if (error) {
        toast.error('commons:genericError') //TODO Handle error
        return null
    }

    return (
        <Flexbox gap={8} direction="column">
            {isLoadingBudgetStats || !isSuccessBudgetStats ? (
                <StatsLoader />
            ) : (
                <StatsOverview
                    budget={budgetStats.budget}
                    usedBudget={budgetStats.used_budget}
                    turnAroundTime={budgetStats.turn_around_time}
                />
            )}
            {isLoadingTasksStats || !isSuccessTasksStats ? (
                <StatsLoader />
            ) : (
                <TasksStatsWithParams
                    pendingCount={tasksStats.pending_count}
                    onGoingCount={tasksStats.ongoing_count}
                    completedCount={tasksStats.completed_count}
                    totalCount={tasksStats.total_count}
                />
            )}
            <StatsPerformanceWithParams />
            {subBatch && <Charts projectId={subBatch.project_id} subbatchId={subBatchId} />}
        </Flexbox>
    )
}
