import {StyledOperatorsTableEmptyState} from '@/features/operators/components/OperatorsTableEmptyState/style.ts'
import {IconContainer} from '@components/ui/IconContainer'
import {Users01Icon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'

export const OperatorsTableEmptyState = () => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <>
            <StyledOperatorsTableEmptyState>
                <IconContainer>
                    <Users01Icon fill={theme.palette.neutral['700']} />
                </IconContainer>
                <hgroup>
                    <h3>{t('operators:table:empty_state_title')}</h3>
                    <h4>{t('operators:table:empty_state_subtitle')}</h4>
                </hgroup>
            </StyledOperatorsTableEmptyState>
        </>
    )
}

OperatorsTableEmptyState.displayName = 'OperatorsTableEmptyState'
