import InputSelect, {SelectValue} from '@/components/commons/Select'
import {ComponentProps, useDeferredValue, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTaxonomies} from '../../queries/useTaxonomies'
import {Copy01Icon, File06Icon} from '@/components/ui/Icon'
import {TaxonomiesSelectWrapper} from './styles'
import {SingleValue} from 'react-select'
import {useTaxonomy} from '../../queries/useTaxonomy'
import {TaxonomyModal} from '../TaxonomyModal'
import Label from '@/components/ui/Label'
import Button from '@/components/ui/Button'
import {copyTextToClipboard} from '@/utilities/helpers'
import {toast} from 'react-hot-toast'

type TaxonomiesSelectProps = Omit<
    ComponentProps<typeof InputSelect>,
    'options' | 'placeholder' | 'inputValue' | 'onInputChange' | 'value' | 'onChange' | 'isMulti'
> & {showInstructions?: boolean; value: number | null; onChange: (newVal: number | null) => void}

export const TaxonomiesSelect = ({showInstructions, value, onChange, label, ...props}: TaxonomiesSelectProps) => {
    const {t} = useTranslation()

    const [openTaxonomyModal, setOpenTaxonomyModal] = useState(false)

    const {
        data: taxonomy,
        isLoading: isLoadingTaxonomy,
        isFetching: isFetchingTaxonomy,
        error: taxonomyError
    } = useTaxonomy(value as number, {enabled: !!value})

    const taxonomyInstructions = taxonomy?.instructions?.[0].url
    const taxonomyName = taxonomyInstructions?.split('/')?.pop()

    if (taxonomyError) {
        //TODO handle get taxonomy error
    }

    const [inputValue, setInputValue] = useState('')
    const deferedSearch = useDeferredValue(inputValue)

    const {data} = useTaxonomies({page: 1, name: deferedSearch, is_active: true})

    let options = data?.data?.map(taxonomy => ({
        value: taxonomy?.id.toString() || '',
        label: taxonomy?.name || ''
    }))

    if (taxonomy && options && !options.map(opt => opt.value).includes(taxonomy.id.toString())) {
        options = [{value: taxonomy.id.toString(), label: taxonomy.name || ''}, ...options]
    }

    const selectValue = options?.find(opt => opt.value === value?.toString())

    return (
        <>
            <TaxonomyModal
                open={openTaxonomyModal}
                setOpen={setOpenTaxonomyModal}
                onSuccess={newTaxonomy => onChange(newTaxonomy.id)}
            />
            <TaxonomiesSelectWrapper showInstructions={showInstructions}>
                <div className="label-container">
                    <Label> {label ?? t('taxonomies:new_taxonomy')} </Label>
                    <Button disabled={props.disabled} variant="linkColor" onClick={() => setOpenTaxonomyModal(true)}>
                        {t('taxonomies:addTaxonomy')}
                    </Button>
                </div>

                <InputSelect
                    {...props}
                    className="select"
                    isMulti={false}
                    value={selectValue}
                    onChange={newValue => {
                        const value = (newValue as SingleValue<SelectValue> | undefined)?.value
                        const parsedNewValue = value ? Number(value) : null
                        onChange(parsedNewValue)
                    }}
                    placeholder={t('taxonomies:selectATaxonomy')}
                    options={options ?? []}
                    isSearchable
                    inputValue={inputValue}
                    onInputChange={newText => setInputValue(newText)}
                />

                {showInstructions && taxonomyInstructions && !isLoadingTaxonomy && !isFetchingTaxonomy && (
                    <div className="instruction-container">
                        <div className="main-icon-container">
                            <File06Icon size={24} />
                        </div>
                        <div className="info-container">
                            <p className="name">{taxonomyName}</p>
                            <a href={taxonomyInstructions} target={'_blank'} rel="noopener noreferrer">
                                {taxonomyInstructions}
                            </a>
                        </div>
                        <div className="copy-icon-container">
                            <Button
                                variant="ghost"
                                iconOnly
                                onClick={() => {
                                    toast.success(t('commons:copied'))
                                    copyTextToClipboard(taxonomyInstructions ?? '')
                                }}
                            >
                                <Copy01Icon size={20} />
                            </Button>
                        </div>
                    </div>
                )}
            </TaxonomiesSelectWrapper>
        </>
    )
}
