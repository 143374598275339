import {z} from 'zod'

export const OPERATOR_FORM_MODEL = {
    preferred_modalities: {
        name: 'preferred_modalities',
        label: 'settings:operator:preferred_modalities',
        defaultValue: '',
        helpText: '',
        placeholder: 'settings:operator:preferred_modalities_placeholder'
    },
    preferred_task_types: {
        name: 'preferred_task_types',
        label: 'settings:operator:preferred_task_types',
        defaultValue: '',
        helpText: '',
        placeholder: 'settings:operator:preferred_task_types'
    }
} as const

export const OperatorFromSchema = z.object({
    preferred_modalities:
        z
            .array(
                z.object({
                    label: z.string(),
                    value: z.string()
                })
            )
            .min(1, {message: 'settings:operator:preferred_modalities_error'}) ||
        z.object({
            label: z.string(),
            value: z.string()
        }),
    preferred_task_types:
        z
            .array(
                z.object({
                    label: z.string(),
                    value: z.string()
                })
            )
            .min(1, {message: 'settings:operator:preferred_task_types_error'}) ||
        z.object({
            label: z.string(),
            value: z.string()
        })
})

export type OperatorValidationSchema = z.infer<typeof OperatorFromSchema>
