import Container from '@components/ui/Container'
import PageHeader from '@components/commons/PageHeader'
import {useTranslation} from 'react-i18next'
import {OverallStats} from '@/features/analytics/views/overall-view'

export const Stats = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <PageHeader title={t('stats:title')} />
            <OverallStats />
        </Container>
    )
}

Stats.displayName = 'Stats'
