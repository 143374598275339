import {StyledContainer, TabsWrapper, StyledNavLink} from '@pages/users/style.ts'
import {useTranslation} from 'react-i18next'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useRoles} from '@/features/roles/queries/useRoles.ts'
import Spinner from '@components/ui/Spinner'
import {useTheme} from 'styled-components'
import {Flexbox} from '@components/ui/Flexbox'
import {useAuth} from '@context/AuthProvider.tsx'
import {checkUsersRouteRedirect, roleSlugToUsersTabs} from '@/features/roles/helpers.tsx'
import {useEffect} from 'react'
import {httpExportUsers} from '@/features/users/services/users.http.ts'
import {buildTSV, downloadFile} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'

const Users = () => {
    const auth = useAuth()
    const {t} = useTranslation()
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const rolesQuery = useRoles()
    const tabs = auth.user ? roleSlugToUsersTabs[auth.user.role.slug] : []

    useEffect(() => {
        const redirectToPath = checkUsersRouteRedirect(location.pathname, auth.user?.role.slug)
        if (redirectToPath) {
            navigate(redirectToPath, {replace: true})
        }
    }, [auth.user?.role.slug, location.pathname])

    const exportUsersTSV = async (roleID: number) => {
        try {
            const {data} = await httpExportUsers(roleID)
            downloadFile(buildTSV('users', data))
        } catch (err) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <StyledContainer fullHeight>
            <h1>{t('users:headline')}</h1>
            <TabsWrapper>
                {tabs.map(tab => (
                    <StyledNavLink to={tab.path} key={tab.path}>
                        {t(tab.label, {count: 1})}
                    </StyledNavLink>
                ))}
            </TabsWrapper>
            {rolesQuery.data.length >= 1 ? (
                <Outlet context={{exportUsersTSV}} />
            ) : (
                <Flexbox justify="center" align="center">
                    <Spinner size={48} color={theme.palette.neutral['500']} />
                </Flexbox>
            )}
        </StyledContainer>
    )
}

export default Users

Users.displayName = 'Users'
