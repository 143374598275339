import {z} from 'zod'

export type FormValues = {
    need_date: Date | null
    possible_date: Date | null
    name: string
    priority: number | null
}

export const validationSchema = z.object({
    need_date: z.coerce
        .date({
            invalid_type_error: 'errors:date',
            required_error: 'errors:required'
        })
        .nullable()
        .optional(),
    possible_date: z.coerce
        .date({
            invalid_type_error: 'errors:date',
            required_error: 'errors:required'
        })
        .nullable()
        .optional(),
    name: z.string({
        invalid_type_error: 'errors:required',
        required_error: 'errors:required'
    }),
    priority: z.coerce
        .number({
            invalid_type_error: 'errors:number',
            required_error: 'errors:number'
        })
        .int('errors:number')
        .gte(0, 'errors:positiveNumber')
        .refine(prio => /^[0-9]+$/.test(prio.toString()), 'errors:number')
        .nullable()
        .optional()
})
