import styled, {css} from 'styled-components'

export const ProjectDetailsStatsWrapper = styled.div`
    ${() => css`
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    `}
`

export const StyledProjectStatsGrid = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`
