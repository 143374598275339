import {useOutletContext} from 'react-router-dom'
import {useRoles} from '@/features/roles/queries/useRoles.ts'
import {Trans, useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useRef, useState} from 'react'
import {UserRecord, UsersSearchParams} from '@/features/users/types.ts'
import useUsers from '@/features/users/queries/useUsers.ts'
import {useDeleteUser} from '@/features/users/queries/useDeleteUser.ts'
import toast from 'react-hot-toast'
import Table from '@components/commons/Table'
import Button from '@components/ui/Button'
import {DownloadCloud02Icon, Edit01Icon, SearchLgIcon, Trash01Icon, Users01Icon} from '@components/ui/Icon'
import InputText from '@components/commons/InputText'
import {UsersTableEmptyState} from '@/features/users/components/UsersTableEmptyState'
import {EmptySearch} from '@components/commons/EmptySearch'
import {ManageUsersModal} from '@/features/users/components/ManageUsersModal'
import {DeleteModal} from '@components/commons/DeleteModal'
import {EditUserSidebar} from '@/features/users/components/edit-user-sidebar/EditUserSidebar.tsx'
import {roleSlugToID} from '@/features/roles/helpers.tsx'
import {Flexbox} from '@components/ui/Flexbox'
import {StyledTabContent} from '@pages/users/style.ts'
import {buildJSON, downloadFile} from '@utilities/helpers.ts'

export const OperatorUserList = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const rolesQuery = useRoles()
    const labelerRoleID = roleSlugToID(rolesQuery.data, 'labeler')
    const [showManageUsersModal, setShowManageUsersModal] = useState(false)
    const [userToDelete, setUserToDelete] = useState<UserRecord | null>(null)
    const [userToEdit, setUserToEdit] = useState<UserRecord | null>(null)
    const labelersQuery = useUsers(labelerRoleID, UsersSearchParams['labeler'])
    const {exportUsersTSV} = useOutletContext<{exportUsersTSV: (roleID: number) => void}>()

    const deleteUserMutation = useDeleteUser({
        onSuccess: () => {
            setUserToDelete(null)
            toast.success(t('users:delete_user_success', {name: userToDelete?.full_name}))
        },
        roleID: labelerRoleID
    })

    return (
        <StyledTabContent>
            <Flexbox justify="space-between">
                <InputText
                    width="320px"
                    type="text"
                    ref={searchRef}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    defaultValue={labelersQuery.searchValue}
                    placeholder={t('users:search_users') ?? 'Search users'}
                    onChange={event => labelersQuery.search(event.target.value)}
                />
                <Flexbox gap={3}>
                    <Button
                        variant="secondary"
                        onClick={() => exportUsersTSV(labelerRoleID)}
                        disabled={!labelersQuery.remappedData.length}
                    >
                        <DownloadCloud02Icon />
                        {t('users:export_tsv')}
                    </Button>
                    <Button onClick={() => setShowManageUsersModal(true)}>
                        <Users01Icon />
                        {t('users:manage_users')}
                    </Button>
                </Flexbox>
            </Flexbox>
            <Table
                columns={[
                    {
                        label: t('commons:name'),
                        width: '20%',
                        sortName: 'full_name',
                        cellRender: user => <strong>{user?.full_name ?? '-'}</strong>
                    },
                    {
                        label: t('commons:email'),
                        width: '20%',
                        sortName: 'email',
                        cellRender: user => user?.email
                    },
                    {
                        label: t('users:vendor_name'),
                        width: '15%',
                        sortName: 'company_name',
                        cellRender: user => user?.company_name
                    },
                    {
                        label: t('users:supervisor_email'),
                        width: '20%',
                        sortName: 'supervisor_email',
                        cellRender: user => user?.supervisor_email ?? '-'
                    },
                    {
                        label: t('users:skills'),
                        width: '15%',
                        cellRender: user => (
                            <Button
                                variant="linkColor"
                                onClick={() => downloadFile(buildJSON(user?.full_name ?? 'user', user.taxonomy))}
                            >
                                {t('users:view_json')}
                            </Button>
                        )
                    },
                    {
                        alignment: 'right',
                        width: '10%',
                        cellRender: user => (
                            <Flexbox gap={1}>
                                <Button variant="ghost" iconOnly onClick={() => setUserToDelete(user ?? null)}>
                                    <Trash01Icon fill={theme.palette.danger['700']} />
                                </Button>
                                <Button variant="ghost" iconOnly onClick={() => setUserToEdit(user ?? null)}>
                                    <Edit01Icon />
                                </Button>
                            </Flexbox>
                        )
                    }
                ]}
                data={labelersQuery.remappedData}
                emptyStateComponent={<UsersTableEmptyState roleSlug="labeler" />}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => {
                            if (searchRef.current) {
                                searchRef.current.value = ''
                            }
                            labelersQuery.search('')
                        }}
                        title={t('users:empty_search_state_title')}
                        subtitle={t('users:empty_search_state_subtitle', {searchValue: labelersQuery.searchValue})}
                    />
                }
                isChangingPage={labelersQuery.isFetchingNextPage}
                isError={labelersQuery.isError}
                isLoading={labelersQuery.isLoading}
                onChangePage={labelersQuery.fetchNextPage}
                searchValue={labelersQuery.searchValue}
                sorter={labelersQuery.sorter}
            />

            <ManageUsersModal
                isOpen={showManageUsersModal}
                roleSlug="labeler"
                onClose={() => setShowManageUsersModal(false)}
            />
            {!!userToDelete && (
                <DeleteModal
                    isLoading={deleteUserMutation.isLoading}
                    onClose={() => setUserToDelete(null)}
                    onDelete={() => deleteUserMutation.mutate(userToDelete.id)}
                    title={t('users:delete_user_title')}
                    paragraph={
                        <Trans
                            i18nKey="users:delete_user_subtitle"
                            values={{email: userToDelete.email}}
                            components={[<br />]}
                        />
                    }
                />
            )}
            {!!userToEdit && (
                <EditUserSidebar onClose={() => setUserToEdit(null)} roleSlug="labeler" user={userToEdit} />
            )}
        </StyledTabContent>
    )
}

OperatorUserList.displayName = 'OperatorUserList'
