import styled, {css, DefaultTheme} from 'styled-components'
import Container from '@components/ui/Container'

export const StyledContainer = styled(Container)`
    ${({theme: {spacing, typography, palette}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-rows: auto auto 1fr;
        gap: ${spacing * 6}px;

        & h1 {
            ${typography.displaySm};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }
    `}
`
export const StyledProductionBatchDetailsGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 8}px;
    `
)
