import {useQuery} from '@tanstack/react-query'
import {httpGetManHours, httpGetManHoursParams} from '../services/http'
import {QUERY_KEYS} from '@/queryClient'

export const useManHours = (params?: httpGetManHoursParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.MAN_HOURS, params],
        queryFn: () => httpGetManHours(params)
    })
}
