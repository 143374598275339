import {useEffect, useState} from 'react'
import {
    IncrementEventBody,
    projectBatchesSocketIoEvents,
    projectSocket,
    SyncStatusEventBody
} from '@/features/projects/services/projectSocket.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {useQueryClient} from '@tanstack/react-query'

interface UseBatchesGenerationParams {
    projectId: number
    initialProcessedMedia: number
    initialProcessedTask: number
}

export const useBatchesGenerationSocket = ({
    projectId,
    initialProcessedMedia,
    initialProcessedTask
}: UseBatchesGenerationParams) => {
    const [processedMedia, setProcessedMedia] = useState(initialProcessedMedia)
    const [processedTask, setProcessedTask] = useState(initialProcessedTask)
    const queryClient = useQueryClient()

    let processInvalidated = false
    const handleTaskIncrement = async ({amount}: IncrementEventBody) => {
        if (processedTask === 0 && !processInvalidated) {
            processInvalidated = true
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES_STATUS, projectId])
        }
        setProcessedTask(prev => prev + amount)
    }

    const handleMediaIncrement = ({amount}: IncrementEventBody) => setProcessedMedia(prev => prev + amount)

    const handleSyncStatus = async ({status}: SyncStatusEventBody) => {
        if (status === 'completed') {
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECTS])
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BY_ID, projectId])
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BY_ID, projectId.toString()]) //TODO: see comment in useUpdateProject
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES_STATUS, projectId])
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES, projectId])
            await queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_BATCHES])
        }
    }

    const handleKeepConnection = () => {
        setTimeout(() => {
            projectSocket.emit(projectBatchesSocketIoEvents.KEEP_CONNECTION, 'ping')
        }, 5 * 1_000)
    }

    useEffect(() => {
        if (projectId) {
            console.log('here socket')
            projectSocket.connect()
            projectSocket.emit(projectBatchesSocketIoEvents.JOIN_ROOM, {
                roomId: `projects:sync:${projectId}`
            })

            projectSocket.on(projectBatchesSocketIoEvents.TASK_INCREMENT, handleTaskIncrement)
            projectSocket.on(projectBatchesSocketIoEvents.MEDIA_INCREMENT, handleMediaIncrement)
            projectSocket.on(projectBatchesSocketIoEvents.SYNC_STATUS, handleSyncStatus)
            projectSocket.on(projectBatchesSocketIoEvents.KEEP_CONNECTION, handleKeepConnection)

            return () => {
                projectSocket.emit(projectBatchesSocketIoEvents.LEAVE_ROOM, {
                    roomId: `projects:sync:${projectId}`
                })
                projectSocket.off(projectBatchesSocketIoEvents.TASK_INCREMENT, handleTaskIncrement)
                projectSocket.off(projectBatchesSocketIoEvents.MEDIA_INCREMENT, handleMediaIncrement)
                projectSocket.off(projectBatchesSocketIoEvents.SYNC_STATUS, handleSyncStatus)
                projectSocket.off(projectBatchesSocketIoEvents.KEEP_CONNECTION, handleKeepConnection)
                projectSocket.disconnect()
            }
        }
    }, [projectId])

    return {
        processedMedia,
        processedTask
    }
}
