import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {debounce} from '@utilities/helpers.ts'
import {httpGetUsers} from '@/features/users/services/users.http.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {z, ZodObject, ZodRawShape} from 'zod'

const useUsers = <T extends ZodObject<ZodRawShape>>(roleId: number, FiltersSchema: T) => {
    type FiltersSchemaType = z.infer<typeof FiltersSchema>
    const multiParams = [] as const satisfies ReadonlyArray<keyof typeof FiltersSchema>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, FiltersSchema)

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.USERS, roleId, searchParams.orderBy, searchParams.orderDirection, searchParams.search],
        queryFn: ({pageParam = 1}) =>
            httpGetUsers(pageParam, searchParams.orderBy, searchParams.orderDirection, searchParams.search, roleId),
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: FiltersSchemaType['orderBy']
        orderDirection: FiltersSchemaType['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? [],
        search,
        searchValue: searchParams.search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}

export default useUsers
