import {useTranslation} from 'react-i18next'
import Table from '@components/commons/Table'
import {debounce, formatLocaleDate} from '@utilities/helpers.ts'
import useProductionBatches from '@/features/batches/queries/useProductionBatches.ts'
import {batchBadgeRenderer} from '@utilities/statusesHelpers.tsx'
import {EmptySearch} from '@components/commons/EmptySearch'
import {BatchesTableEmptyState} from '@/features/batches/components/BatchesTableEmptyState'
import {useProjectBatchesSearchParams} from '../../hooks/useProjectBatchesSearchParams'
import {ChangeEvent, useState} from 'react'
import {DotsVerticalIcon, Edit01Icon, SearchLgIcon, User01Icon} from '@/components/ui/Icon'
import InputText from '@/components/commons/InputText'
import {Flexbox} from '@/components/ui/Flexbox'
import DropdownMenu from '@/components/ui/DropdownMenu'
import {BatchRecord, BatchStatus} from '../../types'
import {BatchesEditSidebar} from '../BatchesEditSidebar/BatchesEditSidebar'
import {SubBatchedAssignmentSidebar} from '../SubBatchesAssignmentSidebar'
import {BatchesStatusSelect} from '../BatchesStatusSelect'
import {routes} from '@utilities/constants'
import {StyledLink} from '@/features/batches/components/BatchesTableViews/style.ts'

export const BatchesVendorManagerProdView = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useProjectBatchesSearchParams()
    const query = useProductionBatches(searchParams)

    const [batchToEdit, setBatchToEdit] = useState<{
        projectId: number
        batchId: number
    } | null>(null)

    const [batchToAssign, setBatchToAssign] = useState<BatchRecord | null>(null)

    const onSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event?.target?.value})
    }, 750)

    return (
        <>
            {!!batchToEdit && (
                <BatchesEditSidebar
                    projectId={batchToEdit.projectId}
                    batchId={batchToEdit.batchId}
                    roleSlug="vendor_manager"
                    onClose={() => setBatchToEdit(null)}
                />
            )}
            {batchToAssign && (
                <SubBatchedAssignmentSidebar subBatch={batchToAssign} onClose={() => setBatchToAssign(null)} />
            )}
            <Flexbox direction="row" align="center" gap={1}>
                <InputText
                    width="320px"
                    type="text"
                    defaultValue={searchParams.search}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    placeholder={t('batches:search_batch') ?? 'Search a Batch'}
                    onChange={onSearch}
                />
                <BatchesStatusSelect
                    value={(searchParams.status as BatchStatus[]) ?? []}
                    onChange={newStatuses => {
                        setSearchParams({status: newStatuses})
                    }}
                />
            </Flexbox>

            <Table
                columns={[
                    {
                        label: t('batches:table:batch_name'),
                        width: '20%',
                        sortName: 'name',
                        cellRender: batch => (
                            <Flexbox direction="column" gap={1}>
                                <StyledLink
                                    title={batch?.name}
                                    to={`${routes.PRODUCTION_MANAGEMENT.path}/${routes.PRODUCTION_MANAGEMENT_BATCHES.path}/${batch?.id}/${routes.PROD_MANAGEMENT_BATCH_DETAILS_STATS.path}`}
                                >
                                    {batch?.name}
                                </StyledLink>
                                {batchBadgeRenderer(batch?.status ?? null, t)}
                            </Flexbox>
                        )
                    },
                    {
                        label: t('batches:table:project'),
                        width: '13%',
                        cellRender: batch => batch?.project_name ?? '-'
                    },
                    {
                        label: t('batches:table:tasks'),
                        width: '10%',
                        cellRender: batch => (
                            <span>
                                {batch?.completed_tasks}/{batch?.total_tasks}
                            </span>
                        )
                    },
                    {
                        label: t('batches:table:priority'),
                        width: '10%',
                        sortName: 'priority',
                        cellRender: batch => batch?.priority ?? 'None'
                    },
                    {
                        label: t('batches:table:team_leads'),
                        width: '13%',
                        cellRender: batch => {
                            if (!batch?.team_leads.length) {
                                return 'Unassigned'
                            }

                            if (batch.team_leads.length === 1) {
                                return `${batch.team_leads[0].first_name} ${batch.team_leads[0].last_name}`
                            }

                            return t('users:roles:team_lead', {count: batch.team_leads.length})
                        }
                    },
                    {
                        label: t('batches:table:need_date'),
                        width: '10%',
                        sortName: 'need_date',
                        cellRender: batch => (batch?.need_date ? formatLocaleDate(batch.need_date) : '-')
                    },
                    {
                        label: t('batches:table:possible_date'),
                        width: '10%',
                        sortName: 'possible_date',
                        cellRender: batch => (batch?.possible_date ? formatLocaleDate(batch.possible_date) : '-')
                    },
                    {
                        label: t('batches:table:time_budget'),
                        width: '10%',
                        cellRender: batch => (
                            <span>
                                {batch?.used_time ? Math.round(batch?.used_time) : '0'}/
                                {batch?.estimated_time ? Math.round(batch?.estimated_time) : '-'}
                            </span>
                        )
                    },
                    {
                        alignment: 'right',
                        width: '4%',
                        cellRender: batch =>
                            batch && (
                                <DropdownMenu
                                    trigger={<DotsVerticalIcon />}
                                    actions={[
                                        {
                                            component: t('commons:edit'),
                                            icon: <Edit01Icon size={16} />,
                                            onClickCb: () =>
                                                setBatchToEdit({
                                                    batchId: batch.batch_id,
                                                    projectId: batch.project_id
                                                })
                                        },
                                        {
                                            component: t('batches:assign'),
                                            icon: <User01Icon size={16} />,
                                            onClickCb: () => setBatchToAssign(batch),
                                            disabled: !!batch.team_leads.length || batch.status === 'inactive'
                                        }
                                    ]}
                                />
                            )
                    }
                ]}
                emptyStateComponent={<BatchesTableEmptyState />}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => setSearchParams({search: '', status: []})}
                        title={t('batches:table:empty_search_state_title')}
                        subtitle={t('batches:table:empty_search_state_subtitle')}
                    />
                }
                searchValue={Object.keys(searchParams).length > 0}
                data={query.remappedData}
                isChangingPage={query.isFetchingNextPage}
                isError={query.isError}
                isLoading={query.isLoading}
                sorter={{
                    orderBy: searchParams.orderBy,
                    orderDirection: searchParams.orderDirection,
                    onSort: setSearchParams
                }}
                onChangePage={query.fetchNextPage}
            />
        </>
    )
}

BatchesVendorManagerProdView.displayName = 'BatchesVendorManagerProdView'
