import * as pages from '@/pages'
import HomeRedirect from '@route-guards/home-route/HomeRedirect.tsx'
import {ErrorBox} from 'src/components/commons/ErrorBox'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />,
        errorElement: <ErrorBox />
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <pages.SsoRedirect />,
        errorElement: <ErrorBox />
    },
    HOME: {
        path: '/',
        element: <HomeRedirect />,
        errorElement: <ErrorBox />,
        handle: {
            path: '/',
            paramsName: [],
            name: 'routeNames:home',
            end: true
        }
    },
    SHOWCASE: {
        path: '/showcase',
        element: <pages.Home />,
        errorElement: <ErrorBox />,
        handle: {
            path: '/showcase',
            paramsName: [],
            name: 'routeNames:showcase',
            end: true
        }
    },
    USERS: {
        path: '/users',
        element: <pages.Users />,
        errorElement: <ErrorBox />,
        handle: {
            path: '/users',
            paramsName: [],
            name: 'routeNames:users',
            end: false
        }
    },
    USERS_PROGRAM_MANAGER: {
        path: 'program-manager',
        element: <pages.ProgramManagerUserList />,
        errorElement: <ErrorBox />
    },
    USERS_CLIENT_ENGINEER: {
        path: 'client-engineer',
        element: <pages.ClientEngineerUserList />,
        errorElement: <ErrorBox />
    },
    USERS_VENDOR_ADMIN: {
        path: 'vendor-admin',
        element: <pages.VendorAdminUserList />,
        errorElement: <ErrorBox />
    },
    USERS_VENDOR_MANAGER: {
        path: 'vendor-manager',
        element: <pages.VendorManagerUserList />,
        errorElement: <ErrorBox />
    },
    USERS_TEAM_LEAD: {
        path: 'team-lead',
        element: <pages.TeamLeadUserList />,
        errorElement: <ErrorBox />
    },
    USERS_OPERATOR: {
        path: 'operators',
        element: <pages.OperatorUserList />,
        errorElement: <ErrorBox />
    },
    PROJECTS: {
        path: '/projects',
        element: <pages.Projects />,
        errorElement: <ErrorBox />,
        handle: {
            path: '/projects?orderBy=created_at&orderDirection=desc',
            paramsName: [],
            name: 'routeNames:projects',
            end: true
        }
    },
    PROJECT_DETAIL: {
        path: `/projects/:id`,
        element: <pages.ProjectDetails />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/projects/:id/properties`,
            paramsName: ['id'],
            name: '',
            end: true
        }
    },
    PROJECT_DETAIL_SETTINGS: {
        path: 'settings',
        element: <pages.ProjectSettings />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_PROPERTIES: {
        path: 'properties',
        element: <pages.ProjectProperties />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_PROCESS: {
        path: 'process',
        element: <pages.ProjectProcess />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_VENDORS: {
        path: 'vendors',
        element: <pages.ProjectVendors />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_BATCHES: {
        path: 'batches',
        element: <pages.ProjectBatches />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_STATS: {
        path: 'stats',
        element: <pages.ProjectStats />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_OPERATORS: {
        path: 'operators',
        element: <pages.ProjectOperators />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_BATCH: {
        path: `/projects/:id/batches/:batchId`,
        element: <pages.ProjectBatchDetails />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/projects/:id/batches/:batchId/stats`,
            paramsName: ['id', 'batchId'],
            name: '',
            end: true
        }
    },
    PROJECT_DETAIL_BATCH_STATS: {
        path: 'stats',
        element: <pages.BatchStats />,
        errorElement: <ErrorBox />
    },
    PROJECT_DETAIL_BATCH_OPERATORS: {
        path: 'operators',
        element: <pages.ProjectOperators />,
        errorElement: <ErrorBox />
    },
    PRODUCTION_MANAGEMENT: {
        path: '/production-management',
        element: <pages.ProductionManagement />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/production-management/batches`,
            paramsName: [],
            name: 'routeNames:production_management',
            end: true
        }
    },
    PRODUCTION_MANAGEMENT_BATCHES: {
        path: 'batches',
        element: <pages.ProductionManagementBatches />,
        errorElement: <ErrorBox />
    },
    PROD_MANAGEMENT_BATCH_DETAILS: {
        path: `/production-management/batches/:batchId`,
        element: <pages.ProductionBatchDetails />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/production-management/batches/:batchId/stats`,
            paramsName: ['batchId'],
            name: '',
            end: true
        }
    },
    PROD_MANAGEMENT_BATCH_DETAILS_STATS: {
        path: 'stats',
        element: <pages.BatchStats />,
        errorElement: <ErrorBox />
    },
    PROD_MANAGEMENT_BATCH_DETAILS_OPERATORS: {
        path: 'operators',
        element: <pages.ProjectOperators />,
        errorElement: <ErrorBox />
    },
    PRODUCTION_MANAGEMENT_OPERATORS: {
        path: 'operators',
        element: <pages.ProductionManagementLateOperators />,
        errorElement: <ErrorBox />
    },
    TEMPLATES: {
        path: '/templates',
        element: <pages.Templates />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/templates`,
            paramsName: [],
            name: 'routeNames:templates',
            end: true
        }
    },
    ACTIVE_TEMPLATES: {
        path: 'active',
        element: <pages.ActiveTemplates />,
        errorElement: <ErrorBox />
    },
    ARCHIVED_TEMPLATES: {
        path: 'archived',
        element: <pages.ArchivedTemplates />,
        errorElement: <ErrorBox />
    },
    TAXONOMIES: {
        path: '/taxonomies',
        element: <pages.Taxonomies />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/taxonomies/active`,
            paramsName: [],
            name: 'routeNames:taxonomies',
            end: true
        }
    },
    ACTIVE_TAXONOMIES: {
        path: 'active',
        element: <pages.ActiveTaxonomies />,
        errorElement: <ErrorBox />
    },
    INACTIVE_TAXONOMIES: {
        path: 'inactive',
        element: <pages.InactiveTaxonomies />,
        errorElement: <ErrorBox />
    },
    TASKS: {
        path: '/tasks',
        element: <pages.Tasks />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/tasks?orderBy=claimed_at&orderDirection=desc`,
            paramsName: [],
            name: 'routeNames:tasks',
            end: true
        }
    },
    STATS: {
        path: '/stats',
        element: <pages.Stats />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/stats`,
            paramsName: [],
            name: 'routeNames:stats',
            end: true
        }
    },
    SETTINGS: {
        path: '/settings',
        element: <pages.Settings />,
        errorElement: <ErrorBox />,
        handle: {
            path: `/settings`,
            paramsName: [],
            name: 'routeNames:settings',
            end: true
        }
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />,
        errorElement: <ErrorBox />
    }
}

export const TASK_HEADER_OBJ = {
    name: 'routeNames:tasks',
    path: `${routes.TASKS.path}?orderBy=claimed_at&orderDirection=desc`
} as const
export const PROJECTS_HEADER_OBJ = {
    name: 'routeNames:projects',
    path: `${routes.PROJECTS.path}?orderBy=created_at&orderDirection=desc`
} as const
export const PROD_MANAGEMENT_HEADER_OBJ = {
    name: 'routeNames:production_management',
    path: `${routes.PRODUCTION_MANAGEMENT.path}/${routes.PRODUCTION_MANAGEMENT_BATCHES.path}`
} as const
export const USERS_HEADER_OBJ = {
    name: 'routeNames:users',
    path: `${routes.USERS.path}`
} as const
export const TEMPLATES_HEADER_OBJ = {
    name: 'routeNames:templates',
    path: `${routes.TEMPLATES.path}/${routes.ACTIVE_TEMPLATES.path}`
} as const
export const TAXONOMIES_HEADER_OBJ = {
    name: 'routeNames:taxonomies',
    path: `${routes.TAXONOMIES.path}/${routes.ACTIVE_TAXONOMIES.path}`
} as const
export const STATS_HEADER_OBJ = {name: 'routeNames:stats', path: routes.STATS.path} as const
