import React, {useState} from 'react'
import Button from '@components/ui/Button'
import Container from '@components/ui/Container'
import {LoginBox, LoginWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo_icon.svg'
import {httpOneLoginSSO} from '@services/auth.http.ts'
import toast from 'react-hot-toast'
import {OneLoginIcon} from '@components/ui/Icon'
import Spinner from '@components/ui/Spinner'
import {useTheme} from 'styled-components'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    /*const onChangeLang = async (lang: string) => {
        await i18n.changeLanguage(lang === 'en' ? 'it' : 'en')
    }*/

    const oneLoginSSOHandler = async () => {
        setIsLoading(true)
        try {
            const {
                data: {url}
            } = await httpOneLoginSSO()
            window.location.href = url
        } catch (e) {
            toast.error(t('errors:default'))
            setIsLoading(false)
        }
    }

    return (
        <Container>
            <LoginWrapper>
                <LoginBox>
                    <img src={Logo} alt={'logo'} width={48} />
                    <h4>{t('login:title')}</h4>
                    <p>{t('login:subtitle')}</p>
                    <Button variant={'secondary'} size={'lg'} onClick={oneLoginSSOHandler} disabled={isLoading}>
                        {isLoading ? (
                            <Spinner size={16} color={palette.neutral.black} />
                        ) : (
                            <>
                                <OneLoginIcon viewBox={'0 0 409 409'} />
                                {t('login:cta_one_Login')}
                            </>
                        )}
                    </Button>
                    {/*<Button variant={'ghost'} size={'sm'} onClick={() => onChangeLang(language)}>
                        {t('login:change_lang')}
                    </Button>*/}
                </LoginBox>
            </LoginWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
