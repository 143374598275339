import {TableActiveSorter} from '@components/commons/Table'
import {GetTemplateResponse, TemplateRecord} from '@/features/templates/types.ts'
import axiosInstance from '@/services/axiosInstance'

const dummyActiveTemplates: TemplateRecord[] = [
    {
        id: 1,
        name: 'Template 1',
        workstream: 'Workstream A',
        json_link: '123',
        projects_usage: 456,
        created_at: '2023-08-01T12:00:00Z',
        updated_at: '2023-08-02T15:30:00Z'
    },
    {
        id: 2,
        name: 'Template 2',
        workstream: 'Workstream B',
        json_link: '123',
        projects_usage: 789,
        created_at: '2023-08-03T14:00:00Z',
        updated_at: '2023-08-04T16:45:00Z'
    },
    {
        id: 3,
        name: 'Template 3',
        workstream: 'Workstream C',
        json_link: '789',
        projects_usage: 1011,
        created_at: '2023-08-05T10:30:00Z',
        updated_at: '2023-08-06T13:15:00Z'
    },
    {
        id: 4,
        name: 'Template 4',
        workstream: 'Workstream D',
        json_link: '123',
        projects_usage: 1213,
        created_at: '2023-08-07T09:00:00Z',
        updated_at: '2023-08-08T11:45:00Z'
    },
    {
        id: 5,
        name: 'Template 5',
        workstream: 'Workstream E',
        json_link: '123',
        projects_usage: 1415,
        created_at: '2023-08-09T08:30:00Z',
        updated_at: '2023-08-10T10:15:00Z'
    },
    {
        id: 6,
        name: 'Template 6',
        workstream: 'Workstream F',
        json_link: '123',
        projects_usage: 1617,
        created_at: '2023-08-11T16:45:00Z',
        updated_at: '2023-08-12T18:30:00Z'
    },
    {
        id: 7,
        name: 'Template 7',
        workstream: 'Workstream G',
        json_link: '123',
        projects_usage: 1819,
        created_at: '2023-08-13T13:00:00Z',
        updated_at: '2023-08-14T15:30:00Z'
    },
    {
        id: 8,
        name: 'Template 8',
        workstream: 'Workstream H',
        json_link: '123',
        projects_usage: 2021,
        created_at: '2023-08-15T11:15:00Z',
        updated_at: '2023-08-16T13:45:00Z'
    },
    {
        id: 9,
        name: 'Template 9',
        workstream: 'Workstream I',
        json_link: '123',
        projects_usage: 2223,
        created_at: '2023-08-17T14:30:00Z',
        updated_at: '2023-08-18T16:00:00Z'
    },
    {
        id: 10,
        name: 'Template 10',
        workstream: 'Workstream J',
        json_link: '123',
        projects_usage: 2425,
        created_at: '2023-08-19T09:30:00Z',
        updated_at: '2023-08-20T12:15:00Z'
    },
    {
        id: 11,
        name: 'Template 11',
        workstream: 'Workstream K',
        json_link: '123',
        projects_usage: 2627,
        created_at: '2023-08-21T08:00:00Z',
        updated_at: '2023-08-22T10:30:00Z'
    },
    {
        id: 12,
        name: 'Template 12',
        workstream: 'Workstream L',
        json_link: '123',
        projects_usage: 2829,
        created_at: '2023-08-23T15:15:00Z',
        updated_at: '2023-08-24T17:45:00Z'
    },
    {
        id: 13,
        name: 'Template 13',
        workstream: 'Workstream M',
        json_link: '123',
        projects_usage: 3031,
        created_at: '2023-08-25T11:30:00Z',
        updated_at: '2023-08-26T14:00:00Z'
    },
    {
        id: 14,
        name: 'Template 14',
        workstream: 'Workstream N',
        json_link: '123',
        projects_usage: 3233,
        created_at: '2023-08-27T10:45:00Z',
        updated_at: '2023-08-28T13:15:00Z'
    },
    {
        id: 15,
        name: 'Template 15',
        workstream: 'Workstream O',
        json_link: '123',
        projects_usage: 3435,
        created_at: '2023-08-29T12:45:00Z',
        updated_at: '2023-08-30T15:00:00Z'
    },
    {
        id: 16,
        name: 'Template 16',
        workstream: 'Workstream P',
        json_link: '123',
        projects_usage: 3637,
        created_at: '2023-08-31T14:00:00Z',
        updated_at: '2023-09-01T16:30:00Z'
    },
    {
        id: 17,
        name: 'Template 17',
        workstream: 'Workstream Q',
        json_link: '123',
        projects_usage: 3839,
        created_at: '2023-09-02T10:00:00Z',
        updated_at: '2023-09-03T12:45:00Z'
    },
    {
        id: 18,
        name: 'Template 18',
        workstream: 'Workstream R',
        json_link: '123',
        projects_usage: 4041,
        created_at: '2023-09-04T09:15:00Z',
        updated_at: '2023-09-05T11:30:00Z'
    },
    {
        id: 19,
        name: 'Template 19',
        workstream: 'Workstream S',
        json_link: '123',
        projects_usage: 4243,
        created_at: '2023-09-06T11:30:00Z',
        updated_at: '2023-09-07T14:00:00Z'
    },
    {
        id: 20,
        name: 'Template 20',
        workstream: 'Workstream T',
        json_link: '123',
        projects_usage: 4445,
        created_at: '2023-09-08T13:00:00Z',
        updated_at: '2023-09-09T15:15:00Z'
    }
]
const dummyArchivedTemplates: TemplateRecord[] = [
    {
        id: 21,
        name: 'Template 21',
        workstream: 'Workstream U',
        json_link: '123',
        projects_usage: 4647,
        created_at: '2023-09-10T12:00:00Z',
        updated_at: '2023-09-11T15:30:00Z'
    },
    {
        id: 22,
        name: 'Template 22',
        workstream: 'Workstream V',
        json_link: '123',
        projects_usage: 4849,
        created_at: '2023-09-12T14:00:00Z',
        updated_at: '2023-09-13T16:45:00Z'
    },
    {
        id: 23,
        name: 'Template 23',
        workstream: 'Workstream W',
        json_link: '123',
        projects_usage: 5051,
        created_at: '2023-09-14T10:30:00Z',
        updated_at: '2023-09-15T13:15:00Z'
    },
    {
        id: 24,
        name: 'Template 24',
        workstream: 'Workstream X',
        json_link: '123',
        projects_usage: 5253,
        created_at: '2023-09-16T09:00:00Z',
        updated_at: '2023-09-17T11:45:00Z'
    },
    {
        id: 25,
        name: 'Template 25',
        workstream: 'Workstream Y',
        json_link: '123',
        projects_usage: 5455,
        created_at: '2023-09-18T08:30:00Z',
        updated_at: '2023-09-19T10:15:00Z'
    },
    {
        id: 26,
        name: 'Template 26',
        workstream: 'Workstream Z',
        json_link: '123',
        projects_usage: 5657,
        created_at: '2023-09-20T16:45:00Z',
        updated_at: '2023-09-21T18:30:00Z'
    },
    {
        id: 27,
        name: 'Template 27',
        workstream: 'Workstream AA',
        json_link: '123',
        projects_usage: 5859,
        created_at: '2023-09-22T13:00:00Z',
        updated_at: '2023-09-23T15:30:00Z'
    },
    {
        id: 28,
        name: 'Template 28',
        workstream: 'Workstream BB',
        json_link: '123',
        projects_usage: 6061,
        created_at: '2023-09-24T11:15:00Z',
        updated_at: '2023-09-25T13:45:00Z'
    },
    {
        id: 29,
        name: 'Template 29',
        workstream: 'Workstream CC',
        json_link: '123',
        projects_usage: 6263,
        created_at: '2023-09-26T14:30:00Z',
        updated_at: '2023-09-27T16:00:00Z'
    },
    {
        id: 30,
        name: 'Template 30',
        workstream: 'Workstream DD',
        json_link: '123',
        projects_usage: 6465,
        created_at: '2023-09-28T09:30:00Z',
        updated_at: '2023-09-29T12:15:00Z'
    }
]

export const httpGetTemplates = () => {
    return axiosInstance.get('/templates').then(res => res.data as TemplateRecord[])
}

export const httpGetActiveTemplates = async (
    pageParam: number,
    activeSorter: TableActiveSorter<undefined>,
    searchValue: string
) => {
    console.log(activeSorter, searchValue)
    const response: GetTemplateResponse = {
        current_page: pageParam,
        data: dummyActiveTemplates,
        last_page: 4
    }

    await new Promise(resolve => {
        setTimeout(() => resolve(response), 2000)
    })

    return response
}

export const httpGetArchivedTemplates = async (
    pageParam: number,
    activeSorter: TableActiveSorter<undefined>,
    searchValue: string
) => {
    console.log(activeSorter, searchValue)
    const response: GetTemplateResponse = {
        current_page: pageParam,
        data: dummyArchivedTemplates,
        last_page: 4
    }

    await new Promise(resolve => {
        setTimeout(() => resolve(response), 2000)
    })

    return response
}

export const httpCreateTemplate = async (template: TemplateRecord) => {
    await new Promise(resolve => {
        setTimeout(() => resolve(null), 1000)
    })
        .then(() => template)
        .catch(() => {
            throw new Error('error')
        })
}

export const httpDeleteTemplate = async (id: number) => {
    try {
        await new Promise(resolve => {
            setTimeout(() => resolve(null), 1000)
        })
        return id
    } catch (e) {
        console.log(e)
    }
}

export const httpArchiveTemplate = async (id: number) => {
    await new Promise(resolve => {
        setTimeout(() => resolve(null), 1000)
    })
        .then(() => id)
        .catch(() => {
            throw new Error('error')
        })
}

export const httpRestoreTemplate = async (id: number) => {
    await new Promise(resolve => {
        setTimeout(() => resolve(null), 1000)
    })
        .then(() => id)
        .catch(() => {
            throw new Error('error')
        })
}

export const httpUpdateTemplate = async (template: TemplateRecord) => {
    await new Promise(resolve => {
        setTimeout(() => resolve(null), 1000)
    })
        .then(() => template)
        .catch(() => {
            throw new Error('error')
        })
}
