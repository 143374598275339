import {useQuery} from '@tanstack/react-query'
import {HttpGetTasksFeedbackParams, httpGetTasksFeedback} from '../services/http'
import {QUERY_KEYS} from '@/queryClient'

export const useTasksFeedback = (params?: HttpGetTasksFeedbackParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TASKS_FEEDBACK, params],
        queryFn: () => httpGetTasksFeedback(params)
    })
}
