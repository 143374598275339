import {useTheme} from 'styled-components'
import ContentLoader from 'react-content-loader'
import {Flexbox} from '@components/ui/Flexbox'

export const ProjectSettingsContentLoader = () => {
    const {palette} = useTheme()
    const backgroundColor = palette.neutral[100]
    const foregroundColor = palette.neutral[200]

    return (
        <Flexbox align={'start'} direction={'column'} gap={6}>
            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={16} width={100}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={44}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>

            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={16} width={100}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={44}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>

            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={16} width={100}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={44}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>

            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={16} width={100}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader backgroundColor={backgroundColor} foregroundColor={foregroundColor} height={44}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
        </Flexbox>
    )
}

ProjectSettingsContentLoader.displayName = 'ProjectSettingsContentLoader'
