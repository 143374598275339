import {useTranslation} from 'react-i18next'
import Table from '@components/commons/Table'
import {debounce} from '@utilities/helpers.ts'
import CheckBox from '@components/commons/CheckBox'
import {EmptySearch} from '@components/commons/EmptySearch'
import {useRecordSelection} from '@hooks/useRecordSelection.ts'
import {useLateOperators} from '@/features/operators/queries/useLateOperators.ts'
import {OperatorRecord} from '@/features/operators/types.ts'
import {ChangeEvent, useRef} from 'react'
import {DotsVerticalIcon, MessageSquare01Icon, SearchLgIcon, UserX01Icon} from '@components/ui/Icon'
import DropdownMenu from '@components/ui/DropdownMenu'
import {OperatorsTableEmptyState} from '@/features/operators/components/OperatorsTableEmptyState'
import {StyledFiltersContainer} from '@/features/operators/components/OperatorsTable/style.ts'
import {SelectionFastActions} from '@components/commons/SelectionFastActions'
import InputText from '@components/commons/InputText'
import {useParams} from 'react-router-dom'
import {useLateOperatorsSearchParams} from '@/features/operators/queries/useLateOperatorsSearchParams.ts'
import toast from 'react-hot-toast'
import {useSendOperatorsNotification} from '@/features/operators/queries/useSendOperatorsNotification.ts'
import {useUnassignOpertors} from '@/features/operators/queries/useUnassignOperators.ts'

export const OperatorsTable = () => {
    const {t} = useTranslation()
    const {id, batchId} = useParams()
    console.log(id, batchId)
    /*const subProjectId = Number(id)
    const subBatchId = Number(batchId)*/
    const {searchParams, setSearchParams} = useLateOperatorsSearchParams()
    const query = useLateOperators(id, batchId, searchParams)
    const {selectedItems, selectedItemsCounter, selectedItemsToggle, resetSelectedItems} =
        useRecordSelection<OperatorRecord>()
    const inputTextRef = useRef<HTMLInputElement>(null)
    //search
    const onSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event?.target?.value})
    }, 750)
    //send notification
    const {mutate: notifyOperators} = useSendOperatorsNotification({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess')), resetSelectedItems()
        },
        onError: () => toast.error(t('commons:genericError'))
    })
    //unassign operators
    const {mutate: unassignOperators} = useUnassignOpertors({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess')), resetSelectedItems()
        },
        onError: () => toast.error(t('commons:genericError'))
    })

    return (
        <>
            <StyledFiltersContainer>
                <InputText
                    ref={inputTextRef}
                    width="320px"
                    type="text"
                    defaultValue={searchParams.search}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    placeholder={t('operators:search_operator') ?? 'Search by name or Batch name'}
                    onChange={onSearch}
                />
                {!!selectedItemsCounter && (
                    <SelectionFastActions
                        selectedItemsCounter={selectedItemsCounter}
                        actions={[
                            {
                                text: t('commons:send_notification'),
                                icon: <MessageSquare01Icon size={16} />,
                                onClickCb: () =>
                                    notifyOperators({userIds: Object.values(selectedItems).map(item => item.user_id)})
                            },
                            {
                                text: t('commons:unassign'),
                                isDanger: true,
                                icon: <UserX01Icon size={16} />,
                                onClickCb: () => unassignOperators(Object.keys(selectedItems))
                            }
                        ]}
                    />
                )}
            </StyledFiltersContainer>
            <Table
                columns={[
                    {
                        width: '8%',
                        cellRender: operator => (
                            <div style={{display: 'flex', justifyItems: 'center', height: 24, width: '100%'}}>
                                {operator?.id && (
                                    <CheckBox
                                        checked={operator.id in selectedItems}
                                        id={`c-${operator.id}`}
                                        onChange={() => selectedItemsToggle(operator)}
                                    />
                                )}
                            </div>
                        )
                    },
                    {
                        label: t('operators:table:name'),
                        width: '20%',
                        sortName: 'full_name',
                        cellRender: operator => <strong>{operator?.full_name}</strong>
                    },
                    {
                        label: t('operators:table:email'),
                        width: '32%',
                        cellRender: operator => operator?.user.email ?? '-'
                    },
                    {
                        label: t('operators:table:batch_name'),
                        width: '20%',
                        cellRender: operator => operator?.batch?.name
                    },
                    {
                        label: t('operators:table:delta_time'),
                        width: '10%',
                        sortName: 'delta_time',
                        cellRender: operator => <span>{operator?.delta_time}h</span> ?? '-'
                    },
                    {
                        alignment: 'right',
                        width: '10%',
                        cellRender: operator => (
                            <DropdownMenu
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: t('commons:send_notification'),
                                        icon: <MessageSquare01Icon size={16} />,
                                        onClickCb: () => notifyOperators({userIds: [Number(operator?.user_id)]})
                                    },
                                    {
                                        component: t('commons:unassign'),
                                        isDanger: true,
                                        icon: <UserX01Icon size={16} />,
                                        onClickCb: () => unassignOperators([String(operator?.id)])
                                    }
                                ]}
                            />
                        )
                    }
                ]}
                emptyStateComponent={<OperatorsTableEmptyState />}
                searchValue={Object.keys(searchParams).length > 0}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => {
                            if (inputTextRef.current) {
                                inputTextRef.current.value = ''
                            }
                            setSearchParams({search: '', status: []})
                        }}
                        title={t('batches:table:empty_search_state_title')}
                        subtitle={t('batches:table:empty_search_state_subtitle')}
                    />
                }
                data={query.remappedData}
                isChangingPage={query.isFetchingNextPage}
                isError={query.isError}
                isLoading={query.isLoading}
                onChangePage={query.fetchNextPage}
                sorter={{
                    orderBy: searchParams.orderBy,
                    orderDirection: searchParams.orderDirection,
                    onSort: setSearchParams
                }}
            />
        </>
    )
}

OperatorsTable.displayName = 'OperatorsTable'
