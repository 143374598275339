import styled, {css} from 'styled-components'

export const DataServiceModalWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        .header-section {
            padding: ${spacing * 4}px;
        }
        h1 {
            font-size: ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral[900]};
            margin-bottom: ${spacing * 5}px;
        }

        p {
            margin-bottom: ${spacing * 2}px;
            color: ${palette.neutral[700]};
            font-size: ${typography.textSm};
            font-weight: 500;
        }

        .checkbox-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    `}
`

export const StyledCheckBoxContainer = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        align-items: center;
        padding: ${spacing * 4}px;
        width: 186px;
        justify-content: space-between;
        font-size: ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral[700]};
        border: 1px solid ${palette.neutral[200]};
        border-radius: 12px;

        &:has(input:checked) {
            border: 2px solid ${palette.primary[600]};
            color: ${palette.primary[600]};
            background-color: ${palette.primary[50]};
        }

        &:has(input:disabled) {
            cursor: not-allowed;
            background-color: ${palette.neutral[100]};
        }
    `}
`

export const StyledForm = styled.form`
    ${() => css`
        width: 100%;
    `}
`

export const FormWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        .form-fields-section {
            display: flex;
            flex-direction: column;
            padding: 0 ${spacing * 4}px ${spacing * 5}px ${spacing * 4}px;
            margin-bottom: 1px solid ${palette.neutral[200]};
            gap: ${spacing * 4}px;
        }

        .two-columns-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: ${spacing * 2}px;
        }
    `}
`

export const FormControlsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;

        .cancel-btn {
            margin-left: auto;
        }
    `}
`
