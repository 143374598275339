import styled, {css} from 'styled-components'

export const StyledNodeSidebarForm = styled.form`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        height: 100%;

        .override-config-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: ${spacing}px;
            margin-top: ${spacing * 2}px;
            margin-bottom: ${spacing * 4}px;
        }

        .main-content {
            padding: 0 ${spacing * 6}px ${spacing * 6}px ${spacing * 6}px;
            flex-grow: 1;
            overflow-y: auto;
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 4}px;
        }

        .header {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 8}px ${spacing * 6}px;
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;

            p {
                font-size: ${typography.textSm};
                font-weight: 400;
                color: ${palette.neutral[600]};
            }

            h2 {
                font-size: ${typography.textXl};
                font-weight: 600;
                color: ${palette.neutral[900]};
            }
        }

        .footer {
            border-top: 1px solid ${palette.neutral[300]};
            padding: ${spacing * 6}px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .footer-right {
            display: flex;
            flex-direction: row;
            gap: ${spacing * 2}px;
            align-items: center;
            justify-content: space-between;
        }

        section {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 4}px;
        }

        .dataset-container {
            margin-top: ${spacing * 2}px;
            display: flex;
            flex-direction: column;
            gap: ${spacing * 4}px;
        }

        h2 {
            font-size: ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral.black};
        }

        h3 {
            font-size: ${typography.textMd};
            font-weight: 600;
            margin-bottom: 0;
            color: ${palette.neutral.black};
        }

        .sample-info {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;

            p {
                font-size: ${typography.textSm};
                font-weight: 400;
                color: ${palette.neutral[600]};
            }
        }

        .confidence-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: ${spacing * 4}px;
        }

        .workflow-container {
            border-radius: 12px;
            border: 1px solid ${palette.neutral[200]};
            padding-top: ${spacing * 4}px;
        }

        .edit-label {
            padding: 0 ${spacing * 4}px ${spacing * 4}px ${spacing * 4}px;
            border-bottom: 1px solid ${palette.neutral[200]};
        }

        .estimated-time-container {
            padding: ${spacing * 4}px;
            background: ${palette.neutral[50]};
        }

        .arrow-container {
            display: grid;
            place-items: center;
        }

        .qa-container {
            display: flex;
            flex-direction: column;
            border: 1px solid ${palette.neutral[200]};
            border-radius: 12px;
        }

        .qa-container-footer {
            padding: ${spacing * 4}px;
            display: flex;
            background: ${palette.neutral[50]};
            border-top: 1px solid ${palette.neutral[200]};
        }

        .qa-container-header {
            padding: ${spacing * 4}px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .label-with-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    `}
`
