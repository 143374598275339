import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useAuth} from '@context/AuthProvider.tsx'

const PrivateRoute = () => {
    const {accessToken} = useAuth()

    return accessToken ? <Outlet /> : <Navigate to={routes.LOGIN.path} />
}

export default PrivateRoute
