import {AlertCircleIcon, AlertTriangleIcon, CheckIcon} from '@components/ui/Icon'
import {
    StyledMessage,
    StyledManageUsersRecap,
    StyledFailedUsers,
    StyledFailedUserHeadings,
    StyledFailedUser,
    StyledCounters,
    StyledButtons,
    StyledManageUsersResult
} from '@/features/users/components/ManageUsersModal/components/ManageUsersResult/style.ts'
import {Trans, useTranslation} from 'react-i18next'
import {FC} from 'react'
import {Flexbox} from '@components/ui/Flexbox'
import Button from '@components/ui/Button'
import {AddUsersResponse} from '@/features/users/services/users.http.ts'

interface ManageUsersResultProps {
    onClose: () => void
    uploadResults: AddUsersResponse
}
export const ManageUsersResult: FC<ManageUsersResultProps> = ({onClose, uploadResults}) => {
    const {t} = useTranslation()
    const totalCount = uploadResults.counts.added + uploadResults.counts.removed + uploadResults.counts.updated
    const errors = [...uploadResults.errors.added, ...uploadResults.errors.removed, ...uploadResults.errors.updated]

    return (
        <StyledManageUsersResult>
            <Flexbox direction="column" gap={1} className="header">
                <h2>{t('users:result_modal_title')}</h2>
                <h3>{t('users:result_modal_subtitle')}</h3>
            </Flexbox>
            <StyledManageUsersRecap direction="column" gap={6} hasErrors={errors.length >= 1}>
                <Flexbox direction="column" gap={4} width="100%">
                    <StyledMessage variant="success" gap={2}>
                        <CheckIcon size={24} />
                        <h4>{t('users:tsv_file_actions_count', {totalCount: totalCount})}</h4>
                    </StyledMessage>
                    <StyledCounters>
                        <Flexbox direction={'column'} gap={2} align={'center'}>
                            <Trans
                                i18nKey="users:result_modal_added"
                                values={{users: uploadResults.counts.added}}
                                components={[<span />]}
                            />
                        </Flexbox>
                        <Flexbox direction={'column'} gap={2} align={'center'}>
                            <Trans
                                i18nKey="users:result_modal_edited"
                                values={{users: uploadResults.counts.updated}}
                                components={[<span />]}
                            />
                        </Flexbox>
                        <Flexbox direction={'column'} gap={2} align={'center'}>
                            <Trans
                                i18nKey="users:result_modal_removed"
                                values={{users: uploadResults.counts.removed}}
                                components={[<span />]}
                            />
                        </Flexbox>
                    </StyledCounters>
                </Flexbox>
                {errors.length >= 1 && (
                    <Flexbox direction="column" gap={4} width="100%">
                        <StyledMessage variant={'danger'} gap={2} width="100%">
                            <AlertCircleIcon size={24} />
                            <Flexbox direction={'column'} gap={1}>
                                <h4>{t('users:tsv_file_errors_count', {errors: errors.length})}</h4>
                                {t('users:tsv_file_errors')}
                            </Flexbox>
                        </StyledMessage>
                        <Flexbox direction="column" width="100%">
                            <StyledFailedUserHeadings justify="space-between" width="100%">
                                <span>{t('commons:user')}</span>
                                <span>{t('commons:error')}</span>
                            </StyledFailedUserHeadings>
                            <StyledFailedUsers direction="column" width="100%">
                                {errors.map(error => (
                                    <StyledFailedUser key={error.email}>
                                        <p>{error.email || '-'}</p>
                                        <Flexbox as="span" align="center" gap={1}>
                                            <AlertTriangleIcon size={20} />
                                            {t(`errors:${error.error}`)}
                                        </Flexbox>
                                    </StyledFailedUser>
                                ))}
                            </StyledFailedUsers>
                        </Flexbox>
                    </Flexbox>
                )}
            </StyledManageUsersRecap>
            <StyledButtons justify="flex-end">
                <Button variant="secondary" size="lg" onClick={onClose}>
                    {t('users:result_modal_cta')}
                </Button>
            </StyledButtons>
        </StyledManageUsersResult>
    )
}
