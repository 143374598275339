import {FC} from 'react'
import {BatchesAssignmentModal} from '.'
import {useUpdateCompany} from '@/features/companies/queries/useUpdateCompany'
import {CompanyAssignmentMode} from '@/features/companies/types'
import {useCompany} from '@/features/companies/queries/useCompany'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'

type ProductionBatchesAssignmentModalProps = {
    companyId: number
    onClose: () => void
}

export const ProductionBatchesAssignmentModal: FC<ProductionBatchesAssignmentModalProps> = ({companyId, onClose}) => {
    const {data, isLoading: isLoadingCompany, error} = useCompany(companyId)

    const {t} = useTranslation()

    const {mutate, isLoading: isLoadingUpdateCompany} = useUpdateCompany({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onClose()
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    if (error) {
        throw error
    }

    if (isLoadingCompany || !data) {
        return null
    }

    return (
        <BatchesAssignmentModal
            defaultValue={data.assignment_mode}
            companyDefault={data.assignment_mode}
            type="production"
            onClose={onClose}
            isLoading={isLoadingUpdateCompany}
            onSubmit={({assignment_mode}) =>
                mutate({
                    id: companyId,
                    data: {
                        assignment_mode: assignment_mode as CompanyAssignmentMode
                    }
                })
            }
        />
    )
}
