import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetProcessTypes} from '@/features/settings/services/settings.http.ts'

export const useProcessTypes = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROCESS_TYPES],
        queryFn: httpGetProcessTypes
    })
}
