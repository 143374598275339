import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, {css} from 'styled-components'

export const StyledDropdownMenuTrigger = styled(RDropdownMenu.Trigger)`
    cursor: pointer;
`

export const StyledDropdownMenuContent = styled(RDropdownMenu.Content)`
    ${({theme: {palette, shadows}}) => css`
        min-width: 200px;
        background-color: ${palette.neutral.white};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
        border: 1px solid ${palette.neutral['200']};
        z-index: 50;
    `}
`

type StyledDropdownProps = {
    isDanger?: boolean
} & RDropdownMenu.DropdownMenuItemProps

export const StyledDropdownMenuItem = styled(RDropdownMenu.Item)<StyledDropdownProps>`
    ${({theme: {spacing, palette}, isDanger}) => css`
        cursor: pointer;
        display: flex;
        align-items: center;
        align-self: stretch;
        gap: ${spacing * 2}px;
        padding: ${spacing * 3}px ${spacing * 4}px;
        transition: ease-in-out 300ms;
        color: ${isDanger ? palette.danger['700'] : palette.neutral['700']};
        &:hover {
            background-color: ${palette.neutral['50']};
        }
        &[data-disabled] {
            background-color: ${palette.neutral['25']};
            cursor: not-allowed;
            color: ${palette.neutral['300']};
        }
    `}
`
export const StyledDropdownActionText = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
    `}
`

export const StyledDropdownActionComponent = styled.div`
    ${() => css``}
`

export const StyledDropdownSeparator = styled(RDropdownMenu.Separator)`
    ${({theme: {palette}}) => css`
        height: 1px;
        background: ${palette.neutral['200']};
    `}
`
