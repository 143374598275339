import {useOutletContext} from 'react-router-dom'
import {useRoles} from '@/features/roles/queries/useRoles.ts'
import {Trans, useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useRef, useState} from 'react'
import {UserRecord, UsersSearchParams} from '@/features/users/types.ts'
import useUsers from '@/features/users/queries/useUsers.ts'
import {useDeleteUser} from '@/features/users/queries/useDeleteUser.ts'
import toast from 'react-hot-toast'
import Table from '@components/commons/Table'
import Button from '@components/ui/Button'
import {DownloadCloud02Icon, Edit01Icon, SearchLgIcon, Trash01Icon, Users01Icon} from '@components/ui/Icon'
import InputText from '@components/commons/InputText'
import {UsersTableEmptyState} from '@/features/users/components/UsersTableEmptyState'
import {EmptySearch} from '@components/commons/EmptySearch'
import {ManageUsersModal} from '@/features/users/components/ManageUsersModal'
import {DeleteModal} from '@components/commons/DeleteModal'
import {EditUserSidebar} from '@/features/users/components/edit-user-sidebar/EditUserSidebar.tsx'
import {roleSlugToID} from '@/features/roles/helpers.tsx'
import {Flexbox} from '@components/ui/Flexbox'
import {StyledTabContent} from '@pages/users/style.ts'

export const VendorManagerUserList = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const rolesQuery = useRoles()
    const roleID = roleSlugToID(rolesQuery.data, 'vendor_manager') ?? 0
    const [showManageUsersModal, setShowManageUsersModal] = useState(false)
    const [userToDelete, setUserToDelete] = useState<UserRecord | null>(null)
    const [userToEdit, setUserToEdit] = useState<UserRecord | null>(null)
    const userQuery = useUsers(roleID, UsersSearchParams['vendor_manager'])
    const {exportUsersTSV} = useOutletContext<{exportUsersTSV: (roleID: number) => void}>()
    const deleteUserMutation = useDeleteUser({
        onSuccess: () => {
            setUserToDelete(null)
            toast.success(t('users:delete_user_success', {name: userToDelete?.full_name}))
        },
        roleID
    })

    return (
        <StyledTabContent>
            <Flexbox justify="space-between">
                <InputText
                    width="320px"
                    type="text"
                    ref={searchRef}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    defaultValue={userQuery.searchValue}
                    placeholder={t('users:search_users') ?? 'Search users'}
                    onChange={event => userQuery.search(event.target.value)}
                />
                <Flexbox gap={3}>
                    <Button
                        variant="secondary"
                        onClick={() => exportUsersTSV(roleID)}
                        disabled={!userQuery.remappedData.length}
                    >
                        <DownloadCloud02Icon />
                        {t('users:export_tsv')}
                    </Button>
                    <Button onClick={() => setShowManageUsersModal(true)}>
                        <Users01Icon />
                        {t('users:manage_users')}
                    </Button>
                </Flexbox>
            </Flexbox>
            <Table
                columns={[
                    {
                        label: t('commons:name'),
                        width: '25%',
                        sortName: 'full_name',
                        cellRender: user => <strong>{user?.full_name ?? '-'}</strong>
                    },
                    {
                        label: t('commons:email'),
                        width: '40%',
                        sortName: 'email',
                        cellRender: user => user?.email
                    },
                    {
                        label: t('users:vendor_name'),
                        width: '25%',
                        sortName: 'company_name',
                        cellRender: user => user?.company_name
                    },
                    {
                        alignment: 'right',
                        width: '10%',
                        cellRender: user => (
                            <Flexbox gap={1}>
                                <Button variant="ghost" iconOnly onClick={() => setUserToDelete(user ?? null)}>
                                    <Trash01Icon fill={theme.palette.danger['700']} />
                                </Button>
                                <Button variant="ghost" iconOnly onClick={() => setUserToEdit(user ?? null)}>
                                    <Edit01Icon />
                                </Button>
                            </Flexbox>
                        )
                    }
                ]}
                data={userQuery.remappedData}
                emptyStateComponent={<UsersTableEmptyState roleSlug="vendor_manager" />}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => {
                            if (searchRef.current) {
                                searchRef.current.value = ''
                            }
                            userQuery.search('')
                        }}
                        title={t('users:empty_search_state_title')}
                        subtitle={t('users:empty_search_state_subtitle', {searchValue: userQuery.searchValue})}
                    />
                }
                isChangingPage={userQuery.isFetchingNextPage}
                isError={userQuery.isError}
                isLoading={userQuery.isLoading}
                onChangePage={userQuery.fetchNextPage}
                searchValue={userQuery.searchValue}
                sorter={userQuery.sorter}
            />

            <ManageUsersModal
                isOpen={showManageUsersModal}
                roleSlug="vendor_manager"
                onClose={() => setShowManageUsersModal(false)}
            />
            {!!userToDelete && (
                <DeleteModal
                    isLoading={deleteUserMutation.isLoading}
                    onClose={() => setUserToDelete(null)}
                    onDelete={() => deleteUserMutation.mutate(userToDelete.id)}
                    title={t('users:delete_user_title')}
                    paragraph={
                        <Trans
                            i18nKey="users:delete_user_subtitle"
                            values={{email: userToDelete.email}}
                            components={[<br />]}
                        />
                    }
                />
            )}
            {!!userToEdit && (
                <EditUserSidebar onClose={() => setUserToEdit(null)} roleSlug="vendor_manager" user={userToEdit} />
            )}
        </StyledTabContent>
    )
}

VendorManagerUserList.displayName = 'VendorManagerUserList'
