import Container from '@components/ui/Container'
import {useTranslation} from 'react-i18next'
import {RedirectBox, RedirectCtaWrapper, RedirectWrapper} from '@pages/sso-redirect/style.ts'
import Spinner from '@components/ui/Spinner'
import {useTheme} from 'styled-components'
import {Link, useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import {useAuth} from '@context/AuthProvider.tsx'

export const SsoRedirect = () => {
    //here we will perform all the logic needed after sso response
    const {palette} = useTheme()
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const SSOCode = searchParams.get('code')
    const {getAuthenticatedSSOUser} = useAuth()

    const performSSORedirect = async () => {
        if (!getAuthenticatedSSOUser) {
            throw new Error('getAuthenticatedSSOUser should be defined')
        }
        try {
            await getAuthenticatedSSOUser(SSOCode)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        performSSORedirect()
    }, [])

    return (
        <Container>
            <RedirectWrapper>
                <RedirectBox>
                    <Spinner size={48} color={palette.neutral['500']} />
                    <h3>{t('redirect:title')}</h3>
                    <p>{t('redirect:subtitle')}</p>
                    <RedirectCtaWrapper>
                        <small>{t('redirect:cta_pre')}</small>
                        <Link to={'/redirect'}>{t('redirect:cta')}</Link>
                    </RedirectCtaWrapper>
                </RedirectBox>
            </RedirectWrapper>
        </Container>
    )
}

SsoRedirect.displayName = 'SsoRedirect'
