import styled, {css} from 'styled-components'

export const StyledBreadCrumbs = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        color: ${palette.neutral['500']};
        height: 36px;
        & a {
            cursor: pointer;
        }
        & span {
            color: ${palette.neutral['300']};
            margin: 0 ${spacing * 2}px;
        }
    `}
`
