import {CSSProperties, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, useState} from 'react'
import Label from '@components/ui/Label'
import {StyledInput, StyledInputWrapper, StyledVisibleToggle} from '@components/commons/InputText/style.ts'
import {EyeIcon, EyeOffIcon} from '@components/ui/Icon'
import InputHelpText from '@components/ui/InputHelpText'
import InputStatusIcon from '@components/ui/InputStatusIcon'
import {DefaultTFuncReturn} from 'i18next'
import {Flexbox} from '@components/ui/Flexbox'

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    label?: DefaultTFuncReturn | ReactNode
    name?: string
    type: HTMLInputTypeAttribute
    typeIcon?: ReactNode
    touched?: boolean
    /**
     * how to use errorMessage with translations
     *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
     */
    errorMessage?: string | DefaultTFuncReturn
    visibilityToggle?: boolean
    helpText?: string | DefaultTFuncReturn
    placeholder?: string
    inputSize?: 'sm' | 'md' | 'lg'
    width?: CSSProperties['width']
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
    (
        {
            label,
            name,
            type,
            typeIcon,
            touched = false,
            errorMessage,
            helpText,
            className,
            disabled,
            placeholder,
            visibilityToggle = false,
            inputSize = 'md',
            width = '100%',
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <Flexbox className={className} width={width} direction="column" gap={1.5}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <Flexbox align="center" width="100%">
                    <StyledInputWrapper
                        touched={touched}
                        hasError={!!errorMessage}
                        size={inputSize}
                        visibilityToggle={visibilityToggle}
                    >
                        {typeIcon}
                        <StyledInput
                            ref={ref}
                            name={name}
                            type={eyeVisible ? 'text' : type}
                            placeholder={placeholder}
                            disabled={disabled}
                            {...rest}
                        />
                        <InputStatusIcon touched={touched} hasError={!!errorMessage} />
                    </StyledInputWrapper>

                    {/*input Action toggle*/}
                    {visibilityToggle && (
                        <StyledVisibleToggle
                            size={inputSize}
                            disabled={disabled}
                            onClick={() => setEyeVisible(!eyeVisible)}
                        >
                            {eyeVisible ? <EyeIcon size={20} data-color /> : <EyeOffIcon size={20} data-color />}
                        </StyledVisibleToggle>
                    )}
                </Flexbox>
                <InputHelpText error={errorMessage} helpText={helpText} />
            </Flexbox>
        )
    }
)

export default InputText

InputText.displayName = 'InputText'
