import styled, {css} from 'styled-components'
import {NODE_CONTAINER_PADDING, NODE_HEIGHT, NODE_WIDTH, TERMINAL_NODE_SIZE} from './constants'

export const TerminalNodeWrapper = styled.div`
    ${() => css`
        cursor: default;
        width: ${TERMINAL_NODE_SIZE + NODE_CONTAINER_PADDING}px;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
        position: relative;
    `}
`

export const TerminalNode = styled.div`
    ${({theme: {palette, typography}}) => css`
        width: ${TERMINAL_NODE_SIZE}px;
        border-radius: 100%;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
        position: relative;
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[300]};
        color: ${palette.neutral[900]};
        ${typography.textXs}
        font-weight: 500;
    `}
`

export const ProcessNodeWrapper = styled.div`
    ${({theme: {typography, palette}}) => css`
        cursor: default;
        width: ${NODE_WIDTH + NODE_CONTAINER_PADDING}px;
        height: ${NODE_HEIGHT + NODE_CONTAINER_PADDING}px;
        display: grid;
        place-items: center;
        position: relative;

        h2 {
            font-size: ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }

        .subtitle {
            font-size: ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral[600]};
        }
    `}
`

export const StyledProcessNode = styled.div<{isSelected?: boolean}>`
    ${({theme: {palette, spacing}, isSelected}) => css`
        cursor: pointer;
        width: ${NODE_WIDTH}px;
        height: ${NODE_HEIGHT}px;
        background-color: ${palette.neutral.white};
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        padding: ${spacing * 4}px ${spacing * 5}px;
        align-items: start;
        justify-content: center;
        position: relative;
        border: 1px solid ${isSelected ? palette.primary[600] : palette.neutral[300]};
        border-radius: 9px;

        .delete-btn-container {
            position: absolute;
            top: ${spacing}px;
            right: ${spacing}px;

            .thrash-icon-container {
                color: ${palette.danger[700]} !important;
            }
        }

        .subtitle-container {
            display: flex;
            align-items: center;
            gap: ${spacing * 2}px;
        }
    `}
`
