import {Pagination} from '@/types/commons'
import {Company, CompanyAssignmentMode, TeamLead, VendorManager} from '../companies/types'
import {z} from 'zod'

export type BatchStatus = 'in_progress' | 'paused' | 'inactive' | 'completed'
export type BatchAssignmentMethod = CompanyAssignmentMode | 'default'

export type BatchRecord = {
    completed_tasks: number
    estimated_time: number
    id: number
    name: string
    need_date: string | null
    possible_date: string | null
    priority: number | null
    project_id: number
    status: BatchStatus
    subproject_id: number | null
    total_tasks: number
    used_time: number
    vendor_amount?: number
    vendor_managers_amount?: number
    assignment_mode: BatchAssignmentMethod
    project_name?: string
    vendor_managers: (VendorManager & {company: Pick<Company, 'id' | 'name' | 'assignment_mode'>})[]
    team_leads: TeamLead[]
    batch_id: number
}

export interface GetBatchesResponse {
    data?: BatchRecord[]
    pagination: Pagination
}

export type MediaCount = {
    socketIORoomId: string
    cached: boolean
    totalAmount: string
}

export type MediaIncrementEventBody = {
    amount: number
    currentAmount: number
    status: 'pending' | 'failed' | 'completed'
    type?: string
    message?: string
    totalAmount: number
}

export const ProjectBatchesSearchParams = z.object({
    orderBy: z.enum(['name', 'priority', 'need_date', 'possible_date']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type ProjectBatchesSearchParams = z.infer<typeof ProjectBatchesSearchParams>
