import {palette as mainPalette} from './palette.ts'
import mediaQueries from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {css} from 'styled-components'
import {typography} from './typography.ts'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light'
    //todo: we will support the dark mode decommenting this one and adding the dark types to our style.d.ts
    /*name: 'light' | 'dark'*/
    direction?: 'ltr' | 'rtl'
}

export const zIndex = {
    sidebarOverlay: 10,
    sidebar: 11,
    modalOverlay: 12,
    modal: 13,
    tooltip: 14,
    popover: 15
} as const

const getAppTheme = ({name = 'light'}: ThemeInterface) => {
    const palette = mainPalette[name]

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `

    const link = css`
        ${typography.textSm};
        cursor: pointer;
        text-decoration: none;
        color: ${palette?.primary?.['700']};
        font-weight: 600;
        &:hover {
            color: ${palette?.neutral?.['900']};
            text-decoration: none;
        }

        &:focus {
            color: ${palette?.neutral?.['900']};
        }

        &:visited {
            color: ${palette?.neutral?.['500']};
        }

        &:active {
            color: ${palette?.primary?.['700']};
        }

        &:link {
            color: ${palette?.neutral?.['600']};
        }
    `

    return {
        spacing,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows,
        truncateText,
        link
    }
}

export {GlobalStyles, getAppTheme}
