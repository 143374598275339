import {usePerformanceStatsSearchParams} from '@/features/analytics/hooks/usePerformanceSearchParams'
import {FC} from 'react'
import {EfficiencyCharts} from './efficiency-charts'
import {QualityCharts} from './quality-charts'
import {TroughputCharts} from './throughput-charts'
import {ChartProps} from './types'

export const Charts: FC<ChartProps> = props => {
    const {
        searchParams: {metric}
    } = usePerformanceStatsSearchParams()

    let charts
    if (metric === 'efficiency') {
        charts = <EfficiencyCharts {...props} />
    } else if (metric === 'quality') {
        charts = <QualityCharts {...props} />
    } else {
        charts = <TroughputCharts {...props} />
    }

    return charts
}
