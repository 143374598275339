import {Trans, useTranslation} from 'react-i18next'
import {StyledNodeSidebarForm} from './style'
import Divider from '@/components/ui/Divider'
import {Controller, useForm} from 'react-hook-form'
import Label from '@/components/ui/Label'
import InputText from '@/components/commons/InputText'
import {ArrowDownIcon, HelpCircleIcon} from '@/components/ui/Icon'
import {FC, Fragment, useId} from 'react'
import {GraphNode} from '../../types'
import Button from '@/components/ui/Button'
import {ProjectRecord, ProjectStructure} from '@/features/projects/types'
import {useCreateProcessStep} from '@/features/process/queries/useCreateProcessStep'
import {toast} from 'react-hot-toast'
import {useGraphContext} from '../../context/graphContext'
import {useRemoveNode} from '../../helpers/useRemoveNode'
import {ProcessRecord} from '@/features/process/services/process.http'
import {useEditProcess} from '@/features/process/queries/useEditProcess'
import {Tooltip} from '@/components/ui/Tooltip'
import {useCenterViewPortOnNode} from '../../helpers/useCenterViewPortOnNode'
import {Toggle} from '@/components/ui/Toggle'
import {generateField} from '@/features/projects/form/fieldgenerator'
import {DynamicErrors, setErrorsFromResponse} from '@/features/projects/form/dynamicErrors'
import {AxiosError} from 'axios'

const processTypeIdMap = {
    '1': 'Image Box',
    '2': 'Image Segmentation'
} as const

type NodeSidebarFormProps = {
    onClose: () => void
    removeTempNode: () => void
    selectedNode: Extract<GraphNode, {type: 'processNode' | 'tempProcessNode'}>
    project: ProjectRecord
    process: ProcessRecord | undefined
    projectStructure: ProjectStructure
}

const getFormValuesFromProcess = (process: ProcessRecord) => {
    return {
        ...process.template_values,
        override_options: !!process.override_options,
        confidence_interval: process.template_values.workflow.confidence_interval || 5,
        confidence_rate: process.template_values.workflow.confidence_rate || 95,
        qa: process.template_values.workflow.task_types_id.length > 1,
        estimated_time_edit: process.template_values?.workflow.estimated_time_edit,
        estimated_time_qa: process.template_values?.workflow.estimated_time_qa
    }
}

const getFormValuesFromProject = (project: ProjectRecord) => {
    return {
        ...project.template_values,
        override_options: false,
        confidence_interval: 5,
        confidence_rate: 95,
        estimated_time_edit: 5,
        estimated_time_qa: 5,
        qa: false
    }
}

const getDefaultValues = (project: ProjectRecord, process: ProcessRecord | undefined) => {
    if (process) {
        return getFormValuesFromProcess(process)
    }

    return getFormValuesFromProject(project)
}

export const NodeSidebarForm: FC<NodeSidebarFormProps> = ({
    onClose,
    removeTempNode,
    selectedNode,
    project,
    projectStructure,
    process
}) => {
    const {t} = useTranslation()
    const {centerViewPortOnParent} = useCenterViewPortOnNode()

    const {setNodes, setEdges} = useGraphContext()
    const {removeNode} = useRemoveNode()
    const checkboxId = useId()

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
        setError
    } = useForm({
        defaultValues: getDefaultValues(project, process),
        mode: 'onChange'
    })

    const watchedQa = watch('qa')
    const watchedOverrideOptions = watch('override_options')

    const {mutate: editProcess, isLoading: isLoadingEditProcess} = useEditProcess({
        onSuccess: data => {
            toast.success(t('commons:genericSuccess'))
            setNodes(prev =>
                prev.map(node => {
                    if (node.type === 'processNode' && node.id === selectedNode.id) {
                        return {
                            ...node,
                            data: {...node.data, is_qa: data.template_values.workflow.task_types_id.length > 1}
                        }
                    }

                    return node
                })
            )
            onClose()
        },
        onError: e => {
            setErrorsFromResponse(e as AxiosError<DynamicErrors>, setError, t)
        }
    })

    const {mutate: createProcessStep, isLoading: isLoadingCreateProcessStep} = useCreateProcessStep({
        onSuccess: data => {
            toast.success(t('commons:genericSuccess'))
            setNodes(prev =>
                prev.map(node => {
                    if (node.type === 'tempProcessNode' && node.id === selectedNode.id) {
                        return {
                            ...node,
                            type: 'processNode',
                            id: data.id.toString(),
                            data: {...node.data, is_qa: data.template_values.workflow.task_types_id.length > 1}
                        }
                    }

                    return node
                })
            )
            setEdges(prev =>
                prev.map(edge => {
                    if (edge.source === 'new-node') {
                        return {...edge, source: data.id.toString(), id: `${data.id}-${edge.target}`}
                    }

                    if (edge.target === 'new-node') {
                        return {...edge, target: data.id.toString(), id: `${edge.source}-${data.id}`}
                    }

                    return edge
                })
            )
            onClose()
        },
        onError: e => {
            setErrorsFromResponse(e as AxiosError<DynamicErrors>, setError, t)
        }
    })

    const isLoading = isLoadingCreateProcessStep || isLoadingEditProcess

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = (values: any) => {
        const {
            override_options,
            qa,
            confidence_rate,
            confidence_interval,
            estimated_time_edit,
            estimated_time_qa,
            ...templateValues
        } = values
        const newProcessData = {
            override_options: !!override_options,
            projectId: project.id,
            process_type_id: selectedNode.data.process_type_id,
            template_values: {
                ...templateValues,
                workflow: {
                    task_types_id: qa ? [1, 2] : [1],
                    confidence_rate: parseFloat(confidence_rate),
                    confidence_interval: parseFloat(confidence_interval),
                    estimated_time_edit: parseFloat(estimated_time_edit),
                    estimated_time_qa: parseFloat(estimated_time_qa)
                }
            }
        }

        if (process) {
            editProcess({
                ...newProcessData,
                processId: process.id
            })
            return
        }
        createProcessStep(newProcessData)
    }

    const onDelete = () => {
        removeNode({nodeId: selectedNode.id})
        centerViewPortOnParent({nodeId: selectedNode.id})
        onClose()
    }

    const onOverrideToggle = () => {
        if (!process) {
            reset({...getFormValuesFromProject(project), override_options: !watchedOverrideOptions})
            return
        }

        if (watchedOverrideOptions) {
            reset({...getFormValuesFromProject(project), override_options: !watchedOverrideOptions})
        } else {
            reset({...getFormValuesFromProcess(process), override_options: !watchedOverrideOptions})
        }
    }

    return (
        <StyledNodeSidebarForm
            onSubmit={e => {
                e.stopPropagation()
                handleSubmit(onSubmit)(e)
            }}
        >
            <div className="header">
                <h2>
                    {t('commons:edit')}{' '}
                    {
                        processTypeIdMap[
                            (selectedNode.data?.process_type_id.toString() as keyof typeof processTypeIdMap) || '1'
                        ]
                    }
                </h2>
            </div>

            <div className="main-content">
                <section>
                    <div className="info">
                        <h2>{t('commons:info')}</h2>
                        <p>{t('projectDetails:process:processSidebarHelper')}</p>

                        <div className="override-config-container">
                            <Controller
                                control={control}
                                name="override_options"
                                render={({field: {onChange, value}}) => (
                                    <Toggle onClick={() => onOverrideToggle()} value={value} onChange={onChange} />
                                )}
                            />

                            <Label htmlFor={checkboxId}>{t('projectDetails:process:override_config')}</Label>
                        </div>
                    </div>

                    {Object.entries(projectStructure).map(([k, v]) => {
                        return (
                            <Fragment key={k}>
                                {generateField({
                                    fieldName: k,
                                    fieldData: v,
                                    control,
                                    register,
                                    disabled: !watchedOverrideOptions,
                                    dataServiceConfiguratorProps: {alwaysCreate: true},
                                    t,
                                    errors
                                })}
                            </Fragment>
                        )
                    })}
                </section>
                <Divider />
                <section>
                    <h2>{t('projectDetails:process:workflow')}</h2>

                    <div className="workflow-container">
                        <h3 className={'edit-label'}>{t('commons:edit')}</h3>
                        <div className="estimated-time-container">
                            <InputText
                                type={'text'}
                                label={t('projectDetails:process:estimatedTime')}
                                placeholder={t('commons:optional') satisfies string}
                                {...register('estimated_time_edit')}
                            />
                        </div>
                    </div>
                    <div className="arrow-container">
                        <ArrowDownIcon size={24} />
                    </div>
                    <div className="qa-container">
                        <div className="qa-container-header">
                            <h3>{t('projectDetails:process:qa')}</h3>
                            <Controller
                                control={control}
                                name="qa"
                                render={({field: {onChange, value}}) => (
                                    <Toggle
                                        onClick={() => {
                                            if (watchedQa) {
                                                setValue('estimated_time_qa', null)
                                            }
                                        }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>

                        {watchedQa && (
                            <div className="qa-container-footer">
                                <InputText
                                    type={'text'}
                                    label={t('projectDetails:process:estimatedTime')}
                                    placeholder={t('commons:optional') satisfies string}
                                    {...register('estimated_time_qa')}
                                />
                            </div>
                        )}
                    </div>

                    {watchedQa && (
                        <Fragment>
                            {/*<h2>{t('commons:quality')}</h2>*/}

                            <div className="sample-info">
                                <h3>{t('projectDetails:process:sampleSize')}</h3>
                                <p>{t('projectDetails:process:sampleInfo')}</p>
                            </div>

                            <div className="confidence-container">
                                <InputText
                                    type={'text'}
                                    typeIcon={'%'}
                                    label={
                                        <div className="label-with-info">
                                            <span>{t('projectDetails:process:confidenceLevel')}</span>
                                            <Tooltip
                                                triggerProps={{type: 'button'}}
                                                trigger={<HelpCircleIcon size={18} />}
                                                content={
                                                    <Trans
                                                        i18nKey="projectDetails:process:confidence_level_helper"
                                                        components={[<br />]}
                                                    />
                                                }
                                                // content={t('projectDetails:process:confidence_level_helper')}
                                            />
                                        </div>
                                    }
                                    {...register('confidence_rate')}
                                />
                                <InputText
                                    type={'text'}
                                    typeIcon={'%'}
                                    label={
                                        <div className="label-with-info">
                                            <span>{t('projectDetails:process:confidenceInterval')}</span>
                                            <Tooltip
                                                triggerProps={{type: 'button'}}
                                                trigger={<HelpCircleIcon size={18} />}
                                                content={
                                                    <Trans
                                                        i18nKey="projectDetails:process:confidence_interval_helper"
                                                        components={[<br />]}
                                                    />
                                                }
                                            />
                                        </div>
                                    }
                                    {...register('confidence_interval')}
                                />
                            </div>
                        </Fragment>
                    )}
                </section>
            </div>
            <div className="footer">
                {selectedNode.type !== 'tempProcessNode' ? (
                    <Button variant="ghostDanger" onClick={onDelete}>
                        {t('commons:delete')}
                    </Button>
                ) : (
                    <div />
                )}

                <div className="footer-right">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            onClose()
                            removeTempNode()
                        }}
                    >
                        {t('commons:cancel')}
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                        {t('commons:save')}
                    </Button>
                </div>
            </div>
        </StyledNodeSidebarForm>
    )
}
