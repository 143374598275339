import styled, {css, DefaultTheme} from 'styled-components'
import {Dropzone} from '@components/ui/Dropzone'

export const StyledManageUsersDropzone = styled('div')(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
    `
)

interface StyledDropzoneProps {
    isActive: boolean
    theme: DefaultTheme
}
export const StyledDropzone = styled(Dropzone)(
    ({isActive, theme: {spacing, palette, typography}}: StyledDropzoneProps) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 3}px;
        border: 1px solid ${isActive ? palette.neutral['400'] : palette.neutral['200']};
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-radius: 12px;
        cursor: pointer;
        text-align: center;
        transition: ease-in-out 200ms;

        p {
            ${typography.textSm}
            span {
                font-weight: 600;
            }
        }
        small {
            ${typography.textXs}
        }
        span {
            color: ${palette.primary['700']};
        }

        &:hover {
            border: 1px solid ${palette.neutral['400']};
        }
    `
)

export const StyledAcceptedFileName = styled('p')(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['900']};
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `
)

export const StyledError = styled('p')(
    ({theme: {spacing, palette, typography}}) => css`
        display: flex;
        gap: ${spacing}px;
        color: ${palette.danger['700']};
        ${typography.textSm}
    `
)
