import {envVars} from '@/envVars.ts'
import {io} from 'socket.io-client'

export const projectSocket = io(`${envVars.VITE_API_ENDPOINT}projects`, {
    autoConnect: false,
    transports: ['websocket']
})

export const projectBatchesSocketIoEvents = {
    JOIN_ROOM: 'joinRoom',
    LEAVE_ROOM: 'leaveRoom',
    TASK_INCREMENT: 'taskProcessedIncrement',
    MEDIA_INCREMENT: 'mediaProcessedIncrement',
    MEDIA_COUNT: 'projectMediaCount',
    SYNC_STATUS: 'projectSyncStatus',
    KEEP_CONNECTION: 'keepConnection'
} as const

export type IncrementEventBody = {
    amount: number
}

export type SyncStatusEventBody = {
    status: string
}
