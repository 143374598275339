import ReactSelect, {ActionMeta, GroupBase, MultiValue, SingleValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customStyles, StyledContainer} from './style.ts'
import {useTheme} from 'styled-components'
import Label from '@components/ui/Label'
import {forwardRef, ReactNode, RefCallback, useCallback} from 'react'
import InputHelpText from '@components/ui/InputHelpText'
import {customComponents} from '@components/commons/Select/commons.tsx'
import {DefaultTFuncReturn} from 'i18next'
import {Percentage} from '@/types/commons.ts'
import Select from 'react-select/base'

export type SelectValue = {
    label: string
    value: string
}

export interface SelectProps {
    className?: string
    name?: string
    options: SelectValue[]
    label?: string | DefaultTFuncReturn
    placeholder?: string | DefaultTFuncReturn
    helpText?: string | DefaultTFuncReturn
    readOnly?: boolean
    defaultValue?: SelectValue | SelectValue[] | [] | null
    isSearchable?: boolean
    isClearable?: boolean
    isCreatable?: boolean
    formatCreateLabel?: (value: string) => ReactNode
    addOptionMessage?: string
    maxItems?: number
    isMulti?: boolean
    closeMenuOnSelect?: boolean
    disabled?: boolean
    size?: 'medium' | 'large'
    /**
     * How to use the onChange based on controlled or uncontrolled select:
     *
     * uncontrolled: onChange={(event) => console.log(event)}
     * controlled multi: onChange={(newValue) => {onChange(newValue as SelectValue[])}}
     * controlled single: onChange={(newValue) => {onChange([newValue] as SelectValue[])}}
     */
    onChange?: (
        newValue: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue[],
        actionMeta: ActionMeta<SelectValue>
    ) => void
    value?: SelectValue | SelectValue[] | null
    ref?: RefCallback<unknown>
    errorMessage?: string | DefaultTFuncReturn
    width?: number | Percentage
    inputValue?: string
    onInputChange?: (newVal: string) => void
}

const InputSelect = forwardRef<Select<SelectValue, boolean, GroupBase<SelectValue>> | null, SelectProps>(
    (
        {
            className,
            name,
            options = [],
            label,
            placeholder,
            helpText,
            defaultValue,
            isSearchable = false,
            isCreatable = false,
            addOptionMessage = '',
            maxItems = 100,
            isMulti = false,
            closeMenuOnSelect = !isMulti,
            disabled,
            onChange,
            errorMessage,
            width,
            ...rest
        },
        ref
    ) => {
        const theme = useTheme()

        // Label for new item creation
        const createLabel = useCallback(
            (value: string) => (
                <span style={{fontSize: 14}}>
                    {addOptionMessage}
                    <span>{value}</span>
                </span>
            ),
            [addOptionMessage]
        )

        const selectProps = {
            options,
            name,
            closeMenuOnSelect,
            isSearchable,
            isCreatable,
            isMulti,
            isDisabled: rest.readOnly || disabled,
            classNamePrefix: isCreatable ? 'creatable_select' : 'select',
            placeholder,
            createLabel,
            disabled,
            maxItems,
            defaultValue,
            onChange,
            ref,
            menuPlacement: 'auto' as const,
            ...rest
        }

        const selectComponentProps = {
            formatCreateLabel: createLabel,
            components: customComponents,
            ...selectProps,
            styles: customStyles({theme, isError: !!errorMessage})
        }

        return (
            <StyledContainer className={className} width={width}>
                {label && <Label htmlFor={name}>{label}</Label>}
                {isCreatable ? (
                    <CreatableSelect {...selectComponentProps} />
                ) : (
                    <ReactSelect {...selectComponentProps} />
                )}
                <InputHelpText helpText={helpText} error={errorMessage} />
            </StyledContainer>
        )
    }
)

export default InputSelect

InputSelect.displayName = 'InputSelect'
