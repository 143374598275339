import {forwardRef, PropsWithChildren, ReactNode} from 'react'
import * as RTooltip from '@radix-ui/react-tooltip'
import {StyledTooltipContent} from '@components/ui/Tooltip/style.ts'

interface TooltipProps extends PropsWithChildren {
    rootProps?: RTooltip.TooltipContentProps
    triggerProps?: RTooltip.TooltipTriggerProps
    portalProps?: RTooltip.TooltipPortalProps
    contentProps?: RTooltip.TooltipContentProps
    variant?: 'light' | 'dark'
    side?: 'top' | 'right' | 'bottom' | 'left'
    delayDuration?: number
    trigger: ReactNode
    content: ReactNode
}

/**
 * sample usage
 *
<Tooltip
    trigger={
        <Button variant="ghost" iconOnly onClick={() => console.log('clone')}>
            <Copy03Icon fill={'transparent'} />
        </Button>
    }
    content={t('projects:table:duplicate_cta_tooltip')}
/>
*
*/
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
    (
        {
            trigger,
            rootProps,
            triggerProps,
            portalProps,
            contentProps,
            side,
            content,
            variant = 'dark',
            delayDuration = 350
        }, //TODO add a max-width props
        ref
    ) => {
        return (
            <RTooltip.Provider delayDuration={delayDuration}>
                <RTooltip.Root {...rootProps}>
                    <RTooltip.Trigger {...triggerProps}>{trigger}</RTooltip.Trigger>
                    <RTooltip.Portal {...portalProps}>
                        <StyledTooltipContent {...contentProps} variant={variant} side={side} ref={ref}>
                            {content}
                            <RTooltip.Arrow width={16} height={6} />
                        </StyledTooltipContent>
                    </RTooltip.Portal>
                </RTooltip.Root>
            </RTooltip.Provider>
        )
    }
)

Tooltip.displayName = 'Tooltip'
