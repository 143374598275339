import {FC} from 'react'
import {StyledProgressIndicator, StyledProgressRoot} from './style'

type ProgressProps = {
    progress: number
}

export const Progress: FC<ProgressProps> = ({progress}) => {
    return (
        <StyledProgressRoot value={progress}>
            <StyledProgressIndicator progress={progress} />
        </StyledProgressRoot>
    )
}
