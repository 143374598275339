import {z} from 'zod'

export type FormValues = {
    batchType: 'one' | 'tasks' | 'percentage' | null
    tasks?: number
    percentages: {value: number}[]
}

const batchEnum = z.enum(['one', 'tasks', 'percentage'])

const oneBatchSchema = z.object({
    batchType: z.literal(batchEnum.enum.one)
})

const taskNumberBatchSchema = z.object({
    batchType: z.literal(batchEnum.enum.tasks),
    tasks: z.coerce
        .number({
            required_error: 'errors:number',
            invalid_type_error: 'errors:number'
        })
        .int('errors:number')
        .positive('errors:positiveNumber')
})

const percentagesBatchSchema = z.object({
    batchType: z.literal(batchEnum.enum.percentage),
    percentages: z
        .array(
            z.object({
                value: z.coerce
                    .number({
                        required_error: 'errors:number',
                        invalid_type_error: 'errors:number'
                    })
                    .int('errors:number')
                    .positive('errors:positiveNumber')
            })
        )
        .refine(percentages => percentages.reduce((prev, curr) => prev + curr.value, 0) === 100, 'errors:sum_to_100')
})

export const validationSchema = z.discriminatedUnion('batchType', [
    oneBatchSchema,
    taskNumberBatchSchema,
    percentagesBatchSchema
])
