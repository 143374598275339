import {BatchRecord, GetBatchesResponse, MediaCount} from '@/features/batches/types.ts'
import axios from '@/services/axiosInstance'

export type BatchesIndexParams = {
    currentPage: number
    perPage?: number
    orderBy?: string
    orderDirection?: string
    search?: string
    status?: string[]
}

type httpGetProjectBatchesParams = {
    projectId: number
    params?: BatchesIndexParams
}
export const httpGetProjectBatches = ({
    projectId,
    params
}: httpGetProjectBatchesParams): Promise<GetBatchesResponse> => {
    return axios
        .get(`projects/${projectId}/batches`, {
            params: {
                perPage: 50,
                ...params
            }
        })
        .then(res => res.data)
}

type httpGetSubProjectBatchesParams = {
    subProjectId: number
    params?: BatchesIndexParams
}
export const httpGetSubProjectBatches = ({
    subProjectId,
    params
}: httpGetSubProjectBatchesParams): Promise<GetBatchesResponse> => {
    return axios
        .get(`subprojects/${subProjectId}/subbatches`, {
            params: {
                perPage: 50,
                ...params
            }
        })
        .then(res => res.data)
}

type httpGetProjectBatchParams = {
    projectId: number
    batchId: number
}
export const httpGetProjectBatch = ({projectId, batchId}: httpGetProjectBatchParams): Promise<BatchRecord> => {
    return axios.get(`projects/${projectId}/batches/${batchId}`).then(res => res.data)
}

type httpEditProjectBatchParams = {
    projectId: number
    batchId: number
    payload: {
        name: string
        need_date: string | null
        possible_date: string | null
        priority: number | null
    }
}
export const httpEditProjectBatch = ({
    projectId,
    batchId,
    payload
}: httpEditProjectBatchParams): Promise<BatchRecord> => {
    return axios.patch(`projects/${projectId}/batches/${batchId}`, payload).then(res => res.data)
}

export const httpGetProjectMediaCount = (projectId: number): Promise<MediaCount> => {
    return axios.get(`projects/${projectId}/media-count-v2`).then(res => res.data)
}

export type SyncBatchesPayload =
    | {
          batch_mode: 'one'
      }
    | {
          batch_mode: 'fixed'
          batch_tasks_amount: number
      }
    | {
          batch_mode: 'percentage'
          batch_percentages: number[]
      }
export const httpSyncBatches = (projectId: number, payload: SyncBatchesPayload) => {
    return axios.post(`projects/${projectId}/batches/sync-v2`, payload).then(res => res.data)
}

type httpGetProductionBatchesProps = {
    params?: BatchesIndexParams
}
export const httpGetProductionBatches = ({params}: httpGetProductionBatchesProps) => {
    return axios.get('/batches', {params}).then(res => res.data as GetBatchesResponse)
}

export const httpGetProductionSubBatches = ({params}: httpGetProductionBatchesProps) => {
    return axios.get('/subbatches', {params}).then(res => res.data as GetBatchesResponse)
}

type httpGetProjectBatchesStatusResponse =
    | {
          totalMediaAmount: number
          processedMedia: number
          totalTasksAmount: number
          processedTask: number
      }
    | Record<string, never>

export const httpGetProjectBatchesStatus = (projectId: number) => {
    return axios
        .get(`/projects/${projectId}/batches/sync/progress-status`)
        .then(res => res.data as httpGetProjectBatchesStatusResponse)
}

type httActivateBatchPayload = {
    batchId: number
}

export const httActivateBatch = ({batchId}: httActivateBatchPayload) => {
    return axios.post(`batches/${batchId}/activate`).then(res => res.data)
}

type httDeactivateBatchPayload = {
    batchId: number
}

export const httDeactivateBatch = ({batchId}: httDeactivateBatchPayload) => {
    return axios.post(`batches/${batchId}/deactivate`).then(res => res.data)
}

type httBulkDeactivateBatchesPayload = {
    body: {ids: number[]}
}

export const httBulkDeactivateBatches = ({body}: httBulkDeactivateBatchesPayload) => {
    return axios.post(`batches/deactivate/bulk`, body).then(res => res.data)
}

type httpManualAssignBatchPayload = {
    batchId: number
    vendor_manager_id: number
}

export const httpManualAssignBatch = ({batchId, ...data}: httpManualAssignBatchPayload) => {
    return axios.post(`/batches/${batchId}/manual-assign`, data).then(res => res.data)
}

type httpManualBulkAssignBatchPayload = {
    projectId: number
    assignments: {
        batch_id: number
        vendor_manager_id: number
    }[]
}

export const httpManualBulkAssignBatches = ({projectId, assignments}: httpManualBulkAssignBatchPayload) => {
    return axios.post(`/projects/${projectId}/batches/assign`, assignments).then(res => res.data)
}

export type httpAssignSubBatchPayload = {
    subBatchId: number
    assignments: {
        user_id: number
        percentage: number
    }[]
}

export const httpAssignSubBatch = ({subBatchId, assignments}: httpAssignSubBatchPayload) => {
    return axios.post(`/subbatches/${subBatchId}/assign`, assignments).then(res => res.data)
}

export type httpUnassignBatchPayload = {
    batchId: number
}

export const httpUnssignbBatch = ({batchId}: httpUnassignBatchPayload) => {
    return axios.post(`/batches/${batchId}/unassign`).then(res => res.data)
}

export type httpBulkUnassignBatchesPayload = {
    ids: number[]
}

export const httpBulkUnssignbBatches = (data: httpBulkUnassignBatchesPayload) => {
    return axios.post(`/batches/bulk-unassign`, data).then(res => res.data)
}

/**
 * Get batch and sub-batch details
 */
type httpGetBatchDetailsParams = {
    id: string
}
export const httpGetBatchDetails = ({id}: httpGetBatchDetailsParams): Promise<BatchRecord> => {
    return axios.get(`batches/${id}`).then(res => res.data)
}
export const httpGetSubBatchDetails = ({id}: httpGetBatchDetailsParams): Promise<BatchRecord> => {
    return axios.get(`subbatches/${id}`).then(res => res.data)
}
