import {Company} from '@/features/companies/types'
import {FC} from 'react'
import {StyledVendorManagerItem, VendorListItemWrapper} from './style'
import {Flexbox} from '@/components/ui/Flexbox'
import {CheckIcon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'

type VendorListItemProps = {
    vendor: Pick<Company, 'id' | 'name' | 'vendor_managers' | 'workload'>
    selectable?: boolean
    value?: number | null
    onChange?: (vendorManager: number | null) => void
}

export const VendorListItem: FC<VendorListItemProps> = ({vendor, selectable, value, onChange}) => {
    const {t} = useTranslation()
    return (
        <VendorListItemWrapper direction="column" gap={4} width={'100%'}>
            <Flexbox align="center" justify="space-between" width={'100%'}>
                <p className="vendor-name">{vendor.name}</p>
                <p className="workload">
                    {t('batches:workload', {
                        used_units: vendor.workload.assigned_tasks,
                        percentage_used: vendor.workload.percent_assigned_tasks
                    })}
                </p>
            </Flexbox>
            <Flexbox direction="column" width={'100%'}>
                {vendor.vendor_managers?.map(vendorManager => (
                    <StyledVendorManagerItem
                        key={vendorManager.id}
                        width={'100%'}
                        gap={2}
                        direction="row"
                        justify="space-between"
                        align="center"
                        isSelectable={selectable}
                        isSelected={value === vendorManager.id}
                        onClick={() => {
                            if (!selectable) {
                                return
                            }
                            onChange?.(vendorManager.id)
                        }}
                    >
                        <Flexbox direction="column" gap={2}>
                            <p className="vendor-manager-name">
                                {vendorManager.first_name} {vendorManager.last_name}
                            </p>
                            <p className="vendor-manager-email">{vendorManager.email}</p>
                        </Flexbox>
                        {value === vendorManager.id && (
                            <div className="check-icon-container">
                                <CheckIcon />
                            </div>
                        )}
                    </StyledVendorManagerItem>
                ))}
            </Flexbox>
        </VendorListItemWrapper>
    )
}

type VendorListProps = {
    vendors: Pick<Company, 'id' | 'name' | 'vendor_managers' | 'workload'>[]
    selectable?: boolean
    value?: number | null
    onChange?: (vendorManager: number | null) => void
}

export const VendorList: FC<VendorListProps> = ({vendors, selectable, value, onChange}) => {
    return (
        <Flexbox width={'100%'} direction="column" gap={10}>
            {vendors.map(vendor => (
                <VendorListItem
                    key={vendor.id}
                    vendor={vendor}
                    selectable={selectable}
                    value={value}
                    onChange={onChange}
                />
            ))}
        </Flexbox>
    )
}
