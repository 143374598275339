import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/IconContainer'
import {SearchLgIcon} from '@components/ui/Icon'
import Button from '@components/ui/Button'
import {StyledContainer, StyledNotFoundWrapper} from '@pages/not-found/style.ts'
import {roleBasedRedirect} from '@/features/roles/helpers.tsx'
import {useAuth} from '@context/AuthProvider.tsx'
import {useNavigate} from 'react-router-dom'

export const NotFound = () => {
    const {accessToken, user} = useAuth()
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <StyledContainer fullHeight={true}>
            <StyledNotFoundWrapper>
                <IconContainer>
                    <SearchLgIcon />
                </IconContainer>
                <h1>{t('notFound:title')}</h1>
                <p>{t('notFound:subtitle')}</p>
                <Button
                    variant={'primary'}
                    size={'lg'}
                    onClick={() => navigate(roleBasedRedirect(accessToken, user?.role.slug))}
                >
                    {t('notFound:cta')}
                </Button>
            </StyledNotFoundWrapper>
        </StyledContainer>
    )
}

NotFound.displayName = 'NotFound'
