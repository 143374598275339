import styled, {css, DefaultTheme} from 'styled-components'

interface StyledIconContainerProps {
    size: 'md' | 'lg'
    theme: DefaultTheme
}
export const StyledIconContainer = styled('div')(
    ({size, theme: {palette}}: StyledIconContainerProps) => css`
        width: ${size == 'lg' ? '48px' : '40px'};
        height: ${size == 'lg' ? '48px' : '40px'};
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${palette.neutral['200']};

        svg {
            width: ${size == 'lg' ? '24px' : '20px'};
        }
    `
)
