import Container from '@components/ui/Container'
import {HeaderWrapper, HeaderGrid, MenuItems, DropdownUserInfosItems} from '@components/commons/Header/style.ts'
import Avatar from '@components/ui/Avatar'
import LogoDark from '@assets/images/logo_dark.svg'
import {NavLink} from '@components/ui/NavLink'
import {Link, useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {LogoutIcon, Settings01Icon} from '@components/ui/Icon'
import {useAuth} from '@context/AuthProvider.tsx'
import {useTranslation} from 'react-i18next'
import DropdownMenu from '@components/ui/DropdownMenu'
import {roleSlugToLabel, rolesWithSettings} from '@/features/roles/helpers.tsx'
import {Slug} from '@/types/auth.ts'

type MenuItem = {
    disabled?: boolean
    name: string
    path: string
}

interface HeaderProps {
    items: MenuItem[]
}

export const Header = ({items}: HeaderProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {logout, user} = useAuth()
    const canSeeSettings = rolesWithSettings.includes(user?.role.slug as Slug)

    return (
        <HeaderWrapper>
            <Container>
                <HeaderGrid>
                    <Link to={routes.HOME.path}>
                        <img src={LogoDark} alt={'logo'} />
                    </Link>
                    <MenuItems>
                        {items.map(({name, path, disabled}) => (
                            <NavLink disabled={disabled} path={path} name={t(name)} key={name} end={false} />
                        ))}
                    </MenuItems>
                    <DropdownMenu
                        sideOffset={8}
                        trigger={<Avatar name={user?.first_name || '-'} imageUrl={''} />}
                        actions={[
                            {
                                component: (
                                    <DropdownUserInfosItems>
                                        <span>
                                            {user?.first_name} {user?.last_name}
                                        </span>
                                        <small>{user?.email}</small>
                                        {user && <p>{t(roleSlugToLabel[user.role.slug], {count: 1})}</p>}
                                    </DropdownUserInfosItems>
                                ),
                                withSeparator: true
                            },
                            ...(canSeeSettings
                                ? [
                                      {
                                          component: t('commons:settings'),
                                          icon: <Settings01Icon size={16} />,
                                          onClickCb: () => navigate(routes.SETTINGS.path)
                                      }
                                  ]
                                : []),
                            {
                                component: t('commons:logout'),
                                icon: <LogoutIcon size={16} />,
                                onClickCb: logout
                            }
                        ]}
                    />
                </HeaderGrid>
            </Container>
        </HeaderWrapper>
    )
}

Header.displayName = 'Header'
