import {AvatarFallback, AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot} from '@components/ui/Avatar/style.ts'
import {getInitials} from '@utilities/helpers.ts'
import {useMemo} from 'react'

interface AvatarProps {
    name: string
    imageUrl: string
}

const Avatar = ({name, imageUrl}: AvatarProps) => {
    const renderAbbreviation = useMemo(() => getInitials(name), [name])

    return (
        <AvatarRoot>
            <AvatarImage src={imageUrl} alt={name} />
            <AvatarFallback delayMs={600}>{renderAbbreviation}</AvatarFallback>
        </AvatarRoot>
    )
}

export default Avatar

Avatar.displayName = 'Avatar'
