import {StyledTaxonomiesTableEmptyState} from '@/features/taxonomies/components/TaxonomiesTableEmptyState/style.ts'
import {IconContainer} from '@components/ui/IconContainer'
import {FolderIcon, PlusIcon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import Button from '@components/ui/Button'
import toast from 'react-hot-toast'

export const TaxonomiesTableEmptyState = () => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <>
            <StyledTaxonomiesTableEmptyState>
                <IconContainer>
                    <FolderIcon fill={theme.palette.neutral['700']} />
                </IconContainer>
                <hgroup>
                    <h3>{t('taxonomies:table:empty_state_title')}</h3>
                    <h4>{t('taxonomies:table:empty_state_subtitle')}</h4>
                </hgroup>
                <Button onClick={() => toast.success(t('projects:new_success'))}>
                    <PlusIcon />
                    {t('taxonomies:new_taxonomy')}
                </Button>
            </StyledTaxonomiesTableEmptyState>
        </>
    )
}

TaxonomiesTableEmptyState.displayName = 'TaxonomiesTableEmptyState'
