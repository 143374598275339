import {useTranslation} from 'react-i18next'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useEffect, useMemo} from 'react'
import {projectDetailsRoleBasedRedirect, ProjectTab, projectTabsRoleBased} from '@/features/roles/helpers.tsx'
import {useAuth} from '@context/AuthProvider.tsx'
import {ProjectDetailsHeader} from '@/features/projects/components/project-details-header/ProjectDetailsHeader.tsx'
import {useProject} from '@/features/projects/queries/useProject'
import {routes} from '@/utilities/constants'
import {useProcesses} from '@/features/process/queries/useProcesses.ts'
import {StyledContainer, StyledNavLink, TabsWrapper} from '@pages/project-details/style.ts'
import {ProjectRecord, ProjectStructure} from '@/features/projects/types'
import {ProcessRecord} from '@/features/process/services/process.http'
import {useProjectStructure} from '@/features/projects/queries/useProjectStructure'

const isTabDisabled = (
    tab: ProjectTab,
    project: ProjectRecord | undefined,
    projectStructure: ProjectStructure | undefined,
    processes: ProcessRecord[] | undefined
) => {
    switch (tab.path) {
        case routes.PROJECT_DETAIL_BATCHES.path: {
            return !(processes && processes.length)
        }

        case routes.PROJECT_DETAIL_PROCESS.path: {
            if (!project?.template_values || !projectStructure) {
                return true
            }

            const requiredFields = Object.entries(projectStructure)
                .filter(([, v]) => v.is_required)
                .map(([k]) => k)

            return Object.entries(project.template_values).length < requiredFields.length
            /*return Object.entries(project.template_values)
                .filter(([k]) => requiredFields.includes(k))
                .map(([, v]) => !!v)
                .reduce((prev, curr) => prev && curr, true)*/
        }

        default:
            return false
    }
}

export const ProjectDetails = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {user} = useAuth()
    const {
        t,
        i18n: {language}
    } = useTranslation()

    const tabs = useMemo(
        //TODO: when permission are clear use and redefine projectTabsPermissionsBased Method in features/projects/helpers
        () => projectTabsRoleBased(user?.role?.slug),
        [language, user]
    )

    const {id} = useParams()
    const {data: project} = useProject(id)
    const {data: processes} = useProcesses({id: Number(id)})
    const {data: projectStructure} = useProjectStructure(id)

    useEffect(() => {
        const activeTab = location.pathname.split('/').pop()
        //user can't view this tab
        if (!projectTabsRoleBased(user?.role?.slug).some(tab => tab.path == activeTab)) {
            navigate(projectDetailsRoleBasedRedirect(user?.role?.slug, Number(id)))
        }
    }, [location])

    return (
        <StyledContainer fullHeight>
            <ProjectDetailsHeader />
            <TabsWrapper>
                {tabs.map(tab => {
                    return (
                        <StyledNavLink
                            to={tab.path}
                            key={tab.path}
                            aria-disabled={isTabDisabled(tab, project, projectStructure, processes)}
                        >
                            {t(tab.label)}
                        </StyledNavLink>
                    )
                })}
            </TabsWrapper>
            <Outlet />
        </StyledContainer>
    )
}

ProjectDetails.displayName = 'ProjectDetails'
