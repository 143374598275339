import styled, {css} from 'styled-components'

export const StyledFiltersContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
    `
)

export const StyledFastActionsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `
)

export const StyledSelectedCounter = styled.div(
    ({theme: {typography, palette}}) => css`
        display: flex;
        align-items: center;
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['600']};
    `
)

export const StyledFastActions = styled.div`
    display: flex;
    align-items: center;
`
