import PageHeader from '@components/commons/PageHeader'
import {useTranslation} from 'react-i18next'
import Divider from '@components/ui/Divider'
import {MainWrapper, StyledContainer} from './style.ts'
import {CompletedTasks} from '@/features/tasks/components/CompletedTasks'
import {OngoingTask} from '@/features/tasks/components/OngoingTask'

export const Tasks = () => {
    const {t} = useTranslation()

    return (
        <StyledContainer>
            <PageHeader title={t('tasks:title')} />
            <Divider />
            <MainWrapper>
                <OngoingTask />
                <CompletedTasks />
            </MainWrapper>
        </StyledContainer>
    )
}

Tasks.displayName = 'Tasks'
