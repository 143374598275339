import {useParams} from 'react-router-dom'
import PageHeader from '@components/commons/PageHeader'
import {BatchDetailsHeaderLoader} from '@/features/batches/components/BatchDetailsHeader/BatchDetailsHeaderLoader.tsx'
import {useBatchDetails} from '@/features/batches/queries/useBatchDetails.ts'

export const BatchDetailsHeader = () => {
    const {batchId} = useParams()
    const {data: batch, isLoading} = useBatchDetails(String(batchId))

    return (
        <>
            {isLoading ? (
                <BatchDetailsHeaderLoader ctaHeight={48} ctaWidth={140} titleHeight={44} titleWidth={400} />
            ) : (
                <PageHeader title={batch?.name ?? `Batch: ${batchId}`} />
            )}
        </>
    )
}

BatchDetailsHeader.displayName = 'BatchDetailsHeader'
