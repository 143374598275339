import {Handle, Position} from 'reactflow'
import {TerminalNode, TerminalNodeWrapper} from './style'
import {handleStyle} from './constants'
import {useTranslation} from 'react-i18next'

export const EndNode = () => {
    const {t} = useTranslation()
    return (
        <TerminalNodeWrapper>
            <TerminalNode>
                {t('projectDetails:process:end')}
                <Handle type="target" position={Position.Left} isConnectable={false} style={handleStyle} />
            </TerminalNode>
        </TerminalNodeWrapper>
    )
}
