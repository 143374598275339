import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetCompany} from '../services/companies.http'

type useCompanyProps = {
    enabled?: boolean
}

export const useCompany = (id: number, options?: useCompanyProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.COMPANY, id],
        queryFn: () => httpGetCompany(id),
        ...options
    })
}
