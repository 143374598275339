import {useTranslation} from 'react-i18next'
import PageHeader from '@components/commons/PageHeader'
import Divider from '@components/ui/Divider'
import Button from '@components/ui/Button'
import {PlusIcon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {routes} from '@utilities/constants'
import {StyledNavLink, TabsWrapper} from '@pages/users/style.ts'
import {Outlet} from 'react-router-dom'
import {TaxonomyModal} from '@/features/taxonomies/components/TaxonomyModal'
import {useState} from 'react'
import toast from 'react-hot-toast'
import {useTaxonomies} from '@/features/taxonomies/queries/useTaxonomies.ts'
import {StyledContainer} from '@pages/taxonomies/style.ts'
import {StyledTemplatesGrid} from '@pages/templates/style.ts'

export const Taxonomies = () => {
    const {t} = useTranslation()
    const {spacing} = useTheme()

    const [isTaxonomyModalOpened, setIsTaxonomyModalOpened] = useState(false)

    const active = useTaxonomies({page: 1, is_active: true})
    const inactive = useTaxonomies({is_active: false})

    const tabs = [
        {label: t('commons:active'), path: routes.ACTIVE_TAXONOMIES.path, count: active.data?.pagination.total},
        {label: t('commons:inactive'), path: routes.INACTIVE_TAXONOMIES.path, count: inactive.data?.pagination.total}
    ]

    return (
        <>
            <StyledContainer fullHeight>
                <PageHeader
                    title={t('taxonomies:headline')}
                    ctaComponent={
                        <div style={{display: 'flex', gap: spacing * 3}}>
                            <Button size={'md'} variant={'primary'} onClick={() => setIsTaxonomyModalOpened(true)}>
                                <PlusIcon />
                                {t('taxonomies:new_taxonomy')}
                            </Button>
                        </div>
                    }
                />
                <Divider height={0} topSpacing={12} bottomSpacing={12} />

                <StyledTemplatesGrid>
                    <TabsWrapper>
                        {tabs.map(tab => (
                            <StyledNavLink to={tab.path} key={tab.path}>
                                {tab.label} <span className={'counter'}>{tab.count}</span>
                            </StyledNavLink>
                        ))}
                    </TabsWrapper>
                    <Outlet />
                </StyledTemplatesGrid>
            </StyledContainer>

            <TaxonomyModal
                open={isTaxonomyModalOpened}
                setOpen={setIsTaxonomyModalOpened}
                onSuccess={() => {
                    toast.success(t('taxonomies:add_taxonomy_success'))
                }}
            />
        </>
    )
}

Taxonomies.displayName = 'Taxonomies'
