import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {debounce} from '@utilities/helpers.ts'
import {httpGetInfiniteTaxonomies} from '@/features/taxonomies/services/taxonomies.http.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {TaxonomySearchParams} from '@/features/taxonomies/types.ts'

export const useInfiniteTaxonomies = (active = true) => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof TaxonomySearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, TaxonomySearchParams)

    const query = useInfiniteQuery({
        queryKey: [
            QUERY_KEYS.TAXONOMIES,
            active,
            searchParams.orderBy,
            searchParams.orderDirection,
            searchParams.search
        ],
        queryFn: ({pageParam = 1}) =>
            httpGetInfiniteTaxonomies(
                pageParam,
                searchParams.orderBy,
                searchParams.orderDirection,
                searchParams.search,
                active
            ),
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: TaxonomySearchParams['orderBy']
        orderDirection: TaxonomySearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? [],
        search,
        searchValue: searchParams.search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
