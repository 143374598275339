import axios from '@services/axiosInstance.ts'
import {GetUsersResponse, UserRecord, UserSession, UsersRoleSearchParams} from '@/features/users/types.ts'
import {AxiosResponse} from 'axios'

export const httpGetUsers = (
    pageParam: number,
    orderBy: UsersRoleSearchParams['orderBy'],
    orderDirection: UsersRoleSearchParams['orderDirection'],
    searchValue: UsersRoleSearchParams['search'],
    roleId: number
) => {
    const params = {
        search: searchValue,
        per_page: 50,
        role_id: roleId,
        current_page: pageParam,
        order_by: orderBy,
        order_direction: orderDirection
    }

    return axios.get('users', {params}).then((response: AxiosResponse<GetUsersResponse>) => response.data)
}

export const httpGetUser = (userId: number) => {
    return axios.get(`/users/${userId}`).then(response => response.data as UserRecord)
}

export const httpDeleteUser = (userToDeleteID: number) => {
    return axios.delete(`users/${userToDeleteID}`).then((response: AxiosResponse<void>) => response.data)
}

interface httpEditUserArgs {
    userToEditID: number
    payload: {
        company_id?: number
        email: string
        first_name: string
        last_name: string
        role_id: number
        supervisor_id?: number
        skill_taxonomy_id?: number
        taxonomy?: File
    }
}
export const httpEditUser = ({userToEditID, payload}: httpEditUserArgs) => {
    return axios.patch(`users/${userToEditID}`, payload).then((response: AxiosResponse<UserRecord>) => response.data)
}

export type AddUsersError = {email: string; error: string}
export type AddUsersResponse = {
    counts: {
        added: number
        removed: number
        updated: number
    }
    errors: {
        added: Array<AddUsersError>
        removed: Array<AddUsersError>
        updated: Array<AddUsersError>
    }
}
export const httpAddUsers = (formData: FormData) => {
    return axios
        .post('users/bulk', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response: AxiosResponse<AddUsersResponse>) => response.data)
}

export const httpExportUsers = (roleID: number): Promise<AxiosResponse<string>> => {
    return axios.get('users/export', {params: {role_id: roleID}})
}

export const httpExportExample = (roleID: number): Promise<AxiosResponse<string>> => {
    return axios.get('users/export-example', {params: {role_id: roleID}})
}

export const httpGetUserSessions = () => {
    return axios.get('user-sessions').then((response: AxiosResponse<UserSession>) => response.data)
}

export const httpPostUserSessions = (taskId: number) => {
    return axios.post('user-sessions', {task_id: taskId}).then((response: AxiosResponse<UserSession>) => response.data)
}

export const httpEditUserSessions = (id: number) => {
    return axios.patch(`user-sessions/${id}`).then((response: AxiosResponse<UserSession>) => response.data)
}
