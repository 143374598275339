import {FC} from 'react'
import {StyledListItem, StyledPopoverContent, VendorSorterWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {CheckIcon, ChevronDownIcon} from '@/components/ui/Icon'
import {Flexbox} from '@/components/ui/Flexbox'
import {Popover} from '@/components/ui/Popover'

export type VendorSortValue = {
    order_by: 'unit_capacity' | 'name'
    order_direction: 'asc' | 'desc'
}

type VendorSorterProps = {
    value: VendorSortValue
    onChange: (newValue: VendorSortValue) => void
}

const sortFieldMap: Record<VendorSortValue['order_by'], string> = {
    name: 'commons:name',
    unit_capacity: 'commons:capacity'
} as const

const sortOrderMap: Record<VendorSortValue['order_direction'], string> = {
    asc: 'commons:ascending',
    desc: 'commons:descending'
} as const

export const VendorSorter: FC<VendorSorterProps> = ({value, onChange}) => {
    const {t} = useTranslation()

    const onChangeCb = (newValue: Partial<VendorSortValue>) => {
        onChange({
            ...value,
            ...newValue
        })
    }

    return (
        <VendorSorterWrapper>
            <Popover
                trigger={
                    <Flexbox className="trigger" gap={1.5} align="center" justify="space-between">
                        <p>
                            {t('commons:order_by')}: {t(sortFieldMap[value.order_by])}
                        </p>
                        <div className="chevron-container">
                            <ChevronDownIcon size={20} />
                        </div>
                    </Flexbox>
                }
            >
                <StyledPopoverContent>
                    <Flexbox className="sort-fields-container" direction="column" width={'100%'}>
                        <StyledListItem
                            width={'100%'}
                            align="center"
                            justify="space-between"
                            isSelected={value.order_by === 'unit_capacity'}
                            onClick={() => onChangeCb({order_by: 'unit_capacity'})}
                        >
                            {t(sortFieldMap['unit_capacity'])}
                            {value.order_by === 'unit_capacity' && (
                                <div className="icon-container">
                                    <CheckIcon size={20} />
                                </div>
                            )}
                        </StyledListItem>
                        <StyledListItem
                            width={'100%'}
                            isSelected={value.order_by === 'name'}
                            align="center"
                            justify="space-between"
                            onClick={() => onChangeCb({order_by: 'name'})}
                        >
                            {t(sortFieldMap['name'])}
                            {value.order_by === 'name' && (
                                <div className="icon-container">
                                    <CheckIcon size={20} />
                                </div>
                            )}
                        </StyledListItem>
                    </Flexbox>
                    <Flexbox direction="column" width={'100%'}>
                        <StyledListItem
                            width={'100%'}
                            isSelected={value.order_direction === 'asc'}
                            align="center"
                            justify="space-between"
                            onClick={() => onChangeCb({order_direction: 'asc'})}
                        >
                            {t(sortOrderMap['asc'])}
                            {value.order_direction === 'asc' && (
                                <div className="icon-container">
                                    <CheckIcon size={20} />
                                </div>
                            )}
                        </StyledListItem>
                        <StyledListItem
                            width={'100%'}
                            isSelected={value.order_direction === 'desc'}
                            align="center"
                            justify="space-between"
                            onClick={() => onChangeCb({order_direction: 'desc'})}
                        >
                            {t(sortOrderMap['desc'])}
                            {value.order_direction === 'desc' && (
                                <div className="icon-container">
                                    <CheckIcon size={20} />
                                </div>
                            )}
                        </StyledListItem>
                    </Flexbox>
                </StyledPopoverContent>
            </Popover>
        </VendorSorterWrapper>
    )
}
