import {useTranslation} from 'react-i18next'
import {formatLocaleDate} from '@utilities/helpers.ts'
import Table from '@components/commons/Table'
import {StyledLink} from '@pages/projects/style.ts'
import {routes} from '@utilities/constants'
import {projectBadgeRenderer} from '@utilities/statusesHelpers.tsx'
import {ProjectsTableEmptyState} from '@/features/projects/components/project-table-empty-state/ProjectsTableEmptyState.tsx'
import {EmptySearch} from '@components/commons/EmptySearch'
import {useProjectsTable} from '@/features/projects/queries/useProjectsTable.ts'

interface VendorManagerViewProps {
    onClearSearchCb: () => void
}
export const VendorManagerView = ({onClearSearchCb}: VendorManagerViewProps) => {
    const {t} = useTranslation()
    const query = useProjectsTable()

    return (
        <Table
            columns={[
                {
                    label: t('projects:table:name'),
                    width: '30%',
                    sortName: 'name',
                    cellRender: project => (
                        <StyledLink
                            title={project.name ?? '-'}
                            to={`${routes.PROJECTS.path}/${project.id}/${routes.PROJECT_DETAIL_BATCHES.path}`}
                        >
                            {project.name}
                        </StyledLink>
                    )
                },
                {
                    label: t('projects:table:priority'),
                    width: '10%',
                    sortName: 'priority',
                    cellRender: project => project.priority ?? '-'
                },
                {
                    label: t('projects:table:batches_amount'),
                    width: '10%',
                    sortName: 'batches_amount',
                    cellRender: project => project.batches_amount ?? '-'
                },
                {
                    label: t('commons:status'),
                    width: '20%',
                    cellRender: project => projectBadgeRenderer(project.status ?? null, t)
                },
                {
                    label: t('projects:table:last_ending_batch'),
                    width: '15%',
                    sortName: 'last_ending_batch',
                    cellRender: project => <span>{formatLocaleDate(project.last_ending_batch, 'MMM DD, YYYY')}</span>
                },
                {
                    label: t('projects:table:creation_date'),
                    width: '15%',
                    sortName: 'created_at',
                    cellRender: project => <span>{formatLocaleDate(project.created_at, 'MMM DD, YYYY')}</span>
                }
            ]}
            data={query.remappedData}
            emptyStateComponent={<ProjectsTableEmptyState />}
            emptySearchStateComponent={
                <EmptySearch
                    onClearSearch={onClearSearchCb}
                    title={t('projects:table:empty_search_state_title')}
                    subtitle={t('projects:table:empty_search_state_subtitle', {
                        searchValue: query.searchValue
                    })}
                />
            }
            searchValue={query.searchValue}
            sorter={query.sorter}
            isChangingPage={query.isFetchingNextPage}
            isError={query.isError}
            isLoading={query.isLoading}
            onChangePage={query.fetchNextPage}
        />
    )
}

VendorManagerView.displayName = 'ClientEngineerView'
