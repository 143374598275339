import {GetOperatorsResponse, OperatorsIndexParams} from '@/features/operators/types.ts'
import axios from '@services/axiosInstance.ts'

/*const dummyOperatorsList: OperatorRecord[] = [
    {
        email: 'operator5@example.com',
        first_name: 'David',
        full_name: 'David Wilson',
        id: 5,
        last_name: 'Wilson',
        batch_name: 'Batch B',
        delta_time: 7
    },
    {
        email: 'operator6@example.com',
        first_name: 'Olivia',
        full_name: 'Olivia Martinez',
        id: 6,
        last_name: 'Martinez',
        batch_name: 'Batch C',
        delta_time: 9
    },
    {
        email: 'operator7@example.com',
        first_name: 'William',
        full_name: 'William Anderson',
        id: 7,
        last_name: 'Anderson',
        batch_name: 'Batch A',
        delta_time: 6
    }
]*/

/**
 * Get Late operators
 */
type httpGetLateOperatorsParams = {
    params?: OperatorsIndexParams
}
export const httpGetLateOperators = ({params}: httpGetLateOperatorsParams): Promise<GetOperatorsResponse> => {
    return axios
        .get(`batches/tasks-overdue`, {
            params: {
                perPage: 50,
                ...params
            }
        })
        .then(res => res.data)
}

/**
 * Notify Late operators
 */
type httpUsersNotifyParams = {
    userIds: number[]
}
export const httpUsersNotify = ({userIds}: httpUsersNotifyParams) => {
    return axios.post(`/users/notify`, {user_ids: userIds}).then(res => res.data)
}
