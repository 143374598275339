import styled, {css} from 'styled-components'

export const UploaderWrapper = styled.div<{dragAccepted?: boolean; dragRejected?: boolean; error?: boolean}>`
    ${({theme: {spacing, palette, typography}, dragAccepted, dragRejected, error}) => css`
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${spacing * 4}px ${spacing * 6}px;
        border: 1px solid ${palette.neutral[200]};
        border-radius: 12px;

        ${dragAccepted &&
        css`
            border: 1px solid ${palette.primary[200]};
            background-color: ${palette.primary[50]};
        `}

        ${dragRejected &&
        css`
            border: 1px solid ${palette.danger[200]};
            background-color: ${palette.danger[50]};
        `}

        ${error &&
        css`
            border: 1px solid ${palette.danger[200]};
        `}

        .icon-container {
            display: grid;
            width: 40px;
            height: 40px;
            place-items: center;
            border: 1px solid ${palette.neutral[200]};
            border-radius: 8px;
            margin-bottom: ${spacing * 3}px;
        }

        .text-container {
            font-size: ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral[600]};
        }

        .choose-file {
            font-weight: 600;
            color: ${palette.primary[700]};
            cursor: pointer;
        }

        .filename {
            font-size: ${typography.textSm};
            color: ${palette.neutral[900]};
            font-weight: 600;
        }
    `}
`
