import {IconContainer} from '@components/ui/IconContainer'
import {AlertCircleIcon, FileCheck02Icon, UploadCloud02Icon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {
    StyledManageUsersDropzone,
    StyledDropzone,
    StyledError,
    StyledAcceptedFileName
} from '@/features/users/components/ManageUsersModal/components/ManageUsersDropzone/style.ts'
import {Trans, useTranslation} from 'react-i18next'
import {DropzoneState} from 'react-dropzone'
import {FC} from 'react'
import {MANAGE_USERS_DROPZONE_MAX_MEGABYTES} from 'src/features/users/components/ManageUsersModal/components/ManageUsersPendingUpload'

interface ManageUsersDropzoneProps {
    dropzoneState: DropzoneState
}

export const ManageUsersDropzone: FC<ManageUsersDropzoneProps> = ({dropzoneState}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    if (dropzoneState.acceptedFiles.length) {
        return (
            <StyledManageUsersDropzone>
                <StyledDropzone state={dropzoneState} isActive={dropzoneState.isDragActive}>
                    <IconContainer>
                        <FileCheck02Icon fill={theme.palette.success['600']} />
                    </IconContainer>
                    <div>
                        <StyledAcceptedFileName>{dropzoneState.acceptedFiles[0].name}</StyledAcceptedFileName>
                        <small>
                            <Trans i18nKey="commons:choose_file_drag_drop" components={[<span />]} />
                        </small>
                    </div>
                </StyledDropzone>
            </StyledManageUsersDropzone>
        )
    }

    return (
        <StyledManageUsersDropzone>
            <StyledDropzone state={dropzoneState} isActive={dropzoneState.isDragActive}>
                <IconContainer>
                    <UploadCloud02Icon fill={theme.palette.neutral['600']} />
                </IconContainer>
                <div>
                    <p>
                        <Trans i18nKey="commons:choose_file_drag_drop" components={[<span />]} />
                    </p>
                    <small>{t('users:only_tsv_max_mb', {maxMB: MANAGE_USERS_DROPZONE_MAX_MEGABYTES})}</small>
                </div>
            </StyledDropzone>
            {!!dropzoneState.fileRejections.length && (
                <StyledError>
                    <AlertCircleIcon size={24} />
                    {t('errors:tsv_file')}
                </StyledError>
            )}
        </StyledManageUsersDropzone>
    )
}
