import {ReactNode} from 'react'
import {StyledNavLink} from '@components/ui/NavLink/style.ts'
import {DefaultTFuncReturn} from 'i18next'

interface NavLinkProps {
    disabled?: boolean
    size?: 'sm' | 'md' | 'lg'
    variant?: 'ghost'
    name?: string | DefaultTFuncReturn
    path: string
    end?: boolean
    icon?: ReactNode
}

export const NavLink = ({icon, name, path, end = true, variant = 'ghost', disabled}: NavLinkProps) => (
    <StyledNavLink disabled={disabled} size={'sm'} variant={variant} to={path} end={end}>
        {icon && icon}
        {name && name}
    </StyledNavLink>
)

NavLink.displayName = 'NavLink'
