import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetProjectStructure} from '@/features/projects/services/projects.http.ts'

type useProjectStructureProps = {
    enabled?: boolean
}

export const useProjectStructure = (id?: string | number, options?: useProjectStructureProps) => {
    if (!id) {
        throw new Error('id is required')
    }
    return useQuery({
        queryKey: [QUERY_KEYS.PROJECT_STRUCTURE, id],
        queryFn: () => httpGetProjectStructure(id),
        ...options
    })
}
