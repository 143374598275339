import {useMutation} from '@tanstack/react-query'
import {httpCreateDataService} from '../services/dataServices.http'
import toast from 'react-hot-toast'
import {AxiosError} from 'axios'

type useCreateDataServiceProps = {
    onSuccess?: (data: {id: number}) => void
    onError?: () => void
}

export const useCreateDataService = ({onSuccess, onError}: useCreateDataServiceProps) => {
    return useMutation({
        mutationFn: httpCreateDataService,
        onSuccess: data => onSuccess?.(data),
        onError: (error: AxiosError) => {
            //TODO: improve errorHandler to handle this case
            const responseData = error?.response?.data as {message: string}
            const errorResponse = responseData.message
            return toast.error(errorResponse) && onError?.()
        }
    })
}
