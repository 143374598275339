import {AxiosError} from 'axios'
import {TFunction} from 'i18next'
import toast from 'react-hot-toast'

export const DYNAMIC_ERROR_MESSAGES = {
    NOT_IN_CONFIG: 'is_not_in_config',
    NOT_OF_CORRECT_TYPE: 'is_not_of_correct_type',
    REQUIRED: ' required'
} as const

type DynamicErrorMessage = (typeof DYNAMIC_ERROR_MESSAGES)[keyof typeof DYNAMIC_ERROR_MESSAGES]

export type DynamicErrors = {
    errors: Record<
        string,
        {
            message: [DynamicErrorMessage]
        }
    >
}

type setErrorFn = (name: string, error: {type: string; message: string}) => void

export const setErrorsFromResponse = (e: AxiosError<DynamicErrors>, setError: setErrorFn, t: TFunction) => {
    if (!e.response?.data) {
        return
    }

    Object.entries(e.response.data.errors).forEach(([k, v]) => {
        const errorMessage = v.message[0]
        if (errorMessage === DYNAMIC_ERROR_MESSAGES.NOT_IN_CONFIG) {
            toast.error(t('errors:wrong_configuration'))
            return
        }
        setError(k, {type: 'custom', message: errorMessage})
    })
}
