import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/Flexbox'

export const StyledInstructions = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 5}px ${spacing * 6}px ${spacing * 8}px;
        color: ${palette.neutral['900']};

        h4 {
            ${typography.textMd};
            font-weight: 500;
        }
        p,
        li {
            ${typography.textSm}
        }
        ul {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: ${spacing * 2}px;
        }
    `
)

export const StyledButtons = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['200']};
    `
)
