import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {GetProjectsResponse} from '@/features/projects/types.ts'
import {httpDeleteProject} from '@/features/projects/services/projects.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

type QueryData = {pages?: GetProjectsResponse[]; pageParams?: unknown[]}
interface useDeleteProjectProps {
    onSuccess: () => void
}

export const useDeleteProject = ({onSuccess}: useDeleteProjectProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_PROJECT],
        mutationFn: httpDeleteProject,
        onMutate: (id: number) => ({id}), //return a context containing data to use when for example rolling back
        onSuccess: async (_, __, context) => {
            console.log(context)
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.PROJECTS]})
            // Optimistically update our data
            queryClient.setQueriesData([QUERY_KEYS.PROJECTS], (oldData?: QueryData) => ({
                ...oldData,
                pages: oldData?.pages?.map(page => ({
                    ...page,
                    data: page.data?.filter(project => project.id !== context?.id)
                }))
            }))
            onSuccess()
        },
        onError: errorHandler,
        // Always refetch cause is a delete action in an infinite table, and we need to be sure to be in sync with the b-end:
        onSettled: () => queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
    })
}
