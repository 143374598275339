import {z} from 'zod'

export const CLONE_PROJECT_FORM_MODEL = {
    project_name: {
        name: 'project_name',
        label: 'projects:clone_modal_form_name',
        defaultValue: '',
        helpText: '',
        placeholder: 'projects:clone_modal_form_name_placeholder'
    }
} as const

export const CloneProjectFromSchema = z.object({
    project_name: z.string().min(1, {message: 'projects:clone_modal_form_name_error'})
})

export type CloneProjectValidationSchema = z.infer<typeof CloneProjectFromSchema>
