import {StyledContainer, StyledProductionGrid} from '@pages/production-management/style.ts'
import {useTranslation} from 'react-i18next'
import Divider from '@components/ui/Divider'
import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {StyledNavLink, TabsWrapper} from '@pages/users/style.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import {useMemo} from 'react'
import {INVENTORY_ACCESS} from '@utilities/constants/permissions.ts'

export const ProductionManagement = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const tabs = [
        {label: t('commons:batches'), path: routes.PRODUCTION_MANAGEMENT_BATCHES.path},
        {label: t('commons:operators'), path: routes.PRODUCTION_MANAGEMENT_OPERATORS.path}
    ]
    const canSeeTabs = useMemo(() => user?.permissions.includes(INVENTORY_ACCESS), [user])
    const operatorsRoute = `${routes.PRODUCTION_MANAGEMENT.path}/${routes.PRODUCTION_MANAGEMENT_OPERATORS.path}`

    if (location.pathname == routes.PRODUCTION_MANAGEMENT.path) {
        return (
            <Navigate
                to={`${routes.PRODUCTION_MANAGEMENT.path}/${routes.PRODUCTION_MANAGEMENT_BATCHES.path}`}
                replace
            />
        )
    }

    if (location.pathname == operatorsRoute && !canSeeTabs) {
        return (
            <Navigate
                to={`${routes.PRODUCTION_MANAGEMENT.path}/${routes.PRODUCTION_MANAGEMENT_BATCHES.path}`}
                replace
            />
        )
    }

    return (
        <StyledContainer fullHeight>
            <h1>{t('productionManagement:headline')}</h1>
            <Divider topSpacing={0} bottomSpacing={0} />
            <StyledProductionGrid canSeeTabs={canSeeTabs}>
                {canSeeTabs && (
                    <TabsWrapper>
                        {tabs.map(tab => (
                            <StyledNavLink to={tab.path} key={tab.path}>
                                {tab.label}
                            </StyledNavLink>
                        ))}
                    </TabsWrapper>
                )}
                <Outlet />
            </StyledProductionGrid>
        </StyledContainer>
    )
}

ProductionManagement.displayName = 'ProductionManagement'
