import styled, {css, DefaultTheme, keyframes} from 'styled-components'
import Button from '@components/ui/Button'
import {Percentage} from '@/types/commons.ts'

interface StyleOverlayProps {
    transparent?: boolean
}
export const StyledOverlay = styled.div<StyleOverlayProps>(
    ({theme: {palette, zIndex}, transparent}) => css`
        position: fixed;
        inset: 0;
        background: ${transparent ? 'transparent' : palette.neutral['900']};
        opacity: ${transparent ? 0 : 0.9};
        transition: opacity ease-in-out 200ms;
        z-index: ${zIndex.sidebarOverlay};
        animation: ${overlayFadeIn} 200ms ease-in-out forwards;
    `
)

interface StyledModalProps {
    theme: DefaultTheme
    width: number | Percentage
}
export const StyledSidebar = styled.div(
    ({width, theme: {palette, zIndex, shadows}}: StyledModalProps) => css`
        box-shadow: ${shadows.xl};
        position: fixed;
        top: 0;
        right: 0;
        z-index: ${zIndex.sidebar};
        background: ${palette.neutral.white};
        height: 100%;
        width: ${typeof width == 'number' ? `${width}px` : width};
        max-width: 100%;
        animation: ${sidebarFadeIn} 200ms ease-in-out forwards;
    `
)

export const StyledCloseButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 3}px;
        right: ${spacing * 4}px;
    `}
`

const sidebarFadeIn = keyframes`
  from {transform: translateX(100%)}
  to {transform: translateX(0)}
`
const overlayFadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 0.9}
`
