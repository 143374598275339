import styled, {css, DefaultTheme} from 'styled-components'
import Container from '@components/ui/Container'
import {Link} from 'react-router-dom'

export const StyledContainer = styled(Container)`
    ${({theme: {spacing, typography, palette}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 6}px;
        overflow: hidden;
        & h1 {
            ${typography.displaySm};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }
    `}
`

export const StyledProjectsGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 8}px;
    `
)

export const StyledLink = styled(Link)`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.primary['700']};
        font-weight: 500;
        ${typography.textSm};
        cursor: pointer;
    `}
`
