import styled, {css} from 'styled-components'

export const StyledPageHeaderContainer = styled.div`
    ${() => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        width: 100%;
        div {
            height: 100%;
        }
    `}
`

export const StyledPageHeaderText = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        & h1 {
            color: ${palette.neutral['900']};
            ${typography.displaySm};
            font-weight: 600;
        }
        & p {
            margin-top: ${spacing}px;
            color: ${palette.neutral['600']};
            ${typography.textMd}
        }
    `}
`
