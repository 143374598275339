import {useTranslation} from 'react-i18next'
import {formatLocaleDate} from '@utilities/helpers.ts'
import Table from '@components/commons/Table'
import Button from '@components/ui/Button'
import {useArchivedTemplates} from '@/features/templates/queries/useArchivedTemplates.ts'

export const ArchivedTemplates = () => {
    const {t} = useTranslation()
    const query = useArchivedTemplates()

    return (
        <Table
            columns={[
                {
                    label: t('templates:table:name'),
                    width: '16%',
                    cellRender: template => <span title={template?.name ?? '-'}>{template?.name}</span>
                },
                {
                    label: t('templates:table:workstream'),
                    width: '15%',
                    cellRender: template => template?.workstream ?? '-'
                },
                {
                    label: t('templates:table:json_link'),
                    width: '15%',
                    cellRender: template => template?.json_link
                },
                {
                    label: t('templates:table:projects_usage'),
                    width: '12%',
                    cellRender: template => template?.projects_usage
                },
                {
                    label: t('templates:table:created_on'),
                    width: '15%',
                    cellRender: template => <span>{formatLocaleDate(template?.created_at, 'MMM DD, YYYY')}</span>
                },
                {
                    label: t('templates:table:updated_on'),
                    width: '15%',
                    cellRender: template => <span>{formatLocaleDate(template?.updated_at, 'MMM DD, YYYY')}</span>
                },
                {
                    alignment: 'right',
                    width: '12%',
                    cellRender: template => (
                        <Button
                            size={'sm'}
                            variant={'secondary'}
                            onClick={() => console.log('unarchive ', template?.id)}
                        >
                            {t('common:unarchive')}
                        </Button>
                    )
                }
            ]}
            data={query.remappedData}
            isChangingPage={query.isFetchingNextPage}
            isError={query.isError}
            isLoading={query.isLoading}
            onChangePage={query.fetchNextPage}
        />
    )
}

ArchivedTemplates.displayName = 'ArchivedTemplates'
