import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@components/ui/Button'
import {useTranslation} from 'react-i18next'
import {SettingsFormWrapper} from '@/features/settings/components/styles.ts'
import InputSelect, {SelectValue} from '@components/commons/Select'
import {
    OPERATOR_FORM_MODEL,
    OperatorFromSchema,
    OperatorValidationSchema
} from '@/features/settings/components/operator-settings/OperatorFormModel.ts'
import {useProcessTypes} from '@/features/settings/queries/useProcessTypes.ts'
import {useTaskTypes} from '@/features/settings/queries/useTaskTypes.ts'
import {remapSelectOptions, retrieveSelectMultiValues} from '@utilities/helpers.ts'
import {useUpdateLabelerSettings} from '@/features/settings/queries/useUpdateLabelerSettings.ts'
import toast from 'react-hot-toast'
import {useAuth} from '@context/AuthProvider.tsx'
import {useEffect} from 'react'

export const OperatorSettings = () => {
    const {t} = useTranslation()
    const {user, dispatch} = useAuth()
    const {data: processTypes} = useProcessTypes()
    const {data: taskTypes} = useTaskTypes()

    const {mutate: updateSettings} = useUpdateLabelerSettings({
        onSuccess: data => {
            toast.success(t('commons:genericSuccess'))
            if (dispatch) {
                dispatch({type: 'UPDATE_USER', payload: {user: data}})
            }
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<OperatorValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(OperatorFromSchema)
    })

    useEffect(() => {
        if (user && taskTypes && processTypes) {
            reset({
                preferred_modalities: retrieveSelectMultiValues(
                    remapSelectOptions(processTypes, 'id', 'modality_type'),
                    user?.process_types?.map(item => item.id)
                ),
                preferred_task_types: retrieveSelectMultiValues(
                    remapSelectOptions(taskTypes, 'id', 'name'),
                    user?.task_types?.map(item => item.id)
                )
            })
        }
    }, [user, taskTypes, processTypes])

    const onSubmit: SubmitHandler<OperatorValidationSchema> = data => {
        updateSettings({
            process_type_ids: data.preferred_modalities.map(item => +item.value),
            task_type_ids: data.preferred_task_types.map(item => +item.value)
        })
    }

    return (
        <SettingsFormWrapper onSubmit={handleSubmit(onSubmit)}>
            <Controller
                defaultValue={
                    user?.process_types.map(processType => ({
                        label: processType.modality_type,
                        value: processType.id
                    })) ?? []
                }
                render={({field: {onChange, ref, value}}) => (
                    <InputSelect
                        value={value}
                        onChange={newValue => {
                            onChange(newValue as SelectValue[])
                        }}
                        ref={ref}
                        size={'medium'}
                        name={OPERATOR_FORM_MODEL.preferred_modalities.name}
                        label={t(OPERATOR_FORM_MODEL.preferred_modalities.label)}
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.preferred_modalities?.message || '')}
                        placeholder={t(OPERATOR_FORM_MODEL.preferred_modalities.placeholder)}
                        options={remapSelectOptions(processTypes, 'id', 'modality_type')}
                    />
                )}
                control={control}
                name={OPERATOR_FORM_MODEL.preferred_modalities.name}
            />

            <Controller
                defaultValue={
                    user?.task_types.map(taskType => ({
                        label: taskType.name,
                        value: taskType.id
                    })) ?? []
                }
                render={({field: {onChange, ref, value}}) => (
                    <InputSelect
                        value={value}
                        onChange={newValue => {
                            onChange(newValue as SelectValue[])
                        }}
                        ref={ref}
                        size={'medium'}
                        name={OPERATOR_FORM_MODEL.preferred_task_types.name}
                        label={t(OPERATOR_FORM_MODEL.preferred_task_types.label)}
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.preferred_task_types?.message || '')}
                        placeholder={t(OPERATOR_FORM_MODEL.preferred_task_types.placeholder)}
                        options={remapSelectOptions(taskTypes, 'id', 'name')}
                    />
                )}
                control={control}
                name={OPERATOR_FORM_MODEL.preferred_task_types.name}
            />

            <Button type={'submit'} variant={'primary'} size={'lg'}>
                {t('commons:save')}
            </Button>
        </SettingsFormWrapper>
    )
}

OperatorSettings.displayName = 'OperatorSettings'
