import {Flexbox} from '@/components/ui/Flexbox'
import {StyledModal} from './style'
import Button from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {CompanyAssignmentMode} from '@/features/companies/types'
import {BatchAssignmentMethod} from '../../types'
import {Controller, useForm} from 'react-hook-form'
import {Radio} from '@/components/commons/Radio'

type BatchesAssignmentModalProps = {
    onClose: () => void
    type: 'project' | 'production'
    onSubmit: ({assignment_mode}: {assignment_mode: BatchAssignmentMethod | CompanyAssignmentMode}) => void
    defaultValue: BatchAssignmentMethod | CompanyAssignmentMode
    isLoading: boolean
    companyDefault: CompanyAssignmentMode
}

const companyDefaultMap: Record<CompanyAssignmentMode, string> = {
    manual: 'projectDetails:batches:manual',
    batch_level: 'projectDetails:batches:automatic_batch',
    task_level: 'projectDetails:batches:automatic_task'
} as const

export const BatchesAssignmentModal: FC<BatchesAssignmentModalProps> = ({
    type,
    onSubmit,
    onClose,
    isLoading,
    defaultValue,
    companyDefault
}) => {
    const {t} = useTranslation()

    const {handleSubmit, control} = useForm<{assignment_mode: CompanyAssignmentMode | BatchAssignmentMethod}>({
        defaultValues: {
            assignment_mode: defaultValue
        }
    })

    return (
        <StyledModal onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flexbox className="modal-header" gap={5} direction="column">
                    <div>
                        <h2>{t('projectDetails:batches:batch_assignment')}</h2>
                        <h3>
                            {type === 'project'
                                ? t('projectDetails:batches:batch_assignment_proj_subtitle')
                                : t('projectDetails:batches:batch_assignment_prod_subtitle')}
                        </h3>
                    </div>
                    {type === 'project' && (
                        <Flexbox align="start" gap={2}>
                            <Controller
                                name="assignment_mode"
                                control={control}
                                render={({field: {value, onChange}}) => (
                                    <Radio
                                        id="default"
                                        checked={value === 'default'}
                                        onChange={() => {
                                            onChange('default')
                                        }}
                                    />
                                )}
                            />
                            <Flexbox direction="column">
                                <p className="radio-label">{t('commons:default')}</p>
                                <p className="radio-label-subtext">
                                    {t('projectDetails:batches:default_label_subtext')} -{' '}
                                    {t(companyDefaultMap[companyDefault])}
                                </p>
                            </Flexbox>
                        </Flexbox>
                    )}
                    <Flexbox gap={2}>
                        <Controller
                            name="assignment_mode"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <Radio
                                    id="manual"
                                    checked={value === 'manual'}
                                    onChange={() => {
                                        onChange('manual')
                                    }}
                                />
                            )}
                        />
                        <Flexbox direction="column">
                            <p className="radio-label">{t('projectDetails:batches:manual')}</p>
                            <p className="radio-label-subtext">{t('projectDetails:batches:manual_label_subtext')}</p>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox align="start" gap={2}>
                        <Controller
                            name="assignment_mode"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <Radio
                                    id="batch_level"
                                    checked={value === 'batch_level'}
                                    onChange={() => {
                                        onChange('batch_level')
                                    }}
                                />
                            )}
                        />
                        <Flexbox direction="column">
                            <p className="radio-label">{t('projectDetails:batches:automatic_batch')}</p>
                            <p className="radio-label-subtext">
                                {t('projectDetails:batches:automatic_batch_label_subtext')}
                            </p>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox align="start" gap={2}>
                        <Controller
                            name="assignment_mode"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <Radio
                                    id="task_level"
                                    checked={value === 'task_level'}
                                    onChange={() => {
                                        onChange('task_level')
                                    }}
                                />
                            )}
                        />
                        <Flexbox direction="column">
                            <p className="radio-label">{t('projectDetails:batches:automatic_task')}</p>
                            <p className="radio-label-subtext">
                                {t('projectDetails:batches:automatic_task_label_subtext')}
                            </p>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Flexbox align="center" justify="end" className="modal-footer" gap={3}>
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {t('commons:cancel')}
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                        {t('commons:save')}
                    </Button>
                </Flexbox>
            </form>
        </StyledModal>
    )
}
