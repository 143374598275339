import {StyledProjectOperatorsGrid} from '@pages/project-details/operators/style.ts'
import {OperatorsTable} from '@/features/operators/components/OperatorsTable'

export const ProjectOperators = () => {
    return (
        <StyledProjectOperatorsGrid>
            <OperatorsTable />
        </StyledProjectOperatorsGrid>
    )
}

ProjectOperators.displayName = 'ProjectOperators'
