import {FC} from 'react'
import {StyledBatchesEditSidebar} from './style'
import {Slug as RoleSlug} from '@/types/auth'
import {BatchEditSidebarForm} from './BatchesEditSidebarForm'
import {useProjectBatch} from '../../queries/useProjectBatch'
import Spinner from '@/components/ui/Spinner'
import {Flexbox} from '@/components/ui/Flexbox'

type BatchesEditSidebarProps = {
    projectId: number
    batchId: number
    onClose: () => void
    roleSlug: Extract<RoleSlug, 'vendor_manager' | 'program_manager'>
}

export const BatchesEditSidebar: FC<BatchesEditSidebarProps> = ({batchId, projectId, onClose, roleSlug}) => {
    const {data, isLoading, error} = useProjectBatch(projectId, batchId)

    if (error) {
        throw error
    }

    let sidebarContent
    if (isLoading) {
        //TODO: remove spinner color black
        sidebarContent = (
            <Flexbox height={'100%'} width={'100%'} align="center" justify="center">
                <Spinner size={49} color="black" />
            </Flexbox>
        )
    } else if (data) {
        sidebarContent = (
            <BatchEditSidebarForm
                projectId={projectId}
                batch={data}
                roleSlug={roleSlug}
                onSuccess={onClose}
                onClose={onClose}
            />
        )
    } else {
        sidebarContent = null
    }

    return (
        <StyledBatchesEditSidebar width={400} onClose={onClose}>
            {sidebarContent}
        </StyledBatchesEditSidebar>
    )
}
