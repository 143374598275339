import styled, {css, DefaultTheme} from 'styled-components'
import {Flexbox} from '@components/ui/Flexbox'

const CARD_RADIUS = 12
export const StyledCard = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['200']};
        border-radius: ${CARD_RADIUS}px;

        h3 {
            ${typography.textXl};
            color: ${palette.neutral[900]};
            font-weight: 600;
        }

        & small {
            ${typography.textSm};
            color: ${palette.neutral[600]};
            font-weight: 400;
        }

        .claim-date {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[600]};
        }
    `}
`

export const StyledTaskImage = styled.div(
    ({src, theme: {palette}}: {src: string; theme: DefaultTheme}) => css`
        flex: none;
        width: 384px;
        height: 100%;
        border-radius: ${CARD_RADIUS}px 0 0 ${CARD_RADIUS}px;
        background-color: ${palette.neutral['100']};
        background-image: url(${src});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    `
)

export const StyledType = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        min-width: 150px;

        span {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[600]};
        }

        p {
            ${typography.textMd};
            color: ${palette.neutral.black};
            font-weight: 500;
        }
    `
)
