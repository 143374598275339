import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetTemplates} from '../services/templates.http'

export const useTemplates = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.TEMPLATES],
        queryFn: httpGetTemplates
    })
}
