import {z, ZodObject, ZodRawShape} from 'zod'
import {Pagination} from '@/types/commons.ts'
import {Slug} from '@/types/auth.ts'

export type UserRecord = {
    email: string | null
    first_name: string | null
    full_name: string | null
    id: number
    last_name: string | null
    company_name?: string | null
    company_id?: string | number | null
    supervisor_email?: string | null
    supervisor_id?: number | null
    skill_taxonomy_id?: number | null
    taxonomy?: object
}

export interface GetUsersResponse {
    data: UserRecord[]
    pagination: Pagination
}

export type UserSession =
    | undefined
    | {
          created_at: string
          deleted_at: string | null
          end_time: string | null
          id: number
          is_stopped: boolean | null
          start_time: string | null
          task_id: number
          updated_at: string | null
          user_id: number
      }

export const UsersSearchParams = {
    client_engineer: z.object({
        orderBy: z.enum(['full_name', 'email']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    }),
    company_admin: z.object({
        orderBy: z.enum(['full_name', 'email']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    }),
    labeler: z.object({
        orderBy: z.enum(['full_name', 'email', 'company_name', 'supervisor_email']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    }),
    program_manager: z.object({
        orderBy: z.enum(['full_name', 'email']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    }),
    team_lead: z.object({
        orderBy: z.enum(['full_name', 'email', 'company_name', 'supervisor_email']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    }),
    vendor_admin: z.object({
        orderBy: z.enum(['full_name', 'email', 'company_name']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    }),
    vendor_manager: z.object({
        orderBy: z.enum(['full_name', 'email', 'company_name']).optional(),
        orderDirection: z.enum(['asc', 'desc']).optional(),
        search: z.string().optional()
    })
} as const satisfies Record<Slug, ZodObject<ZodRawShape>>
export type UsersRoleSearchParams = z.infer<(typeof UsersSearchParams)[keyof typeof UsersSearchParams]>
