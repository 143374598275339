import {ComponentProps, CSSProperties, ReactNode} from 'react'
import {StyledFlexbox} from '@components/ui/Flexbox/style.ts'

type HTMLBlockElement = 'div' | 'span' | 'form' | 'article' | 'main' | 'header' | 'footer' | 'section'

type FlexBoxCustomProps = {
    className?: string
    children: ReactNode
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    direction?: CSSProperties['flexDirection']
    gap?: number
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    padding?: number
}

type OmittedComponentProps<T extends HTMLBlockElement = 'div'> = Omit<
    ComponentProps<T>,
    keyof FlexBoxCustomProps | 'ref' | 'as'
>

type FlexboxProps<T extends HTMLBlockElement = 'div'> = Omit<
    ComponentProps<T>,
    keyof FlexBoxCustomProps | 'ref' | 'as'
> & {
    as?: T
} & FlexBoxCustomProps

export const Flexbox = <T extends HTMLBlockElement = 'div'>({
    children,
    className,
    justify,
    align,
    direction,
    gap,
    width,
    height,
    padding,
    as,
    ...props
}: FlexboxProps<T>) => {
    return (
        <StyledFlexbox
            {...(props as OmittedComponentProps<'div'>)}
            as={as ?? 'div'}
            className={className}
            justify={justify}
            align={align}
            direction={direction}
            gap={gap}
            width={width}
            height={height}
            padding={padding}
        >
            {children}
        </StyledFlexbox>
    )
}
Flexbox.displayName = 'Flexbox'
