//Node size

export const TERMINAL_NODE_SIZE = 56
export const NODE_HEIGHT = 120
export const NODE_WIDTH = 245
export const ADDER_BUTTON_SIZE = 24
export const ADDER_BUTTON_GAP = 10
export const NODE_CONTAINER_PADDING = (ADDER_BUTTON_SIZE + 2 * ADDER_BUTTON_GAP) * 2

//Edge handle style
export const handleStyle = {
    opacity: 0
}
