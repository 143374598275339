import {useAuth} from '@context/AuthProvider.tsx'
import {useMemo} from 'react'
import {batchesProjectSectionTableViewBySlug} from '@/features/roles/helpers.tsx'
import {StyledProjectBatchesGrid} from '@pages/project-details/batches/style.ts'

export const ProjectBatches = () => {
    const {user} = useAuth()
    const tableRenderer = useMemo(() => batchesProjectSectionTableViewBySlug(user?.role.slug), [user])

    return <StyledProjectBatchesGrid>{tableRenderer}</StyledProjectBatchesGrid>
}

ProjectBatches.displayName = 'ProjectBatches'
