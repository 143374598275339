import {StyledBatchesTableEmptyState} from '@/features/batches/components/BatchesTableEmptyState/style.ts'
import {IconContainer} from '@components/ui/IconContainer'
import {FolderIcon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'

export const BatchesTableEmptyState = () => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <>
            <StyledBatchesTableEmptyState>
                <IconContainer>
                    <FolderIcon fill={theme.palette.neutral['700']} />
                </IconContainer>
                <hgroup>
                    <h3>{t('batches:table:empty_state_title')}</h3>
                    <h4>{t('batches:table:empty_state_subtitle')}</h4>
                </hgroup>
            </StyledBatchesTableEmptyState>
        </>
    )
}

BatchesTableEmptyState.displayName = 'BatchesTableEmptyState'
