import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpManualAssignBatch} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type useManualAssignBatchProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useManualAssignBatch = (options?: useManualAssignBatchProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: httpManualAssignBatch,
        onError: options?.onError,
        onSuccess: () => {
            options?.onSuccess?.()
            queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES])
            queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_BATCHES])
        }
    })
}
