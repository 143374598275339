import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetCurrentSettings} from '@/features/settings/services/settings.http.ts'

export const useSettings = (id: number) => {
    return useQuery({
        queryKey: [QUERY_KEYS.CURRENT_SETTINGS],
        queryFn: () => httpGetCurrentSettings(id)
    })
}
