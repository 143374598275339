import styled, {css} from 'styled-components'
import {Modal} from '@components/ui/Modal'

export const TaxonomyModalWrapper = styled(Modal)`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
        hgroup {
            display: flex;
            flex-direction: column;
            gap ${spacing}px;
            padding: ${spacing * 6}px ${spacing * 6}px 0;
            
            h4 {
              ${typography.textLg};
              font-weight: 600;
              color: ${palette.neutral['900']}
            }
        }
      
        section {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2.5}px;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 4}px;
        }

        .cta-container {
            display: flex;
            flex-direction: row;
            justify-content: end;
            gap: ${spacing * 3}px;
            padding: ${spacing * 6}px;
            border-top: 1px solid ${palette.neutral['200']};
        }
    `}
`

export const StyledFieldsGrid = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: grid;
        justify-content: start;
        align-items: start;
        gap: ${spacing * 4}px;
        grid-template-columns: 1fr;
        padding: 0 ${spacing * 6}px 0;
    `}
`

export const StyledActionsButtons = styled('div')(
    ({theme: {spacing, palette}}) => css`
        display: flex;
        justify-content: end;
        gap: ${spacing * 3}px;
        margin-top: ${spacing * 8}px;
        padding: ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['200']};
    `
)
