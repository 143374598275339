import {forwardRef, PropsWithChildren, ReactNode} from 'react'
import * as RPopover from '@radix-ui/react-popover'
import {StyledPopoverContent, StyledPopoverTrigger} from '@components/ui/Popover/style.ts'

type PopoverProps = {
    rootProps?: RPopover.PopoverProps
    triggerProps?: RPopover.PopoverTriggerProps
    portalProps?: RPopover.PopoverPortalProps
    contentProps?: RPopover.PopoverContentProps
    trigger: ReactNode
} & PropsWithChildren

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
    ({trigger, children, rootProps, triggerProps, portalProps, contentProps}, ref) => {
        return (
            <RPopover.Root {...rootProps}>
                <StyledPopoverTrigger {...triggerProps}>{trigger}</StyledPopoverTrigger>
                <RPopover.Portal {...portalProps}>
                    <StyledPopoverContent ref={ref} sideOffset={8} {...contentProps}>
                        {children}
                    </StyledPopoverContent>
                </RPopover.Portal>
            </RPopover.Root>
        )
    }
)

Popover.displayName = 'Popover'
