import {useMutation, useQueryClient} from '@tanstack/react-query'
import {EditDataServicePayload, httpEditDataService} from '../services/dataServices.http'
import {QUERY_KEYS} from '@/queryClient'
import {AxiosError} from 'axios'
import toast from 'react-hot-toast'

type useEditDataServiceProps = {
    onSuccess?: (data: {id: number}) => void
    onError?: () => void
}

type MutationArgs = EditDataServicePayload & {id: number}

export const useEditDataService = ({onSuccess, onError}: useEditDataServiceProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({id, ...payload}: MutationArgs) => httpEditDataService(id, payload),
        onSuccess: (data, variables) => {
            onSuccess?.(data)
            queryClient.invalidateQueries([QUERY_KEYS.DATASERVICE, variables.id])
        },
        onError: (error: AxiosError) => {
            //TODO: improve errorHandler to handle this case
            const responseData = error?.response?.data as {message: string}
            const errorResponse = responseData.message
            return toast.error(errorResponse) && onError?.()
        }
    })
}
