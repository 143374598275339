import {EmptySearch} from '@components/commons/EmptySearch'
import {SectionTitle, StyledMediaIdField} from '@pages/tasks/style.ts'
import {AlertCircleIcon} from '@components/ui/Icon'
import {useCompletedTasks} from '@/features/tasks/queries/useCompletedTasks.ts'
import Table from '@components/commons/Table'
import {useTranslation} from 'react-i18next'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {useProcessTypes} from '@/features/settings/queries/useProcessTypes.ts'
import {Flexbox} from '@components/ui/Flexbox'

export const CompletedTasks = () => {
    const {t} = useTranslation()
    const tasksQuery = useCompletedTasks()
    const processTypesQuery = useProcessTypes()

    return (
        <Flexbox direction="column" gap={6} width="100%">
            <SectionTitle>{t('tasks:completed')}</SectionTitle>
            <Table
                columns={[
                    {
                        label: t('tasks:media_id'),
                        width: '12%',
                        cellRender: task => <StyledMediaIdField>{task.media.id}</StyledMediaIdField>
                    },
                    {
                        label: t('batches:table:batch_name'),
                        width: '20%',
                        cellRender: task => <span title={task.batch.name}>{task.batch.name}</span>
                    },
                    {
                        label: t('tasks:modality'),
                        width: '15%',
                        sortName: 'modality',
                        cellRender: task => {
                            const modality = processTypesQuery.data?.find(
                                processType => processType.id == task.process.process_type_id
                            )?.modality
                            return modality ? t(`tasks:${modality}`) : '-'
                        }
                    },
                    {
                        label: t('tasks:modality_type'),
                        width: '15%',
                        sortName: 'modality_type',
                        cellRender: task =>
                            processTypesQuery.data?.find(processType => processType.id == task.process.process_type_id)
                                ?.modality_type ?? '-' //TODO: to translate?
                    },
                    {
                        label: t('tasks:task_type'),
                        width: '13%',
                        sortName: 'task_type',
                        cellRender: task => task.type ?? '-' //TODO: to translate?
                    },
                    {
                        label: t('tasks:claim_date'),
                        width: '13%',
                        sortName: 'claimed_at',
                        cellRender: task => formatLocaleDate(task.claimed_at, 'MMM DD, YYYY') ?? '-'
                    },
                    {
                        label: t('tasks:completed_date'),
                        width: '12%',
                        sortName: 'finished_at',
                        cellRender: task => formatLocaleDate(task.finished_at, 'MMM DD, YYYY') ?? '-'
                    }
                ]}
                data={tasksQuery.remappedData}
                emptyStateComponent={
                    <EmptySearch
                        title={t('tasks:no_completed_title')}
                        subtitle={t('tasks:no_completed_subtitle')}
                        icon={<AlertCircleIcon />}
                    />
                }
                isChangingPage={tasksQuery.isFetchingNextPage}
                isError={tasksQuery.isError}
                isLoading={tasksQuery.isLoading || processTypesQuery.isLoading}
                onChangePage={tasksQuery.fetchNextPage}
                sorter={tasksQuery.sorter}
            />
        </Flexbox>
    )
}

CompletedTasks.displayName = 'CompletedTasks'
