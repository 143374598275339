import {useTranslation} from 'react-i18next'
import PageHeader from '@components/commons/PageHeader'
import Divider from '@components/ui/Divider'
import Button from '@components/ui/Button'
import {PlusIcon} from '@components/ui/Icon'
import toast from 'react-hot-toast'
import {useTheme} from 'styled-components'
import {StyledContainer} from '@pages/projects/style.ts'
import {routes} from '@utilities/constants'
import {StyledTemplatesGrid} from '@pages/templates/style.ts'
import {StyledNavLink, TabsWrapper} from '@pages/users/style.ts'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

export const Templates = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const {spacing} = useTheme()

    const tabs = [
        {label: t('commons:active'), path: routes.ACTIVE_TEMPLATES.path},
        {label: t('commons:archived'), path: routes.ARCHIVED_TEMPLATES.path}
    ]

    if (location.pathname === routes.TEMPLATES.path) {
        return <Navigate to={routes.ACTIVE_TEMPLATES.path} replace />
    }

    return (
        <StyledContainer fullHeight>
            <PageHeader
                title={t('templates:headline')}
                ctaComponent={
                    <div style={{display: 'flex', gap: spacing * 3}}>
                        <Button
                            size={'md'}
                            variant={'primary'}
                            onClick={() => toast.success(t('templates:new_template'))}
                        >
                            <PlusIcon />
                            {t('templates:new_template')}
                        </Button>
                    </div>
                }
            />
            <Divider />

            <StyledTemplatesGrid>
                <TabsWrapper>
                    {tabs.map(tab => (
                        <StyledNavLink to={tab.path} key={tab.path}>
                            {tab.label}
                        </StyledNavLink>
                    ))}
                </TabsWrapper>
                <Outlet />
            </StyledTemplatesGrid>
        </StyledContainer>
    )
}

Templates.displayName = 'Templates'
