import Button from '@components/ui/Button'
import {ArrowRightIcon} from '@components/ui/Icon'
import {
    StyledCancelBtnWrapper,
    StyledStepActionsGrid
} from '@/features/projects/components/project-step-actions/style.ts'
import {useFormContext} from 'react-hook-form'
import Spinner from '@components/ui/Spinner'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {DefaultTFuncReturn} from 'i18next'
import {useTheme} from 'styled-components'

interface ProjectStepActionsProps {
    onCloseCb: () => void
    onNextClickCb?: () => void
    nextButtonDisabled?: boolean
    onSave?: () => void
    nextStepLabel?: string | DefaultTFuncReturn
    isLoading: boolean
}

export const ProjectStepActions = ({
    onCloseCb,
    onSave,
    onNextClickCb,
    nextButtonDisabled,
    nextStepLabel,
    isLoading
}: ProjectStepActionsProps) => {
    const formObj = useFormContext()
    const {palette} = useTheme()
    const isValid = formObj?.formState.isValid || true

    const {t} = useTranslation()

    const onNextClick = useCallback(() => {
        onNextClickCb && onNextClickCb()
    }, [onNextClickCb])

    return (
        <StyledStepActionsGrid>
            <StyledCancelBtnWrapper>
                <Button type={'button'} variant={'linkColor'} size={'lg'} disabled={isLoading} onClick={onCloseCb}>
                    {t('commons:close')}
                </Button>
            </StyledCancelBtnWrapper>
            <Button type={'submit'} variant={'secondary'} size={'lg'} disabled={!isValid || isLoading} onClick={onSave}>
                {isLoading && <Spinner size={16} color={palette.neutral['500']} />}
                {t('commons:save')}
            </Button>
            {onNextClickCb && (
                <Button
                    type={'button'}
                    variant={'primary'}
                    size={'lg'}
                    disabled={!isValid || isLoading || nextButtonDisabled}
                    onClick={onNextClick}
                >
                    {isLoading && <Spinner size={16} />}
                    {nextStepLabel}
                    <ArrowRightIcon />
                </Button>
            )}
        </StyledStepActionsGrid>
    )
}

ProjectStepActions.displayName = 'ProjectStepActions'
