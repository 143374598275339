import InputSelect, {SelectValue} from '@/components/commons/Select'
import {ComponentProps, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTemplates} from '../../queries/useTemplates'
import {SingleValue} from 'react-select'

type TemplateSelectProps = Omit<
    ComponentProps<typeof InputSelect>,
    'options' | 'inputValue' | 'onInputChange' | 'value' | 'onChange' | 'isMulti'
> & {value: number | null; onChange: (newVal: number | null) => void}

export const TemplateSelect = ({value, onChange, ...props}: TemplateSelectProps) => {
    const {t} = useTranslation()

    const [inputValue, setInputValue] = useState('')

    const {data} = useTemplates()

    const options = data?.map(taxonomy => ({
        value: taxonomy?.id.toString() || '',
        label: taxonomy?.name || ''
    }))

    const selectValue = options?.find(opt => opt.value === value?.toString())

    return (
        <InputSelect
            {...props}
            isMulti={false}
            value={selectValue}
            onChange={newValue => {
                const value = (newValue as SingleValue<SelectValue> | undefined)?.value
                const parsedNewValue = value ? Number(value) : null
                onChange(parsedNewValue)
            }}
            placeholder={t('projects:select_template')}
            options={options ?? []}
            isSearchable
            inputValue={inputValue}
            onInputChange={newText => setInputValue(newText)}
        />
    )
}
