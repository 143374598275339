import {Trans, useTranslation} from 'react-i18next'
import {ArchiveIcon, DotsVerticalIcon, Edit01Icon, SearchLgIcon, Trash01Icon} from '@components/ui/Icon'
import {buildJSON, downloadFile, formatLocaleDate} from '@utilities/helpers.ts'
import Table from '@components/commons/Table'
import DropdownMenu from '@components/ui/DropdownMenu'
import {useInfiniteTaxonomies} from '@/features/taxonomies/queries/useInfiniteTaxonomies.ts'
import {useState} from 'react'
import {TaxonomiesTableEmptyState} from '@/features/taxonomies/components/TaxonomiesTableEmptyState'
import {EmptySearch} from '@components/commons/EmptySearch'
import {StyledFakeLink, StyledLink, StyledTaxonomyName} from '@pages/taxonomies/style.ts'
import {TaxonomyRecord} from '@/features/taxonomies/types.ts'
import {useDeleteTaxonomy} from '@/features/taxonomies/queries/useDeleteTaxonomy.ts'
import {DeleteModal} from '@components/commons/DeleteModal'

import toast from 'react-hot-toast'
import {TaxonomyStatusToggleModal} from '@/features/taxonomies/components/TaxonomyStatusToggleModal'
import {useDeactivateTaxonomy} from '@/features/taxonomies/queries/useDeactivateTaxonomy.ts'
import InputText from '@components/commons/InputText'
import {TaxonomyModal} from '@/features/taxonomies/components/TaxonomyModal'

export const ActiveTaxonomies = () => {
    const {t} = useTranslation()
    const query = useInfiniteTaxonomies()

    const [taxonomyToEdit, setTaxonomyToEdit] = useState<TaxonomyRecord | undefined>(undefined)

    const deleteTaxonomyMutation = useDeleteTaxonomy({
        onSuccess: () => {
            setTaxonomyToDelete(null)
            toast.success(t('taxonomies:delete_taxonomy_success'))
        }
    })
    const [taxonomyToDelete, setTaxonomyToDelete] = useState<TaxonomyRecord | null>(null)

    const deactivateTaxonomyMutation = useDeactivateTaxonomy({
        onSuccess: () => {
            setTaxonomyToDeactivate(null)
            toast.success(t('taxonomies:deactivate_taxonomy_success'))
        }
    })
    const [taxonomyToDeactivate, setTaxonomyToDeactivate] = useState<TaxonomyRecord | null>(null)

    return (
        <>
            <InputText
                width="320px"
                type="text"
                defaultValue={query.searchValue}
                typeIcon={<SearchLgIcon size={20} />}
                inputSize="sm"
                placeholder={t('taxonomies:search_taxonomy') ?? 'Search Taxonomy'}
                onChange={event => {
                    query.search(event.target.value)
                }}
            />

            <Table
                columns={[
                    {
                        label: t('taxonomies:table:name'),
                        width: '25%',
                        sortName: 'name',
                        cellRender: taxonomy => (
                            <StyledTaxonomyName title={taxonomy?.name ?? '-'}>{taxonomy?.name}</StyledTaxonomyName>
                        )
                    },
                    {
                        label: t('taxonomies:table:json_link'),
                        width: '10%',
                        cellRender: taxonomy => (
                            <StyledFakeLink
                                onClick={() => downloadFile(buildJSON(`JSON-${taxonomy?.name}`, taxonomy?.values))}
                            >
                                {t('taxonomies:table:json_link_cta')}
                            </StyledFakeLink>
                        )
                    },
                    {
                        label: t('taxonomies:table:instructions_link'),
                        width: '10%',
                        cellRender: taxonomy =>
                            taxonomy?.instructions?.[0]?.url ? (
                                <StyledLink to={taxonomy.instructions[0].url} target={'_blank'}>
                                    {t('taxonomies:table:instructions_link_cta')}
                                </StyledLink>
                            ) : (
                                '-'
                            )
                    },
                    {
                        label: t('taxonomies:table:projects_usage'),
                        width: '15%',
                        cellRender: taxonomy => taxonomy?.project_usage ?? '-'
                    },
                    {
                        label: t('taxonomies:table:created_on'),
                        width: '17%',
                        sortName: 'created_at',
                        cellRender: taxonomy => <span>{formatLocaleDate(taxonomy?.created_at, 'MMM DD, YYYY')}</span>
                    },
                    {
                        label: t('taxonomies:table:updated_on'),
                        width: '17%',
                        sortName: 'updated_at',
                        cellRender: taxonomy => <span>{formatLocaleDate(taxonomy?.updated_at, 'MMM DD, YYYY')}</span>
                    },
                    {
                        alignment: 'right',
                        width: '6%',
                        cellRender: taxonomy => (
                            <DropdownMenu
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: t('commons:edit'),
                                        icon: <Edit01Icon size={16} />,
                                        onClickCb: () => {
                                            setTaxonomyToEdit(taxonomy ?? undefined)
                                        }
                                    },
                                    {
                                        component: t('commons:deactivate'),
                                        icon: <ArchiveIcon size={16} />,
                                        onClickCb: () => setTaxonomyToDeactivate(taxonomy ?? null)
                                    },
                                    {
                                        component: t('commons:delete'),
                                        icon: <Trash01Icon size={16} />,
                                        isDanger: true,
                                        onClickCb: () => setTaxonomyToDelete(taxonomy ?? null)
                                    }
                                ]}
                            />
                        )
                    }
                ]}
                sorter={query.sorter}
                data={query.remappedData}
                emptyStateComponent={<TaxonomiesTableEmptyState />}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => query.search('')}
                        title={t('taxonomies:table:empty_search_state_title')}
                        subtitle={t('taxonomies:table:empty_search_state_subtitle', {
                            searchValue: query.searchValue
                        })}
                    />
                }
                searchValue={query.searchValue}
                isChangingPage={query.isFetchingNextPage}
                isError={query.isError}
                isLoading={query.isLoading}
                onChangePage={query.fetchNextPage}
            ></Table>

            {taxonomyToEdit && (
                <TaxonomyModal
                    open={!!taxonomyToEdit}
                    data={taxonomyToEdit}
                    setOpen={() => setTaxonomyToEdit(undefined)}
                    onSuccess={() => {
                        toast.success(t('taxonomies:edit_taxonomy_success'))
                    }}
                />
            )}

            {!!taxonomyToDelete && (
                <DeleteModal
                    isLoading={deleteTaxonomyMutation.isLoading}
                    onClose={() => setTaxonomyToDelete(null)}
                    onDelete={() => deleteTaxonomyMutation.mutate(taxonomyToDelete.id)}
                    title={t('taxonomies:delete_taxonomy_title')}
                    paragraph={
                        <Trans
                            i18nKey="taxonomies:delete_taxonomy_subtitle"
                            values={{name: taxonomyToDelete.name}}
                            components={[<br />]}
                        />
                    }
                />
            )}

            {!!taxonomyToDeactivate && (
                <TaxonomyStatusToggleModal
                    isLoading={deactivateTaxonomyMutation.isLoading}
                    onClose={() => setTaxonomyToDeactivate(null)}
                    onConfirm={() => deactivateTaxonomyMutation.mutate(taxonomyToDeactivate)}
                    title={t('taxonomies:deactivate_taxonomy_title')}
                    paragraph={
                        <Trans
                            i18nKey="taxonomies:deactivate_taxonomy_subtitle"
                            values={{name: taxonomyToDeactivate.name}}
                            components={[<br />]}
                        />
                    }
                />
            )}
        </>
    )
}

ActiveTaxonomies.displayName = 'ActiveTaxonomies'
