import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetCompletedTasks} from '../services/tasks.http'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {CompletedTasksSearchParams} from '@/features/tasks/types.ts'

export const useCompletedTasks = () => {
    const {searchParams, setSearchParams} = useParsedSearchParams([], CompletedTasksSearchParams)
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.COMPLETED_TASKS, searchParams.orderBy, searchParams.orderDirection],
        queryFn: ({pageParam = 1}) =>
            httpGetCompletedTasks({
                currentPage: pageParam,
                perPage: 50,
                order_by: searchParams.orderBy,
                order_direction: searchParams.orderDirection
            }),
        getNextPageParam: ({pagination}) => pagination.nextPage ?? false
    })

    const fetchNextPage = async () => {
        if (query.hasNextPage && !query.isFetching) {
            const pages = query.data?.pages ?? []
            const currentPage = pages[pages.length - 1]?.pagination?.currentPage ?? 1
            await query.fetchNextPage({pageParam: currentPage + 1})
        }
    }

    const sort = (sorter: {
        orderBy: CompletedTasksSearchParams['orderBy']
        orderDirection: CompletedTasksSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...query,
        fetchNextPage,
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? [],
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
