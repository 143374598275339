import {FC} from 'react'
import {DataServiceFormProps} from './types'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {FormWrapper, StyledForm} from './styles'
import InputText from '@/components/commons/InputText'
import {FormControls} from './FormControls'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useCreateDataService} from '../../queries/useCreateDataService'
import {GetDataServiceResponse} from '../../services/dataServices.http'
import {useEditDataService} from '../../queries/useEditDataSerivce'

const validationSchema = z.object({
    storage_name: z.string(),
    bucket_name: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    bucket_prefix: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    region_name: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    s3_endpoint: z.string(),
    access_key_id: z.string(),
    secret_access_key: z.string(),
    session_token: z.string(),
    file_filter_regex: z.string().optional()
})

type FormValues = z.infer<typeof validationSchema>

const getDefaultValues = (data: GetDataServiceResponse) => {
    if (data.data_config.type !== 's3') {
        return {}
    }
    const {
        storage_name,
        bucket_name,
        bucket_prefix,
        region_name,
        s3_endpoint,
        access_key_id,
        secret_access_key,
        session_token,
        file_filter_regex
    } = data.data_config.configuration
    return {
        storage_name,
        bucket_name,
        bucket_prefix,
        region_name,
        s3_endpoint,
        access_key_id,
        secret_access_key,
        session_token,
        file_filter_regex
    }
}

export const AmazonS3Form: FC<DataServiceFormProps> = ({onCancel, onSuccess, data, alwaysCreate, showFileRegex}) => {
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: zodResolver(validationSchema),
        defaultValues: data ? getDefaultValues(data) : undefined
    })
    const {t} = useTranslation()

    const {mutate: createDataService, isLoading: isLoadingCreateDataService} = useCreateDataService({
        onSuccess: data => onSuccess?.(data)
    })

    const {mutate: editDataService, isLoading: isLoadingEditDataService} = useEditDataService({
        onSuccess: data => onSuccess?.(data)
    })

    const isLoading = isLoadingCreateDataService || isLoadingEditDataService

    const onSubmit = (values: FormValues) => {
        if (data && !alwaysCreate) {
            editDataService({
                id: data.id,
                company_id: 1,
                data_config: {
                    id: data.data_config.id,
                    type: 's3',
                    configuration: values
                }
            })
            return
        }

        createDataService({
            company_id: 1,
            data_config: {
                type: 's3',
                configuration: values
            }
        })
    }

    return (
        <StyledForm
            onSubmit={e => {
                e.stopPropagation()
                handleSubmit(onSubmit)(e)
            }}
        >
            <FormWrapper>
                <div className="form-fields-section">
                    <div className="two-columns-row">
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:bucketName')}
                            errorMessage={t(errors.bucket_name?.message ?? '')}
                            {...register('bucket_name')}
                        />
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:bucketPrefix')}
                            errorMessage={t(errors.bucket_prefix?.message ?? '')}
                            {...register('bucket_prefix')}
                        />
                    </div>
                    <div className="two-columns-row">
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:regionName')}
                            errorMessage={t(errors.region_name?.message ?? '')}
                            {...register('region_name')}
                        />
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:s3Endpoint')}
                            errorMessage={t(errors.s3_endpoint?.message ?? '')}
                            {...register('s3_endpoint')}
                        />
                    </div>
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:storageName')}
                        errorMessage={t(errors.storage_name?.message ?? '')}
                        {...register('storage_name')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:accessKeyId')}
                        errorMessage={t(errors.access_key_id?.message ?? '')}
                        {...register('access_key_id')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:secretAccessKey')}
                        errorMessage={t(errors.secret_access_key?.message ?? '')}
                        {...register('secret_access_key')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:sessionToken')}
                        errorMessage={t(errors.session_token?.message ?? '')}
                        {...register('session_token')}
                    />
                    {showFileRegex && (
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:file_filter_regex')}
                            errorMessage={t(errors.file_filter_regex?.message ?? '')}
                            {...register('file_filter_regex')}
                        />
                    )}
                </div>
            </FormWrapper>
            <FormControls onCancel={onCancel} isConfirmLoading={isLoading} />
        </StyledForm>
    )
}
