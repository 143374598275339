import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetAssignedTask} from '../services/tasks.http'

export const useAssignedTask = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.ASSIGNED_TASK],
        queryFn: httpGetAssignedTask,
        staleTime: Infinity
    })
}
