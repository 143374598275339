import {ReactNode} from 'react'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/PageHeader/style.ts'

interface PageHeaderProps {
    title: ReactNode
    subtitle?: string | null
    ctaComponent?: ReactNode
}

const PageHeader = ({title, subtitle, ctaComponent}: PageHeaderProps) => {
    return (
        <StyledPageHeaderContainer>
            <StyledPageHeaderText>
                <h1>{title}</h1>
                {subtitle && <p>{subtitle}</p>}
            </StyledPageHeaderText>
            {ctaComponent && <div>{ctaComponent}</div>}
        </StyledPageHeaderContainer>
    )
}

export default PageHeader

PageHeader.displayName = 'PageHeader'
