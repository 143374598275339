import Button from '@/components/ui/Button'
import styled, {css} from 'styled-components'

export const StyledStatCard = styled.div`
    ${({theme: {spacing, palette}}) => css`
        width: 100%;
        height: 100%;
        border: 1px solid ${palette.neutral[200]};
        border-radius: 12px;
        background-color: ${palette.neutral.white};
        padding: ${spacing * 6}px;
    `}
`

export const StyledStatHeading = styled.h2`
    ${({theme: {typography, palette}}) => css`
        font-size: ${typography.textLg};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `}
`

export const StyledStatLabel = styled.h3`
    ${({theme: {typography, palette}}) => css`
        font-size: ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral[600]};
    `}
`

export const StyledEntityCount = styled.h3`
    ${({theme: {typography, palette}}) => css`
        font-size: ${typography.textMd};
        color: ${palette.neutral[600]};
    `}
`

export const StyledStatValue = styled.p`
    ${({theme: {typography, palette}}) => css`
        font-size: ${typography.displayXs};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `}
`

export const StyledTaskTypeButton = styled(Button)<{isSelected?: boolean}>`
    ${({theme: {palette}, isSelected}) => css`
        ${isSelected &&
        css`
            background-color: ${palette.primary[50]};
            color: ${palette.primary[700]};

            &:hover {
                background-color: ${palette.primary[50]};
                color: ${palette.primary[700]};
            }
        `}
    `}
`

export const StyledButtonGroup = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid ${palette.neutral[300]};

        button {
            cursor: pointer;
            flex-shrink: 0;
            padding: ${spacing * 2.5}px ${spacing * 4}px;
            display: grid;
            place-items: center;
            border-right: 1px solid ${palette.neutral[300]};
            background-color: ${palette.neutral.white};

            font-size: ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral[800]};
        }

        button:last-of-type {
            border-right: 0px;
        }

        button.selected {
            background-color: ${palette.neutral[50]};
        }
    `}
`
