import {FC} from 'react'
import {BatchRecord} from '../../types'
import {BatchesAutomaticAssignmentWrapper} from './style'
import {Flexbox} from '@/components/ui/Flexbox'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {AssignedVendorsList} from './AssignedVendorsList'

type BatchesAutomaticAssigntMentSidebarProps = {
    onClose: () => void
    batch: BatchRecord
}

export const BatchesAutomaticAssigntMentSidebar: FC<BatchesAutomaticAssigntMentSidebarProps> = ({onClose, batch}) => {
    const {t} = useTranslation()

    return (
        <BatchesAutomaticAssignmentWrapper>
            <Flexbox className="sidebar-header" direction="column" width={'100%'}>
                <h2>{t('batches:assign_batch')}</h2>
                <h3>{batch.name}</h3>
            </Flexbox>
            <Flexbox className="vendors-list-container" direction="column" width={'100%'}>
                <AssignedVendorsList batch={batch} />
            </Flexbox>
            <Flexbox className="cta-container" gap={2} justify="end">
                <Button onClick={onClose}>{t('commons:close')}</Button>
            </Flexbox>
        </BatchesAutomaticAssignmentWrapper>
    )
}
