import styled, {css} from 'styled-components'
import {StyledFlexbox} from '@components/ui/Flexbox/style.ts'

interface StyledProjectVendorsFormProps {
    extraRow?: boolean
}
export const StyledProjectVendorsForm = styled.form<StyledProjectVendorsFormProps>`
    ${({extraRow, theme: {typography, palette, spacing}}) => css`
        display: grid;
        grid-template-rows: auto auto 1fr auto;
        gap: ${spacing * 8}px;
        overflow-y: hidden;

        .selection {
            display: grid;
            gap: ${spacing * 8}px;
            grid-template-rows: ${extraRow ? '0.5fr 1fr' : '1fr'};
            overflow-y: auto;
        }
        h2 {
            font-size: ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral[700]};
        }
        h4 {
            font-size: ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral[700]};
            margin-bottom: ${spacing * 5}px;
        }
    `}
`

export const StyledProjectVendorsSelection = styled(StyledFlexbox)`
    ${({theme: {typography, palette}}) => css`
        h2 {
            font-size: ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
        p {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }
    `}
`

export const StyledProjectVendorsGrid = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: ${spacing * 5}px;
        grid-template-rows: auto;
        grid-row-gap: ${spacing * 8}px;
        width: 100%;
        overflow-y: auto;
        .vendor-choice {
            & > div {
                width: 100%;
                border-bottom: 1px ${palette.neutral[200]} solid;
                padding-bottom: ${spacing * 4}px;
            }
        }
    `}
`
