import {useTranslation} from 'react-i18next'
import {ArchiveIcon, DotsVerticalIcon, Edit01Icon, Trash01Icon} from '@components/ui/Icon'
import {formatLocaleDate} from '@utilities/helpers.ts'
import Table from '@components/commons/Table'
import DropdownMenu from '@components/ui/DropdownMenu'
import {useDeleteTemplate} from '@/features/templates/queries/useDeleteTemplate.ts'
import {useActiveTemplates} from '@/features/templates/queries/useActiveTemplates.ts'

export const ActiveTemplates = () => {
    const {t} = useTranslation()
    const query = useActiveTemplates()
    const deleteTemplateMutation = useDeleteTemplate()

    return (
        <Table
            columns={[
                {
                    label: t('templates:table:name'),
                    width: '17%',
                    cellRender: template => <span title={template?.name ?? '-'}>template?.name</span>
                },
                {
                    label: t('templates:table:workstream'),
                    width: '15%',
                    cellRender: template => template?.workstream ?? '-'
                },
                {
                    label: t('templates:table:json_link'),
                    width: '15%',
                    cellRender: template => template?.json_link
                },
                {
                    label: t('templates:table:projects_usage'),
                    width: '15%',
                    cellRender: template => template?.projects_usage
                },
                {
                    label: t('templates:table:created_on'),
                    width: '15%',
                    cellRender: template => <span>{formatLocaleDate(template?.created_at, 'MMM DD, YYYY')}</span>
                },
                {
                    label: t('templates:table:updated_on'),
                    width: '17%',
                    cellRender: template => <span>{formatLocaleDate(template?.updated_at, 'MMM DD, YYYY')}</span>
                },
                {
                    alignment: 'right',
                    width: '6%',
                    cellRender: template => (
                        <DropdownMenu
                            trigger={<DotsVerticalIcon />}
                            actions={[
                                {
                                    component: t('commons:edit'),
                                    icon: <Edit01Icon size={16} />,
                                    onClickCb: () => console.log(t('commons:edit'))
                                },
                                {
                                    component: t('commons:archive'),
                                    icon: <ArchiveIcon size={16} />,
                                    onClickCb: () => console.log(t('commons:archive'))
                                },
                                {
                                    component: t('commons:delete'),
                                    icon: <Trash01Icon size={16} />,
                                    disabled: template?.id === 1,
                                    onClickCb: () => deleteTemplateMutation.mutate(template?.id as number)
                                }
                            ]}
                        />
                    )
                }
            ]}
            data={query.remappedData}
            isChangingPage={query.isFetchingNextPage}
            isError={query.isError}
            isLoading={query.isLoading}
            onChangePage={query.fetchNextPage}
        />
    )
}

ActiveTemplates.displayName = 'ActiveTemplates'
