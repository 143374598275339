import {FC} from 'react'
import {BatchesAssignmentModal} from '.'
import {useUpdateProject} from '@/features/projects/queries/useUpdateProject'
import {useProject} from '@/features/projects/queries/useProject'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {useCompany} from '@/features/companies/queries/useCompany'

type ProjectBatchesAssignmentModalProps = {
    projectId: number
    companyId: number
    onClose: () => void
}

export const ProjectBatchesAssignmentModal: FC<ProjectBatchesAssignmentModalProps> = ({
    projectId,
    companyId,
    onClose
}) => {
    const {data, isLoading: isLoadingProject, error} = useProject(projectId)
    const {data: company, isLoading: isLoadingCompany, error: companyError} = useCompany(companyId)

    const {t} = useTranslation()

    const {mutate, isLoading: isLoadingUpdateProject} = useUpdateProject({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
            onClose()
        }
    })

    if (error || companyError) {
        throw error || companyError
    }

    if (isLoadingProject || isLoadingCompany || !company || !data) {
        return null
    }

    return (
        <BatchesAssignmentModal
            companyDefault={company.assignment_mode}
            defaultValue={data.assignment_mode}
            type="project"
            onClose={onClose}
            isLoading={isLoadingUpdateProject}
            onSubmit={data => mutate({id: projectId, data})}
        />
    )
}
