import {forwardRef, InputHTMLAttributes} from 'react'
import {StyledCheckBox, StyledCheckBoxContainer, StyledCheckBoxInputWrapper} from './style'
import {DefaultTFuncReturn} from 'i18next'
import InputHelpText from '@components/ui/InputHelpText'
import Label from '@components/ui/Label'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string
    label?: string | DefaultTFuncReturn
    labelPosition?: 'left' | 'right'
    name?: string
    errorMessage?: string | DefaultTFuncReturn
    helpText?: string | DefaultTFuncReturn
    placeholder?: string
    containerClass?: string
    invisible?: boolean
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            id,
            label,
            labelPosition = 'right',
            errorMessage,
            helpText,
            name,
            onChange,
            onBlur,
            containerClass,
            invisible,
            ...rest
        },
        ref
    ) => {
        return (
            <StyledCheckBoxContainer label={label} className={containerClass}>
                <StyledCheckBoxInputWrapper labelPosition={labelPosition}>
                    <StyledCheckBox
                        type="checkbox"
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...rest}
                        ref={ref}
                        invisible={invisible}
                    />
                </StyledCheckBoxInputWrapper>
                <CheckBoxText id={id} label={label} errorMessage={errorMessage} helpText={helpText} />
            </StyledCheckBoxContainer>
        )
    }
)

const CheckBoxText = ({id, label, helpText, errorMessage}: CheckboxProps) => (
    <div>
        <Label htmlFor={id}> {label} </Label>
        <InputHelpText helpText={helpText} error={errorMessage} />
    </div>
)

Checkbox.displayName = 'Checkbox'
export default Checkbox
