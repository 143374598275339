import {StyledForm} from '@pages/project-details/settings/style.ts'
import {ProjectSettingsForm} from '@pages/project-details/settings/form/ProjectSettingsForm.tsx'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {ProjectStepActions} from '@/features/projects/components/project-step-actions/ProjectStepActions.tsx'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    ProjectSettingsFormSchema,
    ProjectSettingsValidationSchema
} from '@pages/project-details/settings/form/settingsFormModel.ts'
import {PROJECTS_HEADER_OBJ} from '@utilities/constants'
import {useNavigate, useParams} from 'react-router-dom'
import {useUpdateProject} from '@/features/projects/queries/useUpdateProject.ts'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {useProject} from '@/features/projects/queries/useProject.ts'
import {UnsavedBlocker} from '@components/commons/UnsavedBlocker'
import {ProjectRecord} from '@/features/projects/types.ts'
import {FC} from 'react'
import {UserRecord, UsersSearchParams} from '@/features/users/types.ts'
import useUsers from '@/features/users/queries/useUsers.ts'
import {SelectValue} from '@components/commons/Select'
import {retrieveSelectMultiValues} from '@utilities/helpers.ts'
import {ProjectSettingsContentLoader} from '@/features/projects/components/project-content-loaders/ProjectSettingsContentLoader.tsx'

export const ProjectSettings = () => {
    const {id} = useParams()
    const {data: project, isLoading: isLoadingProject, error: projectError} = useProject(id)
    const {
        remappedData: contactUsers,
        isLoading: isLoadingContactUsers,
        error: contactUsersError
    } = useUsers(3, UsersSearchParams['client_engineer'])
    const isLoading = isLoadingProject || isLoadingContactUsers
    const isError = projectError || contactUsersError
    return (
        <>
            {isLoading && <ProjectSettingsContentLoader />}
            {isError && <>Something went wrong.</>}
            {!isLoading && project && <ProjectSettingsWithData project={project} contactUsersOptions={contactUsers} />}
        </>
    )
}

type ProjectSettingsWithDataProps = {
    project: ProjectRecord
    contactUsersOptions: UserRecord[]
}
const ProjectSettingsWithData: FC<ProjectSettingsWithDataProps> = ({project, contactUsersOptions}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const optionsRetrieved = retrieveSelectMultiValues(
        contactUsersOptions.map(e => ({label: e.full_name, value: e.id.toString()} as SelectValue)),
        project.contact_users?.map(e => e.id?.toString())
    )
    const methods = useForm<ProjectSettingsValidationSchema>({
        mode: 'onBlur',
        defaultValues: {
            name: project?.name ?? '',
            workstream_id: {label: 'Labeling', value: '1'},
            template_id: project.template_id || undefined,
            contact_users: optionsRetrieved as SelectValue[],
            objective: project.objective ?? '',
            budget: project.budget,
            priority: project.priority ?? null
        },
        resolver: zodResolver(ProjectSettingsFormSchema)
    })
    const {
        handleSubmit,
        formState: {isDirty, isSubmitSuccessful}
    } = methods

    const updateProjectMutation = useUpdateProject({
        onSuccess: () => {
            toast.success(t('projectDetails:settings:success'))
            methods.reset(methods.getValues()) //reset the isDirty field
        }
    })

    const onSubmit: SubmitHandler<ProjectSettingsValidationSchema> = data => {
        updateProjectMutation.mutate({
            id: project.id.toString(),
            data: {
                name: data.name,
                workstream_id: +data.workstream_id.value,
                template_id: +data.template_id,
                contact_users: data.contact_users?.map(item => item.value),
                objective: data.objective,
                budget: data.budget,
                priority: data.priority
            }
        })
    }

    const onClose = () => {
        navigate(PROJECTS_HEADER_OBJ.path)
        console.log('on close callback')
    }

    return (
        <UnsavedBlocker isDirty={isDirty && !isSubmitSuccessful} message={t('commons:unsavedAlert')}>
            <FormProvider {...methods}>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <ProjectSettingsForm contactUsersOptions={contactUsersOptions} />
                    <ProjectStepActions onCloseCb={onClose} isLoading={updateProjectMutation.isLoading} />
                </StyledForm>
            </FormProvider>
        </UnsavedBlocker>
    )
}

ProjectSettings.displayName = 'ProjectSettings'
