import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {
    StyledInstructions,
    StyledButtons
} from '@/features/users/components/ManageUsersModal/components/ManageUsersPendingUpload/style.ts'
import Button from '@components/ui/Button'
import {DownloadCloud02Icon} from '@components/ui/Icon'
import {ManageUsersDropzone} from '@/features/users/components/ManageUsersModal/components/ManageUsersDropzone'
import {useDropzone} from 'react-dropzone'
import {Slug} from '@/types/auth.ts'
import {roleSlugToID, usersTabs} from '@/features/roles/helpers.tsx'
import {Flexbox} from '@components/ui/Flexbox'
import {useAddUsers} from '@/features/users/queries/useAddUsers.ts'
import {useRoles} from '@/features/roles/queries/useRoles.ts'
import {AddUsersResponse, httpExportExample} from '@/features/users/services/users.http.ts'
import {buildTSV, downloadFile, megabytesToBytes} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'

const roleSlugToInstructionList = {
    program_manager: [
        'users:instructions_list_first_name',
        'users:instructions_list_last_name',
        'users:instructions_list_email'
    ],
    client_engineer: [
        'users:instructions_list_first_name',
        'users:instructions_list_last_name',
        'users:instructions_list_email'
    ],
    vendor_admin: [
        'users:instructions_list_first_name',
        'users:instructions_list_last_name',
        'users:instructions_list_email',
        'users:instructions_list_vendor_name'
    ],
    vendor_manager: [
        'users:instructions_list_first_name',
        'users:instructions_list_last_name',
        'users:instructions_list_email',
        'users:instructions_list_vendor_name'
    ],
    team_lead: [
        'users:instructions_list_first_name',
        'users:instructions_list_last_name',
        'users:instructions_list_email',
        'users:instructions_list_vendor_name',
        'users:instructions_list_supervisor_email'
    ],
    labeler: [
        'users:instructions_list_first_name',
        'users:instructions_list_last_name',
        'users:instructions_list_email',
        'users:instructions_list_vendor_name',
        'users:instructions_list_supervisor_email'
    ]
} as const satisfies Partial<Record<Slug, ReadonlyArray<string>>>

export const MANAGE_USERS_DROPZONE_MAX_MEGABYTES = 10 as const

interface ManageUsersDefaultProps {
    onClose: () => void
    roleSlug: keyof typeof usersTabs
    setUploadResults: (response: AddUsersResponse) => void
}

export const ManageUsersPendingUpload: FC<ManageUsersDefaultProps> = ({onClose, roleSlug, setUploadResults}) => {
    const {t} = useTranslation()
    const rolesQuery = useRoles()
    const addUsersMutation = useAddUsers({
        onSuccess: (response: AddUsersResponse) => {
            setUploadResults(response)
        }
    })
    const dropzone = useDropzone({
        accept: {
            'text/tsv': ['.tsv']
        },
        maxSize: megabytesToBytes(MANAGE_USERS_DROPZONE_MAX_MEGABYTES)
    })

    const upload = () => {
        const roleID = roleSlugToID(rolesQuery.data, roleSlug)

        if (roleID) {
            const formData = new FormData()
            formData.append('file', dropzone.acceptedFiles[0])
            formData.append('role_id', roleID.toString())
            addUsersMutation.mutate(formData)
        }
    }

    const downloadExample = async () => {
        try {
            const {data} = await httpExportExample(roleSlugToID(rolesQuery.data, roleSlug))
            downloadFile(buildTSV(`${roleSlug}_example`, data))
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            <Flexbox direction="column" gap={1} className="header">
                <h2>{t('users:manage_users')}</h2>
                <h3>{t('users:manage_users_subtitle')}</h3>
            </Flexbox>
            <ManageUsersDropzone dropzoneState={dropzone} />
            <StyledInstructions direction="column" gap={4}>
                <h4>{t('users:instructions')}</h4>
                <p>{t('users:instructions_subtitle')}</p>
                <ul>
                    {roleSlugToInstructionList[roleSlug].map(translationKey => (
                        <li key={translationKey}>
                            <Trans i18nKey={translationKey} components={[<strong />]} />
                        </li>
                    ))}
                </ul>
            </StyledInstructions>
            <StyledButtons justify="space-between">
                <Button variant="ghost" size="lg" onClick={downloadExample}>
                    <DownloadCloud02Icon /> {t('users:tsv_example')}
                </Button>
                <Flexbox justify="flex-end" gap={3}>
                    <Button variant="secondary" size="lg" onClick={onClose}>
                        {t('commons:cancel')}
                    </Button>
                    <Button size="lg" disabled={!dropzone.acceptedFiles.length} onClick={upload}>
                        {t('commons:upload')}
                    </Button>
                </Flexbox>
            </StyledButtons>
        </>
    )
}

ManageUsersPendingUpload.displayName = 'ManageUsersPendingUpload'
