import {useQuery} from '@tanstack/react-query'
import {httpGetDataService} from '../services/dataServices.http'
import {QUERY_KEYS} from '@/queryClient'

export const useDataService = (
    id: number,
    options?: {
        enabled?: boolean
    }
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.DATASERVICE, id],
        queryFn: () => httpGetDataService(id),
        ...options
    })
}
