import {forwardRef, ReactNode, TextareaHTMLAttributes} from 'react'
import Label from '@components/ui/Label'
import InputHelpText from '@components/ui/InputHelpText'
import {DefaultTFuncReturn} from 'i18next'
import {
    StyledTextarea,
    StyledTextareaContainer,
    StyledTextareaGrid,
    StyledTextareaWrapper
} from '@components/commons/TextArea/style.ts'

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    className?: string
    label?: string | DefaultTFuncReturn
    name?: string
    typeIcon?: ReactNode
    touched?: boolean
    errorMessage?: string | DefaultTFuncReturn
    helpText?: string | DefaultTFuncReturn
    placeholder?: string
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {label, name, typeIcon, touched = false, errorMessage, helpText, className, disabled, placeholder, ...rest},
        ref
    ) => {
        return (
            <StyledTextareaContainer className={className}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <StyledTextareaGrid>
                    <StyledTextareaWrapper touched={touched} hasError={!!errorMessage} disabled={disabled}>
                        {typeIcon}
                        <StyledTextarea ref={ref} name={name} placeholder={placeholder} disabled={disabled} {...rest} />
                    </StyledTextareaWrapper>
                </StyledTextareaGrid>
                {/*help text*/}
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledTextareaContainer>
        )
    }
)

export default TextArea

TextArea.displayName = 'TextArea'
