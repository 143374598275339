import {FC} from 'react'
import {StyledEmptyPlaceholder} from '@components/commons/Table/EmptyPlaceholder/style.ts'
import {useTranslation} from 'react-i18next'

const EmptyPlaceholder: FC = () => {
    const {t} = useTranslation()

    return <StyledEmptyPlaceholder>{t('commons:no_results')}</StyledEmptyPlaceholder>
}

EmptyPlaceholder.displayName = 'DesktopTableEmptyPlaceholder'
export default EmptyPlaceholder
