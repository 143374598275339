import {FC} from 'react'
import {DataServiceFormProps} from './types'
import {FormWrapper, StyledForm} from './styles'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {FormControls} from './FormControls'
import InputText from '@/components/commons/InputText'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useCreateDataService} from '../../queries/useCreateDataService'
import toast from 'react-hot-toast'
import {GetDataServiceResponse} from '../../services/dataServices.http'
import {useEditDataService} from '../../queries/useEditDataSerivce'
import TextArea from '@/components/commons/TextArea'

const validationSchema = z.object({
    bucket_name: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    storage_name: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    bucket_prefix: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    type: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    auth_uri: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required')
        .url('errors:url'),
    client_id: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    token_uri: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required')
        .url('errors:url'),
    project_id: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    private_key: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    client_email: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required')
        .email('errrors:email'),
    private_key_id: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    universe_domain: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    client_x509_cert_url: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    auth_provider_x509_cert_url: z
        .string({
            invalid_type_error: 'errors:required',
            required_error: 'errors:required'
        })
        .nonempty('errors:required'),
    file_filter_regex: z.string().optional()
})

type FormValues = z.infer<typeof validationSchema>

const getDefaultValues = (data: GetDataServiceResponse) => {
    if (data.data_config.type !== 'google_cloud') {
        return {}
    }
    const {storage_name, bucket_name, bucket_prefix, credentials} = data.data_config.configuration
    return {storage_name, bucket_name, bucket_prefix, ...credentials}
}

export const GoogleCloudForm: FC<DataServiceFormProps> = ({onCancel, onSuccess, data, alwaysCreate, showFileRegex}) => {
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: zodResolver(validationSchema),
        defaultValues: data ? getDefaultValues(data) : undefined
    })
    const {t} = useTranslation()

    const {mutate: createDataService, isLoading: isLoadingCreateDataService} = useCreateDataService({
        onSuccess: data => onSuccess?.(data),
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {mutate: editDataService, isLoading: isLoadingEditDataService} = useEditDataService({
        onSuccess: data => onSuccess?.(data),
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const isLoading = isLoadingCreateDataService || isLoadingEditDataService

    const onSubmit = (values: FormValues) => {
        const {bucket_name, storage_name, bucket_prefix, file_filter_regex, ...rest} = values
        const body = {
            bucket_name,
            storage_name,
            bucket_prefix,
            file_filter_regex: file_filter_regex || undefined,
            credentials: rest
        }

        if (data && !alwaysCreate) {
            editDataService({
                id: data.id,
                company_id: 1,
                data_config: {
                    id: data.data_config.id,
                    type: 'google_cloud',
                    configuration: body
                }
            })
            return
        }

        createDataService({
            company_id: 1,
            data_config: {
                type: 'google_cloud',
                configuration: body
            }
        })
    }

    return (
        <StyledForm
            onSubmit={e => {
                handleSubmit(onSubmit)(e)
                e.stopPropagation()
            }}
        >
            <FormWrapper>
                <div className="form-fields-section">
                    <div className="two-columns-row">
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:bucketName')}
                            errorMessage={t(errors.bucket_name?.message ?? '')}
                            {...register('bucket_name')}
                        />
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:bucketPrefix')}
                            errorMessage={t(errors.bucket_prefix?.message ?? '')}
                            {...register('bucket_prefix')}
                        />
                    </div>
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:storageName')}
                        errorMessage={t(errors.storage_name?.message ?? '')}
                        {...register('storage_name')}
                    />
                    <InputText
                        type="text"
                        label={t('commons:type')}
                        errorMessage={t(errors.type?.message ?? '')}
                        {...register('type')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:auth_uri')}
                        errorMessage={t(errors.auth_uri?.message ?? '')}
                        {...register('auth_uri')}
                    />

                    <InputText
                        type="text"
                        label={t('projectDetails:properties:clientId')}
                        errorMessage={t(errors.client_id?.message ?? '')}
                        {...register('client_id')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:token_uri')}
                        errorMessage={t(errors.token_uri?.message ?? '')}
                        {...register('token_uri')}
                    />

                    <InputText
                        type="text"
                        label={t('projectDetails:properties:project_id')}
                        errorMessage={t(errors.project_id?.message ?? '')}
                        {...register('project_id')}
                    />
                    <TextArea
                        rows={3}
                        label={t('projectDetails:properties:private_key')}
                        errorMessage={t(errors.private_key?.message ?? '')}
                        {...register('private_key')}
                    />

                    <InputText
                        type="text"
                        label={t('projectDetails:properties:client_email')}
                        errorMessage={t(errors.client_email?.message ?? '')}
                        {...register('client_email')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:private_key_id')}
                        errorMessage={t(errors.private_key_id?.message ?? '')}
                        {...register('private_key_id')}
                    />

                    <InputText
                        type="text"
                        label={t('projectDetails:properties:universe_domain')}
                        errorMessage={t(errors.universe_domain?.message ?? '')}
                        {...register('universe_domain')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:client_x509_cert_url')}
                        errorMessage={t(errors.client_x509_cert_url?.message ?? '')}
                        {...register('client_x509_cert_url')}
                    />
                    <InputText
                        type="text"
                        label={t('projectDetails:properties:auth_provider_x509_cert_url')}
                        errorMessage={t(errors.auth_provider_x509_cert_url?.message ?? '')}
                        {...register('auth_provider_x509_cert_url')}
                    />
                    {showFileRegex && (
                        <InputText
                            type="text"
                            label={t('projectDetails:properties:file_filter_regex')}
                            errorMessage={t(errors.file_filter_regex?.message ?? '')}
                            {...register('file_filter_regex')}
                        />
                    )}
                </div>
            </FormWrapper>
            <FormControls onCancel={onCancel} isConfirmLoading={isLoading} />
        </StyledForm>
    )
}
