import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@components/ui/Button'
import InputText from '@components/commons/InputText'
import {CurrencyDollarIcon} from '@components/ui/Icon'
import {useTranslation} from 'react-i18next'
import {
    VENDOR_ADMIN_FORM_MODEL,
    VendorAdminFromSchema,
    VendorAdminValidationSchema
} from '@/features/settings/components/vendor-admin-settings/VendorAdminFormModel.ts'
import {SettingsFormWrapper} from '@/features/settings/components/styles.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import {useSettings} from '@/features/settings/queries/useSettings.ts'
import Spinner from '@components/ui/Spinner'
import {useEffect} from 'react'
import {useUpdateSettings} from '@/features/settings/queries/useUpdateSettings.ts'
import toast from 'react-hot-toast'

export const VendorAdminSettings = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const {data, isLoading} = useSettings(Number(user?.company_id))

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, touchedFields}
    } = useForm<VendorAdminValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(VendorAdminFromSchema)
    })

    useEffect(() => {
        reset({
            name: data?.name.toString() || '',
            unit_capacity: data?.unit_capacity.toString() || '',
            cost_unit: data?.cost_unit.toString() || ''
        })
    }, [data])

    const {mutate: updateSettings} = useUpdateSettings({
        onSuccess: () => {
            toast.success(t('commons:genericSuccess'))
        },
        onError: () => {
            toast.error(t('errors:genericError'))
        }
    })
    /**
     * todo: here the remaining steps are
     *
     * - add the right fields when the design is updated
     */

    const onSubmit: SubmitHandler<VendorAdminValidationSchema> = data => {
        updateSettings({
            id: Number(user?.company_id),
            data: {
                ...data,
                cost_unit: Number(data?.cost_unit),
                unit_capacity: Number(data?.unit_capacity)
            }
        })
    }

    return (
        <>
            {isLoading && <Spinner size={16} />}
            <SettingsFormWrapper onSubmit={handleSubmit(onSubmit)}>
                <InputText
                    label={t(VENDOR_ADMIN_FORM_MODEL.name.label)}
                    type={'text'}
                    touched={touchedFields.name}
                    errorMessage={t(errors.name?.message || '')}
                    placeholder={t(VENDOR_ADMIN_FORM_MODEL.name.label).toString()}
                    {...register(t(VENDOR_ADMIN_FORM_MODEL.name.name))}
                />
                <InputText
                    label={t(VENDOR_ADMIN_FORM_MODEL.unit_capacity.label)}
                    type={'text'}
                    touched={touchedFields.unit_capacity}
                    errorMessage={t(errors.unit_capacity?.message || '')}
                    placeholder={t(VENDOR_ADMIN_FORM_MODEL.unit_capacity.label).toString()}
                    {...register(t(VENDOR_ADMIN_FORM_MODEL.unit_capacity.name))}
                />
                <InputText
                    label={t(VENDOR_ADMIN_FORM_MODEL.cost_unit.label)}
                    type={'text'}
                    touched={touchedFields.cost_unit}
                    errorMessage={t(errors.cost_unit?.message || '')}
                    placeholder={t(VENDOR_ADMIN_FORM_MODEL.cost_unit.label).toString()}
                    {...register(t(VENDOR_ADMIN_FORM_MODEL.cost_unit.name))}
                    typeIcon={<CurrencyDollarIcon size={20} />}
                />

                <Button type={'submit'} variant={'primary'} size={'lg'}>
                    {t('commons:save')}
                </Button>
            </SettingsFormWrapper>
        </>
    )
}

VendorAdminSettings.displayName = 'VendorAdminSettings'
