import Container from '@components/ui/Container'
import toast from 'react-hot-toast'
import Button from '@components/ui/Button'
import HomeForm from '@pages/home/components/HomeForm'
import PageHeader from '@components/commons/PageHeader'
import {useTranslation} from 'react-i18next'
import {ArchiveIcon, ChevronRightIcon} from '@components/ui/Icon'

export const Home = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <PageHeader
                title={t('home:title')}
                subtitle={t('home:subtitle')}
                ctaComponent={
                    <Button size={'sm'} variant={'primary'} onClick={() => toast.success('Here is your toast.')}>
                        <ArchiveIcon data-color />
                        Make me a toast
                        <ChevronRightIcon data-color />
                    </Button>
                }
            />
            <HomeForm />
        </Container>
    )
}

Home.displayName = 'Home'
