import InputSelect, {SelectValue} from '@/components/commons/Select'
import {ComponentProps, useDeferredValue, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useUser} from '../../queries/useUser'
import {useUsersSelect} from '../../queries/useUsersSelect'
import {SingleValue} from 'react-select'

type TeamLeadsSelectProps = Omit<
    ComponentProps<typeof InputSelect>,
    'options' | 'placeholder' | 'inputValue' | 'onInputChange' | 'value' | 'onChange' | 'isMulti'
> & {onChange: (newVal: number | null) => void; value: number | null}

export const TeamLeadsSelect = ({value, onChange, ...props}: TeamLeadsSelectProps) => {
    const {t} = useTranslation()

    const {data: user, error: userError} = useUser(value as number, {enabled: !!value})

    const [inputValue, setInputValue] = useState('')
    const deferedSearch = useDeferredValue(inputValue)

    const {data} = useUsersSelect({roleId: 6, searchValue: deferedSearch})

    if (userError) {
        //TODO handle get user error
    }

    let options = data?.data?.map(user => ({
        value: user?.id.toString() || '',
        label: user?.full_name || ''
    }))

    if (user && options && !options.map(opt => opt.value).includes(user.id.toString())) {
        options = [{value: user.id.toString(), label: user.full_name || ''}, ...options]
    }

    const selectValue = options?.find(opt => opt.value === value?.toString())

    return (
        <InputSelect
            {...props}
            className="select"
            isMulti={false}
            value={selectValue}
            onChange={newValue => {
                const value = (newValue as SingleValue<SelectValue> | undefined)?.value
                const parsedNewValue = value ? Number(value) : null
                onChange(parsedNewValue)
            }}
            placeholder={t('users:select_team_lead')}
            options={options ?? []}
            isSearchable
            inputValue={inputValue}
            onInputChange={newText => setInputValue(newText)}
        />
    )
}
