import {FC, useState} from 'react'
import {StyledManageUsersModal} from '@/features/users/components/ManageUsersModal/style.ts'
import {usersTabs} from '@/features/roles/helpers.tsx'
import {ManageUsersResult} from 'src/features/users/components/ManageUsersModal/components/ManageUsersResult'
import {ManageUsersPendingUpload} from 'src/features/users/components/ManageUsersModal/components/ManageUsersPendingUpload'
import {AddUsersResponse} from '@/features/users/services/users.http.ts'

interface ManageUsersModalProps {
    isOpen: boolean
    onClose: () => void
    roleSlug: keyof typeof usersTabs
}

export const ManageUsersModal: FC<ManageUsersModalProps> = ({isOpen, onClose: close, roleSlug}) => {
    const [uploadResults, setUploadResults] = useState<AddUsersResponse | null>(null)

    const onClose = () => {
        setUploadResults(null)
        close()
    }

    if (isOpen) {
        return (
            <StyledManageUsersModal width={480} onClose={onClose}>
                {uploadResults ? (
                    <ManageUsersResult onClose={onClose} uploadResults={uploadResults} />
                ) : (
                    <ManageUsersPendingUpload
                        setUploadResults={setUploadResults}
                        onClose={onClose}
                        roleSlug={roleSlug}
                    />
                )}
            </StyledManageUsersModal>
        )
    }

    return null
}
ManageUsersModal.displayName = 'ManageUsersModal'
