import {Modal} from '@/components/ui/Modal'
import styled, {css} from 'styled-components'

export const StyledModal = styled(Modal)`
    ${({theme: {spacing, typography, palette}}) => css`
        width: 480px;

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: ${spacing * 5}px;
        }

        .header {
            padding: ${spacing * 8}px ${spacing * 8}px 0 ${spacing * 8}px;

            h2 {
                font-size: ${typography.textLg};
                font-weight: 600;
                color: ${palette.neutral[900]};
            }

            p {
                font-size: ${typography.textSm};
                color: ${palette.neutral[600]};
            }
        }

        .body {
            padding: 0 ${spacing * 8}px;
        }

        .media-sources-container {
            padding: ${spacing * 4}px;
            border: 1px solid ${palette.neutral[200]};
            border-radius: 12px;

            .text-container {
                h2 {
                    display: flex;
                    flex-direction: column;
                    font-size: ${typography.textSm};
                    font-weight: 600;
                    color: ${palette.neutral[900]};

                    & > small {
                        font-weight: 600;
                        font-size: ${typography.textXs};
                        color: ${palette.neutral[400]};
                    }
                }

                p {
                    font-size: ${typography.textSm};
                    color: ${palette.neutral[600]};
                }
            }
        }

        .radio-container {
            .text-container {
                display: flex;
                flex-direction: column;

                h2 {
                    font-size: ${typography.textSm};
                    font-weight: 500;
                    color: ${palette.neutral[900]};
                }

                p {
                    font-size: ${typography.textSm};
                    color: ${palette.neutral[600]};
                }
            }
        }

        .radio-with-field-container {
            gap: ${spacing * 2}px;

            .field {
                padding-left: ${spacing * 7}px;
            }
        }

        .footer {
            padding: ${spacing * 8}px;
            display: flex;
            padding-top: ${spacing * 8}px;
            margin-top: ${spacing * 4}px;
            border-top: 1px solid ${palette.neutral[200]};
        }
    `}
`
