import {Flexbox} from '@/components/ui/Flexbox'
import styled, {css} from 'styled-components'

export const BatchesManualAssignmentWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .sidebar-header {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 8}px ${spacing * 6}px;
        }

        .search-section {
            padding: 0 ${spacing * 6}px;

            .searchbar {
                width: 253px;
                flex-shrink: 0;
            }
        }

        .sorting-section,
        .existing-selection-section {
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        .vendors-list-container {
            flex-grow: 1;
            overflow-y: auto;
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        .cta-container {
            border-top: 1px solid ${palette.neutral[200]};
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        h2 {
            font-size: ${typography.textXl};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }

        h3 {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }

        .assigned-title,
        .vendors-title {
            font-size: ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `}
`

export const BatchesAutomaticAssignmentWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .sidebar-header {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 8}px ${spacing * 6}px;
        }

        .vendors-list-container {
            flex-grow: 1;
            overflow-y: auto;
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        .cta-container {
            border-top: 1px solid ${palette.neutral[200]};
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        h2 {
            font-size: ${typography.textXl};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }

        h3 {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }

        .no-vendors-title {
            font-size: ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }
        .no-vendors-subtitle {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }
    `}
`

export const BatchesAlreadyAssignedWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .sidebar-header {
            padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 8}px ${spacing * 6}px;
        }

        .cta-container {
            border-top: 1px solid ${palette.neutral[200]};
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        .vendors-list-container {
            flex-grow: 1;
            overflow-y: auto;
            padding: ${spacing * 4}px ${spacing * 6}px;
        }

        h2 {
            font-size: ${typography.textXl};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }

        h3 {
            font-size: ${typography.textSm};
            color: ${palette.neutral[600]};
        }

        .already-assignment-alert-container {
            padding: ${spacing * 4}px;
        }

        .already-assignment-alert {
            border: 1px solid ${palette.neutral[200]};
            border-radius: 8px;
            padding: ${spacing * 4}px;
        }

        .alert-icon-container {
            background: ${palette.warning[100]};
            color: ${palette.warning[600]};
            border-radius: 100%;
            width: 48px;
            aspect-ratio: 1/1;
            display: grid;
            place-items: center;
        }

        .already-assigned-title {
            font-size: ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }

        .already-assigned-subtitle {
            font-size: ${typography.textSm};
            color: ${palette.neutral[900]};
        }
    `}
`

export const VendorListItemWrapper = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        .vendor-name {
            font-size: ${typography.textSm};
            color: ${palette.neutral[500]};
            font-weight: 500;
        }

        .workload {
            font-size: ${typography.textSm};
            color: ${palette.neutral[500]};
        }
    `}
`

export const StyledVendorManagerItem = styled(Flexbox)<{isSelected?: boolean; isSelectable?: boolean}>`
    ${({theme: {spacing, palette, typography}, isSelected, isSelectable}) => css`
        ${isSelectable &&
        css`
            cursor: pointer;
        `};

        padding: ${spacing * 2}px;

        ${isSelected
            ? css`
                  background-color: ${palette.primary[50]};
                  border: 1px solid ${palette.primary[200]};
                  border-radius: 8px;
              `
            : css`
                  border-bottom: 1px solid ${palette.neutral[200]};
              `}

        .vendor-manager-name {
            font-size: ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[700]};
        }

        .vendor-manager-email {
            font-size: ${typography.textSm};
            color: ${palette.neutral[500]};
        }

        .check-icon-container {
            color: ${palette.primary[600]};
        }
    `}
`

export const StyledExistingSelection = styled(Flexbox)`
    ${({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 8px;
        background-color: ${palette.primary[50]};
        border: 1px solid ${palette.primary[200]};
        .existing-selection-title {
            font-size: ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[700]};
        }
        .existing-selection-subtitle {
            font-size: ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral[500]};
        }
    `}
`
