import React, {createContext, PropsWithChildren, Reducer, useCallback, useContext, useEffect, useReducer} from 'react'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@utilities/constants'
import {useAsync} from '@hooks/useAsync.ts'
import {AuthState, LoginFormData, User} from '@/types/auth.ts'
import {PartialBy} from '@/types/utils.ts'
import {httpLogin, httpLogout, httpSSOGetUser, httpUserMe} from '@services/auth.http.ts'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {subscribe, unsubscribe} from '@utilities/helpers.ts'
import {sessionCookie} from '@services/sessionCookie.ts'

type ReducerState = AuthState | Partial<AuthState> | PartialBy<AuthState, 'user'> | PartialBy<AuthState, 'refreshToken'>

type ReducerAction =
    | {
          type: 'SET_USER'
          payload: ReducerState
      }
    | {
          type: 'UPDATE_USER'
          payload: {
              user: User
          }
      }
    | {
          type: 'RESET_USER'
          payload: ReducerState
      }
    | {
          type: 'REFRESH_TOKENS'
          payload: {
              accessToken: string
              refreshToken: string
          }
      }

type AuthContextType = ReducerState & {
    dispatch?: React.Dispatch<ReducerAction>
    getAuthenticatedSSOUser?: (SSOCode: string | null) => void
    login?: (data: LoginFormData) => void
    logout?: () => void
    userMe?: () => void
    isLoading: boolean
    isError: boolean
}

const authInitialState = {
    accessToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || undefined,
    refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || undefined,
    isLoading: false,
    isError: false,
    user: null
}
export const AuthContext = createContext<AuthContextType>(authInitialState)
const authReducer: Reducer<ReducerState, ReducerAction> = (state, action) => ({
    ...state,
    ...action.payload
})

const AuthProvider = ({children}: PropsWithChildren) => {
    const [auth, dispatch] = useReducer(authReducer, authInitialState)
    const {run, isLoading, isError} = useAsync()
    const {t} = useTranslation()

    const getAuthenticatedSSOUser = useCallback(
        async (SSOCode?: string | null) => {
            if (!SSOCode) {
                throw new Error('code is required')
            }
            try {
                const {
                    data: {user, tokens}
                } = await run(httpSSOGetUser(SSOCode))
                dispatch({
                    type: 'SET_USER',
                    payload: {
                        user: user,
                        accessToken: tokens.accessToken,
                        refreshToken: tokens.refreshToken
                    }
                })
                localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, tokens.accessToken)
                localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, tokens.refreshToken)
            } catch (e) {
                toast.error(t('errors:default'))
            }
        },
        [run]
    )

    const login = useCallback(
        async (formData: LoginFormData) => {
            try {
                const {
                    data: {user, tokens}
                } = await run(httpLogin(formData))
                dispatch({
                    type: 'SET_USER',
                    payload: {
                        user: user,
                        accessToken: tokens.accessToken,
                        refreshToken: tokens.refreshToken
                    }
                })
                localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, tokens.accessToken)
                localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, tokens.refreshToken)
            } catch (e) {
                //todo: replace with error handler func
                console.log(e)
            }
        },
        [run]
    )

    const userMe = useCallback(async () => {
        if (!auth.accessToken) {
            //guard condition
            throw new Error('You cannot call a me without a valid access token')
        }
        try {
            const res = await run(httpUserMe())
            dispatch({type: 'UPDATE_USER', payload: {user: res}})
            if (res.role.slug == 'labeler') {
                sessionCookie.set()
            }
        } catch (e) {
            //todo: replace with error handler func
            console.log(e)
        }
    }, [run, auth.accessToken])

    const logout = useCallback(async () => {
        if (!auth.accessToken) {
            //guard condition
            throw new Error('You cannot logout without a valid access token')
        }
        try {
            await run(httpLogout())
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
            dispatch({type: 'RESET_USER', payload: {user: null, accessToken: undefined, refreshToken: undefined}})
            sessionCookie.remove()
        } catch (e) {
            //todo: replace with error handler func
            console.log(e)
        }
    }, [run, auth.accessToken])

    useEffect(() => {
        if (auth.accessToken) {
            userMe()
        }
    }, [userMe, auth.accessToken])

    useEffect(() => {
        subscribe('REFRESH_TOKENS', () =>
            dispatch({
                type: 'REFRESH_TOKENS',
                payload: {
                    accessToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) as string,
                    refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) as string
                }
            })
        )

        subscribe('RESET_USER', () => {
            dispatch({
                type: 'RESET_USER',
                payload: {
                    user: null,
                    accessToken: undefined,
                    refreshToken: undefined
                }
            })
            sessionCookie.remove()
        })

        return () => {
            unsubscribe('REFRESH_TOKENS', Function.prototype())
            unsubscribe('RESET_USER', Function.prototype())
        }
    }, [])

    return (
        <AuthContext.Provider
            value={
                {
                    ...auth,
                    dispatch,
                    getAuthenticatedSSOUser,
                    login,
                    logout,
                    userMe,
                    isError,
                    isLoading
                } satisfies AuthContextType
            }
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = (): AuthContextType => useContext(AuthContext)

export default AuthProvider
