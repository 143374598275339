import * as Progress from '@radix-ui/react-progress'
import styled, {css} from 'styled-components'

export const StyledProgressRoot = styled(Progress.Root)`
    ${({theme: {palette}}) => css`
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background-color: ${palette.neutral[200]};
        overflow: hidden;
    `}
`

export const StyledProgressIndicator = styled(Progress.Indicator)<{progress: number}>`
    ${({theme: {palette}, progress}) => css`
        transform: translateX(-${100 - progress}%);
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transition: all 0.2s ease-out;
        background-color: ${palette.primary[600]};
    `}
`
