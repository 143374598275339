import axios from '@services/axiosInstance.ts'
import {User} from '@/types/auth.ts'
import {AxiosResponse} from 'axios'

export const httpGetCurrentSettings = (id: number) => {
    return axios.get(`companies/${id}`).then(response => response.data)
}

interface EditSettingsData {
    name: string
    unit_capacity: number
    cost_unit: number
}

interface EditSettingsArgs {
    id: number
    data: EditSettingsData
}

interface UpdateSettingsPayload {
    task_type_ids: number[]
    process_type_ids: number[]
}

export const httpUpdateCurrentSettings = ({id, data}: EditSettingsArgs) => {
    //TODO: add a response type into then?
    return axios.patch(`companies/${id}`, data)
}

type HttpGetProcessTypesResponse = Array<{
    created_at: string
    deleted_at: string | null
    id: number
    modality: 'image'
    modality_type: 'Image boxing' | 'Image segmentation'
    updated_at: string
}>
export const httpGetProcessTypes = () => {
    return axios.get(`process-types`).then((response: AxiosResponse<HttpGetProcessTypesResponse>) => response.data)
}

export const httpGetTaskTypes = () => {
    //TODO: add a response type into then
    return axios.get(`task-types`).then(response => response.data)
}

export const httpUpdateSettings = (data: UpdateSettingsPayload) => {
    return axios.post(`users/settings`, data).then((response: AxiosResponse<User>) => response.data)
}
