import styled, {css} from 'styled-components'
import {Trash01Icon} from '@components/ui/Icon'

export const StyledTaxonomyStatusToggleModal = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            margin-top: ${spacing}px;
        }
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `
)

export const StyledFooter = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
    `
)

export const StyledTrashIcon = styled(Trash01Icon)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 3}px;
        box-sizing: content-box;
    `}
`
