import {useIsNodeSidebarOpen} from '../../helpers/useNodeSidebarOpenGuard'
import {useParams, useSearchParams} from 'react-router-dom'
import {SELECTED_NODE_PARAM_KEY} from '../../constants'
import {useRemoveNode} from '../../helpers/useRemoveNode'
import {Sidebar} from '@/components/ui/Sidebar'
import {NodeSidebarForm} from './NodeSidebarForm'
import Spinner from '@/components/ui/Spinner'
import {useProcess} from '@/features/process/queries/useProcess'
import {useProject} from '@/features/projects/queries/useProject.ts'
import {useCenterViewPortOnNode} from '../../helpers/useCenterViewPortOnNode'
import {useProjectStructure} from '@/features/projects/queries/useProjectStructure'
import {useProcessStructure} from '@/features/process/queries/useProcessStructure'
import {useTheme} from 'styled-components'

export const NodeSidebar = () => {
    const theme = useTheme()
    const {isOpen, selectedNode} = useIsNodeSidebarOpen()
    const {centerViewPortOnParent} = useCenterViewPortOnNode()

    const [, setSearchParams] = useSearchParams()
    const {id} = useParams()
    if (!id) {
        throw new Error('Missing param :id')
    }
    const projectId = Number(id)
    const processId = Number(selectedNode?.id)

    const {data: project, isLoading: isLoadingProject, error: ProjectError} = useProject(id)

    const {
        data: projectStructure,
        isLoading: isLoadingProjectStructure,
        error: projectStructureError
    } = useProjectStructure(projectId, {
        enabled: !!selectedNode?.id && selectedNode.id === 'new-node'
    })

    const {
        data: processStructure,
        isLoading: isLoadingProcessStructure,
        error: processStructureError
    } = useProcessStructure(projectId, processId, {
        enabled: !!selectedNode?.id && selectedNode.id !== 'new-node'
    })

    const {
        data: process,
        isLoading: isLoadingProcess,
        error: processError
    } = useProcess({
        projectId: projectId,
        processId: processId,
        enabled: !!selectedNode?.id && selectedNode.id !== 'new-node'
    })

    const isLoadingForNewNode = selectedNode?.id === 'new-node' && isLoadingProjectStructure
    const isLoadingForExistingNode = selectedNode?.id !== 'new-node' && (isLoadingProcessStructure || isLoadingProcess)
    const isLoading = isLoadingProject || isLoadingForNewNode || isLoadingForExistingNode

    const isSuccessForNewNode = selectedNode?.id === 'new-node' && projectStructure
    const isSuccessForExistingNode = selectedNode?.id !== 'new-node' && !!process && processStructure
    const isSuccess = !!project && (isSuccessForNewNode || isSuccessForExistingNode)

    const structure = selectedNode?.id === 'new-node' ? projectStructure : processStructure

    const error = ProjectError || processError || projectStructureError || processStructureError

    const {removeNode} = useRemoveNode()

    const removeSelectionSearchParam = () => {
        setSearchParams(prev => {
            prev.delete(SELECTED_NODE_PARAM_KEY)
            return prev
        })
    }

    if (error) {
        //TODO handle error
    }

    if (isOpen && selectedNode && (selectedNode.type === 'tempProcessNode' || selectedNode.type === 'processNode')) {
        const onClose = () => {
            removeSelectionSearchParam()
        }

        const removeTempNode = () => {
            if (selectedNode.type === 'tempProcessNode') {
                removeNode({nodeId: selectedNode.id})
            }
        }

        return (
            <Sidebar
                onClose={() => {
                    onClose()
                    removeTempNode()
                    if (selectedNode.type === 'tempProcessNode') {
                        centerViewPortOnParent({nodeId: 'new-node'})
                    }
                }}
                transparentOverlay={true}
                width={400}
            >
                {isLoading && <Spinner color={theme.palette.neutral.black} size={24} />}
                {isSuccess && structure && (
                    <NodeSidebarForm
                        project={project}
                        projectStructure={structure}
                        process={process}
                        onClose={onClose}
                        removeTempNode={removeTempNode}
                        selectedNode={selectedNode}
                    />
                )}
            </Sidebar>
        )
    }
    return null
}
