import {StyledActionsButtons, StyledFieldsGrid, TaxonomyModalWrapper} from './style'
import {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {TaxonomyFormValues, TaxonomyRecord} from '../../types'
import InputText from '@/components/commons/InputText'
import Label from '@/components/ui/Label'
import {Uploader} from '@/components/commons/Uploader'
import Button from '@/components/ui/Button'
import {z} from 'zod'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useCreateTaxonomy} from '../../queries/useCreateTaxonomy'
import {toast} from 'react-hot-toast'
import {useEditTaxonomy} from '@/features/taxonomies/queries/useEditTaxonomy.ts'
import {downloadFile, iterateOverDirtyFields} from '@utilities/helpers.ts'
import {buildInstructionsFromUrl, buildTaxonomyJson} from '@/features/taxonomies/helpers.tsx'

type TaxonomyModalProps = {
    open: boolean
    setOpen: (open: boolean) => void // TODO: change to onClose (it's used only to close from inside)
    data?: TaxonomyRecord
    onSuccess?: (data: TaxonomyRecord) => void
}

const validationSchemaCreate = z.object({
    name: z.string().nonempty(),
    taxonomy: z.instanceof(File),
    instructions: z.instanceof(File)
})

export const TaxonomyModal: FC<TaxonomyModalProps> = ({open, setOpen, data, onSuccess}) => {
    const {t} = useTranslation()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, dirtyFields, isDirty},
        reset,
        resetField
    } = useForm<TaxonomyFormValues>({
        mode: 'onBlur',
        resolver: zodResolver(validationSchemaCreate),
        defaultValues: data ? {name: data.name ?? ''} : undefined
    })

    useEffect(() => {
        if (data) {
            buildTaxonomyJson(data, resetField)
            buildInstructionsFromUrl(data, resetField)
        }
        return () => {
            reset()
        }
    }, [data])

    const {mutate: createTaxonomy, isLoading: isLoadingCreateTaxonomy} = useCreateTaxonomy({
        onSuccess: data => {
            onSuccess?.(data)
            onClose()
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {mutate: editTaxonomyMutation} = useEditTaxonomy({
        onSuccess: data => {
            onSuccess?.(data)
            onClose()
        }
    })

    const isLoading = isLoadingCreateTaxonomy

    const onClose = () => {
        setOpen(false)
        reset()
    }

    const onSubmit = (values: TaxonomyFormValues) => {
        if (data) {
            editTaxonomyMutation({
                id: data.id,
                data: {
                    ...iterateOverDirtyFields(dirtyFields, values),
                    is_active: true
                }
            })
        } else {
            createTaxonomy(values)
        }
    }
    if (open) {
        return (
            <TaxonomyModalWrapper onClose={onClose} width={480} closeIconOffset="sm">
                <hgroup>
                    <h4>{data ? t('taxonomies:editTaxonomy') : t('taxonomies:addTaxonomy')}</h4>
                </hgroup>
                <form
                    onSubmit={e => {
                        e.stopPropagation()
                        handleSubmit(onSubmit)(e)
                    }}
                >
                    <StyledFieldsGrid>
                        <InputText
                            type="text"
                            label={t('commons:name')}
                            placeholder={t('commons:name') satisfies string}
                            {...register('name')}
                            errorMessage={errors.name?.message}
                        />

                        <section>
                            <Label>{t('taxonomies:taxonomyFile')}</Label>
                            <Controller
                                control={control}
                                name="taxonomy"
                                render={({field: {onChange, value}}) => (
                                    <Uploader
                                        maxSizeInMB={10}
                                        accept={{'application/json': []}}
                                        value={value}
                                        onChange={onChange}
                                        error={errors.taxonomy?.message}
                                        downloadCallback={downloadFile}
                                    />
                                )}
                            />
                        </section>

                        <section>
                            <Label>{t('taxonomies:instructions')}</Label>
                            <Controller
                                control={control}
                                name="instructions"
                                render={({field: {onChange, value}}) => (
                                    <Uploader
                                        maxSizeInMB={10}
                                        accept={{'application/pdf': []}}
                                        value={value}
                                        onChange={onChange}
                                        error={errors.instructions?.message}
                                        downloadCallback={downloadFile}
                                    />
                                )}
                            />
                        </section>
                    </StyledFieldsGrid>
                    <StyledActionsButtons>
                        <Button variant="secondary" size="lg" onClick={onClose}>
                            {t('commons:cancel')}
                        </Button>
                        <Button size="lg" disabled={isLoading || !isDirty} type={'submit'}>
                            {t('commons:confirm')}
                        </Button>
                    </StyledActionsButtons>
                </form>
            </TaxonomyModalWrapper>
        )
    }

    return null
}
