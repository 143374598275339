import styled, {css} from 'styled-components'

export const StyledForm = styled.form`
    ${() => css`
        position: relative;
        display: grid;
        grid-template-rows: 1fr auto;
        width: 100%;
        overflow-y: hidden;

        & > * {
            min-height: 0;
        }
    `}
`
