import {FC, useEffect} from 'react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import InputSelect, {SelectValue} from '@components/commons/Select'
import TextArea from '@components/commons/TextArea'
import {
    CREATE_PROJECT_FORM_MODEL,
    CreateProjectFormSchema,
    CreateProjectValidationSchema
} from '@/features/projects/components/create-project-modal/createProjectFormModel.ts'
import {useTranslation} from 'react-i18next'
import Button from '@components/ui/Button'
import InputText from '@components/commons/InputText'
import {
    StyledActionsButtons,
    StyledCreateProjectModal,
    StyledFieldsGrid
} from '@/features/projects/components/create-project-modal/style.ts'
import {useCreateProject} from '@/features/projects/queries/useCreateProject.ts'
import toast from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {ProjectRecord} from '@/features/projects/types.ts'
import {routes} from '@utilities/constants'
import Spinner from '@components/ui/Spinner'
import {useUsersSelect} from '@/features/users/queries/useUsersSelect.ts'
import {CurrencyDollarIcon} from '@/components/ui/Icon'
import {TemplateSelect} from '@/features/templates/components/template-select/TemplateSelect'

interface CreateNewProjectProps {
    onClose: () => void
}

export const CreateProjectModal: FC<CreateNewProjectProps> = ({onClose}) => {
    const {t} = useTranslation()
    const {data: contactUsers, isLoading: isLoadingContactUsers, error: contactUsersError} = useUsersSelect({roleId: 3})
    //Those variables are here for future use when we need to integrate workstreams and templates
    const isLoading = isLoadingContactUsers || !contactUsers
    const isError = contactUsersError

    useEffect(() => {
        if (isError) {
            toast.error(t('errors:default'))
        }
    }, [isError])

    return (
        <CreateProjectModalWithData
            isLoadingData={isLoading}
            onClose={onClose}
            contactUsersOptions={
                isLoading
                    ? []
                    : contactUsers?.data.map(
                          item =>
                              ({
                                  label: item.full_name,
                                  value: item.id.toString()
                              } as SelectValue)
                      )
            }
        />
    )
}

interface CreateProjectModalWithDataProps extends CreateNewProjectProps {
    contactUsersOptions: SelectValue[]
    isLoadingData: boolean
}
const CreateProjectModalWithData: FC<CreateProjectModalWithDataProps> = ({
    onClose,
    contactUsersOptions,
    isLoadingData
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {mutate: createNewProject, isLoading: createLoader} = useCreateProject({
        onSuccess: (data: ProjectRecord) => {
            navigate(`${routes.PROJECTS.path}/${data.id}/${routes.PROJECT_DETAIL_PROPERTIES.path}`)
            toast.success(t('projects:create_project_success'))
        }
    })

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid}
    } = useForm<CreateProjectValidationSchema>({
        mode: 'onBlur',
        defaultValues: {
            //just for the mvp
            [CREATE_PROJECT_FORM_MODEL.workstream_id.name]: {label: 'Labeling', value: '1'}
        },
        resolver: zodResolver(CreateProjectFormSchema)
    })

    const onSubmit: SubmitHandler<CreateProjectValidationSchema> = data => {
        const dataToSend = {
            is_draft: true,
            template_values: {},
            status: 'draft',
            [CREATE_PROJECT_FORM_MODEL.project_name.name]: data.name,
            [CREATE_PROJECT_FORM_MODEL.workstream_id.name]: +data.workstream_id.value,
            [CREATE_PROJECT_FORM_MODEL.template_id.name]: +data.template_id,
            [CREATE_PROJECT_FORM_MODEL.contact_users.name]: data.contact_users?.map(item => item.value),
            [CREATE_PROJECT_FORM_MODEL.objective.name]: data.objective,
            [CREATE_PROJECT_FORM_MODEL.budget.name]: data.budget || null
        }
        createNewProject(dataToSend)
    }

    return (
        <StyledCreateProjectModal width={540} closeIconOffset="sm" onClose={onClose}>
            <hgroup>
                <h4>{t('projects:create_project_title')}</h4>
            </hgroup>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledFieldsGrid>
                    <InputText
                        label={t(CREATE_PROJECT_FORM_MODEL.project_name.label)}
                        type={'text'}
                        touched={touchedFields.name}
                        errorMessage={t(errors.name?.message || '')}
                        placeholder={t(CREATE_PROJECT_FORM_MODEL.project_name.label).toString()}
                        {...register(t(CREATE_PROJECT_FORM_MODEL.project_name.name))}
                    />
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                name={CREATE_PROJECT_FORM_MODEL.workstream_id.name}
                                label={t(CREATE_PROJECT_FORM_MODEL.workstream_id.label)}
                                placeholder={t(CREATE_PROJECT_FORM_MODEL.workstream_id.placeholder)}
                                helpText={t(CREATE_PROJECT_FORM_MODEL.workstream_id.helpText)}
                                size={'medium'}
                                options={[{label: 'Labeling', value: '1'}]}
                            />
                        )}
                        control={control}
                        name={CREATE_PROJECT_FORM_MODEL.workstream_id.name}
                    />

                    <Controller
                        render={({field: {onChange, value}}) => (
                            <TemplateSelect
                                value={value}
                                name={CREATE_PROJECT_FORM_MODEL.template_id.name}
                                label={t(CREATE_PROJECT_FORM_MODEL.template_id.label)}
                                placeholder={t(CREATE_PROJECT_FORM_MODEL.template_id.placeholder)}
                                errorMessage={t(errors.template_id?.message || '')}
                                helpText={t(CREATE_PROJECT_FORM_MODEL.template_id.helpText)}
                                onChange={newValue => {
                                    if (!newValue) {
                                        return
                                    }
                                    onChange(newValue)
                                }}
                            />
                        )}
                        control={control}
                        name={CREATE_PROJECT_FORM_MODEL.template_id.name}
                    />

                    <Controller
                        defaultValue={[]}
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue[])
                                }}
                                disabled={isLoadingData}
                                size={'medium'}
                                isMulti={true}
                                name={CREATE_PROJECT_FORM_MODEL.contact_users.name}
                                label={t(CREATE_PROJECT_FORM_MODEL.contact_users.label)}
                                placeholder={t(CREATE_PROJECT_FORM_MODEL.contact_users.placeholder)}
                                errorMessage={t(errors.contact_users?.message || '')}
                                options={contactUsersOptions}
                            />
                        )}
                        control={control}
                        name={CREATE_PROJECT_FORM_MODEL.contact_users.name}
                    />

                    <InputText
                        typeIcon={<CurrencyDollarIcon size={20} />}
                        type="text"
                        label={t(CREATE_PROJECT_FORM_MODEL.budget.label)}
                        placeholder={t(CREATE_PROJECT_FORM_MODEL.budget.label) satisfies string}
                        {...register(CREATE_PROJECT_FORM_MODEL.budget.name)}
                        errorMessage={t(errors.budget?.message ?? '')}
                    />

                    <TextArea
                        label={t(CREATE_PROJECT_FORM_MODEL.objective.label)}
                        rows={4}
                        cols={30}
                        touched={touchedFields.objective}
                        errorMessage={t(errors.objective?.message || '')}
                        placeholder={t(CREATE_PROJECT_FORM_MODEL.objective.placeholder).toString()}
                        helpText={t(CREATE_PROJECT_FORM_MODEL.objective.helpText)}
                        {...register(t(CREATE_PROJECT_FORM_MODEL.objective.name))}
                    />
                </StyledFieldsGrid>
                <StyledActionsButtons>
                    <Button variant="secondary" size="lg" onClick={onClose} disabled={createLoader}>
                        {t('commons:cancel')}
                    </Button>
                    <Button type={'submit'} size="lg" disabled={!isValid || createLoader}>
                        {createLoader && <Spinner size={16} />}
                        {t('commons:create')}
                    </Button>
                </StyledActionsButtons>
            </form>
        </StyledCreateProjectModal>
    )
}

CreateProjectModal.displayName = 'CreateProjectModal'
