import {Flexbox} from '@/components/ui/Flexbox'
import {StyledStatCard, StyledStatHeading, StyledStatLabel, StyledStatValue, StyledTaskTypeButton} from '../style'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {TASK_TYPES, TaskType} from '../../constants'

type TasksStatsProps = {
    onTaskTypeClick: (taskType: TaskType) => void
    taskType: TaskType
    pendingCount: number
    onGoingCount: number
    completedCount: number
    totalCount: number
}

export const TasksStats: FC<TasksStatsProps> = ({
    onTaskTypeClick,
    taskType,
    pendingCount,
    onGoingCount,
    completedCount,
    totalCount
}) => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={4} width={'100%'}>
            <Flexbox align="center" justify="space-between" width={'100%'}>
                <StyledStatHeading>{t('tasks:title')}</StyledStatHeading>
                <Flexbox gap={3}>
                    <StyledTaskTypeButton
                        size="sm"
                        variant="ghost"
                        onClick={() => onTaskTypeClick(TASK_TYPES.ALL)}
                        isSelected={taskType === TASK_TYPES.ALL}
                    >
                        {t('commons:all')}
                    </StyledTaskTypeButton>
                    <StyledTaskTypeButton
                        size="sm"
                        variant="ghost"
                        onClick={() => onTaskTypeClick(TASK_TYPES.EDIT)}
                        isSelected={taskType === TASK_TYPES.EDIT}
                    >
                        {t('commons:edit')}
                    </StyledTaskTypeButton>
                    <StyledTaskTypeButton
                        size="sm"
                        variant="ghost"
                        onClick={() => onTaskTypeClick(TASK_TYPES.QA)}
                        isSelected={taskType === TASK_TYPES.QA}
                    >
                        {t('commons:qa')}
                    </StyledTaskTypeButton>
                </Flexbox>
            </Flexbox>
            <Flexbox gap={8} width={'100%'}>
                <StyledStatCard>
                    <Flexbox width={'100%'}>
                        <Flexbox direction="column" width={'100%'}>
                            <StyledStatLabel>{t('tasks:not_started')}</StyledStatLabel>
                            <StyledStatValue>{pendingCount}</StyledStatValue>
                        </Flexbox>
                    </Flexbox>
                </StyledStatCard>
                <StyledStatCard>
                    <Flexbox width={'100%'}>
                        <Flexbox direction="column" width={'100%'}>
                            <StyledStatLabel>{t('tasks:in_progress')}</StyledStatLabel>
                            <StyledStatValue>{onGoingCount}</StyledStatValue>
                        </Flexbox>
                    </Flexbox>
                </StyledStatCard>
                <StyledStatCard>
                    <Flexbox width={'100%'}>
                        <Flexbox direction="column" width={'100%'}>
                            <StyledStatLabel>{t('tasks:completed')}</StyledStatLabel>
                            <StyledStatValue>{completedCount}</StyledStatValue>
                        </Flexbox>
                    </Flexbox>
                </StyledStatCard>
                <StyledStatCard>
                    <Flexbox width={'100%'}>
                        <Flexbox direction="column" width={'100%'}>
                            <StyledStatLabel>{t('tasks:total')}</StyledStatLabel>
                            <StyledStatValue>{totalCount}</StyledStatValue>
                        </Flexbox>
                    </Flexbox>
                </StyledStatCard>
            </Flexbox>
        </Flexbox>
    )
}
