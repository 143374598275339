import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {ProjectRecord} from '@/features/projects/types.ts'
import {httpCloneProject} from '@/features/projects/services/projects.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

interface useDuplicateProjectProps {
    onSuccess: (data: ProjectRecord) => void
}

export const useCloneProject = ({onSuccess}: useDuplicateProjectProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CLONE_PROJECT],
        mutationFn: httpCloneProject,
        onSuccess: data => {
            onSuccess(data)
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.DATASERVICE]})
        },
        onError: errorHandler
    })
}
