import {useQuery} from '@tanstack/react-query'
import {httpGetProjectMediaCount} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type useProjectMediaCountProps = {
    id: number
}

export const useProjectMediaCount = ({id}: useProjectMediaCountProps) => {
    return useQuery({
        staleTime: 0,
        cacheTime: 0,
        queryKey: [QUERY_KEYS.PROJECT_MEDIA_COUNT, id],
        queryFn: () => httpGetProjectMediaCount(id)
    })
}
