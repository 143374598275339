import axios from '@services/axiosInstance.ts'
import {TaskType} from '../constants'

export type HttpgGetBudgetStatsParams = {
    project_id: number
    batch_id?: number
    subproject_id?: number
    subbatch_id?: number
}

export type HttpgGetBudgetStatsResponse = {
    budget?: number
    used_budget: number
    turn_around_time: number
    projects_count?: number
    batches_count?: number
    subprojects_count?: number
    subbatches_count?: number
}

export const httpgGetBudgetStats = (params?: HttpgGetBudgetStatsParams) => {
    return axios.get(`/stats/budget`, {params}).then(res => res.data as HttpgGetBudgetStatsResponse)
}

export type HttpgGetTasksStatsParams = {
    project_id?: number
    batch_id?: number
    subproject_id?: number
    subbatch_id?: number
    task_type?: Omit<TaskType, 'all'>
}

type HttpgGetTasksStatsResponse = {
    pending_count: number
    ongoing_count: number
    completed_count: number
    total_count: number
}

export const httpgGetTasksStats = (params?: HttpgGetTasksStatsParams) => {
    return axios.get(`/stats/tasks`, {params}).then(res => res.data as HttpgGetTasksStatsResponse)
}

export type HttpgGetTasksAverageParams = {
    project_id?: number
    batch_id?: number
    subproject_id?: number
    subbatch_id?: number
    task_type?: Omit<TaskType, 'all'>
    start_date?: Date
    end_date?: Date
}

type httpGetTasksAverageResponse = {
    edit: {
        total: number
        data: {x: string; y: number}[]
    }
    qa: {
        total: number
        data: {x: string; y: number}[]
    }
}

export const httpGetTasksAverage = (params?: HttpgGetTasksAverageParams) => {
    return axios.get(`/stats/task-averages`, {params}).then(res => res.data as httpGetTasksAverageResponse)
}

export type HttpGetTasksFeedbackParams = {
    project_id?: number
    batch_id?: number
    subproject_id?: number
    subbatch_id?: number
    task_type?: Omit<TaskType, 'all'>
    start_date?: Date
    end_date?: Date
}

export type HttpGetTasksFeedbackResponse = {
    data: {x: string; y: number}[]
    total: number
}

export const httpGetTasksFeedback = (params?: HttpGetTasksFeedbackParams) => {
    return axios.get(`/stats/task-feedbacks`, {params}).then(res => res.data as HttpGetTasksFeedbackResponse)
}

export type httpGetTasksLoadingParams = {
    project_id?: number
    batch_id?: number
    subproject_id?: number
    subbatch_id?: number
    task_type?: Omit<TaskType, 'all'>
    start_date?: Date
    end_date?: Date
}

export type httpGetTasksLoadingResponse = {
    data: {x: string; y: number}[]
    total: number
}

export const httpGetTasksLoading = (params?: httpGetTasksLoadingParams) => {
    return axios.get(`/stats/tasks-loading`, {params}).then(res => res.data as httpGetTasksLoadingResponse)
}

export type httpGetManHoursParams = {
    project_id?: number
    batch_id?: number
    subproject_id?: number
    subbatch_id?: number
    task_type?: Omit<TaskType, 'all'>
    start_date?: Date
    end_date?: Date
}

export type httpGetManHoursResponse = {
    data: {x: string; y_consumed: number; y_estimated: number}[]
    total_consumed: number
    total_estimated: number
}

export const httpGetManHours = (params?: httpGetManHoursParams) => {
    return axios.get(`/stats/man-hours`, {params}).then(res => res.data as httpGetManHoursResponse)
}
