import {useTranslation} from 'react-i18next'
import Table from '@components/commons/Table'
import {debounce, formatLocaleDate} from '@utilities/helpers.ts'

import CheckBox from '@components/commons/CheckBox'
import useProductionBatches from '@/features/batches/queries/useProductionBatches.ts'
import {batchBadgeRenderer} from '@utilities/statusesHelpers.tsx'
import {
    StyledBatchSubText,
    StyledBatchCheckbox,
    StyledBatchName,
    StyledBatchMainText,
    SelectionCounterText
} from '@/features/batches/components/BatchesTableViews/style.ts'
import {EmptySearch} from '@components/commons/EmptySearch'
import {BatchesTableEmptyState} from '@/features/batches/components/BatchesTableEmptyState'
import {useRecordSelection} from '@hooks/useRecordSelection.ts'
import {BatchRecord, BatchStatus} from '@/features/batches/types.ts'
import {useProjectBatchesSearchParams} from '../../hooks/useProjectBatchesSearchParams'
import {ChangeEvent, useRef, useState} from 'react'
import {DotsVerticalIcon, Edit01Icon, PauseCircleIcon, PlayIcon, SearchLgIcon, User01Icon} from '@/components/ui/Icon'
import InputText from '@/components/commons/InputText'
import {Flexbox} from '@/components/ui/Flexbox'
import {BatchesStatusSelect} from '../BatchesStatusSelect'
import {BatchesEditSidebar} from '../BatchesEditSidebar/BatchesEditSidebar'
import DropdownMenu from '@/components/ui/DropdownMenu'
import Button from '@/components/ui/Button'
import {ProductionBatchesAssignmentModal} from '../BatchesAssignmentModal/ProductionBatchesAssignmentModal'
import {useAuth} from '@/context/AuthProvider'
import {useActivateBatch} from '../../queries/useActivateBatch'
import toast from 'react-hot-toast'
import {BatchesDeactivateModal} from '../BatchesDeactivateModal'
import {BatchAssignmentSidebar} from '../BatchesAssignmentSidebar'
import {BatchesUnassignModal} from '../BatchesUnassignModal'
import {useCompany} from '@/features/companies/queries/useCompany'

export const BatchesProgramManagerProdView = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    //queries
    const {searchParams, setSearchParams} = useProjectBatchesSearchParams()
    const query = useProductionBatches(searchParams)
    const {data: companyData} = useCompany(user?.company_id || -1, {enabled: !!user})
    //state and other hooks
    const {selectedItems, selectedItemsToggle, resetSelectedItems} = useRecordSelection<BatchRecord>()
    const [openAssignBatchesModal, setOpenAssignBatchesModal] = useState(false)
    const [batchToEdit, setBatchToEdit] = useState<{batchId: number; projectId: number} | null>(null)
    const [batchToAssign, setBatchToAssign] = useState<{batch: BatchRecord; projectId: number} | null>(null)
    const [batchToUnassignId, setBatchToUnassignId] = useState<number | null>(null)
    const [batchesToDeactivateId, setBatchesToDeactivateId] = useState<number | number[] | null>(null)
    const inputTextRef = useRef<HTMLInputElement>(null)

    //Activate batch mutation
    const {mutate: activateBatch} = useActivateBatch({
        onSuccess: () => toast.success(t('commons:genericSuccess')),
        onError: () => toast.error(t('commons:genericError'))
    })

    const onSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event?.target?.value})
    }, 750)

    const isBulkEnabled = Object.values(selectedItems).some(batch => batch.status !== 'inactive')

    return (
        <>
            {!!batchToEdit && (
                <BatchesEditSidebar
                    projectId={batchToEdit.projectId}
                    batchId={batchToEdit.batchId}
                    roleSlug="program_manager"
                    onClose={() => setBatchToEdit(null)}
                />
            )}
            {!!batchesToDeactivateId && (
                <BatchesDeactivateModal
                    batchId={batchesToDeactivateId}
                    onClose={() => setBatchesToDeactivateId(null)}
                    onSuccess={resetSelectedItems}
                />
            )}
            {!!batchToAssign && user && (
                <BatchAssignmentSidebar
                    companyId={user.company_id}
                    batches={batchToAssign.batch}
                    projectId={batchToAssign.projectId}
                    onClose={() => setBatchToAssign(null)}
                />
            )}
            {openAssignBatchesModal && user && (
                <ProductionBatchesAssignmentModal
                    companyId={user.company_id}
                    onClose={() => setOpenAssignBatchesModal(false)}
                />
            )}
            {!!batchToUnassignId && (
                <BatchesUnassignModal
                    batchId={batchToUnassignId}
                    onClose={() => setBatchToUnassignId(null)}
                    onSuccess={resetSelectedItems}
                />
            )}
            <Flexbox justify="space-between" align="center">
                <Flexbox align="center" gap={1}>
                    <InputText
                        width="320px"
                        type="text"
                        ref={inputTextRef}
                        defaultValue={searchParams.search}
                        typeIcon={<SearchLgIcon size={20} />}
                        inputSize="sm"
                        placeholder={t('batches:search_batch') ?? 'Search a Batch'}
                        onChange={onSearch}
                    />
                    <BatchesStatusSelect
                        value={(searchParams.status as BatchStatus[]) ?? []}
                        onChange={newStatuses => {
                            setSearchParams({status: newStatuses})
                        }}
                    />
                    {!!Object.keys(selectedItems).length && (
                        <>
                            <SelectionCounterText>
                                {t('commons:selected', {selected: Object.keys(selectedItems).length})}.
                            </SelectionCounterText>
                            <Button
                                disabled={!isBulkEnabled}
                                variant="linkColor"
                                size="sm"
                                onClick={() =>
                                    setBatchesToDeactivateId(Object.values(selectedItems).map(batch => batch.id))
                                }
                            >
                                <PauseCircleIcon size={20} />
                                {t('batches:deactivate')}
                            </Button>
                        </>
                    )}
                </Flexbox>
                <Button size="md" onClick={() => setOpenAssignBatchesModal(true)}>
                    {t('projectDetails:batches:batch_assignment')}
                </Button>
            </Flexbox>
            <Table
                columns={[
                    {
                        width: '5%',
                        cellRender: batch => (
                            <StyledBatchCheckbox>
                                {batch?.id && (
                                    <CheckBox
                                        checked={batch.id in selectedItems}
                                        id={`c-${batch.id}`}
                                        onChange={() => selectedItemsToggle(batch)}
                                    />
                                )}
                            </StyledBatchCheckbox>
                        )
                    },
                    {
                        label: t('batches:table:batch_name'),
                        width: '12%',
                        cellRender: batch => (
                            <Flexbox direction="column" gap={1} title={batch?.name}>
                                <StyledBatchName>{batch?.name}</StyledBatchName>
                                {batchBadgeRenderer(batch?.status ?? null, t)}
                            </Flexbox>
                        ),
                        sortName: 'name'
                    },
                    {
                        label: t('batches:table:project'),
                        width: '12%',
                        cellRender: batch => {
                            const assignment_mode =
                                batch?.assignment_mode !== 'default'
                                    ? batch?.assignment_mode
                                    : companyData?.assignment_mode
                            return (
                                <Flexbox direction="column" gap={1}>
                                    <StyledBatchMainText title={batch?.project_name}>
                                        {batch?.project_name}
                                    </StyledBatchMainText>
                                    <StyledBatchSubText title={String(t(`batches:table:${assignment_mode}`))}>
                                        {t(`batches:table:${assignment_mode}`)}
                                    </StyledBatchSubText>
                                </Flexbox>
                            )
                        }
                    },
                    {
                        label: t('batches:table:tasks'),
                        width: '10%',
                        cellRender: batch => (
                            <span>
                                {batch?.completed_tasks}/{batch?.total_tasks}
                            </span>
                        )
                    },
                    {
                        label: t('batches:table:priority'),
                        width: '8%',
                        cellRender: batch => batch?.priority ?? 'None',
                        sortName: 'priority'
                    },
                    {
                        label: t('commons:vendor_manager'),
                        width: '13%',
                        cellRender: batch => {
                            const uniqueVendors = [...new Set(batch?.vendor_managers.map(batch => batch.company_id))]
                            return (
                                <Flexbox direction="column" gap={1}>
                                    <StyledBatchMainText>
                                        {batch?.vendor_managers.length} {t('commons:managers')}
                                    </StyledBatchMainText>
                                    <StyledBatchSubText>
                                        {uniqueVendors.length} {t('commons:vendors')}
                                    </StyledBatchSubText>
                                </Flexbox>
                            )
                        }
                    },
                    {
                        label: t('batches:table:need_date'),
                        width: '13%',
                        sortName: 'need_date',
                        cellRender: batch => (batch?.need_date ? formatLocaleDate(batch.need_date) : '-')
                    },
                    {
                        label: t('batches:table:possible_date'),
                        width: '13%',
                        sortName: 'possible_date',
                        cellRender: batch => (batch?.possible_date ? formatLocaleDate(batch.possible_date) : '-')
                    },
                    {
                        label: t('batches:table:time_budget'),
                        width: '10%',
                        cellRender: batch => (
                            <span>
                                {batch?.used_time ? Math.round(batch?.used_time) : '0'}/
                                {batch?.estimated_time ? Math.round(batch?.estimated_time) : '-'}
                            </span>
                        )
                    },
                    {
                        alignment: 'right',
                        width: '4%',
                        cellRender: batch => {
                            return (
                                batch && (
                                    <DropdownMenu
                                        trigger={<DotsVerticalIcon />}
                                        actions={[
                                            {
                                                component: t('commons:edit'),
                                                icon: <Edit01Icon size={16} />,
                                                onClickCb: () =>
                                                    setBatchToEdit({batchId: batch.id, projectId: batch.project_id})
                                            },
                                            batch.status === 'inactive'
                                                ? {
                                                      component: t('batches:activate'),
                                                      icon: <PlayIcon size={16} />,
                                                      onClickCb: () => activateBatch({batchId: batch.id})
                                                  }
                                                : {
                                                      component: t('batches:deactivate'),
                                                      icon: <PauseCircleIcon size={16} />,
                                                      onClickCb: () => setBatchesToDeactivateId(batch.id)
                                                  },
                                            {
                                                component: t('batches:assign'),
                                                icon: <User01Icon size={16} />,
                                                onClickCb: () => setBatchToAssign({batch, projectId: batch.project_id}),
                                                disabled: batch.status === 'inactive'
                                            }
                                        ]}
                                    />
                                )
                            )
                        }
                    }
                ]}
                emptyStateComponent={<BatchesTableEmptyState />}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => {
                            if (inputTextRef.current) {
                                inputTextRef.current.value = ''
                            }
                            setSearchParams({search: '', status: []})
                        }}
                        title={t('batches:table:empty_search_state_title')}
                        subtitle={t('batches:table:empty_search_state_subtitle')}
                    />
                }
                searchValue={Object.keys(searchParams).length > 0}
                data={query.remappedData}
                isChangingPage={query.isFetchingNextPage}
                isError={query.isError}
                isLoading={query.isLoading}
                onChangePage={query.fetchNextPage}
                sorter={{
                    orderBy: searchParams.orderBy,
                    orderDirection: searchParams.orderDirection,
                    onSort: setSearchParams
                }}
            />
        </>
    )
}

BatchesProgramManagerProdView.displayName = 'ProgramManagerProdBatchesView'
