import {FC} from 'react'
import {EmptySearch} from '@/components/commons/EmptySearch'
import {Flexbox} from '@/components/ui/Flexbox'
import {Progress} from '@/components/ui/Progress'
import Spinner from '@/components/ui/Spinner'
import {useTranslation} from 'react-i18next'
import {StyledSubtext} from './style'
import {useBatchesGenerationSocket} from '@/features/batches/hooks/useBatchesGenerationSocket.ts'

type GeneratingBatchesStateProps = {
    projectId: number
    totalMediaAmount: number
    initialProcessedMedia: number
    totalTasksAmount: number
    initialProcessedTask: number
}

export const GeneratingBatchesState: FC<GeneratingBatchesStateProps> = ({
    projectId,
    totalMediaAmount,
    initialProcessedMedia,
    totalTasksAmount,
    initialProcessedTask
}) => {
    const {t} = useTranslation()
    const {processedTask, processedMedia} = useBatchesGenerationSocket({
        projectId,
        initialProcessedMedia,
        initialProcessedTask
    })

    return (
        <EmptySearch
            icon={<Spinner size={24} color="black" />}
            title={t('projectDetails:batches:generating_batches_title')}
            subtitle={t('projectDetails:batches:generating_batches_helper')}
            customContent={
                <Flexbox width={'420px'} direction="column" gap={3}>
                    <Progress progress={Math.round(totalMediaAmount ? (processedMedia / totalMediaAmount) * 100 : 0)} />
                    <Flexbox width={'100%'} direction="column">
                        {totalMediaAmount ? (
                            <>
                                <StyledSubtext>
                                    {t('projectDetails:batches:media_count', {
                                        current: processedMedia,
                                        total: totalMediaAmount
                                    })}
                                </StyledSubtext>
                                <StyledSubtext>
                                    {t('projectDetails:batches:task_count', {
                                        current: processedTask,
                                        total: totalTasksAmount
                                    })}
                                </StyledSubtext>
                            </>
                        ) : (
                            <>
                                <StyledSubtext>The calculation process is scheduled to begin shortly...</StyledSubtext>
                            </>
                        )}
                    </Flexbox>
                </Flexbox>
            }
        />
    )
}
