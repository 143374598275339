import styled, {css, DefaultTheme} from 'styled-components'

type StyledBadgeProps = {
    size: 'sm' | 'md' | 'lg'
    border: 'none' | 'small' | 'medium' | 'large'
    background: string
    color: string
    borderColor: string
}

export const StyledBadge = styled.div<StyledBadgeProps>`
    ${({theme: {spacing}, color, background, borderColor}) => css`
        color: ${color};
        background: ${background};
        border: 1px solid ${borderColor};
        display: inline-flex;
        justify-self: flex-start;
        align-items: center;
        gap: ${spacing}px;
    `}
    ${({size, theme}) => SizeStyles({theme})[size]};
    ${({border}) => borderRadiusStyles()[border]};
`

type SizesProps = {
    theme: DefaultTheme
}
const SizeStyles = ({theme: {spacing, typography}}: SizesProps) => ({
    sm: css`
        padding: ${spacing / 2}px ${spacing * 2}px;
        font-weight: 500;
        ${typography.textXs};
    `,
    md: css`
        padding: ${spacing / 2}px ${spacing * 2.5}px;
        font-weight: 500;
        ${typography.textSm};
    `,
    lg: css`
        padding: ${spacing}px ${spacing * 3}px;
        font-weight: 500;
        ${typography.textSm};
    `
})

const borderRadiusStyles = () => ({
    none: css`
        border-radius: unset;
    `,
    small: css`
        border-radius: 4px;
    `,
    medium: css`
        border-radius: 8px;
    `,
    large: css`
        border-radius: 12px;
    `,
    full: css`
        border-radius: 100%;
    `
})
