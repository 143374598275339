import styled, {css} from 'styled-components'

export const LoginWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    `}
`

export const LoginBox = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        width: 360px;

        & h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 14}px 0;
        }

        & img {
            margin: 0 auto ${spacing * 6}px;
        }
    `}
`
