import {Sidebar} from '@/components/ui/Sidebar'
import styled, {css} from 'styled-components'

export const StyledBatchesEditSidebar = styled(Sidebar)`
    ${({theme: {typography, palette, spacing}}) => css`
        .header {
            padding: ${spacing * 6}px ${spacing * 6}px 0 ${spacing * 6}px;
            margin-bottom: ${spacing * 8}px;
            h2 {
                font-size: ${typography.textXl};
                color: ${palette.neutral[900]};
                font-weight: 600;
            }
            h3 {
                font-size: ${typography.textSm};
                color: ${palette.neutral[600]};
            }
        }

        .body {
            padding: 0 ${spacing * 6}px ${spacing * 2}px ${spacing * 6}px;
            margin-bottom: ${spacing * 8}px;
            flex-grow: 1;
            overflow-y: auto;
        }

        .footer {
            padding: ${4 * spacing}px ${6 * spacing}px;
            border-top: 1px solid ${palette.neutral[200]};
        }

        .w-full {
            width: 100%;
        }
    `}
`
