import styled, {css} from 'styled-components'

export const StyledForm = styled('form')(
    ({theme: {spacing, typography}}) => css`
        padding-top: ${spacing * 6}px;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
        gap: ${spacing * 8}px;

        h1 {
            padding: 0 ${spacing * 6}px;
            ${typography.textXl};
            font-weight: 600;
        }
    `
)

export const StyledFields = styled('div')(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        padding: 0 ${spacing * 6}px;
        overflow: auto;
    `
)

export const StyledButtons = styled('div')(
    ({theme: {spacing, palette}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 4}px;
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['200']};
    `
)
