import {ComponentProps, FC, useDeferredValue, useState} from 'react'
import {useTranslation} from 'react-i18next'
import InputSelect, {SelectValue} from '@/components/commons/Select'
import {SingleValue} from 'react-select'
import {useProjectVendors} from '@/features/projects/queries/useProjectVendor'

type ProjectVendorSelectProps = Omit<
    ComponentProps<typeof InputSelect>,
    'options' | 'placeholder' | 'inputValue' | 'onInputChange' | 'value' | 'onChange' | 'isMulti'
> & {
    value: number | null
    onChange: (id: number | null) => void
    projectId: number
}

export const ProjectVendorSelect: FC<ProjectVendorSelectProps> = ({value, projectId, onChange, ...props}) => {
    const {t} = useTranslation()

    const [inputValue, setInputValue] = useState('')
    const deferedSearch = useDeferredValue(inputValue)

    const vendorsQuery = useProjectVendors(projectId, {vendor_name: deferedSearch || undefined})

    if (vendorsQuery.error) {
        throw vendorsQuery.error
    }

    const options = vendorsQuery.data?.map(vendor => ({
        value: vendor?.id.toString() || '',
        label: vendor?.name || ''
    }))

    const selectValue = options?.find(opt => opt.value === value?.toString())

    return (
        <InputSelect
            {...props}
            className="select"
            isMulti={false}
            value={selectValue}
            isClearable
            onChange={newValue => {
                const value = (newValue as SingleValue<SelectValue> | undefined)?.value
                const parsedNewValue = value ? Number(value) : null
                onChange(parsedNewValue)
            }}
            placeholder={t('projectDetails:tabs:vendors')}
            options={options ?? []}
            isSearchable
            inputValue={inputValue}
            onInputChange={newText => setInputValue(newText)}
        />
    )
}
