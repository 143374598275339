import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
import en from './en/en.json'
import it from './it/it.json'

export const APP_LANGUAGES = [
    {label: 'English (EN)', value: 'en', json: en},
    {label: 'Italiano (IT)', value: 'it', json: it}
]

export const resources = {
    en: en,
    it: it
} as const

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export default i18n
