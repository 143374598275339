import {useTheme} from 'styled-components'
import ContentLoader from 'react-content-loader'
import {Flexbox} from '@components/ui/Flexbox'

interface ProjectHeaderLoaderProps {
    titleHeight: number
    titleWidth: number
    ctaHeight: number
    ctaWidth: number
}

export const ProjectHeaderContentLoader = ({
    titleHeight,
    titleWidth,
    ctaHeight,
    ctaWidth
}: ProjectHeaderLoaderProps) => {
    const {palette} = useTheme()

    return (
        <Flexbox align={'center'} justify={'space-between'}>
            <ContentLoader
                backgroundColor={palette.neutral[100]}
                foregroundColor={palette.neutral[200]}
                height={titleHeight}
                width={titleWidth}
            >
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader
                backgroundColor={palette.neutral[100]}
                foregroundColor={palette.neutral[200]}
                height={ctaHeight}
                width={ctaWidth}
            >
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
        </Flexbox>
    )
}

ProjectHeaderContentLoader.displayName = 'ProjectHeaderContentLoader'
