import styled, {css} from 'styled-components'

export const StyledCloneProjectModal = styled.form(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            margin-top: ${spacing}px;
        }
    `
)

export const StyledFieldWrapper = styled.div(
    ({theme: {spacing}}) => css`
        width: 280px;
        margin: ${spacing * 6}px 0 ${spacing * 4}px 0;
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `
)

export const StyledFooter = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
    `
)
