import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    COMPANY: 'company',
    COMPANIES: 'companies',
    VENDORS: 'vendors',
    USERS: 'users',
    USER_BY_ID: 'user-by-id',
    PROJECTS: 'projects',
    PROJECT_BY_ID: 'project-by-id',
    PROJECT_VENDORS: 'project-vendors',
    PROJECT_STRUCTURE: 'project-structure',
    PROCESS_STRUCTURE: 'process-structure',
    PRODUCTION_MANAGEMENT: 'production-management',
    PRODUCTION_BATCHES: 'production-batches',
    PROCESS: 'process',
    PROCESS_EDGES: 'process-edges',
    PROCESS_TYPES: 'process-types',
    LATE_OPERATORS: 'late-operators',
    PROJECT_BATCHES: 'project-batches',
    PROJECT_BATCHES_STATUS: 'project-batches-status',
    PROJECT_MEDIA_COUNT: 'project-media-count',
    TEMPLATES: 'templates',
    ACTIVE_TEMPLATES: 'active-templates',
    ARCHIVED_TEMPLATES: 'archived-templates',
    TAXONOMIES: 'taxonomies',
    USER_SESSIONS: 'user-sessions',
    TASK: 'task',
    ASSIGNED_TASK: 'assigned-task',
    COMPLETED_TASKS: 'tasks-completed',
    TASKS_TYPES: 'tasks-types',
    CURRENT_SETTINGS: 'current-settings',
    ROLES: 'roles',
    DATASERVICE: 'data-service',
    BATCH_DETAILS: 'batch-service',
    BUDGET_STATS: 'budget-stats',
    TASKS_STATS: 'tasks-stats',
    TASKS_AVERAGE: 'tasks-average',
    TASKS_FEEDBACK: 'tasks-feedback',
    TASKS_LOADING: 'tasks-loading',
    MAN_HOURS: 'man-hours'
} as const

export const MUTATION_KEYS = {
    ADD_USERS: 'add-users',
    EDIT_USER: 'edit-user',
    DELETE_USER: 'delete-user',
    ADD_PROJECT: 'add-project',
    CLONE_PROJECT: 'clone-project',
    UPDATE_PROJECT: 'update-project',
    DELETE_PROJECT: 'delete-project',
    ADD_TEMPLATE: 'add-active-template',
    UPDATE_TEMPLATE: 'update-active-template',
    DELETE_TEMPLATE: 'delete-active-template',
    RESTORE_TEMPLATE: 'restore-template',
    ARCHIVE_TEMPLATE: 'archive-template',
    ADD_TAXONOMY: 'add-taxonomy',
    UPDATE_TAXONOMY: 'update-taxonomy',
    DELETE_TAXONOMY: 'delete-taxonomy',
    DEACTIVATE_TAXONOMY: 'deactivate-taxonomy',
    ACTIVATE_TAXONOMY: 'activate-taxonomy',
    UPDATE_SETTINGS: 'update-current-settings',
    UPDATE_LABELER_SETTINGS: 'update-labeler-settings',
    UPDATE_USER_SESSIONS: 'update-user-sessions',
    POST_USER_SESSIONS: 'post-user-sessions',
    CLAIM_TASKS: 'claim-task'
} as const

//rq-helpers
export const infiniteQueryFetchNextPage = async (query: UseInfiniteQueryResult) => {
    if (query.hasNextPage && !query.isFetching) {
        const pages = query.data?.pages ?? []
        await query.fetchNextPage({pageParam: pages.length + 1})
    }
}
