import styled, {css, DefaultTheme} from 'styled-components'
import {Flexbox} from '@components/ui/Flexbox'

export const StyledManageUsersResult = styled('div')`
    display: grid;
    grid-template-rows: auto 1fr auto;
    overflow: hidden;
`

export const StyledManageUsersRecap = styled(Flexbox)(
    ({theme: {spacing}, hasErrors}: {theme: DefaultTheme; hasErrors: boolean}) => css`
        padding: ${hasErrors ? 0 : spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledFailedUsers = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral['700']};
        ${typography.textSm};
        max-height: 280px;
        overflow-y: auto;
    `
)

export const StyledFailedUserHeadings = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        color: ${palette.neutral['600']};
        ${typography.textXs}
        padding: ${spacing * 2}px 0;
    `
)

export const StyledFailedUser = styled('div')(
    ({theme: {spacing, palette, typography}}) => css`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing}px;
        ${typography.textSm};
        padding: ${spacing * 3}px 0;
        border-top: ${palette.neutral['300']} solid 1px;
        & > * {
            min-width: 0; //TODO: needed for the grid, try a better solution to don't duplicate this css rule
            overflow: hidden;
            word-wrap: normal;
            text-overflow: ellipsis;
        }
        p {
            color: ${palette.neutral['900']};
        }
        span {
            color: ${palette.danger['700']};
            font-weight: 500;
        }
    `
)

interface MessageProps {
    variant: keyof DefaultTheme['palette']
}
export const StyledMessage = styled(Flexbox)<MessageProps>(
    ({variant, theme: {palette, typography}}) => css`
        color: ${palette[variant]['900']};
        ${typography.textSm};
        h4 {
            ${typography.textMd}
            font-weight: 600;
        }
    `
)

export const StyledCounters = styled('div')(
    ({theme: {palette, spacing, typography}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 2}px;
        width: 100%;
        color: ${palette.neutral['900']};
        ${typography.textSm};
        span {
            ${typography.displayMd};
            font-weight: 700;
        }
    `
)

export const StyledButtons = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['200']};
    `
)
