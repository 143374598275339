import {useEffect, useState} from 'react'
import {projectBatchesSocketIoEvents, projectSocket} from '@/features/projects/services/projectSocket.ts'
import {MediaIncrementEventBody} from '@/features/batches/types.ts'
import toast from 'react-hot-toast'
import {useProjectMediaCount} from '@/features/batches/queries/useProjectMediaCount.ts'
import {useTranslation} from 'react-i18next'

export const useMediaCountSocket = ({projectId}: {projectId: number}) => {
    const {t} = useTranslation()
    const {data: mediaCountData, isLoading: mediaCountLoading} = useProjectMediaCount({id: projectId})
    const [mediaAmount, setMediaAmount] = useState(0)
    const [countInProgress, setCountInProgress] = useState(false)

    useEffect(() => {
        if (mediaCountData) {
            //already performed
            if (mediaCountData.cached) {
                setCountInProgress(false)
                setMediaAmount(Number(mediaCountData.totalAmount))
            } else {
                projectSocket.connect()
                projectSocket.emit(projectBatchesSocketIoEvents.JOIN_ROOM, {
                    roomId: mediaCountData.socketIORoomId
                })

                const handleMediaCountIncrement = ({
                    currentAmount,
                    amount,
                    totalAmount,
                    status,
                    message
                }: MediaIncrementEventBody) => {
                    if (status === 'failed') {
                        toast.error(message ?? t('commons:genericError'))
                        setCountInProgress(false)
                    } else if (status === 'pending') {
                        setMediaAmount(currentAmount + amount)
                        setCountInProgress(true)
                    } else {
                        setMediaAmount(totalAmount)
                        setCountInProgress(false)
                    }
                }
                projectSocket.on(projectBatchesSocketIoEvents.MEDIA_COUNT, handleMediaCountIncrement)

                return () => {
                    projectSocket.emit(projectBatchesSocketIoEvents.LEAVE_ROOM, {
                        roomId: mediaCountData.socketIORoomId
                    })
                    projectSocket.disconnect()
                }
            }
        }
    }, [mediaCountData])

    return {
        mediaCountLoading,
        mediaAmount,
        countInProgress
    }
}
