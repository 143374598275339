import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient'
import {httpUpdateSettings} from '@/features/settings/services/settings.http.ts'
import {User} from '@/types/auth.ts'

type UseMarkAsCompletedProps = {
    onSuccess?: (data: User) => void
    onError?: () => void
}

export const useUpdateLabelerSettings = ({onSuccess, onError}: UseMarkAsCompletedProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_LABELER_SETTINGS],
        mutationFn: httpUpdateSettings,
        onError,
        onSuccess: data => {
            onSuccess?.(data)
        }
    })
}
