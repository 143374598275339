import {useMutation, useQueryClient} from '@tanstack/react-query'
import {SyncBatchesPayload, httpSyncBatches} from '../services/batches.http'
import {QUERY_KEYS} from '@/queryClient'

type useSyncProjectBatchesProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useSyncProjectBatches = ({onSuccess, onError}: useSyncProjectBatchesProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({id, ...payload}: SyncBatchesPayload & {id: number}) => httpSyncBatches(id, payload),
        onSuccess: async (_, variables) => {
            console.log(_, variables)
            onSuccess?.()
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECTS])
            //await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BY_ID, variables.id.toString()]) //TODO: See comment in useUpdateProject
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BY_ID, Number(variables.id)])
            await queryClient.invalidateQueries([QUERY_KEYS.PROJECT_BATCHES_STATUS, Number(variables.id)])
        },
        onError
    })
}
