import {CSSProperties, FocusEvent, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, KeyboardEvent} from 'react'
import {StyledEditableInput, StyledEditableInputWrapper} from '@components/commons/EditableInputText/style.ts'
import {Edit01Icon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'

export interface EditableInputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    name?: string
    type: HTMLInputTypeAttribute
    onEnterPress: () => void // handling Enter key press
    onResetCb?: () => void // handling Blur events
    inputSize?: 'sm' | 'md' | 'lg'
    width?: CSSProperties['width']
}

export const EditableInputText = forwardRef<HTMLInputElement, EditableInputProps>(
    ({name, type, className, disabled, placeholder, inputSize = 'md', width = 'auto', onEnterPress, ...rest}, ref) => {
        const {palette} = useTheme()

        const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.currentTarget.blur() // Remove focus from the input
            }
        }

        const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
            event.preventDefault()
            onEnterPress()
            event.currentTarget.blur()
        }

        return (
            <StyledEditableInputWrapper className={className} disabled={disabled} size={inputSize} width={width}>
                <StyledEditableInput
                    ref={ref}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown} // Attach the event handler
                    value={rest.value}
                    {...rest}
                />
                {!disabled && <Edit01Icon color={palette.neutral['400']} size={24} />}
            </StyledEditableInputWrapper>
        )
    }
)

EditableInputText.displayName = 'EditableInputText'
