import {Popover} from '@/components/ui/Popover'
import {BatchesStatusSelectWrapper, SyledPopoverContent} from './style'
import {useTranslation} from 'react-i18next'
import {ChevronDownIcon, XCloseIcon} from '@/components/ui/Icon'
import {FC, useMemo} from 'react'
import Checkbox from '@/components/commons/CheckBox'
import {batchBadgeRenderer} from '@/utilities/statusesHelpers'
import {BatchStatus} from '../../types'

type BatchesStatusSelectProps = {
    value?: BatchStatus[]
    onChange?: (newVal: BatchStatus[]) => void
}

export const BatchesStatusSelect: FC<BatchesStatusSelectProps> = ({value = [], onChange}) => {
    const {t} = useTranslation()

    const statuses = useMemo(
        () =>
            [
                {
                    label: t('project:statuses:inactive'),
                    value: 'inactive'
                },
                {
                    label: t('project:statuses:paused'),
                    value: 'paused'
                },
                {
                    label: t('project:statuses:in_progress'),
                    value: 'in_progress'
                },
                {
                    label: t('project:statuses:completed'),
                    value: 'completed'
                }
            ] as const satisfies ReadonlyArray<{label: string; value: BatchStatus}>,
        [t]
    )

    const toggleStatus = (status: BatchStatus) => {
        if (value.includes(status)) {
            onChange?.(value?.filter(s => s !== status) ?? [])
            return
        }

        onChange?.([...value, status])
    }

    const isActive = !!value.length

    return (
        <BatchesStatusSelectWrapper isActive={isActive}>
            <Popover
                triggerProps={{
                    asChild: true
                }}
                trigger={
                    <div className="trigger">
                        {!!value.length && `(${value.length}) `}
                        {t('commons:status')}
                        <div className="chevron-down">
                            <ChevronDownIcon size={20} />
                        </div>
                        {isActive && (
                            <XCloseIcon
                                onClick={e => {
                                    e.stopPropagation()
                                    onChange?.([])
                                }}
                                size={20}
                            />
                        )}
                    </div>
                }
                contentProps={{
                    align: 'start'
                }}
            >
                <SyledPopoverContent>
                    {statuses.map(status => (
                        <div className="status-item" key={status.value}>
                            <Checkbox
                                id={status.value}
                                checked={value.includes(status.value)}
                                onChange={() => {
                                    toggleStatus(status.value)
                                }}
                            />
                            {batchBadgeRenderer(status.value, t)}
                        </div>
                    ))}
                </SyledPopoverContent>
            </Popover>
        </BatchesStatusSelectWrapper>
    )
}
