import {useQuery} from '@tanstack/react-query'
import {httpGetProcessesEdges} from '../services/process.http'
import {QUERY_KEYS} from '@/queryClient'

type useProcessesPropsEdges = {
    id: number
}

export const useProcessesEdges = ({id}: useProcessesPropsEdges) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROCESS_EDGES, id],
        queryFn: () => httpGetProcessesEdges(id)
    })
}
