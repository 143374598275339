import Button from '@/components/ui/Button'
import {ComponentProps, FC, useState} from 'react'
import {PlusIcon} from '@/components/ui/Icon'
import {Tooltip} from '@/components/ui/Tooltip'
import {useTranslation} from 'react-i18next'
import {Popover} from '@/components/ui/Popover'
import {StyledAdderButton} from './style'
import {AdderButtonPopoverContent} from './AdderButtonPopoverContent'
import {useAddNode} from '@/features/graph-viewer/helpers/useAddNode'
import {useSearchParams} from 'react-router-dom'
import {SELECTED_NODE_PARAM_KEY} from '@/features/graph-viewer/constants'
import {useCenterViewPortOnNode} from '@/features/graph-viewer/helpers/useCenterViewPortOnNode'

type AdderButtonProps = Omit<ComponentProps<typeof Button>, 'children' | 'onClick'> & {
    mode: 'series' | 'parallel'
    parentId: string
    childId: string
    onPopoverOpenChange?: (open: boolean) => void
}
export const AdderButton: FC<AdderButtonProps> = ({mode, onPopoverOpenChange, parentId, childId, ...props}) => {
    const {t} = useTranslation()
    const [, setSearchParams] = useSearchParams()
    const [openPopover, setOpenPopover] = useState(false)
    const {addNode} = useAddNode()

    const {centerViewPort} = useCenterViewPortOnNode()

    const setOpenPopoverFn = (open: boolean) => {
        setOpenPopover(open)
        onPopoverOpenChange?.(open)
    }

    const onClick = !openPopover ? () => setOpenPopoverFn(true) : undefined

    const baseButton = (
        <StyledAdderButton {...props} iconOnly variant="secondary" onClick={onClick}>
            <PlusIcon size={14} />
        </StyledAdderButton>
    )
    const buttonWithTooltip = (
        <Tooltip
            delayDuration={0}
            triggerProps={{
                asChild: true
            }}
            trigger={baseButton}
            content={
                mode === 'series' ? t('projectDetails:process:addInSeries') : t('projectDetails:process:addInParallel')
            }
        />
    )

    const buttonWithPopover = (
        <Popover
            trigger={baseButton}
            triggerProps={{asChild: true}}
            rootProps={{
                open: openPopover,
                onOpenChange: setOpenPopoverFn
            }}
            contentProps={{side: 'right', style: {zIndex: 2}}}
        >
            <AdderButtonPopoverContent
                onOptionSelect={data => {
                    const newNode = addNode({parentId, childId, data})

                    centerViewPort(newNode)

                    setSearchParams(prev => {
                        prev.set(SELECTED_NODE_PARAM_KEY, newNode.id)
                        return prev
                    })
                }}
                setOpenPopover={setOpenPopoverFn}
            />
        </Popover>
    )
    return openPopover ? buttonWithPopover : buttonWithTooltip
}
