import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetTaxonomy} from '../services/taxonomies.http'

export const useTaxonomy = (
    id: number,
    options?: {
        enabled?: boolean
    }
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TAXONOMIES, id],
        queryFn: () => httpGetTaxonomy(id),
        ...options
    })
}
