import {useMutation} from '@tanstack/react-query'
import {httpCompleteTask} from '../services/tasks.http'
import {errorHandler} from '@utilities/helpers.ts'

type UseCompleteTaskProps = {
    onSuccess?: () => void
}
export const useCompleteTask = ({onSuccess}: UseCompleteTaskProps) => {
    return useMutation({
        mutationFn: httpCompleteTask,
        onError: errorHandler,
        onSuccess
    })
}
