import {Flexbox} from '@/components/ui/Flexbox'
import {FC} from 'react'
import {StyledStatCard, StyledStatHeading, StyledStatValue, StyledTaskTypeButton} from '../style'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {TASK_TYPES, TaskType} from '../../constants'

type ManHoursChartsProps = {
    total_consumed: number
    total_estimated: number
    data: {x: string; y_consumed: number; y_estimated: number}[]
    taskType: TaskType
    onTaskTypeChange: (newTaskType: TaskType) => void
}

export const ManHoursCharts: FC<ManHoursChartsProps> = ({
    data,
    total_consumed,
    total_estimated,
    taskType,
    onTaskTypeChange
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Flexbox width={'100%'} height={'369px'}>
            <StyledStatCard>
                <Flexbox height={'100%'} width={'100%'} direction="column" gap={4}>
                    <Flexbox justify="space-between" align="center" width={'100%'}>
                        <StyledStatHeading>{t('stats:man_hours')}</StyledStatHeading>
                        <Flexbox gap={3}>
                            <StyledTaskTypeButton
                                size="sm"
                                variant="ghost"
                                onClick={() => onTaskTypeChange(TASK_TYPES.ALL)}
                                isSelected={taskType === TASK_TYPES.ALL}
                            >
                                {t('commons:all')}
                            </StyledTaskTypeButton>
                            <StyledTaskTypeButton
                                size="sm"
                                variant="ghost"
                                onClick={() => onTaskTypeChange(TASK_TYPES.EDIT)}
                                isSelected={taskType === TASK_TYPES.EDIT}
                            >
                                {t('commons:edit')}
                            </StyledTaskTypeButton>
                            <StyledTaskTypeButton
                                size="sm"
                                variant="ghost"
                                onClick={() => onTaskTypeChange(TASK_TYPES.QA)}
                                isSelected={taskType === TASK_TYPES.QA}
                            >
                                {t('commons:qa')}
                            </StyledTaskTypeButton>
                        </Flexbox>
                    </Flexbox>
                    <StyledStatValue>
                        {t('stats:consumed_over_estimated', {consumed: total_consumed, estimated: total_estimated})}
                    </StyledStatValue>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart width={500} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis tickFormatter={tick => `${tick}h`} />
                            <Legend layout="horizontal" verticalAlign="top" align="center" />
                            <Tooltip />
                            <Line
                                name={t('stats:consumed') satisfies string}
                                type="monotone"
                                dataKey="y_consumed"
                                stroke={theme.palette.primary[400]}
                                strokeWidth={2}
                                dot={false}
                            />
                            <Line
                                name={t('stats:estimated') satisfies string}
                                type="monotone"
                                dataKey="y_estimated"
                                stroke={theme.palette.primary[800]}
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Flexbox>
            </StyledStatCard>
        </Flexbox>
    )
}
