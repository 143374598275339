import {Flexbox} from '@/components/ui/Flexbox'
import {BatchesManualAssignmentWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import InputText from '@/components/commons/InputText'
import {SearchLgIcon} from '@/components/ui/Icon'
import {ProjectVendorSelect} from '@/features/vendors/components/VendorSelect/ProjectVendorSelect'
import {VendorSortValue, VendorSorter} from '@/features/vendors/components/VendorSorter'
import {useProjectVendors} from '@/features/projects/queries/useProjectVendor'
import {VendorList} from './VendorList'
import Spinner from '@/components/ui/Spinner'
import Button from '@/components/ui/Button'
import {useManualAssignBatch} from '../../queries/useManualAssignBatch'
import toast from 'react-hot-toast'
import {EmptySearch} from '@/components/commons/EmptySearch'
import {useManualBulkAssignBatches} from '../../queries/useManualBulkAssignBatches'
import {BatchRecord} from '../../types'

type BatchesManualAssigmentSidebarProps = {
    batches: BatchRecord | BatchRecord[]
    projectId: number
    onClose: () => void
    onSuccess?: () => void
}

export const BatchesManualAssigmentSidebar: FC<BatchesManualAssigmentSidebarProps> = ({
    batches,
    projectId,
    onClose,
    onSuccess
}) => {
    const {t} = useTranslation()

    const [searchText, setSearchText] = useState('')
    const [searchVendor, setSearchVendor] = useState<number | null>(null)
    const [sorting, setSorting] = useState<VendorSortValue>({
        order_by: 'unit_capacity',
        order_direction: 'desc'
    })

    const [selectedVendorManager, setSelectedVendorManager] = useState<number | null>(null)

    const {mutate: manualAssignBatch, isLoading: isLoadingManualAssignment} = useManualAssignBatch({
        onError: () => toast.error(t('commons:genericError')),
        onSuccess: () => {
            onClose()
            onSuccess?.()
            toast.success(t('commons:genericSuccess'))
        }
    })

    const {mutate: manualBulkAssignBatch, isLoading: isLoadingManualBulkAssignment} = useManualBulkAssignBatches({
        onError: () => toast.error(t('commons:genericError')),
        onSuccess: () => {
            onClose()
            onSuccess?.()
            toast.success(t('commons:genericSuccess'))
        }
    })

    const projectVendorsQuery = useProjectVendors(projectId, {
        vendor_manager: searchText || undefined,
        vendor_id: searchVendor || undefined,
        ...sorting
    })

    const handleSubmit = () => {
        if (!selectedVendorManager) {
            return
        }

        if (batches instanceof Array) {
            manualBulkAssignBatch({
                projectId,
                assignments: batches.map(batch => ({
                    batch_id: batch.id,
                    vendor_manager_id: selectedVendorManager
                }))
            })
            return
        }

        manualAssignBatch({batchId: batches.id, vendor_manager_id: selectedVendorManager})
    }

    let listContent
    if (projectVendorsQuery.data && !projectVendorsQuery.data.length) {
        listContent = (
            <Flexbox width={'100%'} justify="center">
                <EmptySearch
                    title={t('projectDetails:vendors:no_vendors')}
                    subtitle={t('projectDetails:vendors:no_vendors_subtitle')}
                />
            </Flexbox>
        )
    } else if (projectVendorsQuery.data) {
        listContent = (
            <VendorList
                vendors={projectVendorsQuery.data}
                value={selectedVendorManager}
                onChange={setSelectedVendorManager}
                selectable
            />
        )
    } else {
        listContent = <Spinner size={50} color="black" />
    }

    return (
        <BatchesManualAssignmentWrapper>
            <Flexbox className="sidebar-header" direction="column" width={'100%'}>
                <h2>{t('batches:assign_batch')}</h2>
                <h3>
                    {batches instanceof Array
                        ? t('batches:batches_count', {batch_number: batches.length})
                        : batches.name}
                </h3>
            </Flexbox>
            <Flexbox className="search-section" gap={4} width={'100%'}>
                <InputText
                    className="searchbar"
                    typeIcon={<SearchLgIcon size={20} />}
                    type="text"
                    value={searchText}
                    onChange={e => setSearchText(e.currentTarget.value)}
                    placeholder={t('projectDetails:vendors:search_vendor_manager') satisfies string}
                />
                <ProjectVendorSelect value={searchVendor} onChange={setSearchVendor} projectId={projectId} />
            </Flexbox>
            <Flexbox className="sorting-section">
                <VendorSorter value={sorting} onChange={setSorting} />
            </Flexbox>
            <Flexbox className="vendors-list-container" direction="column" width={'100%'} gap={5}>
                <p className="vendors-title">{t('projectDetails:tabs:vendors')}</p>
                {listContent}
            </Flexbox>
            <Flexbox className="cta-container" gap={2} justify="end">
                <Button variant="secondary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isLoadingManualAssignment || isLoadingManualBulkAssignment || !selectedVendorManager}
                >
                    {t('commons:save')}
                </Button>
            </Flexbox>
        </BatchesManualAssignmentWrapper>
    )
}
