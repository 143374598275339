import {StatsPerformance} from '.'
import {DATE_RANGES} from '../../constants'
import {dateRangeTypeToDateRange} from '../../helpers'
import {usePerformanceStatsSearchParams} from '../../hooks/usePerformanceSearchParams'

export const StatsPerformanceWithParams = () => {
    const {searchParams: performanceSearchParams, setSearchParams: setPerformanceSearchParams} =
        usePerformanceStatsSearchParams()
    return (
        <StatsPerformance
            onRangeTypeSelect={newRangeType => {
                if (newRangeType !== 'custom') {
                    setPerformanceSearchParams({
                        start_date: null,
                        end_date: null,
                        range_type: newRangeType
                    })
                } else {
                    setPerformanceSearchParams({
                        ...dateRangeTypeToDateRange(DATE_RANGES.LAST_WEEK),
                        range_type: newRangeType
                    })
                }
            }}
            rangeType={performanceSearchParams.range_type}
            dateRange={[performanceSearchParams.start_date ?? null, performanceSearchParams.end_date ?? null]}
            onDateRangeChange={newRange => {
                setPerformanceSearchParams({
                    start_date: newRange?.[0] ?? null,
                    end_date: newRange?.[1] ?? null,
                    range_type: 'custom'
                })
            }}
            metric={performanceSearchParams.metric}
            onMetricChange={newMetric => setPerformanceSearchParams({metric: newMetric})}
        />
    )
}
