import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetRoles} from '@/features/roles/services/roles.http.ts'

export const useRoles = () => {
    const query = useQuery({
        queryKey: [QUERY_KEYS.ROLES],
        queryFn: httpGetRoles
    })

    return {
        ...query,
        data: query.data ?? []
    }
}
