import {useInfiniteQuery} from '@tanstack/react-query'
import {useState} from 'react'
import {TableActiveSorter} from '@components/commons/Table'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {debounce} from '@utilities/helpers.ts'
import {httpGetArchivedTemplates} from '@/features/templates/services/templates.http.ts'

export const useArchivedTemplates = () => {
    const [searchValue, setSearchValue] = useState('')
    const [activeSorter, setActiveSorter] = useState<TableActiveSorter<undefined>>({
        orderBy: undefined,
        orderDirection: undefined
    })

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.ARCHIVED_TEMPLATES, activeSorter, searchValue],
        queryFn: ({pageParam = 1}) => httpGetArchivedTemplates(pageParam, activeSorter, searchValue),
        getNextPageParam: lastPage => lastPage.current_page < lastPage.last_page
    })

    const search = debounce((value: string) => {
        setSearchValue(value)
    }, 750)

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages?.flatMap(page => page.data) ?? [],
        search,
        sorter: {...activeSorter, onSort: setActiveSorter}
    }
}
