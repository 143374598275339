export const CAN_READ_COMPANY_USER = 'can_read_company_users' as const
export const CAN_WRITE_COMPANY_USER = 'can_write_company_users' as const
export const CAN_READ_VENDOR_USER = 'can_read_vendor_users' as const
export const CAN_WRITE_VENDOR_USER = 'can_write_vendor_users' as const
export const CAN_READ_TAXONOMY = 'can_read_template' as const
export const CAN_WRITE_TAXONOMY = 'can_write_template' as const
export const CAN_READ_TEMPLATE = 'can_read_template' as const
export const CAN_WRITE_TEMPLATE = 'can_write_template' as const
export const CAN_READ_COMPANY_STATS = 'can_read_company_stats' as const
export const CAN_READ_VENDOR_STATS = 'can_read_vendor_stats' as const
export const CAN_READ_PROJECT = 'can_read_project' as const
export const CAN_WRITE_PROJECT = 'can_write_project' as const
export const CAN_CHECK_PROJECT_DASH = 'can_check_project_dash' as const
export const CAN_CHECK_SUBPROJECT_DASH = 'can_check_subproject_dash' as const
export const CAN_CHECK_BATCH_DASH = 'can_check_batch_dash' as const
export const CAN_CHECK_SUBBATCH_DASH = 'can_check_subbatch_dash' as const
export const COMPANY_ASSIGN_WORKLOAD = 'company_assign_workload' as const
export const VENDOR_ASSIGN_WORKLOAD = 'vendor_assign_workload' as const
export const INVENTORY_ACCESS = 'sub_batch_inventory_access' as const //used for inner checks
export const CLAIM_TASK = 'claim_task' as const

export const USER_GUARD_PERMISSIONS = [
    CAN_READ_COMPANY_USER,
    CAN_WRITE_COMPANY_USER,
    CAN_READ_VENDOR_USER,
    CAN_WRITE_VENDOR_USER
] as const

export const PROJECTS_GUARD_PERMISSIONS = [CAN_READ_PROJECT, CAN_CHECK_SUBPROJECT_DASH, CAN_WRITE_PROJECT] as const

export const PROD_MANAGEMENT_GUARD_PERMISSIONS = [
    CAN_CHECK_BATCH_DASH,
    CAN_CHECK_SUBBATCH_DASH,
    CAN_CHECK_SUBPROJECT_DASH,
    CAN_CHECK_PROJECT_DASH,
    COMPANY_ASSIGN_WORKLOAD,
    VENDOR_ASSIGN_WORKLOAD
] as const

export const TAXONOMIES_GUARD_PERMISSIONS = [CAN_READ_TAXONOMY, CAN_WRITE_TAXONOMY] as const

export const TEMPLATES_GUARD_PERMISSIONS = [CAN_READ_TEMPLATE, CAN_WRITE_TEMPLATE] as const

export const STATS_GUARD_PERMISSIONS = [CAN_READ_COMPANY_STATS, CAN_READ_VENDOR_STATS] as const

export const TASKS_GUARD_PERMISSIONS = [CLAIM_TASK] as const
