import styled, {css} from 'styled-components'

export const SettingsFormWrapper = styled.form`
    ${({theme: {spacing}}) => css`
        padding-top: ${spacing * 2}px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: ${spacing * 6}px;
        max-width: 600px;

        & button {
            margin-top: ${spacing * 2}px;
            width: 64px;
        }
    `}
`
