import {PropsWithChildren, useEffect} from 'react'
import {DefaultTFuncReturn} from 'i18next'
import {unstable_useBlocker} from 'react-router-dom'

interface UnsavedBlockerProps extends PropsWithChildren {
    isDirty: boolean
    ignoreSearchParams?: boolean
    message?: string | DefaultTFuncReturn
}

export const UnsavedBlocker = ({
    isDirty,
    ignoreSearchParams = false,
    message = 'You have unsaved changes, are you sure you want to leave?',
    children
}: UnsavedBlockerProps) => {
    const blocker = unstable_useBlocker(({currentLocation, nextLocation}) => {
        if (ignoreSearchParams && currentLocation.pathname === nextLocation.pathname) {
            return false
        }

        return isDirty
    })

    if (blocker.state === 'blocked') {
        if (window.confirm(message?.toString())) {
            blocker.proceed?.()
        } else {
            blocker.reset?.()
        }
    }

    // reset the blocker if the user cleans the form
    useEffect(() => {
        if (blocker.state === 'blocked' && !isDirty) {
            blocker.reset()
        }
    }, [blocker, isDirty])

    return <>{children}</>
}
UnsavedBlocker.displayName = 'UnsavedBlocker'
