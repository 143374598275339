import {StyledBatchesTableEmptyState} from '@/features/batches/components/BatchesTableEmptyState/style.ts'
import {IconContainer} from '@components/ui/IconContainer'
import {Database01Icon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import Button from '@/components/ui/Button'

type BatchesTableEmptyStateProgramManagerViewProps = {
    isButtonDisabled?: boolean
    onGenerate: () => void
}

export const BatchesTableEmptyStateProgramManagerView: FC<BatchesTableEmptyStateProgramManagerViewProps> = ({
    isButtonDisabled,
    onGenerate
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <StyledBatchesTableEmptyState>
            <IconContainer>
                <Database01Icon fill={theme.palette.neutral['700']} />
            </IconContainer>
            <hgroup>
                <h3>{t('batches:table:empty_state_title_program_manager')}</h3>
                <h4>{t('batches:table:empty_state_subtitle_program_manager')}</h4>
            </hgroup>
            <Button variant="secondary" size="lg" onClick={onGenerate} disabled={isButtonDisabled}>
                {t('projectDetails:batches:generate_batches')}
            </Button>
        </StyledBatchesTableEmptyState>
    )
}

BatchesTableEmptyStateProgramManagerView.displayName = 'BatchesTableEmptyStateProgramManagerView'
