import {Sidebar} from '@/components/ui/Sidebar'
import {FC, ReactNode} from 'react'
import {BatchesManualAssigmentSidebar} from './BatchesManualAssigmentSidebar'
import {useProject} from '@/features/projects/queries/useProject'
import Spinner from '@/components/ui/Spinner'
import {useCompany} from '@/features/companies/queries/useCompany'
import {Flexbox} from '@/components/ui/Flexbox'
import {BatchRecord} from '../../types'
import {BatchesAutomaticAssigntMentSidebar} from './BatchesAutomaticAssigntMentSidebar'
import {useProjectBatch} from '../../queries/useProjectBatch'
import {BatchAlreadyAssigned} from './BatchAlreadyAssigned'

type BatchAssignmentSidebarProps = {
    batches: BatchRecord | BatchRecord[]
    projectId: number
    companyId: number
    onClose: () => void
    onSuccess?: () => void
}

export const BatchAssignmentSidebar: FC<BatchAssignmentSidebarProps> = ({
    onClose,
    onSuccess,
    batches,
    projectId,
    companyId
}) => {
    const projectQuery = useProject(projectId)
    const companyQuery = useCompany(companyId)

    const batchQuery = useProjectBatch(projectId, (batches as BatchRecord).id, {
        enabled: !(batches instanceof Array)
    })

    const isLoading =
        projectQuery.isLoading || companyQuery.isLoading || (!(batches instanceof Array) && batchQuery.isLoading)

    const error = projectQuery.error || companyQuery.error || batchQuery.isError

    if (error) {
        throw new Error('Cannot fetch project, company or batch')
    }

    const assignment_mode =
        projectQuery.data?.assignment_mode !== 'default'
            ? projectQuery.data?.assignment_mode
            : companyQuery.data?.assignment_mode

    let content: ReactNode

    if (isLoading) {
        content = (
            <Flexbox justify="center" align="center" width={'100%'} height={'100%'}>
                <Spinner size={50} color="black" />
            </Flexbox>
        )
    } else if (assignment_mode === 'manual' && batchQuery.data?.vendor_managers.length) {
        content = <BatchAlreadyAssigned batch={batchQuery.data} onClose={onClose} />
    } else if (assignment_mode === 'manual') {
        content = (
            <BatchesManualAssigmentSidebar
                batches={batches}
                projectId={projectId}
                onClose={onClose}
                onSuccess={onSuccess}
            />
        )
    } else if (!(batches instanceof Array)) {
        content = <BatchesAutomaticAssigntMentSidebar batch={batches} onClose={onClose} />
    }

    return (
        <Sidebar width={480} onClose={onClose}>
            {content}
        </Sidebar>
    )
}
