import {useCallback} from 'react'
import {useGraphContext} from '../context/graphContext'
import {getLayoutedElements} from '../auto-layout/auto-layout'

type RemoveNodeArgs = {
    nodeId: string
}

export const useRemoveNode = () => {
    const {nodes, edges, setNodes, setEdges} = useGraphContext()

    const removeNode = useCallback(
        ({nodeId}: RemoveNodeArgs) => {
            const parentId = edges.find(edge => edge.target === nodeId)?.source || 'start'
            const updatedNodes = nodes.filter(node => node.id !== nodeId)
            const updatedEdges = edges
                .filter(edge => edge.target !== nodeId)
                .map(edge => {
                    if (edge.source !== nodeId) {
                        return edge
                    }
                    return {
                        ...edge,
                        id: `e${parentId}-${edge.target}`,
                        source: parentId
                    }
                })

            const nonRedunantEdges = updatedEdges.filter(
                edge => !(edge.target === 'end' && updatedEdges.filter(e => e.source === edge.source).length > 1)
            )

            const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(updatedNodes, nonRedunantEdges)

            setNodes(layoutedNodes)
            setEdges(layoutedEdges)
        },
        [edges, nodes, setEdges, setNodes]
    )

    return {removeNode}
}
