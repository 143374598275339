import styled, {css} from 'styled-components'
import Container from '@components/ui/Container'

export const StyledContainer = styled(Container)(
    () => css`
        display: grid;
        grid-template-rows: auto auto 1fr;
        height: 100%;
    `
)

export const MainWrapper = styled('div')`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto minmax(320px, 1fr);
        gap: ${spacing * 8}px;
    `}
`

export const SectionTitle = styled.h2(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl}
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)

export const StyledMediaIdField = styled.span(
    ({theme: {palette}}) => css`
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)
