import {AdderButtonPopoverContentWrapper} from './style'
import {FC} from 'react'
import {ProcessNodeData} from '@/features/graph-viewer/types'

type AdderButtonPopoverContentProps = {
    setOpenPopover: (open: boolean) => void
    onOptionSelect: (data: ProcessNodeData) => void
}

export const AdderButtonPopoverContent: FC<AdderButtonPopoverContentProps> = ({setOpenPopover, onOptionSelect}) => {
    // TO DO: use this value to fetch option from be, for now options are hardcoded
    // const [search, setSearch] = useState('')
    // const deferredSearch = useDeferredValue(search)

    const onSelectItem = (value: number) => {
        setOpenPopover(false)
        onOptionSelect({
            process_type_id: value
        })
    }
    return (
        <AdderButtonPopoverContentWrapper>
            {/* TODO: show when be logic is ready*/}
            {/* <InputText
                value={search}
                onChange={e => setSearch(e.currentTarget.value)}
                typeIcon={<SearchLgIcon size={20} />}
                type="text"
                placeholder="Search modality type"
            /> */}
            <ul>
                <li onClick={() => onSelectItem(1)}>Image Box</li>
                <li onClick={() => onSelectItem(2)}>Image Segmentation</li>
            </ul>
        </AdderButtonPopoverContentWrapper>
    )
}
