import {useMutation} from '@tanstack/react-query'
import {httpUsersNotify} from '@/features/operators/services/operators.http.ts'

type useActivateBatchProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useSendOperatorsNotification = (options?: useActivateBatchProps) => {
    return useMutation({
        mutationFn: httpUsersNotify,
        onSuccess: () => {
            options?.onSuccess?.()
        }
    })
}
