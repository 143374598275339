import {TaxonomyFormValues, TaxonomyInstruction} from '@/features/taxonomies/types.ts'
import axios from 'axios'
import {UseFormResetField} from 'react-hook-form'
import {buildJSON} from '@utilities/helpers.ts'

export const buildInstructionsFromUrl = async (
    {instructions}: {instructions: TaxonomyInstruction[] | null},
    formCallback: UseFormResetField<TaxonomyFormValues>
) => {
    if (instructions?.length && instructions[0]) {
        try {
            const {data} = await axios.get(instructions[0].url, {
                responseType: 'blob' // Set the response type to 'blob'
            })

            const urlParts = instructions[0].url.split('/')
            const fileName = urlParts.pop() || 'instructions.pdf'

            const pdfBlob = new Blob([data], {type: 'application/pdf'})
            const pdfFile = new File([pdfBlob], fileName, {type: 'application/pdf'})

            formCallback('instructions', {defaultValue: pdfFile, keepDirty: true})
        } catch (error) {
            console.log('err', error)
            return null
        }
    }
}

export const buildTaxonomyJson = (data: {values: object}, formCallback: UseFormResetField<TaxonomyFormValues>) => {
    const jsonFile = buildJSON('taxonomy', data.values)
    formCallback('taxonomy', {defaultValue: jsonFile, keepDirty: true})
}
