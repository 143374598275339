import {useAuth} from '@/context/AuthProvider'
import {statsBatchSectionViewBySlug} from '@/features/roles/helpers'
import {useMemo} from 'react'

export const BatchStats = () => {
    const {user} = useAuth()
    const statsRenderer = useMemo(() => statsBatchSectionViewBySlug(user?.role.slug), [user])

    return statsRenderer
}

BatchStats.displayName = 'BatchStats'
