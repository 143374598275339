import {useQuery} from '@tanstack/react-query'
import {httpGetProcess} from '../services/process.http'
import {QUERY_KEYS} from '@/queryClient'

type useProcessesProps = {
    projectId: number
    processId: number

    enabled?: boolean
}

export const useProcess = ({projectId, processId, ...options}: useProcessesProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROCESS, projectId, processId],
        queryFn: () => httpGetProcess(projectId, processId),
        ...options
    })
}
