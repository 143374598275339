import {Control, Controller, FieldErrors, FieldValues, Path, UseFormRegister} from 'react-hook-form'
import {ProjectStructure, ProjectStructureType} from '../types'
import {DataServiceConfigurator} from '@/features/data-services/components/data-service-configurator'
import {TaxonomiesSelect} from '@/features/taxonomies/components/TaxonomiesSelect'
import {Flexbox} from '@/components/ui/Flexbox'
import Label from '@/components/ui/Label'
import InputText from '@/components/commons/InputText'
import {ComponentProps} from 'react'
import {TFunction} from 'i18next'
import {DYNAMIC_ERROR_MESSAGES} from './dynamicErrors'

type generateFieldProps<T extends FieldValues> = {
    fieldName: Readonly<string>
    fieldData: ProjectStructure[keyof ProjectStructure]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<T, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: FieldErrors<any>
    register: UseFormRegister<T>
    disabled?: boolean
    dataServiceConfiguratorProps?: Omit<ComponentProps<typeof DataServiceConfigurator>, 'type'>
    t: TFunction
}

const formatError = (errorMessage: string | undefined, fieldType: ProjectStructureType, t: TFunction) => {
    if (!errorMessage) {
        return
    }
    const tMessage = t(`dynamic_errors:${errorMessage}`)
    if (errorMessage === DYNAMIC_ERROR_MESSAGES.NOT_OF_CORRECT_TYPE) {
        const tType = t(`dynamic_types:${fieldType}`)
        return `${tMessage}${tType}`
    }
    return tMessage
}

export const generateField = <T extends FieldValues>({
    fieldData,
    fieldName,
    control,
    errors,
    register,
    dataServiceConfiguratorProps,
    t,
    disabled = false
}: generateFieldProps<T>) => {
    const {rendered_type, label, placeholder, helpText, is_required, type} = fieldData

    switch (rendered_type) {
        case 'data_source':
            return (
                <Flexbox direction="column" gap={1.5} width={'100%'}>
                    <Label>
                        {label}
                        {is_required ? '*' : ''}
                    </Label>
                    <Controller
                        name={fieldName as Path<T>}
                        control={control}
                        render={({field: {onChange, value, onBlur}}) => (
                            <DataServiceConfigurator
                                {...dataServiceConfiguratorProps}
                                disabled={disabled}
                                type="source"
                                value={value}
                                showFileRegex
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onChange={onChange as any}
                                onBlur={onBlur}
                                error={formatError(errors[fieldName]?.message as string, type, t)}
                            />
                        )}
                    />
                </Flexbox>
            )
        case 'data_target':
            return (
                <Flexbox direction="column" gap={1.5} width={'100%'}>
                    <Label>
                        {label}
                        {is_required ? '*' : ''}
                    </Label>
                    <Controller
                        name={fieldName as Path<T>}
                        control={control}
                        render={({field: {onChange, value, onBlur}}) => (
                            <DataServiceConfigurator
                                {...dataServiceConfiguratorProps}
                                disabled={disabled}
                                type="target"
                                value={value}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onChange={onChange as any}
                                onBlur={onBlur}
                                error={formatError(errors[fieldName]?.message as string, type, t)}
                            />
                        )}
                    />
                </Flexbox>
            )
        case 'taxonomy':
            return (
                <Controller
                    control={control}
                    name={fieldName as Path<T>}
                    render={({field: {onChange, value}}) => (
                        <TaxonomiesSelect
                            label={`${t('taxonomies:new_taxonomy')}${is_required ? '*' : ''}`}
                            value={value}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={onChange as any}
                            showInstructions
                            disabled={disabled}
                            errorMessage={formatError(errors[fieldName]?.message as string, type, t)}
                        />
                    )}
                />
            )
        case 'select':
            return null
        case 'text':
            return (
                <InputText
                    label={`${label}${is_required ? '*' : ''}`}
                    type="text"
                    placeholder={placeholder}
                    {...register(fieldName as Path<T>)}
                    disabled={disabled}
                    helpText={helpText}
                    errorMessage={formatError(errors[fieldName]?.message as string, type, t)}
                />
            )
    }
}
