import {StyledTaskImage, StyledCard, StyledType} from '@/features/tasks/components/OngoingTask/style.ts'
import Spinner from '@components/ui/Spinner'
import {useTheme} from 'styled-components'
import {SectionTitle} from '@pages/tasks/style.ts'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/Flexbox'
import {
    AlertCircleIcon,
    AlertTriangleIcon,
    ArrowNarrowUpRightIcon,
    CalendarIcon,
    CheckIcon,
    File06Icon
} from '@components/ui/Icon'
import {EmptySearch} from '@components/commons/EmptySearch'
import Button from '@components/ui/Button'
import {getDatesDifference} from '@utilities/helpers.ts'
import dayjs from '@utilities/dayjs.ts'
import {useAssignedTask} from '@/features/tasks/queries/useAssignedTask.ts'
import {useProcessTypes} from '@/features/settings/queries/useProcessTypes.ts'
import {useClaimTask} from '@/features/tasks/queries/useClaimTask.ts'
import {useCompleteTask} from '@/features/tasks/queries/useCompleteTask.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {useQueryClient} from '@tanstack/react-query'
import {useEffect} from 'react'
import {tasksSocket, tasksSocketEvents} from '@/features/tasks/services/taskSocket.ts'

export const OngoingTask = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const queryClient = useQueryClient()
    //const userSessionsQuery = useUserSessions()
    const assignedTaskQuery = useAssignedTask()
    const processTypesQuery = useProcessTypes()
    const claimTaskMutation = useClaimTask({
        onError: async error => {
            if (error.response?.data.message == 'no_free_task') {
                await queryClient.cancelQueries([QUERY_KEYS.ASSIGNED_TASK])
                queryClient.setQueriesData([QUERY_KEYS.ASSIGNED_TASK], () => '')
                queryClient.setQueriesData([QUERY_KEYS.USER_SESSIONS], () => '')
            }
        }
    })

    const handleCompleteTask = async () => {
        await queryClient.cancelQueries([QUERY_KEYS.COMPLETED_TASKS])
        await queryClient.invalidateQueries([QUERY_KEYS.COMPLETED_TASKS])
        await queryClient.cancelQueries([QUERY_KEYS.ASSIGNED_TASK])
        queryClient.setQueriesData([QUERY_KEYS.ASSIGNED_TASK], () => '')
    }
    const completeTaskMutation = useCompleteTask({
        onSuccess: handleCompleteTask
    })
    const processType = processTypesQuery.data?.find(
        processType => processType.id == assignedTaskQuery.data?.process?.process_type_id
    )

    useEffect(() => {
        if (assignedTaskQuery.data) {
            tasksSocket.connect()
            tasksSocket.emit(tasksSocketEvents.JOIN_ROOM, {
                roomId: `tasks:sync:${assignedTaskQuery.data?.id}`
            })
            tasksSocket.on(tasksSocketEvents.TASK_SYNC_STATUS, handleCompleteTask)

            return () => {
                tasksSocket.emit(tasksSocketEvents.LEAVE_ROOM, {
                    roomId: `tasks:sync:${assignedTaskQuery.data?.id}`
                })
                tasksSocket.off(tasksSocketEvents.TASK_SYNC_STATUS, handleCompleteTask)
                tasksSocket.disconnect()
            }
        }
    }, [assignedTaskQuery.data])

    return (
        <Flexbox direction="column" gap={6} width="100%">
            <SectionTitle>{t('tasks:ongoing')}</SectionTitle>
            {assignedTaskQuery.isFetching ? (
                <StyledCard width="100%" justify="center" align="center" height="200px">
                    <Spinner size={32} color={theme.palette.neutral[900]} />
                </StyledCard>
            ) : assignedTaskQuery.data ? (
                <StyledCard width="100%">
                    <StyledTaskImage src={assignedTaskQuery.data.media.url} />
                    <Flexbox direction="column" gap={6} padding={8} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <Flexbox direction="column" gap={2}>
                                <Flexbox direction="column">
                                    <h3>
                                        {t('tasks:media_id')}: {assignedTaskQuery.data.media.id}
                                    </h3>
                                </Flexbox>
                                <Flexbox className={'claim-date'} align="center" gap={2}>
                                    <CalendarIcon size={16} />
                                    <span>
                                        {t(`tasks:claimed_n_days_ago`, {
                                            count: Math.abs(
                                                getDatesDifference(
                                                    dayjs(assignedTaskQuery.data.claimed_at).startOf('day'),
                                                    dayjs().startOf('day'),
                                                    'day'
                                                )
                                            )
                                        })}
                                    </span>
                                </Flexbox>
                            </Flexbox>
                            {/*TODO: add the anchor ui component with the button style*/}
                            <Button
                                disabled={!assignedTaskQuery.data.instructions_url}
                                onClick={() => window.open(assignedTaskQuery.data?.instructions_url)}
                                variant="linkColor"
                            >
                                <File06Icon width={20} />
                                {t('tasks:view_instructions')}
                            </Button>
                        </Flexbox>
                        <Flexbox gap={4}>
                            <StyledType direction="column" gap={1.5}>
                                <span>{t('tasks:task_type')}</span>
                                {/*TODO: to translate?*/}
                                <p>{assignedTaskQuery.data.type ?? '-'}</p>
                            </StyledType>
                            <StyledType direction="column" gap={1.5}>
                                <span>{t('tasks:modality_type')}</span>
                                {/*TODO: to translate?*/}
                                <p>{processType?.modality_type ?? '-'}</p>
                            </StyledType>
                        </Flexbox>
                        <Flexbox gap={4}>
                            <Button
                                variant="primary"
                                disabled={!assignedTaskQuery.data?.label_studio_url}
                                onClick={() => window.open(assignedTaskQuery.data?.label_studio_url)}
                            >
                                {t('tasks:open_label_editor')}
                                <ArrowNarrowUpRightIcon size={20} />
                            </Button>
                            <Button
                                variant="secondary"
                                disabled={completeTaskMutation.isLoading || claimTaskMutation.isLoading}
                                onClick={() => {
                                    if (assignedTaskQuery.data) {
                                        completeTaskMutation.mutate(assignedTaskQuery.data.id)
                                    }
                                }}
                            >
                                <CheckIcon size={20} />
                                {t('tasks:mark_as_completed')}
                                {(completeTaskMutation.isLoading || claimTaskMutation.isLoading) && (
                                    <Spinner size={20} />
                                )}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </StyledCard>
            ) : assignedTaskQuery.isError ? (
                <EmptySearch title={t('errors:default')} icon={<AlertTriangleIcon />} />
            ) : (
                <EmptySearch
                    title={t('tasks:no_ongoing_title')}
                    subtitle={t('tasks:claim_new_task_subtitle')}
                    customContent={
                        <Button
                            variant="primary"
                            disabled={claimTaskMutation.isLoading}
                            onClick={() => claimTaskMutation.mutate()}
                        >
                            {t('tasks:claim_task')}
                            {claimTaskMutation.isLoading && <Spinner size={20} />}
                        </Button>
                    }
                    icon={<AlertCircleIcon />}
                />
            )}
        </Flexbox>
    )
}

OngoingTask.displayName = 'OngoingTask'
