import {createBrowserRouter} from 'react-router-dom'
import {routes} from '@utilities/constants'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import PublicRoute from '@route-guards/public-route/PublicRoute.tsx'
import PublicLayout from '@layouts/public-layout/PublicLayout.tsx'
import PrivateLayout from '@layouts/private-layout/PrivateLayout.tsx'
import {UserRouteGuard} from '@route-guards/user-route/UserRouteGuard.tsx'
import {TemplatesRouteGuard} from '@route-guards/templates-route/TemplatesRouteGuard.tsx'
import {ProjectsRouteGuard} from '@route-guards/projects-route/ProjectsRouteGuard.tsx'
import {TasksRouteGuard} from '@route-guards/tasks-route/TasksRouteGuard.tsx'
import {ProductionManagementRouteGuard} from '@route-guards/production-management-route/ProductionManagementRouteGuard.tsx'
import {TaxonomiesRouteGuard} from '@route-guards/taxonomies-route/TaxonomiesRouteGuard.tsx'
import {StatsRouteGuard} from '@route-guards/stats-route/StatsRouteGuard.tsx'

const router = createBrowserRouter(
    [
        {
            ...routes.NOT_FOUND
        },
        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <UserRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [
                        {...routes.HOME, handle: {}},
                        {
                            ...routes.USERS,
                            children: [
                                {...routes.USERS_PROGRAM_MANAGER},
                                {...routes.USERS_CLIENT_ENGINEER},
                                {...routes.USERS_VENDOR_ADMIN},
                                {...routes.USERS_VENDOR_MANAGER},
                                {...routes.USERS_TEAM_LEAD},
                                {...routes.USERS_OPERATOR}
                            ]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <ProjectsRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [{...routes.PROJECTS}]
                },
                {
                    element: <ProjectsRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [
                        {
                            handle: {...routes.PROJECTS.handle},
                            children: [
                                {
                                    ...routes.PROJECT_DETAIL,
                                    handle: {...routes.PROJECT_DETAIL.handle},
                                    children: [
                                        {...routes.PROJECT_DETAIL_PROPERTIES},
                                        {...routes.PROJECT_DETAIL_PROCESS},
                                        {...routes.PROJECT_DETAIL_VENDORS},
                                        {...routes.PROJECT_DETAIL_BATCHES},
                                        {...routes.PROJECT_DETAIL_OPERATORS},
                                        {...routes.PROJECT_DETAIL_STATS},
                                        {...routes.PROJECT_DETAIL_SETTINGS}
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            handle: {...routes.HOME.handle},
            children: [
                {
                    element: <ProductionManagementRouteGuard />,
                    handle: {...routes.PROJECTS.handle},
                    children: [
                        {
                            ...routes.PROJECT_DETAIL_BATCH,
                            handle: {...routes.PROJECT_DETAIL.handle},
                            children: [
                                {...routes.PROJECT_DETAIL_BATCH_STATS, handle: {...routes.PROJECT_DETAIL_BATCH.handle}},
                                {
                                    ...routes.PROJECT_DETAIL_BATCH_OPERATORS,
                                    handle: {...routes.PROJECT_DETAIL_BATCH.handle}
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <TasksRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [{...routes.TASKS}]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <ProductionManagementRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [
                        {
                            ...routes.PRODUCTION_MANAGEMENT,
                            children: [
                                {...routes.PRODUCTION_MANAGEMENT_BATCHES},
                                {...routes.PRODUCTION_MANAGEMENT_OPERATORS}
                            ]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            handle: {...routes.HOME.handle},
            children: [
                {
                    element: <ProductionManagementRouteGuard />,
                    handle: {...routes.PRODUCTION_MANAGEMENT.handle},
                    children: [
                        {
                            ...routes.PROD_MANAGEMENT_BATCH_DETAILS,
                            handle: {...routes.PROD_MANAGEMENT_BATCH_DETAILS.handle},
                            children: [
                                {...routes.PROD_MANAGEMENT_BATCH_DETAILS_STATS},
                                {...routes.PROD_MANAGEMENT_BATCH_DETAILS_OPERATORS}
                            ]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <TemplatesRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [
                        {
                            ...routes.TEMPLATES,
                            children: [{...routes.ACTIVE_TEMPLATES}, {...routes.ARCHIVED_TEMPLATES}]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <TaxonomiesRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [
                        {
                            ...routes.TAXONOMIES,
                            children: [{...routes.ACTIVE_TAXONOMIES}, {...routes.INACTIVE_TAXONOMIES}]
                        }
                    ]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <StatsRouteGuard />,
                    handle: {...routes.HOME.handle},
                    children: [{...routes.STATS}]
                }
            ]
        },

        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <PrivateRoute />, //todo: change with Settings route guard
                    handle: {...routes.HOME.handle},
                    children: [{...routes.SETTINGS}]
                }
            ]
        },

        //todo: remove this one
        {
            element: <PrivateLayout withHeader />,
            children: [
                {
                    element: <PrivateRoute />,
                    handle: {...routes.HOME.handle},
                    children: [{...routes.SHOWCASE}]
                }
            ]
        },

        {
            element: <PublicLayout />, // Public layout route
            children: [
                {
                    element: <PublicRoute />,
                    children: [{...routes.LOGIN}, {...routes.SSO_REDIRECT}, {...routes.NOT_FOUND}]
                }
            ]
        }
    ],
    {
        basename: '/'
    }
)

export default router
