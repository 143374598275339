import {FC, ReactNode} from 'react'
import {StyledInputWrapper} from '@components/ui/InputWrapper/style.ts'

interface InputWrapperProps {
    children: ReactNode
    className?: string
    hasError: boolean
    touched: boolean
    visibilityToggle?: boolean
}

export const InputWrapper: FC<InputWrapperProps> = ({children, className, hasError, touched, visibilityToggle}) => {
    return (
        <StyledInputWrapper
            className={className}
            touched={touched}
            hasError={hasError}
            visibilityToggle={visibilityToggle}
            align="center"
            gap={1}
        >
            {children}
        </StyledInputWrapper>
    )
}

InputWrapper.displayName = 'InputWrapper'
