import {Modal} from '@components/ui/Modal'
import {FC, ReactNode} from 'react'
import Button from '@components/ui/Button'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import Spinner from '@components/ui/Spinner'
import {DefaultTFuncReturn} from 'i18next'
import {Copy03Icon} from '@components/ui/Icon'
import {
    StyledContent,
    StyledFooter,
    StyledCloneProjectModal,
    StyledFieldWrapper
} from '@/features/projects/components/clone-project-modal/style.ts'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    CLONE_PROJECT_FORM_MODEL,
    CloneProjectFromSchema,
    CloneProjectValidationSchema
} from '@/features/projects/components/clone-project-modal/CloneProjectFormModel.ts'
import InputText from '@components/commons/InputText'
import {ProjectRecord} from '@/features/projects/types.ts'

interface CloneProjectModalProps {
    project: ProjectRecord
    isLoading: boolean
    onClose: () => void
    onClone: (clonedProject: ProjectRecord) => void
    title: string | DefaultTFuncReturn
    paragraph: ReactNode
}

export const CloneProjectModal: FC<CloneProjectModalProps> = ({
    project,
    isLoading,
    onClose,
    onClone,
    title,
    paragraph
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields}
    } = useForm<CloneProjectValidationSchema>({
        mode: 'onBlur',
        defaultValues: {
            project_name: `Copy of ${project.name}`
        },
        resolver: zodResolver(CloneProjectFromSchema)
    })

    const onSubmit: SubmitHandler<CloneProjectValidationSchema> = data => {
        onClone({...project, name: data.project_name})
    }

    return (
        <Modal width={544} closeIconOffset="sm" onClose={onClose}>
            <StyledCloneProjectModal onSubmit={handleSubmit(onSubmit)}>
                <StyledContent>
                    <Copy03Icon size={24} fill={theme.palette.secondary['600']} />
                    <div>
                        <h3>{title}</h3>
                        <p>{paragraph}</p>
                        <StyledFieldWrapper>
                            <InputText
                                label={t(CLONE_PROJECT_FORM_MODEL.project_name.label)}
                                type={'text'}
                                touched={touchedFields.project_name}
                                errorMessage={t(errors.project_name?.message || '')}
                                placeholder={t(CLONE_PROJECT_FORM_MODEL.project_name.label).toString()}
                                {...register(t(CLONE_PROJECT_FORM_MODEL.project_name.name))}
                            />
                        </StyledFieldWrapper>
                    </div>
                </StyledContent>
                <StyledFooter>
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {t('commons:cancel')}
                    </Button>
                    <Button variant="primary" type={'submit'} disabled={isLoading}>
                        {t('commons:clone')}
                        {isLoading && <Spinner size={16} />}
                    </Button>
                </StyledFooter>
            </StyledCloneProjectModal>
        </Modal>
    )
}

CloneProjectModal.displayName = 'CloneProjectModal'
