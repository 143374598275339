import {useQuery} from '@tanstack/react-query'
import {httpGetProjectVendors, httpGetProjectVendorsParams} from '../services/projects.http'
import {QUERY_KEYS} from '@/queryClient'

export const useProjectVendors = (projectId: number, params: httpGetProjectVendorsParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROJECT_VENDORS, projectId, params],
        queryFn: () => httpGetProjectVendors(projectId, params)
    })
}
