import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

export const StyledBatchName = styled.span(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    `
)

export const StyledBatchCheckbox = styled.div`
    display: flex;
    justify-items: center;
    height: 24px;
    width: 100%;
`

export const StyledBatchMainText = styled.p`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.neutral[900]};
        font-size: ${typography.textSm};
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    `}
`

export const StyledBatchSubText = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textXs};
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    `}
`

export const SelectionCounterText = styled.p`
    ${({theme: {typography, palette}}) => css`
        font-size: ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral[600]};
    `}
`

export const StyledLink = styled(Link)`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.primary['700']};
        font-weight: 500;
        ${typography.textSm};
        cursor: pointer;
    `}
`
