import {Navigate} from 'react-router-dom'
import {useAuth} from '@context/AuthProvider.tsx'
import {roleBasedRedirect} from '@/features/roles/helpers.tsx'

const HomeRedirect = () => {
    const {user, accessToken} = useAuth()

    return <Navigate to={roleBasedRedirect(accessToken, user?.role?.slug)} />
}

export default HomeRedirect
