import {debounce} from '@utilities/helpers.ts'
import {ProjectsSearchParams} from '@/features/projects/types.ts'
import {useProjects} from '@/features/projects/queries/useProjects.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'

export const useProjectsTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof ProjectsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, ProjectsSearchParams)
    const projectsQuery = useProjects(searchParams)

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: ProjectsSearchParams['orderBy']
        orderDirection: ProjectsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...projectsQuery,
        search,
        searchValue: searchParams.search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
