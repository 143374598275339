import {z} from 'zod'

export type AuthState = {
    user: User | null
    accessToken: string
    refreshToken: string
}

export const Slug = z.enum([
    'company_admin',
    'vendor_admin',
    'program_manager',
    'client_engineer',
    'vendor_manager',
    'team_lead',
    'labeler'
])
export type Slug = z.infer<typeof Slug>

export type UserRole = {
    id: number
    name: string
    slug: Slug
    is_vendor: boolean
    created_at: string
    updated_at: string
    deleted_at: string
}

type TaskType = {
    created_at: string
    deleted_at: string | null
    id: string
    name: string
    description: string
    updated_at: string
}

type ProcessType = {
    created_at: string
    deleted_at: string | null
    id: string
    modality: string
    modality_type: string
    updated_at: string
}

export type User = {
    jti: string
    id: number
    first_name: string
    last_name: string
    email: string
    company_id: number
    role_id: number
    supervisor_id: number
    created_at: string
    updated_at: string
    deleted_at: string
    skill_taxonomy_id: number
    role: UserRole
    permissions: string[]
    task_types: TaskType[]
    process_types: ProcessType[]
}

/*{
    "jti": "8ddc4001-81c2-403b-a1aa-10ec32583cb2",
    "id": 2,
    "first_name": "Francesco",
    "last_name": "Monti",
    "email": "francesco.monti@kromin.it",
    "company_id": null,
    "role_id": 2,
    "supervisor_id": null,
    "created_at": "2023-07-21T09:20:30.000Z",
    "updated_at": "2023-08-01T07:59:35.000Z",
    "deleted_at": null,
    "skill_taxonomy_id": null,
    "role": {
        "id": 2,
            "name": "program_manager",
            "slug": "program_manager",
            "is_vendor": 0,
            "created_at": "2023-07-20T10:04:48.000Z",
            "updated_at": "2023-07-20T10:04:48.000Z",
            "deleted_at": null
    },
    "permissions": [
        'can_read_company_users',
        'can_write_company_users',
        'can_read_vendor_users',
        'can_write_vendor_users',
        'can_read_template',
        'can_write_template',
        'can_read_project',
        'can_write_project',
        'can_check_project_dash',
        'can_check_subproject_dash',
        'can_check_batch_dash',
        'can_check_subbatch_dash',
        'company_assign_workload',
        'vendor_assign_workload',
        'batch_inventory_access',
        'project_inventory_access',
        'claim_batch',
        'claim_task'
]
}*/

export type LoginFormData = {
    email: string
    password: string
}
