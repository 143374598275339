import {Pagination} from '@/types/commons.ts'
import {z} from 'zod'

export type TaxonomyRecord = {
    id: number
    name: string | null
    values: object
    instructions: TaxonomyInstruction[] | null
    project_usage: number
    created_at: string | null
    updated_at: string | null
    deleted_at: string | null
    is_reusable: number
    is_active: number
}

export type TaxonomyInstruction = {
    url: string
    created_at: string
    updated_at: string
    deleted_at: string | null
    id: number
    taxonomy_id: number
}

export interface GetTaxonomiesResponse {
    pagination: Pagination
    data?: TaxonomyRecord[]
}

export type TaxonomyFormValues = {
    name: string
    taxonomy: File
    instructions: File
    is_active: boolean
}

export const TaxonomySearchParams = z.object({
    orderBy: z.enum(['name', 'values', 'instructions', 'projects_usage', 'created_at', 'updated_at']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type TaxonomySearchParams = z.infer<typeof TaxonomySearchParams>
