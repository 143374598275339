import {z} from 'zod'

export const HOME_FORM_MODEL = {
    text: {
        name: 'text',
        label: 'home:form:text',
        defaultValue: '',
        helpText: 'home:formHelpTexts:text'
    },
    email: {name: 'email', label: 'home:form:email', defaultValue: '', helpText: ''},
    password: {
        name: 'password',
        label: 'home:form:password',
        defaultValue: '',
        helpText: ''
    },
    confirmPassword: {
        name: 'confirmPassword',
        label: 'home:form:confirmPassword',
        defaultValue: '',
        helpText: ''
    },
    select: {
        name: 'select',
        label: 'home:form:select',
        defaultValue: [],
        helpText: 'home:formHelpTexts:select'
    },
    textarea: {
        name: 'textarea',
        label: 'home:form:textarea',
        defaultValue: '',
        helpText: 'home:formHelpTexts:textarea'
    },
    singleDate: {
        name: 'singleDate',
        label: 'home:form:singleDate',
        defaultValue: '',
        helpText: 'home:formHelpTexts:singleDate'
    },
    dateRange: {
        name: 'dateRange',
        label: 'home:form:dateRange',
        defaultValue: [],
        helpText: 'home:formHelpTexts:dateRange'
    },
    multipleDate: {
        name: 'multipleDate',
        label: 'home:form:multipleDate',
        defaultValue: '',
        helpText: 'home:formHelpTexts:multipleDate'
    },
    checkbox: {
        name: 'checkbox',
        label: 'home:form:checkbox',
        defaultValue: false,
        helpText: 'home:formHelpTexts:checkbox'
    },
    radio: {
        name: 'radio',
        label: 'home:form:checkbox',
        defaultValue: false,
        helpText: 'home:formHelpTexts:checkbox'
    }
} as const

export const HomeFormSchema = z
    .object({
        text: z.string().min(1, {message: 'errors:text'}),
        email: z
            .string()
            .min(1, {message: 'errors:email'})
            .email({
                message: 'errors:email_valid'
            })
            .optional(),
        password: z.string().min(6, {message: 'errors:password'}),
        confirmPassword: z.string().min(1, {message: 'errors:confirmPassword'}),
        select:
            z
                .array(
                    z.object({
                        label: z.string(),
                        value: z.string()
                    })
                )
                .min(1, {message: 'errors:select'}) ||
            z.object({
                label: z.string(),
                value: z.string()
            }),
        textarea: z.string().min(1, {message: 'errors:text'}).optional(),
        date: z.string().min(1, {message: 'errors:text'}).optional(),
        singleDate: z.date(),
        dateRange: z.array(z.date()).min(2, {message: 'errors:endDateRequired'}),
        multipleDate: z.array(z.date()).min(1, {message: 'errors:required'}),
        checkbox: z.boolean().refine(Boolean, {
            message: 'you must accept the checkbox to proceed'
        }),
        radio: z.boolean().refine(Boolean, {
            message: 'you must select a radio to proceed'
        })
    })
    .refine(data => data.password === data.confirmPassword, {
        path: ['confirmPassword'],
        message: 'errors:password_match'
    })

export type ValidationSchema = z.infer<typeof HomeFormSchema>
