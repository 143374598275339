import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import TextArea from '@components/commons/TextArea'
import InputSelect, {SelectValue} from '@components/commons/Select'
import {
    PROJECT_SETTINGS_FORM_MODEL,
    ProjectSettingsValidationSchema
} from '@pages/project-details/settings/form/settingsFormModel.ts'
import {StyledFields} from '@pages/project-details/settings/form/style.ts'
import {UserRecord} from '@/features/users/types.ts'
import InputText from '@components/commons/InputText'
import {CurrencyDollarIcon} from '@/components/ui/Icon'
import {Flexbox} from '@components/ui/Flexbox'
import {TemplateSelect} from '@/features/templates/components/template-select/TemplateSelect'

type ProjectSettingsForm = {
    contactUsersOptions: UserRecord[]
}
export const ProjectSettingsForm = ({contactUsersOptions}: ProjectSettingsForm) => {
    const {t} = useTranslation()
    const {
        control,
        register,
        formState: {errors, touchedFields}
    } = useFormContext<ProjectSettingsValidationSchema>()

    return (
        <StyledFields>
            <Flexbox direction="column" gap={4} width="500px">
                <InputText
                    label={t(PROJECT_SETTINGS_FORM_MODEL.project_name.label)}
                    type={'text'}
                    touched={touchedFields.name}
                    errorMessage={t(errors.name?.message || '')}
                    placeholder={t(PROJECT_SETTINGS_FORM_MODEL.project_name.label).toString()}
                    {...register(t(PROJECT_SETTINGS_FORM_MODEL.project_name.name))}
                />
                <Controller
                    render={({field: {onChange, value}}) => (
                        <InputSelect
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                            }}
                            disabled={true}
                            name={PROJECT_SETTINGS_FORM_MODEL.workstream_id.name}
                            label={t(PROJECT_SETTINGS_FORM_MODEL.workstream_id.label)}
                            placeholder={t(PROJECT_SETTINGS_FORM_MODEL.workstream_id.placeholder)}
                            helpText={t(PROJECT_SETTINGS_FORM_MODEL.workstream_id.helpText)}
                            size={'medium'}
                            options={[{label: 'Labeling', value: '1'}]}
                        />
                    )}
                    control={control}
                    name={PROJECT_SETTINGS_FORM_MODEL.workstream_id.name}
                />

                <Controller
                    render={({field: {onChange, value}}) => (
                        <TemplateSelect
                            disabled
                            value={value}
                            name={PROJECT_SETTINGS_FORM_MODEL.template_id.name}
                            label={t(PROJECT_SETTINGS_FORM_MODEL.template_id.label)}
                            placeholder={t(PROJECT_SETTINGS_FORM_MODEL.template_id.placeholder)}
                            errorMessage={t(errors.template_id?.message || '')}
                            helpText={t(PROJECT_SETTINGS_FORM_MODEL.template_id.helpText)}
                            onChange={newValue => {
                                if (!newValue) {
                                    return
                                }
                                onChange(newValue)
                            }}
                        />
                    )}
                    control={control}
                    name={PROJECT_SETTINGS_FORM_MODEL.template_id.name}
                />

                <Controller
                    defaultValue={[]}
                    render={({field: {onChange, value}}) => (
                        <InputSelect
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue[])
                            }}
                            size={'medium'}
                            isMulti={true}
                            name={PROJECT_SETTINGS_FORM_MODEL.contact_users.name}
                            label={t(PROJECT_SETTINGS_FORM_MODEL.contact_users.label)}
                            placeholder={t(PROJECT_SETTINGS_FORM_MODEL.contact_users.placeholder)}
                            errorMessage={t(errors.contact_users?.message || '')}
                            options={contactUsersOptions.map(e => ({
                                label: e.full_name ?? '-',
                                value: e.id.toString()
                            }))}
                        />
                    )}
                    control={control}
                    name={PROJECT_SETTINGS_FORM_MODEL.contact_users.name}
                />

                <InputText
                    typeIcon={<CurrencyDollarIcon size={20} />}
                    type="text"
                    label={t(PROJECT_SETTINGS_FORM_MODEL.budget.label)}
                    placeholder={t(PROJECT_SETTINGS_FORM_MODEL.budget.label) satisfies string}
                    {...register(PROJECT_SETTINGS_FORM_MODEL.budget.name)}
                    errorMessage={t(errors.budget?.message ?? '')}
                />
                <Controller
                    name={PROJECT_SETTINGS_FORM_MODEL.priority.name}
                    render={({field: {onChange, value}}) => (
                        <InputText
                            type="text"
                            label={t(PROJECT_SETTINGS_FORM_MODEL.priority.label)}
                            placeholder={t(PROJECT_SETTINGS_FORM_MODEL.priority.placeholder) satisfies string}
                            value={value}
                            onChange={e => {
                                const newValue = e.currentTarget.value
                                if (newValue === '') {
                                    onChange(null)
                                    return
                                }
                                onChange(e.currentTarget.value)
                            }}
                            errorMessage={t(errors.priority?.message ?? '')}
                        />
                    )}
                />

                <TextArea
                    label={t(PROJECT_SETTINGS_FORM_MODEL.objective.label)}
                    rows={4}
                    cols={30}
                    touched={touchedFields.objective}
                    errorMessage={t(errors.objective?.message || '')}
                    placeholder={t(PROJECT_SETTINGS_FORM_MODEL.objective.placeholder).toString()}
                    helpText={t(PROJECT_SETTINGS_FORM_MODEL.objective.helpText)}
                    {...register(t(PROJECT_SETTINGS_FORM_MODEL.objective.name))}
                />
            </Flexbox>
        </StyledFields>
    )
}

ProjectSettingsForm.displayName = 'ProjectSettingsForm'
