import {FC} from 'react'
import {BatchRecord} from '../../types'
import {StyledSidebar} from './style'
import {Flexbox} from '@/components/ui/Flexbox'
import {IconContainer} from '@/components/ui/IconContainer'
import {PlusIcon, Trash01Icon, User01Icon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {FormValues, makeValidationSchema} from './form-model'
import {zodResolver} from '@hookform/resolvers/zod'
import {TeamLeadsSelect} from '@/features/users/components/TeamLeadsSelect'
import InputText from '@/components/commons/InputText'
import {useAssignSubBatch} from '../../queries/useAssignSubBatch'
import toast from 'react-hot-toast'
import {httpAssignSubBatchPayload} from '../../services/batches.http'
import InputHelpText from '@/components/ui/InputHelpText'

type SubBatchedAssignmentSidebarProps = {
    subBatch: BatchRecord
    onClose: () => void
}

export const SubBatchedAssignmentSidebar: FC<SubBatchedAssignmentSidebarProps> = ({subBatch, onClose}) => {
    const {t} = useTranslation()
    const {
        control,
        register,
        watch,
        handleSubmit,
        formState: {errors}
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(makeValidationSchema(subBatch.total_tasks)),
        defaultValues: {
            assignments: []
        }
    })

    const {mutate, isLoading} = useAssignSubBatch({
        onSuccess: () => {
            onClose()
            toast.success(t('commons:genericSuccess'))
        },
        onError: () => {
            toast.error(t('commons:genericError'))
        }
    })

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'assignments'
    })

    const watchedAssignMents = watch('assignments')

    const onSubmit = (values: FormValues) => {
        mutate({subBatchId: subBatch.id, assignments: values.assignments as httpAssignSubBatchPayload['assignments']})
    }

    return (
        <StyledSidebar width={480} onClose={onClose}>
            <Flexbox as="form" width={'100%'} height={'100%'} direction="column" onSubmit={handleSubmit(onSubmit)}>
                <Flexbox direction="column" gap={1} className="title-section" width={'100%'}>
                    <h2>{t('batches:assign_batch')}</h2>
                    <h3>{subBatch.name}</h3>
                </Flexbox>
                {!watchedAssignMents.length ? (
                    <Flexbox
                        direction="column"
                        align="center"
                        justify="start"
                        gap={6}
                        className="empty-state-section"
                        width={'100%'}
                    >
                        <IconContainer>
                            <User01Icon size={24} />
                        </IconContainer>

                        <Flexbox direction="column" justify="center" gap={1}>
                            <p className="empty-state-title">{t('batches:no_team_leads_title')}</p>
                            <p className="empty-state-subtitle">{t('batches:no_team_leads_subtitle')}</p>
                        </Flexbox>

                        <Button variant="secondary" onClick={() => append({user_id: null, percentage: 0})}>
                            <PlusIcon />
                            {t('users:roles:team_lead', {count: 1})}
                        </Button>
                    </Flexbox>
                ) : (
                    <Flexbox direction="column" gap={4} className="form-section" width={'100%'}>
                        <Flexbox gap={1}>
                            <p className="team-lead-column">{t('users:roles:team_lead', {count: 2})}</p>
                            <p className="percentage-column">{t('batches:percentages_of_tasks')}</p>
                            <div className="action-column"></div>
                        </Flexbox>

                        {fields.map((field, index) => (
                            <Flexbox gap={1} key={field.id}>
                                <div className="team-lead-column">
                                    <Controller
                                        control={control}
                                        name={`assignments.${index}.user_id`}
                                        render={({field: {value, onChange}}) => (
                                            <TeamLeadsSelect
                                                value={value}
                                                onChange={onChange}
                                                errorMessage={t(errors.assignments?.[index]?.user_id?.message ?? '')}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="percentage-column">
                                    <InputText
                                        {...register(`assignments.${index}.percentage`)}
                                        typeIcon="%"
                                        type="text"
                                        placeholder={t('commons:percentage') satisfies string}
                                        errorMessage={t(errors.assignments?.[index]?.percentage?.message ?? '')}
                                    />
                                </div>
                                <div className="action-column">
                                    <Button variant="ghostDanger" onClick={() => remove(index)}>
                                        <Trash01Icon size={40} />
                                    </Button>
                                </div>
                            </Flexbox>
                        ))}
                        {errors.assignments?.message && <InputHelpText error={t(errors.assignments?.message)} />}
                        <Button variant="secondary" onClick={() => append({user_id: null, percentage: 0})}>
                            <PlusIcon />
                            {t('users:roles:team_lead', {count: 1})}
                        </Button>
                    </Flexbox>
                )}
                <Flexbox justify="end" gap={3} className="cta-section" width={'100%'}>
                    <Button variant="secondary" onClick={onClose}>
                        {t('commons:close')}
                    </Button>
                    <Button type="submit" disabled={isLoading || !watchedAssignMents.length}>
                        {t('commons:save')}
                    </Button>
                </Flexbox>
            </Flexbox>
        </StyledSidebar>
    )
}
