import {useMutation, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {httpUnassignTasks} from '@/features/tasks/services/tasks.http.ts'

type useDeactivateBatchProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useUnassignOpertors = (options?: useDeactivateBatchProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: httpUnassignTasks,
        onSuccess: () => {
            options?.onSuccess?.()
            queryClient.invalidateQueries([QUERY_KEYS.LATE_OPERATORS])
        }
    })
}
