import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetUser} from '../services/users.http'

type useUserProps = {
    enabled?: boolean
}

export const useUser = (userId: number, options?: useUserProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.USER_BY_ID, userId],
        queryFn: () => httpGetUser(userId),
        ...options
    })
}
