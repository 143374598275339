import {Company, VendorManager} from '@/features/companies/types'
import {BatchRecord} from '../../types'
import {httpGetCompanyVendorById} from '@/features/companies/services/companies.http'
import {useQuery} from '@tanstack/react-query'
import {FC} from 'react'
import {VendorList} from './VendorList'
import {Flexbox} from '@/components/ui/Flexbox'
import {IconContainer} from '@/components/ui/IconContainer'
import {User01Icon} from '@/components/ui/Icon'
import {useTranslation} from 'react-i18next'

const extractVendorsList = (
    batch: BatchRecord
): Promise<Pick<Company, 'id' | 'name' | 'vendor_managers' | 'workload'>[]> => {
    const map = new Map<number, {name: string; vendor_managers: VendorManager[]}>()
    batch.vendor_managers.forEach(vm => {
        const prevValue = map.get(vm.company_id)
        if (!prevValue) {
            map.set(vm.company_id, {name: vm.company.name, vendor_managers: [vm]})
        } else {
            map.set(vm.company_id, {...prevValue, vendor_managers: [...prevValue.vendor_managers, vm]})
        }
    })
    return Promise.all(
        [...map.entries()].map(async ([k, v]) => {
            const vendor = await httpGetCompanyVendorById(k)
            return {
                id: k,
                workload: vendor.workload,
                ...v
            }
        })
    )
}

type AssignedVendorsListProps = {
    batch: BatchRecord
}

export const AssignedVendorsList: FC<AssignedVendorsListProps> = ({batch}) => {
    const {t} = useTranslation()

    const {
        data: vendorList,
        isLoading,
        error
    } = useQuery({
        queryKey: ['extract-vendors'],
        queryFn: () => extractVendorsList(batch),
        refetchOnMount: 'always',
        staleTime: 0
    })

    if (error) {
        //TODO handle error
    }

    if (isLoading) {
        //TODO handle loading
    }

    return vendorList?.length ? (
        <VendorList vendors={vendorList} />
    ) : (
        <Flexbox direction="column" gap={6}>
            {
                <IconContainer>
                    <User01Icon size={24} />
                </IconContainer>
            }
            <Flexbox direction="column" gap={1}>
                <p className="no-vendors-title">{t('batches:no_vendor_managers_title')}</p>
                <p className="no-vendors-subtitle">{t('batches:no_vendor_managers_subtitle')}</p>
            </Flexbox>
        </Flexbox>
    )
}
