import {Flexbox} from '@/components/ui/Flexbox'
import {FC} from 'react'
import {StyledStatCard, StyledStatHeading, StyledStatValue} from '../style'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'

type TasksSuccessChartsProps = {
    total: number
    data: {x: string; y: number}[]
}

export const TasksSuccessCharts: FC<TasksSuccessChartsProps> = ({data, total}) => {
    const theme = useTheme()
    const {t} = useTranslation()
    return (
        <Flexbox width={'100%'} height={'369px'}>
            <StyledStatCard>
                <Flexbox height={'100%'} width={'100%'} direction="column" gap={4}>
                    <StyledStatHeading>{t('stats:tasks_success')}</StyledStatHeading>
                    <StyledStatValue>{t('stats:percentage', {percentage: total})}</StyledStatValue>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart width={500} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            <Legend layout="horizontal" verticalAlign="top" align="center" />
                            <Line
                                type="monotone"
                                name={t('stats:accepted') satisfies string}
                                dataKey="y_accepted"
                                stroke={theme.palette.primary[400]}
                                strokeWidth={2}
                                dot={false}
                            />
                            <Line
                                type="monotone"
                                name={t('stats:rejected') satisfies string}
                                dataKey="y_rejected"
                                stroke={theme.palette.primary[800]}
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Flexbox>
            </StyledStatCard>
        </Flexbox>
    )
}
