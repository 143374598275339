import {DateRangeType} from './constants'

const getTodayNoHours = () => new Date(new Date().setHours(0, 0, 0, 0))

export const dateRangeTypeToDateRange = (dateRangeType: DateRangeType) => {
    const today = getTodayNoHours()

    switch (dateRangeType) {
        case 'this_week': {
            const tempToday = getTodayNoHours()
            const diff = tempToday.getDate() - tempToday.getDay()
            const firstDayOfWeek = new Date(tempToday.setDate(diff))

            return {start_date: firstDayOfWeek, end_date: today}
        }

        case 'this_month': {
            const firstDayOfMonth = getTodayNoHours()
            firstDayOfMonth.setDate(1)

            return {start_date: firstDayOfMonth, end_date: today}
        }
        case 'this_year': {
            const firstDayOfYear = getTodayNoHours()
            firstDayOfYear.setMonth(0)
            firstDayOfYear.setDate(1)

            return {start_date: firstDayOfYear, end_date: today}
        }

        case 'last_week': {
            const currentDate = getTodayNoHours()

            const currentDayOfWeek = currentDate.getDay()

            const daysToSubtract = (currentDayOfWeek + 6) % 7

            const currentMonday = new Date(currentDate)
            currentMonday.setDate(currentDate.getDate() - daysToSubtract)

            const previousMonday = new Date(currentMonday)
            previousMonday.setDate(currentMonday.getDate() - 7)

            const previousSunday = new Date(previousMonday)
            previousSunday.setDate(previousMonday.getDate() + 6)

            return {start_date: previousMonday, end_date: previousSunday}
        }

        case 'last_month': {
            const currentDate = getTodayNoHours()
            const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
            const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth)
            lastDayOfPreviousMonth.setDate(firstDayOfCurrentMonth.getDate() - 1)
            const firstDayOfPreviousMonth = new Date(lastDayOfPreviousMonth)
            firstDayOfPreviousMonth.setDate(1)

            return {start_date: firstDayOfPreviousMonth, end_date: lastDayOfPreviousMonth}
        }

        default: {
            const currentDate = getTodayNoHours()

            const currentDayOfWeek = currentDate.getDay()

            const daysToSubtract = (currentDayOfWeek + 6) % 7

            const currentMonday = new Date(currentDate)
            currentMonday.setDate(currentDate.getDate() - daysToSubtract)

            const previousMonday = new Date(currentMonday)
            previousMonday.setDate(currentMonday.getDate() - 7)

            const previousSunday = new Date(previousMonday)
            previousSunday.setDate(previousMonday.getDate() + 6)

            return {start_date: previousMonday, end_date: previousSunday}
        }
    }
}
