import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetBatchDetails, httpGetSubBatchDetails} from '../services/batches.http'
import {useAuth} from '@context/AuthProvider.tsx'

export const useBatchDetails = (batchId: string) => {
    const {user} = useAuth()
    const canQueryBatch = user?.role.slug === 'program_manager' || user?.role.slug === 'client_engineer'
    return useQuery({
        queryKey: [QUERY_KEYS.BATCH_DETAILS, batchId],
        //todo: pay attention here those check is needed cause we have a distinction between batches and sub-batches
        queryFn: () => (canQueryBatch ? httpGetBatchDetails({id: batchId}) : httpGetSubBatchDetails({id: batchId}))
    })
}
