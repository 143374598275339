import {useTranslation} from 'react-i18next'
import Table from '@components/commons/Table'
import {debounce, formatLocaleDate} from '@utilities/helpers.ts'
import useProductionBatches from '@/features/batches/queries/useProductionBatches.ts'
import {batchBadgeRenderer} from '@utilities/statusesHelpers.tsx'
import {StyledLink} from '@/features/batches/components/BatchesTableViews/style.ts'
import {EmptySearch} from '@components/commons/EmptySearch'
import {BatchesTableEmptyState} from '@/features/batches/components/BatchesTableEmptyState'
import {SearchLgIcon} from '@components/ui/Icon'
import {ChangeEvent} from 'react'
import InputText from '@components/commons/InputText'
import {useProjectBatchesSearchParams} from '../../hooks/useProjectBatchesSearchParams'
import {Flexbox} from '@/components/ui/Flexbox'
import {BatchStatus} from '../../types'
import {BatchesStatusSelect} from '../BatchesStatusSelect'
import {routes} from '@utilities/constants'

export const BatchesTeamLeadProdView = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useProjectBatchesSearchParams()
    const query = useProductionBatches(searchParams)

    const onSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event?.target?.value})
    }, 750)

    return (
        <>
            <Flexbox direction="row" align="center" gap={1}>
                <InputText
                    width="320px"
                    type="text"
                    defaultValue={searchParams.search}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    placeholder={t('batches:search_batch') ?? 'Search a Batch'}
                    onChange={onSearch}
                />
                <BatchesStatusSelect
                    value={(searchParams.status as BatchStatus[]) ?? []}
                    onChange={newStatuses => {
                        setSearchParams({status: newStatuses})
                    }}
                />
            </Flexbox>

            <Table
                columns={[
                    {
                        label: t('batches:table:batch_name'),
                        width: '19%',
                        cellRender: batch => (
                            <StyledLink
                                title={batch?.name}
                                to={`${routes.PRODUCTION_MANAGEMENT.path}/${routes.PRODUCTION_MANAGEMENT_BATCHES.path}/${batch?.id}/${routes.PROD_MANAGEMENT_BATCH_DETAILS_STATS.path}`}
                            >
                                {batch?.name}
                            </StyledLink>
                        )
                    },
                    {
                        label: t('commons:status'),
                        width: '10%',
                        cellRender: project => batchBadgeRenderer(project?.status ?? null, t)
                    },
                    {
                        label: t('batches:table:tasks'),
                        width: '10%',
                        cellRender: batch => (
                            <span>
                                {batch?.completed_tasks}/{batch?.total_tasks}
                            </span>
                        )
                    },

                    {
                        label: t('batches:table:priority'),
                        width: '10%',
                        cellRender: batch => batch?.priority ?? 'None'
                    },
                    {
                        label: t('batches:table:need_date'),
                        width: '17%',
                        sortName: 'need_date',
                        cellRender: batch => (batch?.need_date ? formatLocaleDate(batch.need_date) : '-')
                    },
                    {
                        label: t('batches:table:possible_date'),
                        width: '17%',
                        sortName: 'possible_date',
                        cellRender: batch => (batch?.possible_date ? formatLocaleDate(batch.possible_date) : '-')
                    },
                    {
                        label: t('batches:table:time_budget'),
                        width: '17%',
                        cellRender: batch => (
                            <span>
                                {batch?.used_time ? Math.round(batch?.used_time) : '0'}/
                                {batch?.estimated_time ? Math.round(batch?.estimated_time) : '-'}
                            </span>
                        )
                    }
                ]}
                emptyStateComponent={<BatchesTableEmptyState />}
                emptySearchStateComponent={
                    <EmptySearch
                        onClearSearch={() => setSearchParams({search: '', status: []})}
                        title={t('batches:table:empty_search_state_title')}
                        subtitle={t('batches:table:empty_search_state_subtitle')}
                    />
                }
                searchValue={Object.keys(searchParams).length > 0}
                data={query.remappedData}
                isChangingPage={query.isFetchingNextPage}
                isError={query.isError}
                isLoading={query.isLoading}
                onChangePage={query.fetchNextPage}
            />
        </>
    )
}

BatchesTeamLeadProdView.displayName = 'BatchesTeamLeadProdView'
