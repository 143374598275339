import {Handle, Position} from 'reactflow'
import {TerminalNode, TerminalNodeWrapper} from './style'
import {useState} from 'react'
import {handleStyle} from './constants'
import {AdderButton} from './adder-button'
import {useTranslation} from 'react-i18next'
import {useHasMultipleChildren} from '../../helpers/useHasMultipleChildren'
import {useGraphContext} from '../../context/graphContext'

export const StartNode = () => {
    const {t} = useTranslation()

    const [showControls, setShowControls] = useState(false)
    const [lockAdderButton, setLockAdderButton] = useState(false)

    const {hasMultipleChildren} = useHasMultipleChildren('start')
    const {edges, nodes} = useGraphContext()
    const noNodes = nodes.filter(node => node.type === 'processNode' || node.type === 'tempProcessNode').length === 0
    const childId = edges.find(edge => edge.source === 'start')?.target || 'end'

    return (
        <TerminalNodeWrapper onMouseOver={() => setShowControls(true)} onMouseOut={() => setShowControls(false)}>
            {(showControls || lockAdderButton || noNodes) && !hasMultipleChildren && (
                <AdderButton
                    parentId="start"
                    childId={childId}
                    className="right"
                    mode="series"
                    onPopoverOpenChange={open => {
                        setLockAdderButton(open)
                        if (!open) {
                            setShowControls(false)
                        }
                    }}
                />
            )}

            <TerminalNode>
                {t('projectDetails:process:start')}
                <Handle type="source" position={Position.Right} isConnectable={false} style={handleStyle} />
            </TerminalNode>
        </TerminalNodeWrapper>
    )
}
