import {useTranslation} from 'react-i18next'
import Table from '@components/commons/Table'
import {debounce, formatLocaleDate} from '@utilities/helpers.ts'
import CheckBox from '@components/commons/CheckBox'
import {batchBadgeRenderer} from '@utilities/statusesHelpers.tsx'
import {
    SelectionCounterText,
    StyledBatchCheckbox,
    StyledBatchMainText,
    StyledBatchName,
    StyledBatchSubText
} from '@/features/batches/components/BatchesTableViews/style.ts'
import {EmptySearch} from '@components/commons/EmptySearch'
import useProjectBatches from '@/features/batches/queries/useProjectBatches.ts'
import {
    DotsVerticalIcon,
    Edit01Icon,
    PauseCircleIcon,
    PlayIcon,
    SearchLgIcon,
    User01Icon,
    UserX01Icon
} from '@components/ui/Icon'
import {ChangeEvent, useRef, useState} from 'react'
import {useRecordSelection} from '@hooks/useRecordSelection.ts'
import {BatchRecord, BatchStatus} from '@/features/batches/types.ts'
import InputText from '@components/commons/InputText'
import {BatchesStatusSelect} from '../BatchesStatusSelect'
import Button from '@/components/ui/Button'
import {EmptyDatasourceModal} from '../EmptyDataSourceModal'
import {GenerateBatchesModal} from '../GenerateBatchesModal'
import {BatchesTableEmptyStateProgramManagerView} from '../BatchesTableEmptyState/BatchesTableEmptyStateProgramManagerView'
import {GeneratingBatchesState} from '../GeneratingBatchesState/GeneratingBatchesState'
import {Flexbox} from '@/components/ui/Flexbox'
import {BatchesEditSidebar} from '../BatchesEditSidebar/BatchesEditSidebar'
import {useParams} from 'react-router-dom'
import {useProject} from '@/features/projects/queries/useProject'
import {useProjectBatchesSearchParams} from '../../hooks/useProjectBatchesSearchParams'
import DropdownMenu from '@/components/ui/DropdownMenu'
import {useProjectBatchesStatus} from '../../queries/useProjectBatchesStatus'
import {ProjectBatchesAssignmentModal} from '../BatchesAssignmentModal/ProjectBatchesAssignmentModal'
import {useAuth} from '@/context/AuthProvider'
import {BatchesDeactivateModal} from '../BatchesDeactivateModal'
import {useActivateBatch} from '../../queries/useActivateBatch'
import toast from 'react-hot-toast'
import {BatchAssignmentSidebar} from '../BatchesAssignmentSidebar'
import {useCompany} from '@/features/companies/queries/useCompany'
import {BatchesUnassignModal} from '../BatchesUnassignModal'
import {useMediaCountSocket} from '@/features/batches/hooks/useMediaCountSocket.ts'

export const BatchesProgramManagerProjectView = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const {id} = useParams()
    if (!id) {
        throw new Error('Missing param :id')
    }
    const projectId = Number(id)
    const {searchParams, setSearchParams} = useProjectBatchesSearchParams()
    const query = useProjectBatches(projectId, searchParams)
    const {data: batchesStatus} = useProjectBatchesStatus({id: projectId})
    const projectQuery = useProject(projectId)
    const companyQuery = useCompany(user?.company_id || -1, {enabled: !!user})

    const {mutate: activateBatch} = useActivateBatch({
        onSuccess: () => toast.success(t('commons:genericSuccess')),
        onError: () => toast.error(t('commons:genericError'))
    })

    const {mediaAmount, mediaCountLoading} = useMediaCountSocket({projectId})
    const {selectedItems, selectedItemsToggle, resetSelectedItems} = useRecordSelection<BatchRecord>()
    const [batchToEditId, setBatchToEditId] = useState<number | null>(null)
    const [batchesToAssign, setBatchesToAssign] = useState<BatchRecord | BatchRecord[] | null>(null)
    const [batchesToDeactivateId, setBatchesToDeactivateId] = useState<number | number[] | null>(null)
    const [batchesToUnassignIds, setBatchesToUnassignIds] = useState<number[] | null>(null)
    const [openGenerateBatchesModal, setOpenGenerateBatchesModal] = useState(false)
    const [openBatchAssignmentModal, setOpenBatchAssignmentModal] = useState(false)
    const [openEmptyDatasourceModal, setOpenEmptyDatasourcModal] = useState(false)

    const inputTextRef = useRef<HTMLInputElement>(null)

    const onSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event?.target?.value})
    }, 750)

    const onGenerateBatches = () => {
        if (mediaAmount) {
            setOpenGenerateBatchesModal(true)
        } else {
            setOpenEmptyDatasourcModal(true)
        }
    }

    const isSelectionAssignable = Object.values(selectedItems).every(batch => !batch.vendor_managers.length)
    const isBulkEnabled = Object.values(selectedItems).some(batch => batch.status !== 'inactive')

    const assignment_mode =
        projectQuery.data?.assignment_mode !== 'default'
            ? projectQuery.data?.assignment_mode
            : companyQuery.data?.assignment_mode

    return (
        <>
            {!!batchToEditId && (
                <BatchesEditSidebar
                    projectId={projectId}
                    batchId={batchToEditId}
                    roleSlug="program_manager"
                    onClose={() => setBatchToEditId(null)}
                />
            )}
            {!!batchesToDeactivateId && (
                <BatchesDeactivateModal
                    batchId={batchesToDeactivateId}
                    onClose={() => setBatchesToDeactivateId(null)}
                    onSuccess={resetSelectedItems}
                />
            )}
            {!!batchesToAssign && user && (
                <BatchAssignmentSidebar
                    companyId={user.company_id}
                    batches={batchesToAssign}
                    projectId={projectId}
                    onSuccess={() => {
                        if (batchesToAssign instanceof Array) {
                            resetSelectedItems()
                        }
                    }}
                    onClose={() => setBatchesToAssign(null)}
                />
            )}
            {!!batchesToUnassignIds && (
                <BatchesUnassignModal
                    batchId={batchesToUnassignIds}
                    onClose={() => setBatchesToUnassignIds(null)}
                    onSuccess={resetSelectedItems}
                />
            )}
            {openBatchAssignmentModal && user && (
                <ProjectBatchesAssignmentModal
                    companyId={user.company_id}
                    onClose={() => setOpenBatchAssignmentModal(false)}
                    projectId={projectId}
                />
            )}
            <EmptyDatasourceModal open={openEmptyDatasourceModal} setOpen={setOpenEmptyDatasourcModal} />
            <GenerateBatchesModal
                projectId={projectId}
                open={openGenerateBatchesModal}
                setOpen={setOpenGenerateBatchesModal}
            />
            <Flexbox direction="row" justify="space-between" align="center">
                <Flexbox direction="row" justify="space-between" align="center" gap={1}>
                    <InputText
                        ref={inputTextRef}
                        width="320px"
                        type="text"
                        defaultValue={searchParams.search}
                        typeIcon={<SearchLgIcon size={20} />}
                        inputSize="sm"
                        placeholder={t('batches:search_batch') ?? 'Search a Batch'}
                        onChange={onSearch}
                    />
                    <BatchesStatusSelect
                        value={(searchParams.status as BatchStatus[]) ?? []}
                        onChange={newStatuses => {
                            setSearchParams({status: newStatuses})
                        }}
                    />
                    {!!Object.keys(selectedItems).length && (
                        <>
                            <SelectionCounterText>
                                {t('commons:selected', {selected: Object.keys(selectedItems).length})}.
                            </SelectionCounterText>
                            {assignment_mode === 'manual' && (
                                <>
                                    {isSelectionAssignable ? (
                                        <Button
                                            disabled={!isBulkEnabled}
                                            variant="linkColor"
                                            size="sm"
                                            onClick={() => setBatchesToAssign(Object.values(selectedItems))}
                                        >
                                            <User01Icon size={20} />
                                            {t('batches:assign')}
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={!isBulkEnabled}
                                            variant="linkColor"
                                            size="sm"
                                            onClick={() =>
                                                setBatchesToUnassignIds(
                                                    Object.values(selectedItems).map(batch => batch.id)
                                                )
                                            }
                                        >
                                            <UserX01Icon size={20} />
                                            {t('commons:unassign')}
                                        </Button>
                                    )}
                                </>
                            )}
                            <Button
                                variant="linkColor"
                                size="sm"
                                disabled={!isBulkEnabled}
                                onClick={() =>
                                    setBatchesToDeactivateId(Object.values(selectedItems).map(batch => batch.id))
                                }
                            >
                                <PauseCircleIcon size={20} />
                                {t('batches:deactivate')}
                            </Button>
                        </>
                    )}
                </Flexbox>
                <Flexbox direction="row" justify="space-between" align="center" gap={1}>
                    <Button variant="linkColor" size="md" onClick={() => setOpenBatchAssignmentModal(true)}>
                        {t('projectDetails:batches:batch_assignment')}
                    </Button>
                    <Button
                        disabled={mediaCountLoading || projectQuery.data?.status !== 'paused'}
                        variant="secondary"
                        size="md"
                        onClick={onGenerateBatches}
                    >
                        {t('projectDetails:batches:generate_batches')}
                    </Button>
                </Flexbox>
            </Flexbox>

            {/*batchesStatus && Object.entries(batchesStatus).length*/}
            {projectQuery.data?.status === 'syncing' && batchesStatus && (
                <GeneratingBatchesState
                    projectId={projectId}
                    totalMediaAmount={batchesStatus.totalMediaAmount ?? 0}
                    initialProcessedMedia={batchesStatus.processedMedia ?? 0}
                    totalTasksAmount={batchesStatus.totalTasksAmount ?? 0}
                    initialProcessedTask={batchesStatus.processedTask ?? 0}
                />
            )}

            {projectQuery.data?.status !== 'syncing' && batchesStatus && (
                <Table
                    columns={[
                        {
                            width: '5%',
                            cellRender: batch => (
                                <StyledBatchCheckbox>
                                    {batch?.id && (
                                        <CheckBox
                                            checked={batch.id in selectedItems}
                                            id={`c-${batch.id}`}
                                            onChange={() => selectedItemsToggle(batch)}
                                        />
                                    )}
                                </StyledBatchCheckbox>
                            )
                        },
                        {
                            label: t('batches:table:batch_name'),
                            width: '13%',
                            sortName: 'name',
                            /*cellRender: batch => (
                                <StyledLink
                                    to={`${routes.PROJECTS.path}/${projectId}/${routes.PROJECT_DETAIL_BATCHES.path}/${batch?.id}/${routes.PROJECT_DETAIL_BATCH_STATS.path}`}
                                >
                                    {batch?.name}
                                </StyledLink>
                            )*/
                            cellRender: batch => <StyledBatchName title={batch?.name}>{batch?.name}</StyledBatchName>
                        },
                        {
                            label: t('batches:table:tasks'),
                            width: '10%',
                            cellRender: batch => (
                                <span>
                                    {batch?.completed_tasks}/{batch?.total_tasks}
                                </span>
                            )
                        },
                        {
                            label: t('commons:status'),
                            width: '10%',
                            cellRender: project => batchBadgeRenderer(project?.status ?? null, t)
                        },
                        {
                            label: t('batches:table:priority'),
                            width: '8%',
                            sortName: 'priority',
                            cellRender: batch => batch?.priority ?? 'None'
                        },
                        {
                            label: t('batches:table:vendor_manager'),
                            width: '14%',
                            cellRender: batch => {
                                if (!batch?.vendor_managers.length) {
                                    return t('batches:unassigned')
                                }

                                if (batch.vendor_managers.length === 1) {
                                    return (
                                        <Flexbox direction="column" gap={1}>
                                            <StyledBatchMainText>{`${batch.vendor_managers[0].first_name} ${batch.vendor_managers[0].last_name}`}</StyledBatchMainText>
                                            <StyledBatchSubText>{batch.vendor_managers[0].email}</StyledBatchSubText>
                                        </Flexbox>
                                    )
                                }

                                if (batch.vendor_managers.length > 1) {
                                    const uniqueVendors = [...new Set(batch.vendor_managers.map(vm => vm.company_id))]
                                    return (
                                        <Flexbox direction="column" gap={1}>
                                            <StyledBatchMainText>
                                                {t('batches:managers_count', {
                                                    managers_count: batch.vendor_managers.length
                                                })}
                                            </StyledBatchMainText>
                                            <StyledBatchSubText>
                                                {t('batches:vendors_count', {vendors_count: uniqueVendors.length})}
                                            </StyledBatchSubText>
                                        </Flexbox>
                                    )
                                }

                                return '-'
                            }
                        },
                        {
                            label: t('batches:table:need_date'),
                            width: '13%',
                            sortName: 'need_date',
                            cellRender: batch => (batch?.need_date ? formatLocaleDate(batch.need_date) : '-')
                        },
                        {
                            label: t('batches:table:possible_date'),
                            width: '13%',
                            sortName: 'possible_date',
                            cellRender: batch => (batch?.possible_date ? formatLocaleDate(batch.possible_date) : '-')
                        },
                        {
                            label: t('batches:table:time_budget'),
                            width: '10%',
                            cellRender: batch => (
                                <span>
                                    {batch?.used_time ? Math.round(batch?.used_time) : '0'}/
                                    {batch?.estimated_time ? Math.round(batch?.estimated_time) : '-'}
                                </span>
                            )
                        },
                        {
                            alignment: 'right',
                            width: '4%',
                            cellRender: batch =>
                                batch && (
                                    <DropdownMenu
                                        trigger={<DotsVerticalIcon />}
                                        actions={[
                                            {
                                                component: t('commons:edit'),
                                                icon: <Edit01Icon size={16} />,
                                                onClickCb: () => setBatchToEditId(batch.id)
                                            },
                                            batch.status === 'inactive'
                                                ? {
                                                      component: t('batches:activate'),
                                                      icon: <PlayIcon size={16} />,
                                                      onClickCb: () => activateBatch({batchId: batch.id})
                                                  }
                                                : {
                                                      component: t('batches:deactivate'),
                                                      icon: <PauseCircleIcon size={16} />,
                                                      onClickCb: () => setBatchesToDeactivateId(batch.id)
                                                  },
                                            {
                                                component: t('batches:assign'),
                                                icon: <User01Icon size={16} />,
                                                onClickCb: () => setBatchesToAssign(batch),
                                                disabled: batch.status === 'inactive'
                                            }
                                        ]}
                                    />
                                )
                        }
                    ]}
                    emptyStateComponent={
                        <BatchesTableEmptyStateProgramManagerView
                            onGenerate={onGenerateBatches}
                            isButtonDisabled={mediaCountLoading}
                        />
                    }
                    emptySearchStateComponent={
                        <EmptySearch
                            onClearSearch={() => {
                                if (inputTextRef.current) {
                                    inputTextRef.current.value = ''
                                }
                                setSearchParams({search: '', status: []})
                            }}
                            title={t('batches:table:empty_search_state_title')}
                            subtitle={t('batches:table:empty_search_state_subtitle')}
                        />
                    }
                    searchValue={Object.keys(searchParams).length > 0}
                    data={query.remappedData}
                    isChangingPage={query.isFetchingNextPage}
                    isError={query.isError}
                    isLoading={query.isLoading}
                    onChangePage={query.fetchNextPage}
                    sorter={{
                        orderBy: searchParams.orderBy,
                        orderDirection: searchParams.orderDirection,
                        onSort: setSearchParams
                    }}
                />
            )}
        </>
    )
}

BatchesProgramManagerProjectView.displayName = 'BatchesProgramManagerProjectView'
