import {StyledProjectProcessGrid} from '@pages/project-details/process/style.ts'
import {GraphViewer} from '@/features/graph-viewer/GraphViewer'
import {useParams} from 'react-router-dom'
import {useProcesses} from '@/features/process/queries/useProcesses'
import Spinner from '@/components/ui/Spinner'
import {Edge, GraphNode} from '@/features/graph-viewer/types'
import {useProcessesEdges} from '@/features/process/queries/useProcessesEdges'

export const ProjectProcess = () => {
    const {id} = useParams()

    if (!id) {
        throw new Error('Missing url param :id')
    }

    const {data: processes, isLoading: isLoadingProcess, error: processError} = useProcesses({id: Number(id)})
    const {data: edges, isLoading: isLoadingEdges, error: edgesError} = useProcessesEdges({id: Number(id)})

    const initialNodes: GraphNode[] =
        processes?.map(process => ({
            id: process.id.toString(),
            type: 'processNode',
            position: {x: 0, y: 0},
            dragHandle: 'no-drag',
            data: {
                process_type_id: process.process_type_id,
                is_qa: process.template_values.workflow.task_types_id.length > 1
            }
        })) ?? []

    const initialEdges: Edge[] =
        edges?.map(edge => ({
            id: `${edge.from_process_id}-${edge.to_process_id}`,
            source: edge.from_process_id?.toString() || '',
            target: edge.to_process_id?.toString() || '',
            type: 'wideStep'
        })) ?? []

    const isLoading = isLoadingProcess || isLoadingEdges

    const isSuccess = !!processes && !!edges

    if (processError || edgesError) {
        // todo handle get error
    }

    if (isLoading) {
        //TODO: remove color black
        return <Spinner color="black" size={24} />
    }

    return (
        <StyledProjectProcessGrid>
            {isSuccess && (
                <GraphViewer
                    initialNodes={initialNodes}
                    initialEdges={initialEdges}
                    projectId={Number(id)}
                    processes={processes}
                />
            )}
        </StyledProjectProcessGrid>
    )
}
