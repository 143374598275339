import {useQuery} from '@tanstack/react-query'
import {HttpgGetTasksStatsParams, httpgGetTasksStats} from '../services/http'
import {QUERY_KEYS} from '@/queryClient'

export type UseTasksStatsProps = {
    enabled: boolean
}

export const useTasksStats = (params?: HttpgGetTasksStatsParams, options?: UseTasksStatsProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TASKS_STATS, params],
        queryFn: () => httpgGetTasksStats(params),
        ...options
    })
}
