import {Flexbox} from '@/components/ui/Flexbox'
import {FC} from 'react'
import {StyledStatCard, StyledStatHeading, StyledStatValue, StyledTaskTypeButton} from '../style'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {TASK_TYPES, TaskType} from '../../constants'

type TasksLoadingChartsProps = {
    total: number
    data: {x: string; y: number}[]
    taskType: TaskType
    onTaskTypeChange: (newTaskType: TaskType) => void
}

export const TasksLoadingCharts: FC<TasksLoadingChartsProps> = ({data, total, taskType, onTaskTypeChange}) => {
    const theme = useTheme()
    const {t} = useTranslation()
    return (
        <Flexbox width={'100%'} height={'369px'}>
            <StyledStatCard>
                <Flexbox height={'100%'} width={'100%'} direction="column" gap={4}>
                    <Flexbox justify="space-between" align="center" width={'100%'}>
                        <StyledStatHeading>{t('stats:workforce_loading')}</StyledStatHeading>
                        <Flexbox gap={3}>
                            <StyledTaskTypeButton
                                size="sm"
                                variant="ghost"
                                onClick={() => onTaskTypeChange(TASK_TYPES.ALL)}
                                isSelected={taskType === TASK_TYPES.ALL}
                            >
                                {t('commons:all')}
                            </StyledTaskTypeButton>
                            <StyledTaskTypeButton
                                size="sm"
                                variant="ghost"
                                onClick={() => onTaskTypeChange(TASK_TYPES.EDIT)}
                                isSelected={taskType === TASK_TYPES.EDIT}
                            >
                                {t('commons:edit')}
                            </StyledTaskTypeButton>
                            <StyledTaskTypeButton
                                size="sm"
                                variant="ghost"
                                onClick={() => onTaskTypeChange(TASK_TYPES.QA)}
                                isSelected={taskType === TASK_TYPES.QA}
                            >
                                {t('commons:qa')}
                            </StyledTaskTypeButton>
                        </Flexbox>
                    </Flexbox>
                    <StyledStatValue>{t('stats:tasks', {tasks: total})}</StyledStatValue>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart width={500} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            <Line
                                type="monotone"
                                dataKey="y"
                                stroke={theme.palette.primary[600]}
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Flexbox>
            </StyledStatCard>
        </Flexbox>
    )
}
