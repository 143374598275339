import styled, {css} from 'styled-components'

export const GraphViewerWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .react-flow {
            z-index: 1 !important;
        }

        .graph-container {
            flex-grow: 1;
        }

        .cta-container {
            padding-top: ${spacing * 4}px;
        }
    `}
`
