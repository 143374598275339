import {z} from 'zod'
import {Pagination} from '@/types/commons.ts'
import {User} from '@/types/auth.ts'
import {Company} from '../companies/types'
import {BatchAssignmentMethod} from '../batches/types'

export type ProjectStatus = 'draft' | 'syncing' | 'paused' | 'in_progress' | 'inactive' | 'completed'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TemplateValues = any

export type ProjectRecord = {
    id: number
    company_id: number | null
    assignment_mode: BatchAssignmentMethod
    managed_by_user_id: number | null
    is_draft: null
    budget: number
    name: string | null
    objective: string | null
    data_source_id: number | null
    data_target_id: number | null
    taxonomy_id: number | null
    workflow_id: number | null
    need_date: string | null
    possible_date: string | null
    priority: number | null
    created_at: string
    updated_at: string | null
    deleted_at: string | null
    status: ProjectStatus | null
    workstream_id: number | null
    template_id: number | null
    template_values?: TemplateValues | null
    contact_users: User[] | null
    data_services: []
    vendors: Company[] | null
    vendor_managers: string[] | null
    batches_amount: number | null
    last_ending_batch: string | null
    //TODO this values should not be here but in the subproject, but now with a single query (useProjects/useProject) TS cannot understand if we are fetching a Project or a Subproject
    revoked?: boolean
    revoked_at?: string
    project_id?: number
}

export type SubProjectRecord = ProjectRecord & {
    revoked: boolean
    revoked_at: string
    project_id: number
}

export type ProjectStructureRenderType = 'data_source' | 'data_target' | 'text' | 'select' | 'data_service' | 'taxonomy'
export type ProjectStructureType = 'taxonomy' | 'data_target' | 'data_source' | 'number' | 'string'

export type ProjectStructure = Readonly<{
    readonly [key: string]: {
        helpText: string
        label: string
        placeholder: string
        is_required: boolean
        rendered_type: ProjectStructureRenderType
        type: ProjectStructureType
    }
}>

export interface GetProjectsResponse {
    data: ProjectRecord[]
    pagination: Pagination
}

export interface GetSubProjectsResponse {
    data: SubProjectRecord[]
    pagination: Pagination
}

export const ProjectsSearchParams = z.object({
    orderBy: z.enum(['name', 'priority', 'batches_amount', 'last_ending_batch', 'created_at']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type ProjectsSearchParams = z.infer<typeof ProjectsSearchParams>
