import styled, {css} from 'styled-components'

export const TaxonomiesSelectWrapper = styled.div<{showInstructions?: boolean}>`
    ${({theme: {spacing, palette, typography}, showInstructions}) => css`
        width: 100%;
        display: flex;
        flex-direction: column;

        p {
            margin-bottom: ${spacing * 2}px;
        }

        .dots-icon {
            margin-top: ${spacing * 2.5}px;
        }

        .select {
            margin-bottom: ${showInstructions ? spacing * 4 : 0}px;
        }

        .label-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .instruction-container {
            display: flex;
            flex-direction: row;
            gap: ${spacing * 2}px;

            .main-icon-container {
                display: flex;
                align-items: start;
                color: ${palette.neutral[500]};
            }

            .info-container {
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: ${spacing}px;
                flex-grow: 1;

                p,
                a {
                    margin: 0;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .name {
                    font-size: ${typography.textSm};
                    font-weight: 500;
                    color: ${palette.neutral[900]};
                }

                a {
                    cursor: pointer;
                    font-size: ${typography.textXs};
                    font-weight: 400;
                    color: ${palette.neutral[600]};
                    text-decoration: underline;
                }
            }

            .copy-icon-container {
                display: flex;
                align-items: end;
            }
        }
    `}
`
