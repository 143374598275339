import {z} from 'zod'
import {floatNumberRegex} from '@utilities/regex.ts'

export const VENDOR_ADMIN_FORM_MODEL = {
    name: {
        name: 'name',
        label: 'users:vendor_name',
        defaultValue: '',
        helpText: '',
        placeholder: 'settings:vendor_admin:vendor_name_placeholder'
    },
    unit_capacity: {
        name: 'unit_capacity',
        label: 'settings:vendor_admin:unit_capacity',
        defaultValue: 0,
        helpText: '',
        placeholder: 'settings:vendor_admin:unit_capacity_placeholder'
    },
    cost_unit: {
        name: 'cost_unit',
        label: 'settings:vendor_admin:cost_per_1k_units',
        defaultValue: 0,
        helpText: '',
        placeholder: 'settings:vendor_admin:cost_per_1k_units_placeholder'
    }
} as const

export const VendorAdminFromSchema = z.object({
    name: z.string().min(1, {message: 'settings:vendor_admin:vendor_name_error'}),
    unit_capacity: z
        .string()
        .min(1, {message: 'settings:vendor_admin:unit_capacity_error'})
        .refine(value => floatNumberRegex.test(value.toString()), 'errors:number'),
    cost_unit: z
        .string()
        .min(1, {message: 'settings:vendor_admin:cost_per_1k_units_error'})
        .refine(value => floatNumberRegex.test(value.toString()), 'errors:number')
})

export type VendorAdminValidationSchema = z.infer<typeof VendorAdminFromSchema>
