import {RedirectBox, RedirectWrapper} from '@pages/sso-redirect/style.ts'
import Spinner from '@components/ui/Spinner'
import Container from '@components/ui/Container'
import {useTheme} from 'styled-components'

export const LoadingScreen = () => {
    const theme = useTheme()

    return (
        <Container>
            <RedirectWrapper>
                <RedirectBox>
                    <Spinner size={48} color={theme.palette.neutral['500']} />
                </RedirectBox>
            </RedirectWrapper>
        </Container>
    )
}

LoadingScreen.displayName = 'LoadingScreen'
