import {useCallback} from 'react'
import {useGraphContext} from '../context/graphContext'
import {getLayoutedElements} from '../auto-layout/auto-layout'
import {Edge, ProcessNodeData} from '../types'

type addNodeProps = {
    parentId?: string
    childId?: string
    data: ProcessNodeData
}

export const useAddNode = () => {
    const {nodes, edges, setNodes, setEdges} = useGraphContext()

    const addNode = useCallback(
        ({parentId, childId, data}: addNodeProps) => {
            if (!parentId || !childId) {
                throw new Error('Cannot add a node without knowing its parent and its child')
            }

            const newId = 'new-node'

            const newNode = {
                id: newId.toString(),
                data,
                type: 'tempProcessNode' as const,
                position: {x: 0, y: 0},
                dragHandle: 'no-drag'
            }

            const parentEdge = {
                id: `e${parentId}-${newId}`,
                source: parentId,
                target: newId.toString(),
                type: 'wideStep'
            }

            const childEdge = {
                id: `e${newId}-${childId}`,
                source: newId.toString(),
                target: childId,
                type: 'wideStep'
            }

            let updatedEdges: Edge[] = []
            if (parentId === 'start' && childId === 'end') {
                updatedEdges = [...edges, parentEdge, childEdge]
            } else if (parentId === 'start') {
                const idx = edges.findIndex(edge => edge.source === 'start' && edge.target === childId)
                updatedEdges = [...edges]
                updatedEdges.splice(idx, 0, parentEdge)
                updatedEdges.splice(idx + 1, 0, childEdge)
            } else {
                const idx = edges.findIndex(edge => edge.target === parentId)
                updatedEdges = [...edges]
                updatedEdges.splice(idx, 0, parentEdge)
                updatedEdges.splice(idx + 1, 0, childEdge)
            }

            const filteredEdges = updatedEdges.filter(edge => edge.source !== parentId || edge.target !== childId)

            const updatedNodes = [...nodes, newNode]

            const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(updatedNodes, filteredEdges)

            setNodes(layoutedNodes)
            setEdges(layoutedEdges as Edge[])

            const layoutedNewNode = layoutedNodes.find(node => node.id === newId.toString()) ?? newNode

            return layoutedNewNode
        },
        [nodes, edges, setNodes, setEdges]
    )

    return {addNode}
}
