import {Outlet} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'
import React from 'react'
import BreadCrumbs from '@components/commons/BreadCrumbs'
import Container from '@components/ui/Container'
import {Header} from '@components/commons/Header'
import {useAuth} from '@context/AuthProvider.tsx'
import {headerItemsBySlug} from '@/features/roles/helpers.tsx'

type PrivateLayoutProps = {
    withHeader?: boolean
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({withHeader}) => {
    const {user} = useAuth()

    return (
        <StyledPrivateLayout>
            {withHeader && <Header items={user ? headerItemsBySlug(user?.role.slug) : []} />}
            <StyledMain>
                <Container fullHeight={false}>
                    <BreadCrumbs />
                </Container>
                <Outlet />
            </StyledMain>
        </StyledPrivateLayout>
    )
}

export default PrivateLayout
