import {floatNumberRegex} from '@/utilities/regex'
import {z} from 'zod'

export const PROJECT_SETTINGS_FORM_MODEL = {
    project_name: {
        name: 'name',
        label: 'projectDetails:settings:form:name',
        placeholder: 'projectDetails:settings:form:name',
        defaultValue: [],
        helpText: ''
    },
    workstream_id: {
        name: 'workstream_id',
        label: 'projectDetails:settings:form:workstream',
        placeholder: 'projectDetails:settings:form:workstream_placeholder',
        defaultValue: [],
        helpText: ''
    },
    template_id: {
        name: 'template_id',
        label: 'projectDetails:settings:form:template',
        placeholder: 'projectDetails:settings:form:template_placeholder',
        defaultValue: [],
        helpText: ''
    },
    budget: {
        name: 'budget',
        label: 'projectDetails:settings:form:budget',
        placeholder: 'projectDetails:settings:form:budget',
        defaultValue: null,
        helpText: ''
    },
    objective: {
        name: 'objective',
        label: 'projectDetails:settings:form:objectives',
        placeholder: 'projectDetails:settings:form:objectives_placeholder',
        defaultValue: [],
        helpText: ''
    },
    priority: {
        name: 'priority',
        label: 'projectDetails:settings:form:priority',
        placeholder: 'projectDetails:settings:form:priority_placeholder',
        defaultValue: [],
        helpText: ''
    },
    contact_users: {
        name: 'contact_users',
        label: 'projectDetails:settings:form:partner_team',
        placeholder: 'projectDetails:settings:form:partner_team_placeholder',
        defaultValue: [],
        helpText: ''
    }
} as const

export const ProjectSettingsFormSchema = z.object({
    name: z.string().min(1, {message: 'projectDetails:settings:form:name_error'}),
    workstream_id: z.object({label: z.string(), value: z.string()}),
    template_id: z.number({
        required_error: 'errors:required',
        invalid_type_error: 'errors:required'
    }),
    budget: z.coerce
        .number({
            required_error: 'errors:number',
            invalid_type_error: 'errors:number'
        })
        .min(1, {message: 'errors:positiveNumber'})
        .refine(value => floatNumberRegex.test(value.toString()), 'errors:number'),
    contact_users: z.array(z.object({label: z.string(), value: z.string()})).optional(),
    //.min(1, {message: 'projectDetails:settings:form:partner_team_error'}),
    objective: z.string().optional(),
    priority: z.coerce
        .number({
            invalid_type_error: 'errors:number',
            required_error: 'errors:number'
        })
        .int('errors:number')
        .gte(0, 'errors:positiveNumber')
        .refine(prio => /^[0-9]+$/.test(prio.toString()), 'errors:number')
        .nullable()
        .optional()
})

export type ProjectSettingsValidationSchema = z.infer<typeof ProjectSettingsFormSchema>
