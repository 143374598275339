import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {httpDeleteUser} from '@/features/users/services/users.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

interface UseDeleteUserProps {
    onSuccess: () => void
    roleID: number
}

export const useDeleteUser = ({onSuccess, roleID}: UseDeleteUserProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_USER],
        mutationFn: httpDeleteUser,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.USERS, roleID]})
            onSuccess()
        },
        onError: errorHandler
    })
}
