import {spacing} from '@/theme'
import styled, {css} from 'styled-components'

export const BatchesStatusSelectWrapper = styled.div<{isActive: boolean}>`
    ${({theme: {palette, typography}, isActive}) => css`
        .trigger {
            gap: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${palette.primary[700]};
            font-weight: 500;
            font-size: ${typography.textMd};
            transition: all 0.3s ease-out;
            padding: ${spacing * 2}px ${spacing * 4}px;
            border: 1px solid transparent;
            border-radius: 8px;

            &:hover {
                background-color: ${palette.primary[50]};
                border: 1px solid ${palette.primary[50]};
            }

            ${isActive &&
            css`
                background-color: ${palette.primary[50]};
                border: 1px solid ${palette.primary[200]};
            `}

            .chevron-down {
                display: grid;
                place-items: center;
                transition: all 0.3s ease-out;
            }

            &[data-state='open'] {
                .chevron-down {
                    transform: rotate(180deg);
                }
            }

            &[data-state='closed'] {
                .chevron-down {
                    transform: rotate(0);
                }
            }
        }
    `}
`

export const SyledPopoverContent = styled.div`
    ${({theme: {shadows, spacing, palette}}) => css`
        border-radius: 8px;
        border: 1px solid ${palette.neutral[200]};
        box-shadow: ${shadows.lg};
        min-width: 176px;
        padding: ${spacing * 1.5}px;

        .status-item {
            display: flex;
            align-items: center;
            gap: ${spacing * 2}px;
            padding: ${spacing * 2.5}px;
        }
    `}
`
