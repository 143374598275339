import {Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useCallback, useContext, useState} from 'react'
import {useEdgesState, useNodesState} from 'reactflow'

import {getLayoutedElements} from '../auto-layout/auto-layout'
import {Edge, GraphNode} from '../types'
import {UnsavedBlocker} from '@/components/commons/UnsavedBlocker'

type GraphContextType = {
    nodes: GraphNode[]
    setNodes: Dispatch<SetStateAction<GraphNode[]>>
    edges: Edge[]
    setEdges: Dispatch<SetStateAction<Edge[]>>
    onNodesChange: ReturnType<typeof useNodesState>[2]
    onEdgesChange: ReturnType<typeof useEdgesState>[2]
    setIsDirty: Dispatch<SetStateAction<boolean>>
}

const GraphContext = createContext<GraphContextType | null>(null)

type GraphProviderProps = {
    initialNodes: GraphNode[]
    initialEdges: Edge[]
}

const terminalNodes = [
    {
        id: 'start',
        type: 'startNode',
        position: {x: 0, y: 0},
        data: null,
        dragHandle: 'no-drag'
    },
    {
        id: 'end',
        type: 'endNode',
        position: {x: 0, y: 0},
        data: null,
        dragHandle: 'no-drag'
    }
] as const

const getInitialEdges = ({initialNodes, initialEdges}: {initialNodes: GraphNode[]; initialEdges: Edge[]}) => {
    if (!initialNodes.length) {
        return [{id: 'start-end', source: 'start', target: 'end', type: 'wideStep'}]
    }

    const resultingEdges = [...initialEdges]

    const nodesWithoutParent = initialNodes
        .filter(node => {
            return !initialEdges.find(edge => edge.target === node.id)
        })
        .map(node => node.id)

    const nodesWithoutChildren = initialNodes
        .filter(node => {
            return !initialEdges.find(edge => edge.source === node.id)
        })
        .map(node => node.id)

    nodesWithoutParent.forEach(nodeId => {
        const idx = resultingEdges.findIndex(edge => edge.source === nodeId)

        resultingEdges.splice(idx, 0, {id: `start-${nodeId}`, source: 'start', target: nodeId, type: 'wideStep'})
    })

    nodesWithoutChildren.forEach(nodeId => {
        const idx = resultingEdges.findIndex(edge => edge.target === nodeId)

        resultingEdges.splice(idx + 1, 0, {id: `${nodeId}-end`, source: nodeId, target: 'end', type: 'wideStep'})
    })

    return resultingEdges
}

export const GraphProvider: FC<PropsWithChildren & GraphProviderProps> = ({children, initialNodes, initialEdges}) => {
    const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(
        [...terminalNodes, ...initialNodes],
        getInitialEdges({initialEdges, initialNodes})
    )

    const [isDirty, setIsDirty] = useState(false)

    const [nodes, _setNodes, onNodesChange] = useNodesState(layoutedNodes)
    const [edges, _setEdges, onEdgesChange] = useEdgesState(layoutedEdges)

    const setNodes = useCallback(
        (arg: Parameters<typeof _setNodes>[0]) => {
            setIsDirty(true)
            _setNodes(arg)
        },
        [_setNodes]
    )

    const setEdges = useCallback(
        (arg: Parameters<typeof _setEdges>[0]) => {
            setIsDirty(true)
            _setEdges(arg)
        },
        [_setEdges]
    )

    return (
        <UnsavedBlocker isDirty={isDirty} ignoreSearchParams={true}>
            <GraphContext.Provider
                value={{
                    nodes: nodes as GraphNode[],
                    setNodes: setNodes as Dispatch<SetStateAction<GraphNode[]>>,
                    onNodesChange,
                    edges,
                    setEdges,
                    onEdgesChange,
                    setIsDirty
                }}
            >
                {children}
            </GraphContext.Provider>
        </UnsavedBlocker>
    )
}

export const useGraphContext = () => {
    const ctx = useContext(GraphContext)
    if (!ctx) {
        throw new Error('Graph context must be used within provider')
    }

    return ctx
}
